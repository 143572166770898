<div class="p-4">
    <div class="-mx-4">
        <stable *ngIf="discoveryTableOptions && discoveryTableOptions.tableOptions && discoveryTableOptions.tableOptions.serverSide" aria-label="Discovery Settings" role="table"
            (addCallback)="addTableData()" (actionCallback)="discactionCall($event)"
            [sTableOptions]="discoveryTableOptions" [loadtable]="loadtable">
        </stable>
    </div>
    <div class="w-full flex flex-row h-1">&nbsp;</div>
    <credentials></credentials>
</div>
<s-modal id="newDiscoverySetting">
    <div class="mx-auto w-full flex max-w-lg flex-col md:max-w-none md:flex-row">
        <div class="w-1/3 h-[75vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
            <p class="mb-10 font-bold tracking-wider">DISCOVERY SETTINGS</p>
            <p class="mb-4 text-2xl font-bold md:text-2xl md:leading-snug"> Update your <br> network scope here, </p>
            <p class="mb-4 text-sm text-gray-200">Customize your vulnerability scanning scope by inputting network
                details,
                IP ranges, CIDR ranges, Static IPs, and Domains in the discovery settings.
                This tailored configuration ensures accurate security assessments.</p>
            <div class="bg-blue-600/80 rounded-2xl p-3">
                <p class="mb-3 text-sm text-gray-200">
                    Discovery settings in vulnerability scanning are a fundamental component of the scanning process,
                    aimed at identifying and assessing potential security vulnerabilities across an organization's
                    digital assets.
                </p>
            </div>
        </div>
        <div class="w-2/3 h-[75vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
            <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
                matTooltip="Close" (click)="modalService.close('newDiscoverySetting');">
                <mat-icon>close</mat-icon>
            </button>
            <h2 class="mb-4 text-3xl font-bold">Discovery Settings</h2>
            <form *ngIf="credForm" class="flex flex-col m-2 px-2 overflow-hidden" [formGroup]="credForm">
                <div class="flex mt-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Name</mat-label>
                        <input matInput [placeholder]="'Enter Name'" [formControlName]="'name'" [spellcheck]="false">
                    </mat-form-field>
                </div>
                <div class="flex mt-4">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Address Type</mat-label>
                        <mat-select (selectionChange)="credForm.get('address').setValue('')"
                            [formControlName]="'address_type'">
                            <ng-container *ngFor="let data of addressTypes">
                                <mat-option [value]="data.value"> {{data.label}} </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                <ng-container *ngIf="credForm.get('address_type').value === 'STATICIP'">
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>IP Address</mat-label>
                            <input matInput [placeholder]="'Enter IP'" [formControlName]="'address'">
                            <mat-hint>Example: 192.168.0.0</mat-hint>
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="credForm.get('address_type').value === 'IPRANGE'">
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>IP Range</mat-label>
                            <input matInput [placeholder]="'Enter IP Range'" [formControlName]="'address'">
                            <mat-hint>Enter IP in range like 191.168.2.1-192.168.2.100</mat-hint>
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="credForm.get('address_type').value === 'CIDR'">
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>CIDR</mat-label>
                            <input matInput [placeholder]="'Enter CIDR'" [formControlName]="'address'">
                            <mat-hint>Example: 10.12.23.14/31 (/value in range /31-/1)</mat-hint>
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="credForm.get('address_type').value === 'DOMAIN'">
                    <div class="flex mt-4">
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Domain</mat-label>
                            <input matInput [placeholder]="'Enter Domain'" [formControlName]="'address'">
                            <mat-hint>Example: xyz.com</mat-hint>
                        </mat-form-field>
                    </div>
                </ng-container>
                <div
                    class="flex items-center mt-10 -mx-6 sm:-mx-12 py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t dark:bg-transparent">
                    <!-- Cancel -->
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
                        (click)="modalService.close('newDiscoverySetting');">
                        Cancel
                    </button>
                    <!-- Save -->
                    <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick
                        [disabled]="!credForm.valid" [matTooltip]="'Save'" (click)="save()">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</s-modal>


<!-- <app-company-onboarding></app-company-onboarding> -->

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from "@angular/material/paginator";
import { QueryBuilderComponent, QueryBuilderModule } from "@syncfusion/ej2-angular-querybuilder";
import { GridModule } from "@syncfusion/ej2-angular-grids";
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';

@Component({
    selector: 'filter-widget',
    standalone: true,
    imports: [CommonModule, MatPaginatorModule, GridModule, QueryBuilderModule, CheckBoxModule, DropDownListModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './filter-widget.component.html',
    styleUrls: ['./filter-widget.component.scss']
})

export class FilterWidgetComponent implements AfterViewInit {
    @ViewChild('querybuilder') public qryBldrObj?: QueryBuilderComponent;
    @Input() showFilter: boolean = false;
    @Input() sColumns: any = [];
    @Output() setRulesFn = new EventEmitter();
    @Output() resetRulesFn = new EventEmitter();
    @Output() toggleFilterFn = new EventEmitter();
    @Input() filterQuery = '';

    public customOperators?: any;

    constructor(private _changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.customOperators = [
            { value: 'equal', key: 'Equal' },
            { value: 'notequal', key: 'Not Equal' }
        ];
    }
    ngAfterViewInit(): void {
        this._changeDetectorRef.markForCheck();
    }

    toggleFilter(): void {
        this.showFilter = !this.showFilter;
        if (!this.showFilter) {
            this.filterQuery = '';
            this.toggleFilterFn.emit({ query: this.filterQuery });
        }
    }

    setRules(): void {
        this.filterQuery = this.qryBldrObj!.getSqlFromRules(
            this.qryBldrObj!.getRules()
        );
        if (this.filterQuery) {
            this.setRulesFn.emit({ query: this.filterQuery });
        }
    }

    resetRules(): void {
        this.qryBldrObj!.reset();
        this.filterQuery = '';
        this.setRulesFn.emit({ query: '' });
    }

    filterChange(e: any, ruleID: string, values: any): void {
        const valueHash: any = {}; values.map((x: any) => valueHash[x.name] = x.value);
        let elem: HTMLElement = document.getElementById(ruleID)!.querySelector('.e-rule-value') as HTMLElement;
        this.qryBldrObj!.notifyChange(valueHash[e.value] as string, elem, 'value');
    }

    checkboxChange(e: any, ruleID: string): void {
        let elem: HTMLElement = document.getElementById(ruleID)!.querySelector('.e-rule-value') as HTMLElement;
        this.qryBldrObj!.notifyChange(e.checked as boolean, elem, 'value');
    }

    getDataSourceValues($event: any): any {
        return $event.map((x: any) => x.name);
    }
}

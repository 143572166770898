import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FuseAlertComponent } from '@fuse/components/alert';
import { ConfirmDialogService } from 'app/_services/confirmdialog.service';
import { MaterialModule } from 'app/material.module';

@Component({
  selector: 'app-manage-agent-version',
  templateUrl: './manage-agent-version.component.html',
  standalone: true,
  imports: [CommonModule, FuseAlertComponent, FormsModule, NgIf, NgFor, MaterialModule],
  styleUrls: ['./manage-agent-version.component.scss']
})
export class ManageAgentVersionComponent {
  @Output() checkBoxCallback = new EventEmitter();
  isMultiple = true;
  initSelectedValues = [];
  selection = new SelectionModel<any>(this.isMultiple, this.initSelectedValues);
  numSelected!: number;
  selectedCompanies: any = [];
  selectedVersion : any ;
  allCompanies: any = {
    "data": [
        {
            "c": "2023-11-23T07:22:48.447606",
            "name": "!!!!!!!!!!!!!!!All",
            "_id": "d1737726-3ebd-42d5-adc3-a5f06cc05824"
        },
        {
            "c": "2023-11-22T12:17:01.238101",
            "name": "!!!!!!all pii test",
            "_id": "afc2d1ba-521c-4d4f-a7c4-0914653973ca"
        },
        {
            "c": "2023-12-04T06:32:33.812163",
            "name": "!!!!!!aye",
            "_id": "f96f1df9-ed4c-45dc-9e21-afed5d789fcf"
        },
        {
            "c": "2023-11-04T05:28:03.965448",
            "name": "!!!!!Agent",
            "_id": "453afcc8-77bb-4baf-b247-c6b54158f684"
        },
        {
            "c": "2023-11-22T11:54:52.106702",
            "name": "!!!!*Touch Me Not*!!!!",
            "_id": "4c5341b0-9d3d-4df9-bcab-7c6e8e6905a7"
        },
        {
            "c": "2023-12-08T04:25:34.399077",
            "name": "!!!ACWTEST",
            "_id": "ac747f01-af21-455c-adf4-6cae99c74e8b"
        },
    ],
    "total": 830,
    "count": 830
};
  agentVersions: any = [
    {
        "present_version": "2.1.9",
        "agent_versions": [
            "2.0.100",
            "2.0.101",
            "2.0.102",
            "2.0.103",
            "2.0.55",
            "2.0.56",
            "2.0.57",
            "2.0.58",
            "2.0.59",
            "2.0.60",
            "2.0.61",
            "2.0.62",
            "2.0.63",
            "2.0.64",
            "2.0.65",
            "2.0.66",
            "2.0.67",
            "2.0.68",
            "2.0.69",
            "2.0.70",
            "2.0.71",
            "2.0.72",
            "2.0.73",
            "2.0.74",
            "2.0.75",
            "2.0.76",
            "2.0.77",
            "2.0.78",
            "2.0.79",
            "2.0.80",
            "2.0.81",
            "2.0.82",
            "2.0.83",
            "2.0.84",
            "2.0.85",
            "2.0.86",
            "2.0.87",
            "2.0.88",
            "2.0.89",
            "2.0.90",
            "2.0.91",
            "2.0.92",
            "2.0.93",
            "2.0.94",
            "2.0.95",
            "2.0.96",
            "2.0.97",
            "2.0.98",
            "2.0.99",
            "2.1.4",
            "2.1.5",
            "2.1.6",
            "2.1.7",
            "2.1.8",
            "2.1.9"]
        }
  ];
  constructor(public confirmDialog: ConfirmDialogService){

}
 
  clearSelection(): void {
    this.selection.clear();
    // @ts-ignore
    this.numSelected = undefined;
  }

  isAllSelected(): any {
    this.numSelected = this.selection.selected.length;
    const numRows = this.allCompanies.data.length;
    console.log("numSelected---->",this.numSelected);
    return this.numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.allCompanies.data.forEach(row => this.selection.select(row));
      console.log("selected companies--->",this.allCompanies.data);
  }

  rowToggle(row: any): void {
    this.selection.toggle(row);
    console.log("row--->",this.selection);
  }

  updateAgentVersion(company?: any): void {
    console.log("company selected---->",company);
    const titleName = 'Confirmation';
    const message = (company)
        ? `Are you sure you want to update, ${company.name} agent version from  ${company.agent_version} to ${this.selectedVersion}?`
        : `Are you sure you want to update, all the selected company(ies) agent version to ${this.selectedVersion}?`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      // if (res) {
      //   const companies: any = [];
      //   if (company) {
      //     company.agent_version =  this.agentVersion;
      //     companies.push(company);
      //   } else {
      //     this.selectedCompanies.forEach((obj: any) => {
      //       obj.agent_version =  this.agentVersion;
      //       companies.push(obj);
      //     });
      //   }
      //   this.loaderService.display(true);
      //   this.baseService.doRequest(`/api/manageagentupdates/companymanageagentupdates`,
      //       'post', companies).subscribe((result: any) => {
      //         this.loaderService.display(false);
      //         if (result[0]) {
      //           this.toast.sToast('success', 'Updated successfully');
      //           if (this.cmpSelected) {
      //             this.closeVersionModal();
      //           }
      //           setTimeout(() => {
      //             this.getCompany();
      //           }, 3000);
      //         } else {
      //          this.toast.sToast('error', result[1]);
      //         }
      //   });
      // }
    });
}
}

import { SelectionModel } from '@angular/cdk/collections';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatMenuTrigger } from '@angular/material/menu';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Router, RouterLink } from '@angular/router';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyToastrService } from 'app/_services/toastr.service';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'app/_services/common.service';
import { MatDrawer } from '@angular/material/sidenav';
import { CompanySharedService } from 'app/_services/company-shared.service';
import { CommonModule, DatePipe, NgFor, NgIf, NgOptimizedImage } from '@angular/common';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { DynamicComponent } from 'ng-dynamic-component';
import { MaterialModule } from 'app/material.module';
import { PaginatorWidgetComponent } from "../paginator-widget/paginator-widget.component";
import { ChangeDetectorRef } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { TranslocoModule } from '@ngneat/transloco';
import { FilterWidgetComponent } from "../filter-widget/filter-widget.component";
import { FuseScrollbarDirective } from "../../../../@fuse/directives/scrollbar";
import { FuseConfirmationService } from "../../../../@fuse/services/confirmation";
import { FilterComponent } from '../filter/filter.component';
import { fuseAnimations } from '@fuse/animations';
import { FormatCellPipe } from "../../../_filters/app.filter.pipe";
import { DomSanitizer } from "@angular/platform-browser";
@Component({
    selector: 'stable',
    standalone: true,
    animations: fuseAnimations,
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    imports: [
        CommonModule,
        TranslocoModule,
        AppFilterPipeModule,
        ReactiveFormsModule,
        FormsModule,
        DynamicComponent,
        MaterialModule,
        RouterLink,
        PaginatorWidgetComponent,
        FilterWidgetComponent,
        FuseScrollbarDirective,
        FilterComponent,
        NgOptimizedImage
    ],
    providers: [NgIf, NgFor],
})
export class TableComponent
    implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    showAllFlag: boolean = false
    sortOptions: any = '';
    @ViewChild(MatSort, { static: false })
    set sort(value: MatSort) {
        this.dataSource.sort = value;
    }
    @ViewChild('drawer') drawer: MatDrawer;
    drawerMode: 'over' | 'side' = 'over';
    drawerOpened: boolean = false;
    showFilter: boolean = false;
    showTagFilter: boolean = false;
    sColumns: any = [];
    private tags: Subscription;
    private subs: Subscription;
    private change: Subscription;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private activeRoute: Router,
        private loaderService: LoaderService,
        private commonService: CommonService,
        public baseService: BaseRequestService,
        private eRef: ElementRef,
        public toast: MyToastrService,
        public cs: CompanySharedService,
        public cdr: ChangeDetectorRef,
        public confirmDialog: FuseConfirmationService,
        private formatCell: FormatCellPipe,
    ) {
        /*this.loaderService.selectedSiteChanged.subscribe(() => {
            localStorage.removeItem(this.tableOptions.id);
            this.filterText = '';
        });*/
        this.loaderService.tableProgressOff.subscribe(() => {
            this.tableOptions.loading = false;
            this.cdr.detectChanges()
        });
        // Debounce search.
        this.filterUpdate
            .pipe(debounceTime(1500), distinctUntilChanged())
            .subscribe((value) => {
                this.isActionChanged = true;
                this.doFilter(value);
                this.cdr.markForCheck();
            });
        this.colfilterUpdate
            .pipe(debounceTime(1500), distinctUntilChanged())
            .subscribe((value) => {
                this.doColumFilter(value.value, value.col);
            });
        this.subs = this.commonService.selectedSiteChanged.subscribe((company: any) => {
            this.sTableOptions.columns.forEach((obj: any) => {
                obj.cType = (obj.cType) ? obj.cType : 'string';
                const company = ['company_count', 'company_name', 'affected_companies', 'companies', 'company_id'];
                if ((company.indexOf(obj.columnDef) !== -1)) {
                    obj.visible = true;
                    if (this.commonService.currentScope !== '*') {
                        obj.isNoVisible = true;
                    } else {
                        obj.isNoVisible = false;
                    }
                }
            });
            if (this.sTableOptions.tableOptions.isNotSiteChange) {
                return;
            }
            this.currentPageIndex = 0;
            this.getCollectionData();
            // this.initUpdateData();
        });
        this.tags = this.commonService.selectedTagChange.subscribe((tags: any) => {
            this.tagList = tags;
            this.currentPageIndex = 0;
            this.getCollectionData();
        });
    }
    public assetDrpCtrl: FormControl = new FormControl();
    public assetDrpFilterCtrl: FormControl = new FormControl();
    public filterDrpLstFilteredList: ReplaySubject<any> =
        new ReplaySubject<any>(1);
    @ViewChild('filterMenuTrigger', { static: false })
    filterMenuTrigger!: MatMenuTrigger;
    @ViewChild('listMenuTrigger', { static: false })
    listMenuTrigger!: MatMenuTrigger;
    @ViewChild('exportMenuTrigger', { static: false })
    exportMenuTrigger!: MatMenuTrigger;
    filterText!: string;
    currentPageIndex: any;
    isExport = false;
    tableId: any;
    // @ts-ignore
    //@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild('paginator') paginatorWidget: PaginatorWidgetComponent;
    // tslint:disable-next-line:variable-name
    _sTableOptions: any;
    isLoading = true;
    dataSource = new MatTableDataSource<any>();
    hiddenDataSource = new MatTableDataSource<any>();
    Objectkeys = Object.keys;
    @ViewChild('MAINTABLEDIV', { static: false })
    tableDiv!: ElementRef;
    @Input() loadtable: any;
    @Input() sTableOptions: any;
    @Input() hideDownload: any;
    @Output() filterCallback = new EventEmitter();
    @Output() colFilterCallback = new EventEmitter();
    @Output() publishCallback = new EventEmitter();
    @Output() sortCallback = new EventEmitter();
    @Output() actionCallback = new EventEmitter();
    @Output() globalActionCallback = new EventEmitter();
    @Output() pageCallback = new EventEmitter();
    @Output() refreshCallback = new EventEmitter();
    @Output() hyperlinkCallback = new EventEmitter();
    @Output() addCallback = new EventEmitter();
    @Output() compareCallback = new EventEmitter();
    @Output() timerCallback = new EventEmitter();
    @Output() checkBoxCallback = new EventEmitter();
    @Output() selectionchangeCallback = new EventEmitter();
    @Output() parentCallback = new EventEmitter();
    @Output() totalCallback = new EventEmitter();
    @Output() apiDataDownload = new EventEmitter();
    tagList: any = '';
    colFilters: any = [];
    filterValues!: string;
    filterArray: any = [];
    selectedTimer = '0';
    intervalId: any;
    called = false;
    cFilter: any = {};
    colHash: any = {};
    colFilterQuery: any;
    filterQuery: any;
    private tmpOption: any;
    columnsList: string[] = [];
    cpIndex = 0;
    tableOptions: any = {
        id: 'imaws',
        title: '',
        isServerSide: true,
        selectText: 'item(s)',
        floatingFilter: true,
        rowSelection: true,
        loading: true,
        showAction: false,
        actionMenuItems: [
            {
                text: 'Details',
                icon: 'info',
                callback: 'editFunction',
                hideLocal: false,
                isGlobal: false,
                actionConditions: { key: '' },
            },
        ],
        isActionCondition: false,
        actionConditionsList: {},
        pagination: true,
        pageOptions: [5, 10, 20, 30, 50, 100, 200],
        pageSize: 5,
        pageTotal: 0,
        search: true,
        showhideList: true,
        refreshData: true,
        dropdwn: false,
        add: false,
        addText: '',
        showColFilter: false,
        exportExcel: true,
        compareData: false,
        publish: false,
        parentCalls: [],
        dataMapping: [],
        saveData: false,
        filterDownload: true,
        changeValue: new Subject<any>(),
    };

    isMUltiple = true;
    initSelectedValues = [];
    selection = new SelectionModel<any>(
        this.isMUltiple,
        this.initSelectedValues
    );
    numSelected!: number;

    isCompMUltiple = true;
    initCompSelectedValues = [];
    compSelection = new SelectionModel<any>(
        this.isMUltiple,
        this.initCompSelectedValues
    );
    compNumSelected!: number;

    columns = [
        {
            columnDef: 'name',
            header: 'Name',
            cell: (element: any) => `${element.name}`,
        },
        {
            columnDef: 'dateOfBirth',
            header: 'Date of Birth',
            filter: 'date',
            cell: (element: any) => `${element.dateOfBirth}`,
        },
        {
            columnDef: 'address',
            header: 'Address',
            cell: (element: any) => `${element.address}`,
        },
    ];

    public displayedColumns!: Array<any>;
    public showhideList!: Array<any>;
    orderlist!: Array<any>;

    filterUpdate = new Subject<string>();
    colfilterUpdate = new Subject<any>();
    settingsObj = [];
    sessionData: any;
    searchedColName = '';
    colfilter: any = {};
    pastIndex: any;
    isPageLoad = true;

    isActionChanged = false;
    isTablePadding = false;
    columnsCnt = 0;
    exportCnt = 0;
    isExportClick = false;

    hasSelect = false;
    @HostListener('document:click', ['$event'])
    clickout(event: any): void {
        if (this.eRef.nativeElement.contains(event.target)) {
            if (this.isExportClick) {
                if (this.exportCnt > 1) {
                    if (this.exportMenuTrigger !== undefined) {
                        this.exportMenuTrigger.closeMenu();
                    }
                    this.exportCnt = 1;
                    this.isExportClick = false;
                } else {
                    if (this.listMenuTrigger !== undefined) {
                        this.listMenuTrigger.closeMenu();
                    }
                    this.exportCnt++;
                }
            } else {
                if (this.columnsCnt > 1) {
                    if (this.listMenuTrigger !== undefined) {
                        this.listMenuTrigger.closeMenu();
                    }
                    this.columnsCnt = 1;
                } else {
                    if (this.exportMenuTrigger !== undefined) {
                        this.exportMenuTrigger.closeMenu();
                    }
                    this.columnsCnt++;
                }
            }
        } else {
            this.columnsCnt = 0;
            if (this.listMenuTrigger !== undefined) {
                this.listMenuTrigger.closeMenu();
            }
        }
    }
    columnFilterxlsx(col: any): void {
        this.searchedColName = col.col;
        this.colFilters.push({
            key: col.col,
            name: col.header,
            value: col.val,
            hKey: col.colFilters.hKey,
        });
        this.processColFilterQuery();
    }
    removeFilterxlsx(rFilter: any): void {
        if (this.sTableOptions.gFilter) {
            delete this.sTableOptions.gFilter;
            this.colFilters = [];
            this.colFilterQuery = [];
            this.filterQuery = {};
            this.getCollectionData();
            return;
        }
        this.colFilters.forEach((obj: { key: string | number }, index: any) => {
            if (rFilter.key === obj.key) {
                this.colfilter[obj.key] = '';
                this.colFilters.splice(index, 1);
            }
        });
        this.processColFilterQuery();
    }
    processColFilterQuery(): void {
        this.colFilterQuery = [];
        for (const cl of this.colFilters) {
            const tmpObj1: any = { bool: { should: [{ match: {} }] } };
            let tmpObj: any = {};
            if (cl.hKey) {
                tmpObj = {
                    bool: {
                        should: [
                            {
                                query_string: {
                                    fields: [cl.key],
                                    query: `*${cl.value}*`,
                                },
                            },
                        ],
                    },
                };
            } else {
                tmpObj1.bool.should[0].match[cl.key] = cl.value;
                tmpObj = tmpObj1;
            }
            this.colFilterQuery.push(tmpObj);
        }
        this.getCollectionData();
    }
    apiDownload(type: any): void {
        const file_name = `${this.changeHeader(this.tableOptions.title)}.csv`;
        this.apiDataDownload.emit({ value: file_name });
    }
    downloadAsXls(type: string): void {
        if (!this.sTableOptions.tableOptions.pageTotal) {
            this.toast.sToast('error', 'No data available!');
            return;
        }
        const _sTableOptionsC = cloneDeep(this.sTableOptions);
        let skip = 0;
        let limit = _sTableOptionsC.tableOptions.pageSize;
        if (this.currentPageIndex) {
            skip = this.currentPageIndex * this.tableOptions.pageSize;
            limit = this.tableOptions.pageSize;
        }
        let order_by = `${_sTableOptionsC.sortOptions.active} ${_sTableOptionsC.sortOptions.direction}`
        let condition: any = { condition: true, skip, limit, order_by };
        if (this.commonService.currentScope !== '*' && !_sTableOptionsC.tableOptions.serverSide.isGlobal && _sTableOptionsC.tableOptions.serverSide.type !== 'post') {
            condition.condition = "company_id=" + parseInt(this.commonService.currentScope.id) + "";
        }
        if (_sTableOptionsC.tableOptions.serverSide.condition && this.commonService.currentScope === '*') {
            condition.condition = _sTableOptionsC.tableOptions.serverSide.condition;
        } else if (_sTableOptionsC.tableOptions.serverSide.condition) {
            if (condition.condition === true) {
                condition.condition = _sTableOptionsC.tableOptions.serverSide.condition;
            } else {
                condition.condition += ' and ' + _sTableOptionsC.tableOptions.serverSide.condition;
            }
        }
        if (this.filterText) {
            const columns = _sTableOptionsC.columns.filter((y: any) => y.cType === 'string' && y.visible && !y.gloNoSearch).map((x: any) => x.columnDef).join(", ' ', ");
            if (condition.condition === true) {
                condition.condition = `concat(${columns}) ILIKE '%${this.filterText}%'`;
            } else {
                condition.condition += ` and concat(${columns}) ILIKE '%${this.filterText}%'`;
            }
        }
        if (_sTableOptionsC.tableOptions.serverSide.type === 'post') {
            const con = Object.assign({}, _sTableOptionsC.tableOptions.serverSide.params);
            delete con.name;
            if (this.commonService.currentScope?.id && !_sTableOptionsC.tableOptions.serverSide.isGlobal) {
                _sTableOptionsC.tableOptions.serverSide.params.company_id = parseInt(this.commonService.currentScope.id);
            }
            _sTableOptionsC.tableOptions.serverSide.params.skip = skip;
            _sTableOptionsC.tableOptions.serverSide.params.limit = limit;
            _sTableOptionsC.tableOptions.serverSide.params.order_by = order_by;
            if (condition.condition) {
                _sTableOptionsC.tableOptions.serverSide.params.condition = condition.condition; // + this.commonService.objectToQueryString(con);
            }
            const url = `${_sTableOptionsC.tableOptions.serverSide.url}` // /${_sTableOptionsC.tableOptions.serverSide.params.name}

            _sTableOptionsC.tableOptions.serverSide.params.file_name = `${this.changeHeader(this.tableOptions.title)}.csv`;
            this.baseService
                .doRequest(url, 'get', null, _sTableOptionsC.tableOptions.serverSide.params)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    this.loaderService.display(false);
                    if (result.status) {
                        window.open(result.message, '_blank');
                    } else {
                        const data = (result.message) ? result.message : result.data;
                        this.toast.sToast('error', data);
                    }
                    this.cdr.detectChanges();
                });
        } else {
            condition.file_name = `${this.changeHeader(this.tableOptions.title)}.csv`;
            this.baseService
                .doRequest(_sTableOptionsC.tableOptions.serverSide.url, 'get', null, condition)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    this.loaderService.display(false);
                    if (result.status) {
                        window.open(result.message, '_blank');
                    } else {
                        const data = (result.message) ? result.message : result.data;
                        this.toast.sToast('error', data);
                    }
                    this.cdr.detectChanges();
                });
        }
    }

    columnFilter(val: any, col: any): void {
        this.searchedColName = col;
        this.colfilterUpdate.next({ value: val, col });
    }
    removeFilter(filter: any): void {
        this.colFilters.forEach((obj: { key: string | number }, index: any) => {
            if (filter.key === obj.key) {
                this.colfilter[obj.key] = '';
                this.colFilters.splice(index, 1);
            }
        });
        this.colFilterCallback.emit({ value: '', col: filter.key });
    }
    getProperty(object: any, propertyName: any): any {
        let parts: any[];
        let property: string;
        parts = propertyName.split('.');
        const length = parts.length;
        let i: number;
        property = object;
        for (i = 0; i < length; i++) {
            // @ts-ignore
            try {
                property = property[parts[i]];
            } catch (e) {
                return property;
            }
        }
        if (property === undefined || property === null) {
            const defaultValue = this._sTableOptions.columns.filter((x: any) => x.columnDef === propertyName);
            if (defaultValue && defaultValue.length) {
                property = (defaultValue[0].cType && defaultValue[0].cType === 'number') ? '0' : null;
            }
        }
        return property;
    }
    getPropertyCondition(object: any, propertyName: any): any {
        let parts: any[]; let property: string;
        parts = propertyName.split('.'); const length = parts.length;
        let i: number;
        property = object;
        for (i = 0; i < length; i++) {
            // @ts-ignore
            property = property[parts[i]];
        }
        return String(property);
    }
    rowClick(row: any, index: any): void {
        if (row._id) {
            this.dataSource.data.forEach((obj) => {
                obj.highlighted = obj._id === row._id;
            });
        } else {
            if (this.pastIndex !== undefined) {
                this.dataSource.data[this.pastIndex].highlighted = false;
            }
            this.pastIndex = index;
            this.dataSource.data[index].highlighted =
                !this.dataSource.data[index].highlighted;
        }
    }

    setFilterRules($event: any): void {
        this.filterQuery = $event.query;
        this.currentPageIndex = 0;
        this.getCollectionData();
    }

    toggleFilterRules($event: any): void {
        this.showFilter = !this.showFilter;
        this.filterQuery = $event.query;
        this.currentPageIndex = 0;
        this.getCollectionData();
    }

    setTagFilterRules($event: any): void {
        this.tagList = $event;
        this.currentPageIndex = 0;
        this.getCollectionData();
    }

    toggleTagFilterRules($event: any): void {
        if (this._sTableOptions.pageData && this._sTableOptions.pageData.length) {
            this.showTagFilter = !this.showTagFilter;
            this.tagList = $event;
            this.currentPageIndex = 0;
            this.getCollectionData();
        }
    }

    FilterTagTableData(): void {
        if (this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.url) {
            this.showTagFilter = !this.showTagFilter;
        }
        this.filterCallback.emit();
    }
    /*getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
    )*/

    getCustomText(obj: any): any {
        if (this.sTableOptions && !this.sTableOptions.customText) {
            return '';
        }
        // @ts-ignore
        const customText = this.sTableOptions.customText.filter(
            (x: any) => ((typeof x.status === 'string') ? x.status?.toLowerCase() : x.status) === ((typeof obj === 'string') ? obj?.toLowerCase() : obj)
        );
        if (customText.length > 0) {
            return customText[0].DisplayText;
        }
    }

    getCustomColor(obj: any): any {
        if (this.sTableOptions && !this.sTableOptions.customText) {
            return '';
        }
        const customText = this.sTableOptions.customText.filter(
            (x: any) => ((typeof x.status === 'string') ? x.status?.toLowerCase() : x.status) === ((typeof obj === 'string') ? obj?.toLowerCase() : obj)
        );
        if (customText.length > 0) {
            return customText[0]['text-color']
                ? {
                    'background-color': customText[0].color,
                    color: customText[0]['text-color'],
                }
                : { 'background-color': customText[0].color };
        }
        return { 'background-color': '#ffffff' };
    }

    getCustomClass(obj: any): any {
        if (this.sTableOptions && !this.sTableOptions.customText) {
            return '';
        }
        const customText = this.sTableOptions.customText.filter(
            (x: any) => ((typeof x.status === 'string') ? x.status?.toLowerCase() : x.status) === ((typeof obj === 'string') ? obj?.toLowerCase() : obj)
        );
        if (customText.length > 0) {
            return customText[0].class;
        }
        return 'bg-indigo-100 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50';
    }
    getRangeColor(obj: any): any {
        let style: any = { 'background-color': '#000000', 'color': '#c2ffc4' };
        if (this.sTableOptions && !this.sTableOptions.customText) {
            return style;
        }
        this.sTableOptions.customText.forEach((r: any) => {
            if (r.range && r.range.from <= obj && r.range && r.range.to >= obj) {
                style = { 'background-color': r.color, color: r['text-color'] };
            }
        });
        return style;
    }

    getIconPath(obj: any, path: any): any {
        if (this.getProperty(obj, path.columnDef) === path.success) {
            return path.successIconPath !== undefined
                ? path.successIconPath
                : 'assets/images/devices/online.svg';
        } else {
            return path.failureIconPath !== undefined
                ? path.failureIconPath
                : 'assets/images/devices/offline.svg';
        }
    }

    getIconTooltip(obj: any, path: any): any {
        if (this.getProperty(obj, path.columnDef) === path.success) {
            return path.successToolTip !== undefined
                ? path.successToolTip
                : 'Online';
        } else {
            return path.failureToolTip !== undefined
                ? path.failureToolTip
                : 'Offline';
        }
    }
    drop(event: CdkDragDrop<string[]>): any {
        this.isActionChanged = true;
        moveItemInArray(
            this.showhideList,
            event.previousIndex,
            event.currentIndex
        );
        /*for (let idx = event.previousIndex; idx >= event.currentIndex; idx--) {
            this.showhideList[idx].order = idx;
        }*/
        this.showhideList[event.previousIndex].order = event.previousIndex;
        this.showhideList[event.currentIndex].order = event.currentIndex;
        this.initUpdateData();
    }

    actionCall(row: any, action: any): void {
        delete row.highlighted;
        delete row.hovered;
        const resp = { row, action };
        if (
            this.tableOptions.showAction &&
            this.tableOptions.actionMenuItems.length > 0
        ) {
            if (this.tableOptions.serverSide) {
                if (action.id === 'delete' && this.tableOptions.serverSide.dUrl) { this.deleteRecord(row); } else {
                    this.actionCallback.emit(resp);
                }
            } else {
                this.actionCallback.emit(resp);
            }
        }
    }

    deleteRecord(data: any) {
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: `Are you sure you want to delete ?`,
            actions: {
                confirm: {
                    label: 'Yes'
                }
            }
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this.baseService.doRequest(`${this.tableOptions.serverSide.dUrl}/${data.id}`, 'delete')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        if (result.status) {
                            this.toast.sToast('success', 'Removed successfully');
                            setTimeout(() => {
                                this.getCollectionData();
                            }, 3000);
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        })
    }

    globalActionCall(row: any, action: any): void {
        delete row.highlighted;
        delete row.hovered;
        const resp = { row, action };
        this.globalActionCallback.emit(resp);
        /*if (
            this.tableOptions.showAction &&
            this.tableOptions.actionMenuItems.length > 0
        ) {
            this.globalActionCallback.emit(resp);
        }*/
    }

    hyperLinkCall(row: any, col?: any): any {
        delete row.highlighted;
        delete row.hovered;
        const event = { row, col };
        this.hyperlinkCallback.emit(event);
    }

    ngOnChanges(changes: SimpleChanges): any {
        const sTableOptions: SimpleChange = changes.sTableOptions;
        this._sTableOptions = sTableOptions?.currentValue;
        // if (!sTableOptions.firstChange) {
        this.isLoading = false
        // this.initUpdateData();
        // }
    }

    ngOnInit(): void {
        this.sTableOptions.columns.forEach((obj: any) => {
            obj.cType = (obj.cType) ? obj.cType : 'string';
            const company = ['company_count', 'company_name', 'affected_companies', 'companies', 'company_id'];
            if ((company.indexOf(obj.columnDef) !== -1)) {
                obj.visible = true;
                if (this.commonService.currentScope !== '*') {
                    obj.isNoVisible = true;
                } else {
                    obj.isNoVisible = false;
                }
            }
        });
        this.sTableOptions.tableOptions.id = this.changeHeader(this.sTableOptions.tableOptions?.title);
        if (this.sTableOptions && this.sTableOptions.changeValue) {
            this.change = this.sTableOptions.changeValue.subscribe((v: any) => {
                this.sTableOptions.pageData = [];
                this.sTableOptions.tableOptions.pageTotal = 0;
                this.currentPageIndex = 0;
                this.getCollectionData();
            });
        }
        this.sTableOptions.tableOptions.loading = true;
        if (this.sTableOptions.tableOptions.pageTotal) { this.sTableOptions.tableOptions.loading = false; }
        this.tmpOption = { ...this.sTableOptions };
    }

    ngOnDestroy(): void {
        this.isPageLoad = false;
        if (this.intervalId !== undefined && this.intervalId !== null) {
            clearInterval(this.intervalId);
        }
        if (this.subs) { this.subs.unsubscribe(); }
        if (this.change) { this.change.unsubscribe(); }
        if (this.tags) { this.tags.unsubscribe(); }
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    initUpdateData(): void {
        this.tableOptions = Object.assign(
            this.tableOptions,
            this._sTableOptions.tableOptions
        );
        this.isPageLoad = this.sTableOptions.overridePageLoad
            ? false
            : this.isPageLoad;
        const filterK = localStorage.getItem(this.tableOptions.id);
        if (filterK && filterK !== '') {
            this.filterText = filterK;
            this.cdr.detectChanges();
            if (!this.called) {
                setTimeout(() => {
                    localStorage.removeItem(this.tableOptions.id);
                    this.doFilter(this.filterText);
                    this.cdr.detectChanges();
                    this.called = true;
                }, 2000);
            }
        }
        if ((this.isPageLoad && !this.tableOptions.pageTotal) || this.loadtable) {
            if (this.sTableOptions.tableOptions.isSettingCalled) {
                if (this.isPageLoad) {
                    this.isPageLoad = false;
                }
                const filterColumn = this.sTableOptions.columns.filter((x: any) => x.visible && x.cType !== 'number');
                this.sColumns = filterColumn.slice();
                this.getCollectionData(); return;
            }
            this.loaderService.display(true, 'Getting TableSettings data');

            const params: any = {
                condition: `category='table_settings' and value IS NOT NULL and sub_category='${this.changeHeader(this.sTableOptions.tableOptions?.title)}' and name='${this.baseService.user().email}'`
            }
            this.baseService.doRequest(`/r/company/settings`, 'get', null, params)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    this.loaderService.display(false);
                    this.sTableOptions.tableOptions.isSettingCalled = true;
                    if (result.status && result.data && result.data.length) {
                        this.sessionData = result.data[0];
                        try {
                            const savedCols = JSON.parse(result.data[0].value.columnRepr);
                            const savedColsList = savedCols.map((x: any) => x.columnDef);
                            const orginalColsList = this._sTableOptions.columns.map((x: any) => x.columnDef);
                            const difference = orginalColsList.filter((value: any) => !savedColsList.includes(value));
                            if (difference.length === 0) {
                                if (
                                    result.data[0].value.columnRepr &&
                                    savedCols.length === this._sTableOptions.columns.length
                                ) {
                                    savedCols.forEach((obj: any) => {
                                        const a = obj.visible;
                                        const b = this._sTableOptions.columns.filter((x: any) => x.columnDef === a.columnDef);
                                        if (b && b.length) {
                                            b[0].visible = a;
                                            obj = { ...b[0] };
                                        }
                                        const company = ['company_count', 'company_name', 'affected_companies', 'companies', 'company_id'];
                                        if ((company.indexOf(obj.columnDef) !== -1)) {
                                            obj.visible = true;
                                            if (this.commonService.currentScope !== '*') {
                                                obj.isNoVisible = true;
                                            } else {
                                                obj.isNoVisible = false;
                                            }
                                        }
                                    });
                                    this._sTableOptions.columns = savedCols;
                                }
                            }

                        } catch (e) {
                            e = null;
                        }

                        this.selectedTimer =
                            result.data[0].value.refreshInterval + '';
                        this._sTableOptions.sortOptions.direction =
                            result.data[0].value.settings.sortOptions.direction === ''
                                ? 'asc'
                                : result.data[0].value.settings.sortOptions.direction;
                        this._sTableOptions.tableOptions.pageSize =
                            result.data[0].value.settings.pageSize;
                        // if (
                        //     result.data[0].value.settings.pageSize !==
                        //     this.tableOptions.pageSize
                        // ) {
                        //     setTimeout(() => this.RefreshTableData(), 2000);
                        // }
                        setTimeout(() => this.getCollectionData(), 2000);

                        this.tableOptions.pageSize =
                            result.data[0].value.settings.pageSize;
                        this.setTableOptions(this._sTableOptions.tableOptions);
                    } else {
                        this.getCollectionData();
                    }
                });
        } else {
            this.clearSelection();
            try {
                this.sTableOptions.columns = (this.showhideList && this.showhideList.length === this.sTableOptions.columns.length) ? this.showhideList : this.sTableOptions.columns;
            } catch (e) {
                console.log(e);
            }
            this.setTableOptions(this.tableOptions);
            setTimeout(() => this.tableOptions.loading = false)
            if (this.sTableOptions.tableOptions.pageTotal === 0) { this.sTableOptions.tableOptions.loading = false; }
        }
        if (this.isPageLoad) {
            this.isPageLoad = false;
        }
        const filterColumn = this.sTableOptions.columns.filter((x: any) => x.visible && x.cType !== 'number' && !x.isNoVisible);
        this.sColumns = filterColumn.slice();
    }

    setTableOptions(tblOptions: any): any {
        this.displayedColumns = [];
        this.orderlist = [];
        const coulumns = this._sTableOptions.columns.filter((x: any) => !x.isNoVisible);
        this.showhideList = coulumns;
        this.dataSource.data = []; this.cdr.detectChanges();
        coulumns.map((c: any) => {
            if (c.visible) {
                this.displayedColumns.push(c.columnDef);
                this.orderlist.push(c);
            }
        });
        if (tblOptions.compareData) {
            this.displayedColumns.push('compare');
        }

        if (tblOptions.showAction) {
            this.displayedColumns.push('action');
        }

        if (tblOptions.rowSelection) {
            this.displayedColumns.unshift('select');
        }
        this.dataSource.data = this._sTableOptions.pageData; this.cdr.detectChanges();
        /*
        this.tableOptions.loading = false;
        if (this.sort) {
            this.dataSource.sort = this.sort;
        }
        */

        this.dataSource.sortingDataAccessor = (
            data: any,
            sortHeaderId: string
        ): string => {
            if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase();
            }
            return data[sortHeaderId];
        };

        if (!tblOptions.serverSide) {
            this.dataSource.paginator = this.paginatorWidget?.paginator;
        }
        this.filterArray = this.dataSource.filteredData;
    }

    showHideColumn(i: any, val: any): void {
        val.visible = !val.visible;
        const index = this.orderlist.findIndex(
            (x) => x.columnDef === val.columnDef
        );
        if (index >= 0) {
            this.orderlist.splice(index, 1);
        } else {
            this.orderlist.push(val);
        }
        this.displayedColumns = [];
        this.orderlist = this.orderlist.sort((a, b) => a.order - b.order);

        if (this.tableOptions.compareData) {
            this.displayedColumns.push('compare');
        }

        if (this.tableOptions.rowSelection) {
            this.displayedColumns.push('select');
        }

        this.orderlist.forEach((element) => {
            this.displayedColumns.push(element.columnDef);
        });
        if (this.tableOptions.showAction) {
            this.displayedColumns.push('action');
        }
        this.isActionChanged = true;
        this.initUpdateData();
    }

    ngAfterViewInit(): void {
        this.isLoading = false;
        this.filterText = '';
        this.cdr.detectChanges();
        setTimeout(() => {
            this.tableOptions.loading = true;
            this.initUpdateData();
        });
    }

    pageChanged(event: any): void {
        this.isActionChanged = true;
        this.currentPageIndex = event.pageIndex;
        this.cpIndex = event.pageIndex;
        this.tableOptions.pageSize = event.pageSize;
        this.sTableOptions.tableOptions.pageSize = event.pageSize;
        if (!this.tableOptions.serverSide) {
            this.pageCallback.emit(event);
        } else {
            this.getCollectionData();
        }
    }

    RefreshTableData(): void {
        if (this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.url) {
            this.tableOptions.loading = true;
            this.getCollectionData(); return;
        }
        this.refreshCallback.emit();
    }

    FilterTableData(): void {
        if (this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.url) {
            this.showFilter = !this.showFilter;
        }
        this.filterCallback.emit();
    }

    getCollectionData(search?: any): void {
        if (!this.sTableOptions.tableOptions.serverSide || !this.sTableOptions.tableOptions.serverSide.url) { return; }
        this.commonService.selectedRows.next({ row: [], table: '' });
        this.sTableOptions.dataList = []; this.sTableOptions.pageData = [];
        this.tableOptions.loading = true;
        let skip = 0;
        let limit = this.sTableOptions.tableOptions.pageSize;
        if (this.currentPageIndex) {
            skip = this.currentPageIndex * this.tableOptions.pageSize;
            limit = this.tableOptions.pageSize;
        } else {
            this.sTableOptions.tableOptions.pageTotal = 0;
        }
        let order_by = `${this.sTableOptions.sortOptions.active} ${this.sTableOptions.sortOptions.direction}`
        let condition: any = { condition: true, skip, limit, order_by };
        if (this.sTableOptions.tableOptions.serverSide && this.commonService.currentScope !== '*' && !this.sTableOptions.tableOptions.serverSide.isGlobal && this.sTableOptions.tableOptions.serverSide.type !== 'post') {
            condition.condition = "company_id=" + parseInt(this.commonService.currentScope.id) + "";
        }
        if (this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.customCondition) {
            this.sTableOptions.tableOptions.serverSide.condition = this.sTableOptions.tableOptions.serverSide.customCondition.global;
            if (this.commonService.currentScope !== '*' && !this.sTableOptions.tableOptions.serverSide.isGlobal) {
                this.sTableOptions.tableOptions.serverSide.condition = this.sTableOptions.tableOptions.serverSide.customCondition.company;
            }
        }
        if (this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.condition && this.commonService.currentScope === '*') {
            condition.condition = this.sTableOptions.tableOptions.serverSide.condition;
        } else if (this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.condition) {
            if (condition.condition === true) {
                condition.condition = this.sTableOptions.tableOptions.serverSide.condition;
            } else {
                condition.condition += ' and ' + this.sTableOptions.tableOptions.serverSide.condition;
            }
        }
        if (this.filterQuery && this.filterQuery.length) {
            const filterQuery = this.filterQuery.replaceAll('LIKE', 'ILIKE');
            if (condition.condition === true) {
                condition.condition = filterQuery;
            } else {
                condition.condition += ' and ' + filterQuery;
            }
        }
        if (this.filterText) {
            const colLen = this.sTableOptions.columns.filter((y: any) => y.cType === 'string' && y.visible && !y.gloNoSearch)
            const columns: any = this.sTableOptions.columns.filter((y: any) => y.cType === 'string' && y.visible && !y.gloNoSearch).map((x: any) => x.columnDef).join(", ' ', ");
            if (condition.condition === true) {
                condition.condition = (colLen.length > 1) ? `concat(${columns}) ILIKE '%${this.filterText}%'` : `${columns} ILIKE '%${this.filterText}%'`;
            } else {
                condition.condition += (colLen.length > 1) ? ` and concat(${columns}) ILIKE '%${this.filterText}%'` : ` and ${columns} ILIKE '%${this.filterText}%'`;
            }
        }
        this.clearSelection()
        if (this.tagList.api) {
            const url = `${this.sTableOptions.tableOptions.serverSide.url}`
            if (url.indexOf('/r/reports') > -1 || url.indexOf('report_queries') > -1) {
                this.sTableOptions.tableOptions.serverSide.params = { ...this.sTableOptions.tableOptions.serverSide.params, ...this.tagList.report }
            } else {
                if (condition.condition === true) {
                    condition.condition = `${this.tagList.api}`;
                } else {
                    condition.condition += ` and ${this.tagList.api}`;
                }
            }
        } else {
            delete this.sTableOptions.tableOptions.serverSide?.params?.tags_key_value;
            delete this.sTableOptions.tableOptions.serverSide?.params?.manual_tags_key_value;
        }
        if (this.sTableOptions.tableOptions.customPagination && this.sTableOptions.tableOptions.customPagination.value.length > 0) {
            const sortedArray = cloneDeep(this.sTableOptions.tableOptions.customPagination.value.slice().sort((a: any, b: any) => a - b));
            const skipN = (this.currentPageIndex * this.tableOptions.pageSize) || 0;
            const limitN = this.tableOptions.pageSize;
            const subarray = sortedArray.slice(skipN, skipN + limitN + 1);
            if (subarray && subarray.length) {
                let con = '';
                if (subarray.length > 1) {
                    con = `${this.sTableOptions.tableOptions.customPagination.key} IN (${subarray.join(",")})`
                } else {
                    con = `${this.sTableOptions.tableOptions.customPagination.key}=${subarray.join(",")}`
                }
                if (condition.condition === true) {
                    condition.condition = con
                } else {
                    condition.condition += ` and ${con}`;
                }
                condition.skip = 0;
            }
        }
        if (this.sTableOptions.tableOptions.serverSide && this.sTableOptions.tableOptions.serverSide.type === 'post') {
            const con = Object.assign({}, this.sTableOptions.tableOptions.serverSide.params);
            delete con.name;
            if (this.commonService.currentScope?.id && !this.sTableOptions.tableOptions.serverSide.isGlobal) {
                this.sTableOptions.tableOptions.serverSide.params.company_id = parseInt(this.commonService.currentScope.id);
            }
            this.sTableOptions.tableOptions.serverSide.params.skip = skip;
            this.sTableOptions.tableOptions.serverSide.params.limit = limit;
            this.sTableOptions.tableOptions.serverSide.params.order_by = order_by;
            if (this.tagList.api) {
                const url = `${this.sTableOptions.tableOptions.serverSide.url}`
                if (url.indexOf('/r/reports') > -1 || url.indexOf('report_queries') > -1) {
                    this.sTableOptions.tableOptions.serverSide.params = { ...this.sTableOptions.tableOptions.serverSide.params, ...this.tagList.report };
                } else {
                    if (this.sTableOptions.tableOptions.serverSide.params.condition === true) {
                        this.sTableOptions.tableOptions.serverSide.params.condition = `${this.tagList.api}`;
                    } else {
                        this.sTableOptions.tableOptions.serverSide.params.condition += ` and ${this.tagList.api}`;
                    }
                }
            } else {
                delete this.sTableOptions.tableOptions.serverSide?.params?.tags_key_value;
                delete this.sTableOptions.tableOptions.serverSide?.params?.manual_tags_key_value;
            }
            // this.sTableOptions.tableOptions.serverSide.params.condition = this.commonService.objectToQueryString(con);
            if (condition.condition) {
                this.sTableOptions.tableOptions.serverSide.params.condition = condition.condition; // + this.commonService.objectToQueryString(con);
            }
            const url = `${this.sTableOptions.tableOptions.serverSide.url}` // /${this.sTableOptions.tableOptions.serverSide.params.name}
            this.baseService
                .doRequest(url, 'get', null, this.sTableOptions.tableOptions.serverSide.params)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    if (result.status) {
                        if (this.sTableOptions.tableOptions.serverSide.isMergeApi) {
                            this.mergeData(result, this.sTableOptions.tableOptions.serverSide.params);
                        } else {
                            if (this._sTableOptions.tableOptions.dataMapping && this._sTableOptions.tableOptions.dataMapping.length) {
                                result.data.forEach((o: any) => {
                                    this._sTableOptions.tableOptions.dataMapping.forEach((d: any) => {
                                        if (d.filter) {
                                            o[d.d] = this.formatCell.transform(o[d.s], d.filter);
                                        } else {
                                            o[d.d] = o[d.s];
                                        }
                                    });
                                })
                            }
                            this.loaderService.display(false);
                            this.sTableOptions.pageData = result.data;
                            this.sTableOptions.tableOptions.pageTotal = (result.total) ? result.total : 0;
                            this.tableOptions.pageTotal = (result.total) ? result.total : 0;
                            if (this.sTableOptions.tableOptions.customPagination && this.sTableOptions.tableOptions.customPagination.value.length > 0) {
                                this.sTableOptions.tableOptions.pageTotal = this.sTableOptions.tableOptions.customPagination.value.length;
                                this.tableOptions.pageTotal = this.sTableOptions.tableOptions.customPagination.value.length;
                            }
                            this.setTableOptions(this.sTableOptions.tableOptions);
                            this.cdr.detectChanges();
                            setTimeout(() => this.tableOptions.loading = false)
                        }
                    } else {
                        this.loaderService.display(false);
                        this.sTableOptions.pageData = [];
                        this.sTableOptions.tableOptions.pageTotal = 0;
                        this.tableOptions.pageTotal = 0;
                        this.setTableOptions(this.sTableOptions.tableOptions);
                        this.cdr.detectChanges();
                        setTimeout(() => this.tableOptions.loading = false)
                    }
                });
        } else {
            this.baseService
                .doRequest(this.sTableOptions.tableOptions.serverSide.url, 'get', null, condition)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    if (result.status) {
                        if (this.sTableOptions.tableOptions.serverSide.isMergeApi) {
                            this.mergeData(result, condition);
                        } else {
                            this.loaderService.display(false);
                            if (this._sTableOptions.tableOptions.dataMapping && this._sTableOptions.tableOptions.dataMapping.length) {
                                result.data.forEach((o: any) => {
                                    this._sTableOptions.tableOptions.dataMapping.forEach((d: any) => {
                                        if (d.filter) {
                                            o[d.d] = this.formatCell.transform(o[d.s], d.filter);
                                        } else {
                                            o[d.d] = o[d.s];
                                        }
                                    });
                                })
                            }
                            this.sTableOptions.pageData = result.data;
                            this.sTableOptions.tableOptions.pageTotal = (result.total) ? result.total : 0;
                            this.tableOptions.pageTotal = (result.total) ? result.total : 0;
                            if (this.sTableOptions.tableOptions.customPagination && this.sTableOptions.tableOptions.customPagination.value.length > 0) {
                                this.sTableOptions.tableOptions.pageTotal = this.sTableOptions.tableOptions.customPagination.value.length;
                                this.tableOptions.pageTotal = this.sTableOptions.tableOptions.customPagination.value.length;
                            }
                            this.setTableOptions(this.sTableOptions.tableOptions);
                            this.cdr.detectChanges();
                            setTimeout(() => this.tableOptions.loading = false)
                        }
                    } else {
                        this.loaderService.display(false);
                        this.sTableOptions.pageData = [];
                        this.sTableOptions.tableOptions.pageTotal = 0;
                        this.tableOptions.pageTotal = [];
                        this.setTableOptions(this.sTableOptions.tableOptions);
                        this.cdr.detectChanges();
                        setTimeout(() => this.tableOptions.loading = false)
                    }
                });
        }
    }

    mergeData(result: any, condition: any): void {
        this.loaderService.display(true);
        let key = 'id'; let srcKey = 'id';
        let idValues = result.data.map((item: any) => item[key]);
        if (this.sTableOptions.tableOptions.serverSide.mergeCondition) {
            key = (this.sTableOptions.tableOptions.serverSide.mergeKey) ? this.sTableOptions.tableOptions.serverSide.mergeKey : key;
            srcKey = (this.sTableOptions.tableOptions.serverSide.srcKey) ? this.sTableOptions.tableOptions.serverSide.srcKey : srcKey;
            idValues = result.data.map((item: any) => item[srcKey]);
            condition[this.sTableOptions.tableOptions.serverSide.mergeCondition] =  `${idValues.join(',')}`;
        } else {
            if (condition.condition === true) {
                condition.condition = `id IN (${idValues.join(',')})`;
            } else {
                condition.condition += ` and id IN (${idValues.join(',')})`;
            }
        }
        delete condition.order_by;
        this.baseService
            .doRequest(this.sTableOptions.tableOptions.serverSide.mergeurl, 'get', null, condition)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                if (res.status) {
                    const mergedData = result.data.map((item1: any) => {
                        const matchingItem = res.data.find((item2: any) => item2[key] === item1[srcKey]);

                        // If a match is found, merge the properties
                        if (matchingItem) {
                            return { ...item1, ...matchingItem };
                        }

                        // If no match is found, just return the item from the first array
                        return item1;
                    });
                    this.loaderService.display(false);
                    this.sTableOptions.pageData = mergedData;
                    this.sTableOptions.tableOptions.pageTotal = (result.total) ? result.total : 0;
                    this.tableOptions.pageTotal = (result.total) ? result.total : 0;
                    this.setTableOptions(this.sTableOptions.tableOptions);
                    this.cdr.detectChanges();
                    setTimeout(() => this.tableOptions.loading = false)
                } else {
                    this.loaderService.display(false);
                    this.sTableOptions.pageData = [];
                    this.sTableOptions.tableOptions.pageTotal = 0;
                    this.tableOptions.pageTotal = [];
                    this.setTableOptions(this.sTableOptions.tableOptions);
                    this.cdr.detectChanges();
                    setTimeout(() => this.tableOptions.loading = false)
                }
            });
    }

    showAll(item: any) {
        if (!item.showAllFlag) {
            item.showAllFlag = true
        }
        else {
            item.showAllFlag = !item.showAllFlag
        }
    }

    initiateTable(dataList: any): void {
        const cols = dataList
            // tslint:disable-next-line:no-shadowed-variable
            .reduce((cols: any, row: {}) => {
                return [...cols, ...Object.keys(row)];
            }, [])
            // tslint:disable-next-line:no-shadowed-variable
            .reduce((cols: string | any[], column: any) => {
                return cols.includes(column) ? cols : [...cols, column];
            }, []);
        this.displayedColumns = this.columnsList.slice();
        this.dataSource = new MatTableDataSource<any>(dataList);
        this.dataSource.paginator = this.paginatorWidget.paginator;
        // this.dataSource.sort = this.sort;
        this.totalCallback.emit({
            value: this.sTableOptions.pTotal,
            id: this.sTableOptions.id,
        });
    }
    openListColumns(): void {
        this.isExportClick = false;
        this.columnsCnt++;
        if (this.columnsCnt > 1) {
            this.columnsCnt = 1;
        }
    }

    showHideLoading(status: any): void {
        /*const data = Object.assign({}, this.sTableOptions);
        this.sTableOptions = {};
        this.sTableOptions = data;*/
        this.sTableOptions.tableOptions.loading = status;
        this.cdr.detectChanges()
    }

    timerCallData(): void {
        // this.listMenuTrigger.closeMenu();
        clearInterval(this.intervalId);
        this.intervalId = undefined;

        if (
            this.selectedTimer !== undefined &&
            Number(this.selectedTimer) !== 0
        ) {
            clearInterval(this.intervalId);
            this.intervalId = setInterval(() => {
                this.timerCallback.emit(this.selectedTimer);
            }, Number(this.selectedTimer) * 1000);
        }
    }
    filterDrpLstChange(data: any): void {
        console.log(data);
        this.selectionchangeCallback.emit(data);
    }

    ParentCalls(id: any): void {
        this.parentCallback.emit(id);
    }

    AddTableRecord(): void {
        // this.listMenuTrigger.closeMenu();
        this.addCallback.emit();
    }
    publish(): void {
        this.publishCallback.emit();
    }
    CompareData(row: any): any {
        if (row.length <= 1) {
            this.toast.sToast('info', 'Two files requires to compare');
            return false;
        }
        if (row.length > 2) {
            this.toast.sToast(
                'info',
                'Not allow to compare more than 2 records'
            );
            return false;
        }
        const resp = { row };
        this.compareCallback.emit(resp);
    }

    clearSelection(): void {
        this.selection.clear();
        // @ts-ignore
        this.numSelected = undefined;
        /*this.sTableOptions.tableOptions.id === 'agents'
            ? this.cs.agentSelectEVE.next({ numSelected: 0 })
            : null;*/
    }
    clearCompCompareSelection(): void {
        this.compSelection.clear();
        // @ts-ignore
        this.compNumSelected = undefined;
    }

    isAllSelected(): any {
        this.numSelected = this.selection.selected.length;
        /*this.sTableOptions.tableOptions.id === 'agents'
            ? this.cs.agentSelectEVE.next({ numSelected: this.numSelected })
            : null;*/
        const numRows = this.dataSource.data.length;
        if (this.sTableOptions.tableOptions.isCheckboxSelection) {
            this.checkBoxCallback.emit(this.selection.selected);
        }
        return this.numSelected === numRows;
    }

    isAllCompSelected(): any {
        this.compNumSelected = this.compSelection.selected.length;
        const numRows = this.dataSource.data.length;
        this.cdr.detectChanges()
        return this.compNumSelected === numRows;
    }

    masterToggle(): void {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
        /*this.sTableOptions.tableOptions.id === 'agents'
            ? this.cs.agentSelectEVE.next({
                numSelected: this.selection.selected.length,
            })
            : null;*/
        if (this.sTableOptions.tableOptions.isCheckboxSelection) {
            this.checkBoxCallback.emit(this.selection.selected);
        }
        const tablename = this.sTableOptions.tableOptions.title;
        this.commonService.selectedRows.next({ row: this.selection.selected, table: tablename });
        // this.cdr.detectChanges();
    }

    rowToggle(row: any): void {
        this.selection.toggle(row);
        /*this.sTableOptions.tableOptions.id === 'agents'
            ? this.cs.agentSelectEVE.next({
                numSelected: this.selection.selected.length,
            })
            : null;*/
        if (this.sTableOptions.tableOptions.isCheckboxSelection) {
            this.checkBoxCallback.emit(this.selection.selected);
        }
        const tablename = this.sTableOptions.tableOptions.title;
        this.commonService.selectedRows.next({ row: this.selection.selected, table: tablename });
        // this.cdr.detectChanges();
    }

    masterCompToggle(): void {
        this.isAllCompSelected()
            ? this.compSelection.clear()
            : this.dataSource.data.forEach((row) =>
                this.compSelection.select(row)
            );
        this.cdr.detectChanges()
    }

    customSort(event: any): void {
        this._sTableOptions.sortOptions = event;
        if (event.direction !== '') { this.sortOptions = event.direction; };
        if (event.direction === '') { event.direction = (this.sortOptions === 'desc') ? 'asc' : 'desc'; };
        if (this.tableOptions.serverSide) {
            this.getCollectionData();
        } else {
            this.sortCallback.emit(event);
        }
    }
    exportAllData(): void {
        const keymap = {};
        // @ts-ignore
        this._sTableOptions.columns.forEach((obj) => {
            // @ts-ignore
            keymap[obj.columnDef] = obj.header;
        });
        // @ts-ignore
        keymap._id = keymap._id ? keymap._id : '_id';
        this.loaderService.display(true);
        const name =
            this.tableOptions.title !== ''
                ? this.tableOptions.title.replace(/[^A-Z0-9]/gi, '_')
                : 'Report';
        this.baseService
            .doRequest('/api/standardreports/generateReport', 'post', {
                query: this._sTableOptions.query,
                keyMap: keymap,
                name,
            })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (result: any) => {
                    this.loaderService.display(false);
                    if (result) {
                        window.open(result.msg, '_blank');
                    } else {
                        const data = (result.message) ? result.message : result.data;
                        this.toast.sToast('error', data);
                    }
                },
                () => {
                    this.loaderService.display(false);
                }
            );
    }

    SaveSettings(): void {
        let item: any;
        item = {
            columnRepr: JSON.stringify(this._sTableOptions.columns),
            refreshInterval: +this.selectedTimer,
            settings: {
                filterText: this.filterText,
                gFilter: this._sTableOptions.gFilter
                    ? this._sTableOptions.gFilter
                    : [],
                sortOptions: this._sTableOptions.sortOptions,
                pageSize: this._sTableOptions.tableOptions.pageSize,
            },
        };
        const table_settings: any = { 'category': 'table_settings', 'sub_category': this._sTableOptions.tableOptions.id, 'name': this.baseService.user().email, 'value': item };
        item.settings.sortOptions.direction =
            this._sTableOptions.sortOptions.direction === ''
                ? 'asc'
                : this._sTableOptions.sortOptions.direction;
        const method =
            this.sessionData && this.sessionData.id ? 'patch' : 'post';
        const request: any = { data: table_settings };
        if (this.sessionData && this.sessionData.id) { request.id = this.sessionData.id; }
        this.baseService
            .doRequest(`/w/company/settings`, method, request)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                if (res.status) {
                    this.toast.sToast('success', 'Successfully updated!');
                } else {
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                }
            });
    }

    ResetSettings(): void {
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: `Are you sure you want to reset ?`,
            actions: {
                confirm: {
                    label: 'Yes'
                }
            }
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this.baseService.doRequest(`d/company/settings/${this.sessionData.id}`, 'delete')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        if (result.status) {
                            this.toast.sToast('success', 'Reseted successfully');
                            setTimeout(() => {
                                this.RefreshTableData();
                            }, 3000);
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        })
    }

    doFilter = (value: string) => {
        // this.isActionChanged = true;
        if (this._sTableOptions.tableOptions.isDefaultSearch) {
            this.dataSource.filter = value.trim().toLocaleLowerCase();
            return;
        }
        localStorage.setItem(this.tableOptions.id, value);
        if (!this.tableOptions.serverSide) {
            this.filterCallback.emit(value);
        } else if (this.tableOptions.serverSide) {
            this.currentPageIndex = 0;
            this.getCollectionData(value);
        } else {
            this.dataSource.filter = value.trim().toLocaleLowerCase();
        }
    };
    doColumFilter = (value: string, col: string): any => {
        if (this.tableOptions.isServerSide) {
            // @ts-ignore
            if (this.colFilters.filter((x) => x.key === col).length > 0) {
                // @ts-ignore
                this.colFilters.forEach((obj, index) => {
                    if (col === obj.key && value === '') {
                        this.colFilters.splice(index, 1);
                    } else {
                        obj.value = value;
                    }
                });
            } else {
                if (value === '') {
                    return false;
                }
                const colName = this._sTableOptions.columns.filter(
                    (x: any) => x.columnDef === col
                )[0].header;
                this.colFilters.push({ key: col, name: colName, value });
            }
            this.colFilterCallback.emit({ value, col });
        } else {
            this.filterValues = value.toLowerCase();
            this.dataSource.data = this.filterArray.filter((row: any) =>
                row[this.searchedColName]
                    .toLowerCase()
                    .includes(this.filterValues)
            );
        }
    };


    changeHeader(header: any): any {
        return header.replace(/\s+/g, '_').toLowerCase();
    }
}

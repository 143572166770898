import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/material.module';
import { UntypedFormGroup, UntypedFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { ModalService } from 'app/_services/modal.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { FuseAlertComponent } from '@fuse/components/alert';
import { HttpClient } from '@angular/common/http';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-white-label',
  standalone: true,
  imports: [CommonModule, MaterialModule, FuseAlertComponent, ReactiveFormsModule],
  templateUrl: './white-label.component.html',
  styleUrls: ['./white-label.component.scss']
})
export class WhiteLabelComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  editMode = false;
  images: any = {};
  whiteLabelForm: UntypedFormGroup;
  defaultImages: any = {
    logo_dark: '/assets/images/logo/cs_logo.png',
    logo: '/assets/images/logo/cs_logo_light.png',
    icon: '/favicon-16x16.png',
    icon_dark: '/favicon-16x16.png',
  };
  constructor(
    public cs: CommonService,
    private _http: HttpClient,
    private _bs: BaseRequestService,
    private _changeDetectorRef: ChangeDetectorRef,
    public modalService: ModalService,
    private _ls: LoaderService,
    private _formBuilder: UntypedFormBuilder,
    public _toast: MyToastrService,
    public confirmDialog: FuseConfirmationService
  ) { }
  ngOnInit(): void {
    this.getWhiteLabelSetting();
    this.whiteLabelForm = this._formBuilder.group({
      logo_dark: [''],
      logo: [''],
      icon: [''],
      icon_dark: [''],
      page_title: [''],
      terms_of_service: [''],
    });
  }

  /**
     * On destroy
     */
  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
  }

  getWhiteLabelSetting(): void {
    this._ls.display(true);
    const params: any = { condition: true, skip: 0, limit: 100 }
    params.condition = "company_id IS NULL and category='tenant' and name='white_label'";
    this._bs.doRequest(`/r/company/settings`, 'get', null, params)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((result: any) => {
      this._ls.display(false);
      if (result.status) {
        if (result.data && result.data.length) {
          const img = result.data[0].value;
          this.images = {
            logo_dark: img.logo_url_dark || '/assets/images/logo/cs_logo.png',
            logo: img.logo_url || '/assets/images/logo/cs_logo_light.png',
            icon: img.icon_url || '/favicon-16x16.png',
            icon_dark: img.icon_url_dark || '/favicon-16x16.png',
          };
        } else {
          this.images = {
            logo_dark: '/assets/images/logo/cs_logo.png',
            logo: '/assets/images/logo/cs_logo_light.png',
            icon: '/favicon-16x16.png',
            icon_dark: '/favicon-16x16.png',
          };
        }
      }
      else {
        const data = (result.message)? result.message : result.data;  this._toast.sToast('error', data)
      }
    })
  }

  toggleEditMode(editMode: boolean | null = null): void {
    if (editMode === null) {
      this.editMode = !this.editMode;
    }
    else {
      this.editMode = editMode;
    }

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  uploadImage($event: any, key: any): void {
    if (!$event.target.files.length) {
      return;
    }
    const allowedTypes = ['image/jpeg', 'image/png'];
    const file = $event.target.files[0];

    if (file) {
      const maxSizeInBytes = 524288; // 524 kB (1 kB = 1024 bytes)
      if (file.size > maxSizeInBytes) { // Handle the case where the file size exceeds the limit
        this._toast.sToast('error', 'File size exceeds the limit.');
        $event.target.value = null;
        return;
      }
    }
    if (!allowedTypes.includes(file.type)) {
      return;
    }
    // @ts-ignore
    const imageFormControl = this.whiteLabelForm.get(key);
    imageFormControl.setValue(file);

    const reader = new FileReader();
    reader.readAsDataURL($event.target.files[0]);
    reader.onload = (ev) => {
      // @ts-ignore
      this.images[key] = reader.result;
    };
  }

  // Function to retrieve image from URL
  getImageFromURL(url: string) {
    return this._http.get(url, { responseType: 'blob' });
  }


  removeImage(key: any) {
    const confirmation = this.confirmDialog.open({
      title: "Confirmation",
      message: 'Are you sure you would like to reset ?',
      icon: { show: false, name: "", color: "accent" },
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" }
      },
      dismissible: false
    });
    confirmation.afterClosed().subscribe(async (result: any) => {
      if (result === 'confirmed') {
        this._ls.display(true);
        const imgUrl = `${window.location.origin}${this.defaultImages[key]}`;
        const images = this.whiteLabelForm.getRawValue();
        const formData = new FormData();
        Object.keys(images).forEach((obj: any) => {
          formData.append(obj, '');
        });
        this.getImageFromURL(imgUrl).subscribe(
          (imageBlob: Blob) => {
            const name = this.defaultImages[key].split('/');
            formData.append(key, imageBlob, name[name.length - 1]);
            this._bs.uploadFormData('w/setting/upload_logo', formData).subscribe(
              (responseData: any) => {
                this._ls.display(false);
                this._toast.sToast('success', `Logo/Icon has been reset successfully!`);
                this.editMode = false;
                this.getWhiteLabelSetting();
                this._bs.getWhiteLabelSetting();
              },
              (error: any) => { console.error('Error posting image to API:', error); this._ls.display(false); }
            );
          },
          (error: any) => { console.error('Error retrieving image:', error); this._ls.display(false); }
        );
      }
    });

  }

  async resetDefaultImages(): Promise<any> {
    const confirmation = this.confirmDialog.open({
      title: "Confirmation",
      message: 'Are you sure you would like to reset ?',
      icon: { show: false, name: "", color: "accent" },
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" }
      },
      dismissible: false
    });
    confirmation.afterClosed().subscribe(async (result: any) => {
      if (result === 'confirmed') {
        this._ls.display(true);
        const images = this.whiteLabelForm.getRawValue();
        const formData = new FormData();
        Object.keys(images).forEach((obj: any) => {
          if (['page_title', 'terms_of_service'].indexOf(obj) === -1) {
            this.getImageFromURL(`${window.location.origin}${this.defaultImages[obj]}`).subscribe(
              (imageBlob: Blob) => {
                const name = this.defaultImages[obj].split('/');
                formData.append(obj, imageBlob, name[name.length - 1]);
              })
          } else {
            formData.append(obj, '');
          }
        });
        setTimeout(() => {
          this._bs.uploadFormData('w/setting/upload_logo', formData).subscribe(
            (responseData: any) => {
              this._ls.display(false);
              this._toast.sToast('success', `White label settings has been reset successfully!`);
              this.editMode = false;
              this.getWhiteLabelSetting();
              this._bs.getWhiteLabelSetting();
            },
            (error: any) => console.error('Error posting image to API:', error)
          );
        }, 1000);
      }
    });
  }

  areAllValuesEmpty(obj: { [key: string]: any }): boolean {
    return Object.values(obj).every(value => !value);
  }
  async saveImage(): Promise<any> {
    const images = this.whiteLabelForm.getRawValue();
    if (this.areAllValuesEmpty(images)) { return };
    const confirmation = this.confirmDialog.open({
      title: "Confirmation",
      message: 'This action requires a page refresh. Are you sure you would like to update the logos ?',
      icon: { show: false, name: "", color: "accent" },
      actions: {
        confirm: { show: true, label: "Yes", color: "primary" },
        cancel: { show: true, label: "No" }
      },
      dismissible: false
    });
    confirmation.afterClosed().subscribe(async (result: any) => {
      if (result === 'confirmed') {
        const formData = new FormData();
        Object.keys(images).forEach((obj: any) => {
          formData.append(obj, images[obj]);
        });
        try {
          this._ls.display(true);
          const response = await this._bs.uploadFormData('w/setting/upload_logo', formData).toPromise();
          this._ls.display(false);
          if (response.status) {
            this._toast.sToast('success', `Uploaded successfully!`);
            this.editMode = false;
            this._bs.getWhiteLabelSetting();
          } else {
            this._toast.sToast('success', response.message);
          }
          this._changeDetectorRef.markForCheck();
        } catch (error) {
          // Handle errors here
          throw error;
        }
      }
    })
  }
}

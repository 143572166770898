<div class="py-2">
    <fuse-alert [type]="'info'" [appearance]="'border'">
        <span fuseAlertTitle>Backup Software</span>
        <span class="text-sm font-medium text-gray-900 dark:text-white me-3">
            The Global Backup Software contains the list of Backup Software shown in the security report card for
            the
            respective assets and also to reflect as complaint for the asset under the Essential Eight Backup
            Software
            Compliance rule.
        </span><br />
        <span class="text-sm font-medium text-gray-900 dark:text-white me-3">
            Any Backup Software that is not already listed under Global Backup Software can be added here. Please
            run a
            scan after adding here to reflect the software name in the security report card for the respective
            asset.
        </span><br />
        <span class="text-sm font-medium text-gray-900 dark:text-white me-3">
            To Exclude any of the Backup Software from the Global Backup Software list, please click on the 'X' for
            the
            selected software.
        </span>
    </fuse-alert>
    <div class="mt-4 flex flex-row items-start gap-6" *ngIf="!hideTable">
        <div
            class="relative w-[33vw] h-[50vh] bg-card pb-2 border border-gray-200 p-2 rounded-lg dark:bg-gray-800 dark:border-gray-700">
            <div class="flex flex-col">
                <div class="flex flex-row items-center gap-2">
                    <div class="text-lg flex flex-row font-medium tracking-tight leading-6 truncate">
                        Global Backup Software
                        <span *ngIf="!hideTable" mat-button (click)="toggleAddMode(true)"
                            class="ml-2 pt-1 cursor-pointer">
                            <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                        </span>
                    </div>
                    <div class="spcacer"></div>
                    <mat-form-field class="w-1/2 -mr-2 mini-form-field" appearance="fill">
                        <input type="text" matInput name="globalSearch" [(ngModel)]="globalSearch">
                    </mat-form-field>
                </div>
                <mat-divider class="my-2"></mat-divider>
                <mat-list dense class="h-[32vh] b-1" fuseScrollbar>
                    <mat-list-item class="border-b">
                        <div class="flex flex-row items-center">
                            <span>Name</span>
                            <div class="spacer">&nbsp;</div>
                            <span>Action</span>
                        </div>
                    </mat-list-item>
                    <ng-container *ngIf="globalSoftware.length; else noData1">
                        <mat-list-item class="border-b pointer"
                            *ngFor="let sw of globalSoftware | search: globalSearch;let i = index;">
                            <div class="flex flex-row items-center">
                                <span>{{sw.name}}</span>
                                <div class="spacer">&nbsp;</div>
                                <button class="ml-1 mini-form-field" type="button" (click)="changeExclude(sw)">
                                    <mat-icon color="primary">arrow_forward</mat-icon>
                                </button>
                            </div>
                        </mat-list-item>
                    </ng-container>
                    <ng-template #noData1>
                        <div class="flex text-center h-20 items-center justify-center">No software found.</div>
                    </ng-template>
                </mat-list>
            </div>
        </div>
        <div
            class="relative w-[33vw] h-[50vh] bg-card border border-gray-200 p-2 rounded-lg dark:bg-gray-800 dark:border-gray-700">
            <div class="flex flex-col">
                <div class="flex flex-row items-center gap-2">
                    <div class="text-lg flex flex-row font-medium tracking-tight leading-6 truncate">
                        Excluded Backup Software
                    </div>
                    <div class="spcacer"></div>
                    <mat-form-field class="w-1/2 -mr-2 mini-form-field" appearance="fill">
                        <input type="text" matInput name="exSearch" [(ngModel)]="excludedSearch">
                    </mat-form-field>
                </div>
                <mat-divider class="my-2"></mat-divider>
                <mat-list dense class="h-[32vh] b-1" fuseScrollbar>
                    <mat-list-item class="border-b">
                        <div class="flex flex-row items-center">
                            <span>Action</span>
                            <div class="spacer">&nbsp;</div>
                            <span>Name</span>
                        </div>
                    </mat-list-item>
                    <ng-container *ngIf="excludedSoftware.length; else noData1">
                        <mat-list-item class="border-b pointer"
                            *ngFor="let sw of excludedSoftware | search: excludedSearch;let i = index;">
                            <div class="flex flex-row items-center">
                                <button class="ml-1 mini-form-field" type="button" (click)="removeExclude(sw)">
                                    <mat-icon color="primary">arrow_back</mat-icon>
                                </button>
                                <div class="spacer">&nbsp;</div>
                                <span>{{sw}}</span>
                            </div>
                        </mat-list-item>
                    </ng-container>
                    <ng-template #noData1>
                        <div class="flex text-center h-20 items-center justify-center">No software found.</div>
                    </ng-template>
                </mat-list>

            </div>
        </div>
    </div>
    <div class="mt-4" *ngIf="hideTable">
        <div
            class="grid grid-cols-2 mt-4 gap-4 bg-card border border-gray-200 p-2 rounded-lg dark:bg-gray-800 dark:border-gray-700">
            <mat-form-field>
                <mat-label>Software Name</mat-label>
                <input matInput placeholder="Enter name" type="text" required [(ngModel)]="backupSoftware.name"
                    autocomplete="off" name="softwareName">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Manufacturer</mat-label>
                <input matInput placeholder="Enter manufacturer" type="text" [(ngModel)]="backupSoftware.manufacturer"
                    autocomplete="off" name="softwareManufacturer">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Year Of Release</mat-label>
                <input matInput placeholder="Enter year of release" type="number"
                    [(ngModel)]="backupSoftware.year_of_release" autocomplete="off" name="yearOfRelease">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Description</mat-label>
                <input matInput placeholder="Enter description" type="text" [(ngModel)]="backupSoftware.description"
                    autocomplete="off" name="softwareDesc">
            </mat-form-field>
            <mat-checkbox name="softwareRegex" id="softwareRegex" class="ml-2" [(ngModel)]="regex" value=""
                color="primary">Enable
                Regex
            </mat-checkbox>
        </div>
        <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'default'" appPreventMultiClick [matTooltip]="'Cancel'"
                (click)="toggleAddMode(false)">
                Cancel
            </button>
            <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick [matTooltip]="'Save'"
                (click)="saveBackupSoftware()">
                Save
            </button>
        </div>
    </div>
</div>
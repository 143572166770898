import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {
    ApplicationConfig, ErrorHandler,
    importProvidersFrom,
} from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    PreloadAllModules,
    provideRouter,
    withInMemoryScrolling,
    withPreloading,
} from '@angular/router';
import { provideFuse } from '@fuse';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { provideTransloco } from 'app/core/transloco/transloco.provider';
import { mockApiServices } from 'app/mock-api';
import { ToastrModule } from 'ngx-toastr';
import { provideZitadel } from './core/zitadel/zitadel.provider';
import {CustomErrorHandlerService} from "./_services/customErrorHandler.service";
import {HttpInterceptorService} from "./_services/http-interceptor.service";

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideRouter(
            appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
        ),
        importProvidersFrom(
            ToastrModule.forRoot({
                timeOut: 0,
                progressBar: true,
                enableHtml: true,
                closeButton: true,
                progressAnimation: 'increasing',
                preventDuplicates: true,
            })
        ),
        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {provide: ErrorHandler, useClass: CustomErrorHandlerService},
        {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D',
                },
                display: {
                    dateInput: 'DDD',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },

        // Transloco Config
        provideTransloco(),

        // Fuse
        provideAuth(),
        provideIcons(),
        provideFuse({
            mockApi: {
                delay: 0,
                services: mockApiServices,
            },
            fuse: {
                layout: 'centered',
                dynamiPosition:'right',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-default',
                view: 'global',
                views: [
                    {
                        id: 'global',
                        name: 'Global',
                    },
                    {
                        id: 'company',
                        name: 'Company',
                    },
                    {
                        id: 'agent',
                        name: 'Agent',
                    },
                ],
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),
        provideZitadel(),
    ],
};

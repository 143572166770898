import {BooleanInput} from '@angular/cdk/coercion';
import {CommonModule, NgClass, NgFor, NgIf, NgOptimizedImage} from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {Router} from '@angular/router';
import {BaseRequestService} from 'app/_services/base.service';
import {UserService} from 'app/core/user/user.service';
import {Subject, takeUntil} from 'rxjs';
import {CommonService} from "../../../_services/common.service";
import {LoaderService} from 'app/_services/loader.service';
import {MyToastrService} from 'app/_services/toastr.service';
import {MaterialModule} from 'app/material.module';
import {ModalComponent} from 'app/modules/shared/modal.component';
import {ModalService} from 'app/_services/modal.service';
import {FuseScrollbarDirective} from '@fuse/directives/scrollbar';
import {FuseAlertComponent, FuseAlertType} from "../../../../@fuse/components/alert";

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatMenuModule,
        NgIf,
        NgFor,
        MatIconModule,
        NgClass,
        MatDividerModule,
        MaterialModule,
        ModalComponent,
        FuseScrollbarDirective,
        FuseAlertComponent,
        NgOptimizedImage
    ],
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user: any;
    payload: any;
    domainName: any;
    releaseNotes: any = {};
    buildInfo: any = {};
    Objectkeys = Object.keys;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    migrationInfo: any;
    showAlert = false;
    allCompanyHash: any = {};
    alert: { type: FuseAlertType; message: string } = {
        type: 'error',
        message: '',
    };

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router, public _modalService: ModalService,
        private _baseService: BaseRequestService,
        private _userService: UserService,
        private cs: CommonService,
        private toast: MyToastrService, private _ls: LoaderService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to user changes
        /* this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            }); */
        this._userService.userd$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: any) => {
                this.user = user;
                this._baseService.user.set(user);
                this._baseService.getWhiteLabelSetting();
                this.domainName = user['urn:zitadel:iam:user:resourceowner:name'];
                let s = Object.keys(user["urn:zitadel:iam:org:project:roles"])
                let tenantid = Object.keys(user["urn:zitadel:iam:org:project:roles"][s[0]])[0]
                this.payload = {
                    "sub": tenantid,
                    "email": user['email'],
                    "first_name": user['given_name'],
                    "last_name": user['family_name'],
                    "exp": Math.floor((Date.now() + 120 * 60 * 1000) / 1000),
                }
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        this._userService
            .update({
                ...this.user,
                status,
            })
            .subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void {
        this._router.navigate(['/sign-out']).then(r => console.log(r));
    }

    viewUserManagementPage(): void {
        window.open('https://authprod.myconnectsecure.com/ui/console/');
    }

    viewAuthPage() {
        window.open('https://authprod.myconnectsecure.com/ui/console/users/me');
    }

    viewDashboardPage() {
        const token = this.cs.generateToken(this.payload);
        window.open(`https://dashboard.myconnectsecure.com/sso/jwt/callback?jwt=${token}&site_identifier=${this.domainName}`);
    }

    viewDashboard(): void {
        // this._router.navigate(['/dashboard']);
    }

    viewReportPage() {
        window.open(`https://reportcs.myconnectsecure.com/reporting/site/${this.domainName}`);
    }

    viewReleaseNotes() {
        this._ls.display(true);
        this._baseService.doRequest(`${this._baseService.configURL}/getReleaseNotes`, 'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this._ls.display(false);
                if (res.status) {
                    this.releaseNotes = res.data;
                    this._modalService.open('releaseNotes');
                    this._changeDetectorRef.markForCheck();
                }
            });
    }

    viewBuildInfo() {
        this._ls.display(true);
        this._baseService.doRequest(`${this._baseService.configURL}/agentBuildInfo`, 'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this._ls.display(false);
                if (res.status) {
                    this.buildInfo = res.data;
                    this._modalService.open('buildInformation');
                    this._changeDetectorRef.markForCheck();
                }
            });
    }

    getCompanies(): void {
        const condition = {
            condition: true,
            skip: 0,
            limit: 1000,
            order_by: 'created desc'
        };
        this._baseService.doRequest('/r/company/companies', 'get', null, condition)
            .pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
            if (result.status) {
                result.data.forEach((obj: any) => {
                    if (obj.old_id) {
                        this.allCompanyHash[obj.old_id] = obj;
                    }
                });
                this.viewMigrationStatusPage();
            }
        });
    }

    viewMigrationStatusPage(): void {
        this.showAlert = false;
        this._ls.display(true);
        let tid = localStorage.getItem('xtid');
        this._baseService.doRequest(`${this._baseService.configURL}/getMigrationStatus`, 'post', {tid})
            .pipe(takeUntil(this._unsubscribeAll)).subscribe((res: any) => {
            this._ls.display(false);
            if (res.status && res.data) {
                this.migrationInfo = res.data;
                this.migrationInfo.companies = Object.keys(this.migrationInfo.data).length;
                this.migrationInfo.migrated = 0;
                this.migrationInfo.pending = 0;
                this.migrationInfo.offline = 0;
                Object.keys(this.migrationInfo.data).forEach((obj: any) => {
                    this.migrationInfo.migrated += this.migrationInfo.data[obj].migrated;
                    this.migrationInfo.pending += this.migrationInfo.data[obj].pending;
                    this.migrationInfo.offline += this.migrationInfo.data[obj].offline;
                });
                this._modalService.open('migrationStatus');
                this._changeDetectorRef.markForCheck();
            } else {
                this.showAlert = true;
                this.alert.message = 'We appreciate your patience. The replication process is in queue.' +
                    ' Please revisit at a later time for updates. Thank you.';
                this._modalService.open('migrationStatus');
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    viewLink() {
        window.open('https://cybercns.atlassian.net/l/cp/2n7BVMmt')
    }
}

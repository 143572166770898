<div class="p-4">
    <ng-container>
        <div class="flex mt-4">
            <mat-form-field class="w-1/2" [subscriptSizing]="'dynamic'">
                <mat-label>Choose Mode</mat-label>
                <mat-select name="ChooseCom" required [(ngModel)]="company_type">
                    <mat-option value="local"> Local</mat-option>
                    <mat-option value="psa"> PSA Companies </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <ng-container *ngIf="company_type === 'local'"> 
            <div class="flex flex-col flex-auto pt-4" *ngIf="companyElements && companyElements.length">
                <app-dynamic-form [Valuesoutput]="company" [listOfFormElements]="companyElements"
                    (saveCallBack)="save($event)" [fnBtnName]="'Save'" [isCloseBtn]="false"></app-dynamic-form>
            </div>
        </ng-container>
        <ng-container *ngIf="company_type === 'psa'">
            <div class="mt-6">
                <app-import-company></app-import-company>
            </div>
        </ng-container>
    </ng-container>
</div>
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    DatePipe,
    DecimalPipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, EventEmitter, Input,
    OnDestroy,
    OnInit, Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, Scroll } from '@angular/router';
import { ScopeService } from 'app/layout/common/scope/scope.service';
import { Scope } from 'app/layout/common/scope/scope.types';
import {
    BehaviorSubject,
    debounceTime, filter,
    interval,
    ReplaySubject,
    Subject,
    Subscription,
    takeUntil,
} from 'rxjs';
import { CommonService } from '../../../_services/common.service';
import { MaterialModule } from '../../../material.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AppFilterPipeModule } from '../../../_filters/app.filter-pipe.module';
import { BaseRequestService } from '../../../_services/base.service';
import { MatSelect } from '@angular/material/select';
import { DynamicFormComponent } from "../../../modules/shared/forms/dynamic-form/dynamic-form.component";
import { LoaderService } from "../../../_services/loader.service";
import { MyToastrService } from "../../../_services/toastr.service";
import { UserService } from 'app/core/user/user.service';

@Component({
    selector: 'scope',
    templateUrl: './scope.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'scope',
    standalone: true,
    imports: [
        MatButtonModule,
        NgIf,
        MatIconModule,
        MatTooltipModule,
        NgFor,
        NgClass,
        NgTemplateOutlet,
        RouterLink,
        DatePipe,
        MaterialModule,
        NgxMatSelectSearchModule,
        ReactiveFormsModule,
        DecimalPipe,
        AppFilterPipeModule,
        DynamicFormComponent,
    ],
})
export class ScopeComponent implements OnInit, OnDestroy {
    @ViewChild('companySelect', { static: true }) companySelect!: MatSelect;
    @ViewChild('scopeOrigin') private _scopeOrigin: MatButton;
    @ViewChild('scopePanel') private _scopePanel: TemplateRef<any>;
    @ViewChild('companyPanel') private _companyPanel: TemplateRef<any>;
    @Input() isLocal = false;
    @Output() updateCompany = new EventEmitter();
    protected onDestroySearch = new Subject<void>();
    scope: Scope[];
    company_id: any;
    company: any;
    public companySourceHash: any = {};
    cScope: any;
    isSearchK = false;
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    public companyCtrl: FormControl = new FormControl();
    public companyFilterCtrl: FormControl = new FormControl();
    public companySourceFilterCtrl: FormControl = new FormControl();
    public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
    public companies: any = [];
    public sourceCompanies: any = [];
    public allSourceComp: any = [];
    totalCompany: any;
    selectedCompany: any;
    companyElements: any = [];
    formElements: any = [
        {
            "name": "name",
            "description": "Enter Company Name",
            "example": "Ex. Million Dollar Company",
            "required": true,
            min: 3,
            max: 36,
            "schema": {
                "type": "text"
            }
        },
        {
            "name": "description",
            "description": "Enter Description",
            "example": "Ex. Banking, Tampa Florida",
            "required": false,
            "schema": {
                "type": "textarea"
            }
        }
    ];
    updateCmp: Subscription;
    newCmp: Subscription;
    subs: Subscription;
    showCmp: Subscription;
    currentSource: 'azure' | 'ad' | '' = '';
    showCom = true;
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _scopeService: ScopeService, private route: ActivatedRoute,
        private _overlay: Overlay, private toast: MyToastrService,
        private _viewContainerRef: ViewContainerRef,
        public cs: CommonService, private loaderService: LoaderService,
        private _baseRequestService: BaseRequestService,
        private _router: Router, public _us: UserService
    ) {
        console.log('cs.checkLayout',this.cs.checkLayout); 
        this.companyElements = this.cs.processFormElements(this.formElements);
        this.subs = this.cs.selectedSiteChanged.subscribe((res) => {
            this.cScope = res;
            if (!this.companies.filter((x) => x.id === res)
                || !this.companies.filter((x) => x.id === res).length) {
                if (res !== '*') {
                    this.companies.push({ id: res, name: this.cs.companyHash[res].name });
                }
            }
            this._changeDetectorRef.markForCheck();
        });
        // this.companyFilterCtrl.valueChanges
        //     .pipe(debounceTime(300), takeUntil(this.onDestroySearch))
        //     .subscribe(() => {
        //         this.filterCompanies();
        //     });
        this.newCmp = this.cs.newCompanyCall.subscribe((res) => {
            this.getCompanies();
        });
        this.showCmp = this.cs.showHideCom.subscribe((res) => {
            this.showCom = true;
            this._changeDetectorRef.markForCheck();
        })
        this.updateCmp = this.cs.updateCompanyCall.subscribe((res) => {
            this.companyCtrl.setValue(res);
            this.updateCurrentCompany(res);
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to notification changes
        this._scopeService.scope$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((scope: Scope[]) => {
                // Load the scope
                this.scope = scope;
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
        this.getCompanies();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.subs.unsubscribe();
        this.newCmp.unsubscribe();
        this.updateCmp.unsubscribe();
        this.showCmp.unsubscribe();
        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the scope panel
     */
    openPanel(): void {
        // Return if the scope panel or its origin is not defined
        if (!this._scopePanel || !this._scopeOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(
            new TemplatePortal(this._scopePanel, this._viewContainerRef)
        );
    }

    openCompanyPanel(): void {
        this.closePanel();
        // Return if the scope panel or its origin is not defined
        if (!this._companyPanel || !this._scopeOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(
            new TemplatePortal(this._companyPanel, this._viewContainerRef)
        );
    }


    /**
     * Close the scope panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    closeCmpPanel(): void {
        this._overlayRef.detach();
        this.openPanel();
    }

    /**
     * Mark all scope as read
     */
    markAllAsRead(): void {
        // Mark all as read
        this._scopeService.markAllAsRead().subscribe();
    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification: Scope): void {
        // Toggle the read status
        notification.read = !notification.read;

        // Update the notification
        this._scopeService.update(notification.id, notification).subscribe();
    }

    /**
     * Delete the given notification
     */
    delete(notification: Scope): void {
        // Delete the notification
        this._scopeService.delete(notification.id).subscribe();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    public searching = false;
    searchTxt: any = 'Search Company';

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(
                    this._scopeOrigin._elementRef.nativeElement
                )
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    closeCurrentCompany($event: boolean) {
        this.showTimeAgo = $event;
        this.searching = false;
        if (this.cs.allComp) {
            this.companies = this.cs.allComp.slice();
            this.filteredCompanies.next(this.cs.allComp.slice());
            this._changeDetectorRef.markForCheck();
        }
        if (!$event && !this.selectedCompany) {
            this.getCompanies();
        }
    }

    updateCurrentCompany(event: any): void {
        const router = this._router.url.split('/');
        if (router.indexOf('active-directory') !== -1) {
            event === '*' ? this._router.navigateByUrl(`/overview`) : null;
        }
        if (router.indexOf('scheduler') !== -1) {
            event !== '*' ? this._router.navigateByUrl(`/overview`) : null;
        }
        this.showCom = router.indexOf('onboarding') === -1;
        try {
            this.selectedCompany = event === '*' ? event : this.cs.companyHash[event].name;
            this.cs.isComLoading = false;
            localStorage.setItem('cmp', event === '*' ? event : this.cs.companyHash[event].name);
            this.cs.currentScope = event === '*' ? event : this.cs.companyHash[event];
            this.cs.selectedSiteChanged.next(event);
        } catch (e) {
            console.log(e);
        }
    }

    showTimeAgo: boolean;

    private filterCompanies(): void {
        if (!this.companies) {
            return;
        }
        // get the search keyword
        let search = this.companyFilterCtrl.value;
        if (!search) {
            this.companies = this.cs.allComp.slice();
            this._changeDetectorRef.markForCheck();
            return;
        } else {
            search = search.toLowerCase();
        }
        this.getCompanies(search);
    }

    private filterSourceCompanies(): void {
        if (!this.sourceCompanies) {
            return;
        }
        // get the search keyword
        let search = this.companySourceFilterCtrl.value;
        search = search.toLowerCase();
        this.getSourceCompanies(search);
    }
    getCompanies(search?: string): void {
        if (
            !this._baseRequestService.user() ||
            !this._baseRequestService.user().email
        ) {
            setTimeout(() => {
                this.getCompanies(search);
            }, 1000);
            return;
        }
        this.searching = true;
        // this.cs.isComLoading = true;
        const condition: any = search
            ? { condition: "name ilike '%" + search + "%'" }
            : {
                condition: true,
                skip: 0,
                limit: 2000,
                order_by: 'name asc'
            };
        this._baseRequestService
            .doRequest('/r/company/companies', 'get', null, condition)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                if (result.status) {
                    this.totalCompany = result.total;
                    if (this.cs.allComp.length === 0 && result.total === 0 && !search) {
                        this._router.navigateByUrl(`/onboarding`);
                        this.showCom = false; this.cs.isComLoading = false;
                        this._changeDetectorRef.markForCheck();
                        return;
                    }
                    if (result.total === 0 && !search) {
                        this.companyCtrl.setValue('*');
                        this.updateCurrentCompany('*');
                        // this.cs.noCompanyEvent.emit({});
                        return;
                    }
                    if (!search) {
                        this.cs.allComp = result.data;
                        this.cs.totalCompany = result.total;
                    } else {
                        this.isSearchK = true;
                        // this.cs.allComp = Array.from(new Map([...this.cs.allComp, ...result.data].map(obj => [obj.id, obj])).values());
                    }
                    this.cs.allComp.forEach((c: any) => {
                        c.id = c.id + "";
                        this.cs.companyHash[c.id] = c;
                    });
                    this.companies = result.data.slice();
                    this._changeDetectorRef.markForCheck();
                    this.searching = false;
                    const cmp = localStorage.getItem('cmp');
                    const section = this.route.snapshot.queryParams?.company_id;
                    if (section) {
                        this.companyCtrl.setValue(parseInt(section));
                        this.updateCurrentCompany(parseInt(section));
                    } else {
                        if (cmp && cmp !== '*') {
                            const company = this.companies.filter((x: any) => x.name === cmp);
                            if (company.length) {
                                if (!search) {
                                    this.companyCtrl.setValue(company[0].id);
                                    this.updateCurrentCompany(company[0].id);
                                } else {
                                    this.cs.isComLoading = false; this.searching = false;
                                }
                            } else {
                                const condition = { condition: `name='${cmp}'` };
                                this._baseRequestService
                                    .doRequest('/r/company/companies', 'get', null, condition)
                                    .pipe(takeUntil(this._unsubscribeAll))
                                    .subscribe((result: any) => {
                                        if (result.status) {
                                            if (result.data && result.data.length) {
                                                this.cs.companyHash[result.data[0].id] = result.data[0];
                                                // this.cs.allComp = Array.from(new Map([...this.cs.allComp, ...result.data].map(obj => [obj.id, obj])).values());
                                                if (!search) {
                                                    this.companyCtrl.setValue(result.data[0].id);
                                                    this.updateCurrentCompany(result.data[0].id);
                                                } else {
                                                    this.cs.isComLoading = false; this.searching = false;
                                                }
                                            } else {
                                                if (!search) {
                                                    this.companyCtrl.setValue(this.companies[0].id);
                                                    this.updateCurrentCompany(this.companies[0].id);
                                                } else {
                                                    this.cs.isComLoading = false; this.searching = false;
                                                }
                                            }
                                        } else {
                                            if (!search) {
                                                this.companyCtrl.setValue(this.companies[0].id);
                                                this.updateCurrentCompany(this.companies[0].id);
                                            } else {
                                                this.cs.isComLoading = false; this.searching = false;
                                            }
                                        }
                                    })
                            }
                        }
                        if (!cmp || cmp === '*' && !search) {
                            this.companyCtrl.setValue('*');
                            this.updateCurrentCompany('*');
                        } else {
                            this.cs.isComLoading = false; this.searching = false;
                        }
                    }
                }
            });
    }


    getSourceCompanies(search?: string): void {
        if (
            !this._baseRequestService.user() ||
            !this._baseRequestService.user().email
        ) {
            setTimeout(() => {
                this.getSourceCompanies(search);
            }, 1000);
            return;
        }
        this.searching = true;
        const condition = search
            ? {
                condition: "company_name ilike '%" + search + "%'",
                name: 'ad_company_details',
                source: `'${this.currentSource}'`
            }
            : {
                condition: true,
                skip: 0,
                limit: 100,
                source: `'${this.currentSource}'`,
                name: 'ad_company_details',
                order_by: 'company_name desc'
            };
        // /r/company/companies
        this._baseRequestService.doRequest('/report_queries/', 'get', null, condition)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                if (result.status) {
                    this.searching = false;
                    if (result.data && result.data.length) {
                        this.totalCompany = result.total;
                        result.data.forEach((c: any) => {
                            c.company_id = c.company_id + "";
                            this.companySourceHash[c.company_id] = c;
                        });
                        if (!search) {
                            this.allSourceComp = result.data;
                        } else {
                            this.isSearchK = true;
                            this.allSourceComp = Array.from(new Map([...this.allSourceComp, ...result.data].map(obj => [obj.id, obj])).values());
                        }
                        this.sourceCompanies = result.data.slice();
                        this._changeDetectorRef.markForCheck();
                        const cmp = localStorage.getItem('cmp');
                        if (cmp && cmp !== '*') {
                            const company = this.sourceCompanies.filter((x: any) => x.company_name === cmp);
                            if (company.length) {
                                this.companyCtrl.setValue(company[0].company_id);
                                this.updateSourceCurrentCompany(company[0].company_id);
                            } else {
                                this.companyCtrl.setValue(this.sourceCompanies[0].company_id);
                                this.updateSourceCurrentCompany(this.sourceCompanies[0].company_id);
                            }
                        } else {
                            this.companyCtrl.setValue(this.sourceCompanies[0].company_id);
                            this.updateSourceCurrentCompany(this.sourceCompanies[0].company_id);
                        }
                    } else {
                        this.sourceCompanies = [];
                        this._changeDetectorRef.markForCheck();
                    }

                }
            });
    }

    closeSourceCurrentCompany($event: boolean) {
        if (this.allSourceComp) {
            this.sourceCompanies = this.allSourceComp.slice();
            this.filteredCompanies.next(this.allSourceComp.slice());
            this._changeDetectorRef.markForCheck();
        }
        if (!$event && !this.company_id) {
            this.getSourceCompanies();
        }
    }

    updateSourceCurrentCompany(event: any): void {
        this.company_id = event;
        localStorage.setItem('cmp', this.companySourceHash[event].company_name);
        if (this.cs.currentScope !== '*') {
            this.companyCtrl.setValue('*');
            this.cs.currentScope = '*';
            this.cScope = '*';
        }
        this.cs.updateSourceCompany.next({ id: event, name: this.companySourceHash[event].company_name });
    }
}

import { Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { CommonModule, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { HttpClient } from '@angular/common/http';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocomplete, MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { fuseAnimations } from '@fuse/animations';
import { Subject, debounceTime, takeUntil, map, filter } from 'rxjs';
import { Overlay, BlockScrollStrategy } from '@angular/cdk/overlay';
import cloneDeep from 'lodash/cloneDeep';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatOptionModule } from '@angular/material/core';
import { RouterLink } from '@angular/router';
import { MaterialModule } from 'app/material.module';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipsModule } from '@angular/material/chips';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  standalone: true,
  imports: [NgIf, MatButtonModule, MatChipsModule, MaterialModule, MatButtonToggleModule, MatIconModule, FormsModule, MatAutocompleteModule, ReactiveFormsModule, MatOptionModule, NgFor, RouterLink, NgTemplateOutlet, MatFormFieldModule, MatInputModule, NgClass],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: () => {
        const overlay = inject(Overlay);
        return () => overlay.scrollStrategies.block();
      },
    },
  ],
})

export class FilterComponent implements OnChanges, OnInit, OnDestroy {
  appearance: any = 'basic';
  @Input() debounce: number = 300;
  @Input() minLength: number = 2;
  @Input() showTagFilter: boolean = false;
  @Output() setTagRulesFn = new EventEmitter();
  @Output() toggleTagFilterFn = new EventEmitter();

  opened: boolean = false;
  resultSets: any[];
  searchControl: UntypedFormControl = new UntypedFormControl();
  private _matAutocomplete: MatAutocomplete;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  _tags: any = [
    {
      "title": "Server",
      "value": "Server"
    },
    {
      "title": "Workstation",
      "value": "Workstation"
    },
    {
      "title": "Windows 11 Incompatible",
      "value": "Windows 11 Incompatible"
    }
  ];

  selectedTags: any = [];
  selectedTagsHash: any = [];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();

  @ViewChild('chipInput') chipInput: ElementRef;
  /**
   * Constructor
   */
  constructor(
    private _elementRef: ElementRef,
    private _httpClient: HttpClient,
    private _renderer2: Renderer2,
    public cs: CommonService,
    private _baseService: BaseRequestService,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  @HostBinding('class') get classList(): any {
    return {
      'search-appearance-bar': this.appearance === 'bar',
      'search-appearance-basic': this.appearance === 'basic',
      'search-opened': this.opened
    };
  }


  @ViewChild('barSearchInput')
  set barSearchInput(value: ElementRef) {
    if (value) {
      setTimeout(() => {
        value.nativeElement.focus();
      });
    }
  }

  SearchPipe(item: any, args: any): any {
    return JSON.stringify(item).toLowerCase().includes(args);
  }

  @ViewChild('matAutocomplete')
  set matAutocomplete(value: MatAutocomplete) {
    this._matAutocomplete = value;
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnChanges(changes: SimpleChanges): void {
    if ('appearance' in changes) {
      this.close();
    }
  }

  selectionChange($event: any): void {
    this.selectedTags.push(`${$event.source.value.title}: ${$event.source.value.value}`);
    this.selectedTagsHash.push($event.source.value)
    this.searchControl.setValue(null); this.resultSets = [];
    this.chipInput.nativeElement.value = '';
  }


  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(this.debounce),
        takeUntil(this._unsubscribeAll),
        map((value) => {
          if (!value || value.length < this.minLength) {
            this.resultSets = null;
          }
          return value;
        }),
        filter(value => value && value.length >= this.minLength)
      )
      .subscribe((value) => {
        const params: any = value
          ? { condition: "name ilike '%" + value + "%'" }
          : {
            condition: true,
            skip: 0,
            limit: 1000,
            order_by: 'created desc'
          };
        if (this.cs.currentScope !== '*') {
          if (params.condition === true) {
            params.condition = `company_id=${parseInt(this.cs.currentScope.id)}`;
          } else {
            params.condition += ` and company_id=${parseInt(this.cs.currentScope.id)}`;
          }
        }
        this._baseService.doRequest('/r/company/tags', 'get',
          null, params)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            this._tags = [];
            if (result.status) {
              result.data.forEach((obj: any) => {
                this._tags.push({ title: obj.name, value: obj.value });
              });
              const uniqueNames: any = new Set(this.selectedTags);
              const tasksResults = cloneDeep(this._tags)
                .filter((task: any) => task.title.toLowerCase().includes(value.toLowerCase()));
              const results = [];
              const filteredData2 = (uniqueNames) ? tasksResults.filter((obj: any) => !uniqueNames.has(obj.value)) : tasksResults;
              if (filteredData2.length > 0) {
                results.push({
                  id: 'tags',
                  label: 'Tags',
                  results: filteredData2
                });
              }
              this.resultSets = results;
            }
          });
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  onKeydown(event: KeyboardEvent): void {
    // Escape
    if (event.code === 'Escape') {
      if (this.appearance === 'bar' && !this._matAutocomplete.isOpen) {
        this.close();
      }
    }
  }

  close(): void {
    if (!this.opened) {
      return;
    }

    this.searchControl.setValue('');

    this.opened = false;
  }


  trackByFn(index: number, item: any): any {
    return item.id || index;
  }


  remove(fruit: any): void {
    const index = this.selectedTags.indexOf(fruit);
    if (index >= 0) {
      this.selectedTags.splice(index, 1);
      this.selectedTagsHash.splice(index, 1);
    }
  }

  toggleTagFilter(): void {
    this.showTagFilter = !this.showTagFilter;
    if (!this.showTagFilter) {
      this.selectedTagsHash = []; this.selectedTags = [];
      this.setTagRulesFn.emit({ api: null, report: null });
    }
  }

  setTagRules(): void {
    if (this.selectedTagsHash && this.selectedTagsHash.length) {
      const output = {};
      this.selectedTagsHash.forEach((item: any) => {
        const name = item.title;
        const value = item.value;
        if (!output[name]) {
          output[name] = [value];
        } else {
          output[name].push(value);
        }
      });
      const outputString = Object.keys(output).map(key => {
        const conditions = output[key].map((value: any) => `tags @>'{"${key}": ["${value}"]}' OR manual_tags->>'${key}' = '${value}'`);
        return conditions.join(' OR ');
      }).join(' OR ')
      let tags_key_value = {};
      this.selectedTagsHash.forEach((item: any) => {
        const title = item.title;
        const value = item.value;
        if (!tags_key_value[title]) {
          tags_key_value[title] = [];
        }
        tags_key_value[title].push(value);
      });
      this.setTagRulesFn.emit({ api: outputString, report: { tags_key_value, manual_tags_key_value: tags_key_value } });
    }
  }

  resetTagRules(): void {
    this.selectedTags = []; this.selectedTagsHash = [];
    this.setTagRulesFn.emit({ api: null, report: null });
  }
}

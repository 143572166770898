<ng-container *ngIf="showData && data">
  <div
    class="flex flex-col flex-auto overflow-hidden w-full px-4 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
    <div class="my-2">
      <div class="flex flex-row items-center gap-1 p-2 flex-shrink-0 space-x-4">
        <div class="text-md font-bold text-indigo-800 dark:text-indigo-400" *ngIf="data['IP Address']">
          {{ data['IP Address'] }}
        </div>
        <div class="flex flex-row items-center text-center" *ngIf="data.Location">
          <mat-icon class="icon-size-5 text-hint" [svgIcon]="'mat_solid:location_on'"></mat-icon>
          <div class="ml-1.5 text-md text-secondary">
            {{ data.Location }}
          </div>
        </div>
        <div class="flex flex-row items-center text-center" *ngIf="data['ASN']">
          <mat-icon class="icon-size-5 text-hint" [svgIcon]="'heroicons_solid:cube'"></mat-icon>
          <div class="ml-1.5 text-md text-secondary">
            {{ data['ASN'] }}
          </div>
        </div>
        <div class="flex flex-row items-center text-center" *ngIf="data['ASN Description']">
          <mat-icon class="icon-size-5 text-hint" [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
          <div class="ml-1.5 text-md text-secondary">
            {{ data['ASN Description'] }}
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="data['ports'] && data['ports'].length && data['ports'][0]">
      <div class="border-t my-2">
        <div class="my-4">
          <div class="font-medium text-medium tracking-tight leading-6 truncate">Open Port/Protocol
            <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
              {{data['ports'].length }}
            </span>
          </div>
        </div>
      </div>
      <div class="flex flex-row flex-wrap items-center mt-2">
        <span *ngFor="let item of data['ports']" class="text-sm font-semibold mb-2 me-2 px-2.5 py-0.5 rounded border"
          [ngClass]="{'bg-grey-100 text-green-800 dark:text-green-400 border-green-400': item === 443,
             'bg-grey-100 text-red-800  dark:text-red-400 border-red-400': item !== 443}">
          {{ item }} - <span [matTooltip]="commonService.portInfo[item].description"
            *ngIf="commonService.portInfo[item]">{{commonService.portInfo[item].protocol}}</span>
        </span>
      </div>
      <mat-divider class="my-2"></mat-divider>
    </ng-container>
    <ng-container *ngIf="data['Vulnerabilities'] && data['Vulnerabilities'].length && data['Vulnerabilities'][0]">
      <div class="border-b my-2">
        <div class="my-4">
          <div class="font-medium text-medium tracking-tight leading-6 truncate">Vulnerabilities
            <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
              {{data['Vulnerabilities'].length }}
            </span>
          </div>
        </div>
      </div>
      <ng-container *ngFor="let cveDetails of data['Vulnerabilities']">
        <div class="flex flex-col items-start justify-start" *ngIf="cveDetails">
          <div class="flex flex-row items-center justify-center mt-4">
            <div class="text-medium font-semibold leading-tight mr-1">{{cveDetails.cve}}</div>
            <span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full"
              [ngClass]="'cs-' + cveDetails.severity?.toLowerCase()">
              {{cveDetails.severity}}
            </span>
          </div>
          <div class="text-secondary text-medium leading-tight mt-2">{{cveDetails.description}}</div>
          <ng-container *ngIf="cveDetails && cveDetails.scores">
            <hr class="w-full border-t my-2">
            <div class="w-1/2 flex items-start gap-1 justify-between">
              <div class="flex flex-col items-center">
                <span class="font-bold">
                  <span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full" [ngClass]="{
                                              'cs-critical': cveDetails.scores.epss_score >= 0.95,
                                              'cs-high': cveDetails.scores.epss_score >= 0.90 && cveDetails.scores.epss_score <= 0.94,
                                              'cs-medium': cveDetails.scores.epss_score >= 0.85 && cveDetails.scores.epss_score <= 0.89,
                                              'cs-low': cveDetails.scores.epss_score >= 0.00 && cveDetails.scores.epss_score <= 0.84,
                                              }">
                    {{cveDetails.scores.epss_score}}
                  </span>
                </span>
                <span class="text-sm font-medium text-secondary mt-1">EPSS SCORE</span>
              </div>
              <div class="flex flex-col items-center">
                <span class="font-bold">
                  <span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full cs-critical">
                    {{cveDetails.scores.base_score}}
                  </span>
                </span>
                <span class="text-sm font-medium text-secondary mt-1">BASE SCORE</span>
              </div>
              <div class="flex flex-col items-center">
                <span class="font-bold"><span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full cs-critical">
                    {{cveDetails.scores.impact_score}}
                  </span></span>
                <span class="text-sm font-medium text-secondary mt-1">IMPACT SCORE</span>
              </div>
              <div class="flex flex-col items-center">
                <span class="font-bold"><span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full cs-critical">
                    {{cveDetails.scores.exploitability_score}}
                  </span></span>
                <span class="text-sm font-medium text-secondary mt-1">EXPLOITABILITY SCORE</span>
              </div>
            </div>
            <hr class="w-full border-t mt-2 mb-8">
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
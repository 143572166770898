import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild, ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { AppFilterPipeModule } from "../../../../_filters/app.filter-pipe.module";
import { DynamicFormComponent } from "../../../shared/forms/dynamic-form/dynamic-form.component";
import { MatButton, MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatOptionModule } from "@angular/material/core";
import { MatSelect, MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { Router, RouterLink } from "@angular/router";
import { MaterialModule } from "../../../../material.module";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { debounceTime, ReplaySubject, Subject, Subscription, takeUntil } from "rxjs";
import { Scope } from "../../../../layout/common/scope/scope.types";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ScopeService } from "../../../../layout/common/scope/scope.service";
import { MyToastrService } from "../../../../_services/toastr.service";
import { CommonService } from "../../../../_services/common.service";
import { LoaderService } from "../../../../_services/loader.service";
import { BaseRequestService } from "../../../../_services/base.service";
import { TemplatePortal } from "@angular/cdk/portal";
import { ImportCompanyComponent } from 'app/modules/admin/companies/import-company/import-company.component';

@Component({
    selector: 'new-company',
    standalone: true,
    templateUrl: './new-company.component.html',
    styleUrls: ['./new-company.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'new-company',
    imports: [
        MatButtonModule,
        NgIf,
        MatIconModule,
        MatTooltipModule,
        NgFor,
        NgClass,
        NgTemplateOutlet,
        RouterLink,
        DatePipe,
        MaterialModule,
        NgxMatSelectSearchModule,
        ReactiveFormsModule,
        DecimalPipe,
        AppFilterPipeModule,
        DynamicFormComponent,
        ImportCompanyComponent,
        FormsModule
    ],
})
export class NewCompanyComponent implements OnInit, OnDestroy {
    @ViewChild('companySelect', { static: true }) companySelect!: MatSelect;
    @ViewChild('scopeOrigin') private _scopeOrigin: MatButton;
    @ViewChild('scopePanel') private _scopePanel: TemplateRef<any>;
    @ViewChild('companyPanel') private _companyPanel: TemplateRef<any>;
    protected onDestroySearch = new Subject<void>();
    scope: Scope[];
    public companyHash: any = { '*': { name: 'Global' } };
    cScope: any;
    unreadCount: number = 0;
    company_type: any = 'local';
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    public companyCtrl: FormControl = new FormControl();
    public companyFilterCtrl: FormControl = new FormControl();
    public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
    public companies: any = [];
    public allComp: any = [];
    selectedCompany: any;
    companyElements: any = [];
    subs: Subscription;
    company: any = {
        name: '', description: ''
    }
    formElements: any = [
        {
            "name": "name",
            "description": "Enter Company Name",
            "example": "Ex. Million Dollar Company",
            "required": true,
            min: 3,
            max: 36,
            "schema": {
                "type": "text"
            }
        },
        {
            "name": "description",
            "description": "Enter Description",
            "example": "Ex. Banking, Tampa Florida",
            "required": true,
            "schema": {
                "type": "textarea"
            }
        },
        {
            name: "customer_name",
            description: "Customer Name",
            schema: {
                type: "text",
            },
        },
        {
            name: "adddress_city",
            description: "City",
            schema: {
                type: "text",
            },
        },
        {
            name: "adddress_state",
            description: "State",
            schema: {
                type: "text",
            },
        },
        {
            name: "adddress_country",
            description: "Country",
            schema: {
                type: "text",
            },
        },
        {
            name: "adddress_zipcode",
            description: "Zip Code",
            schema: {
                type: "number",
            },
        }
    ];

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _scopeService: ScopeService,
        private _overlay: Overlay, private toast: MyToastrService,
        private _viewContainerRef: ViewContainerRef,
        public cs: CommonService, private loaderService: LoaderService,
        private _bs: BaseRequestService,
        private _router: Router,
    ) {
        this.companyElements = this.cs.processFormElements(this.formElements);
        this.subs = this.cs.selectedSiteChanged.subscribe((res) => {
            this.cScope = res;
            this._changeDetectorRef.markForCheck();
        });
        this.companyFilterCtrl.valueChanges
            .pipe(debounceTime(300), takeUntil(this.onDestroySearch))
            .subscribe(() => {
                this.filterCompanies();
            });
    }



    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.companyCtrl.setValue('*');
        this.cs.currentScope = '*';
        this.cScope = '*';
        // Subscribe to notification changes
        this._scopeService.scope$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((scope: Scope[]) => {
                // Load the scope
                this.scope = scope;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
        // this.getCompanies();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.subs.unsubscribe();
        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the scope panel
     */
    openPanel(): void {
        // Return if the scope panel or its origin is not defined
        if (!this._companyPanel || !this._scopeOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(
            new TemplatePortal(this._companyPanel, this._viewContainerRef)
        );
    }


    /**
     * Close the scope panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    public searching = false;
    searchTxt: any = 'Search Company';

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(
                    this._scopeOrigin._elementRef.nativeElement
                )
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    closeCurrentCompany($event: boolean) {
        if (this.allComp) {
            this.companies = Object.assign([], this.allComp);
            this.filteredCompanies.next(this.allComp.slice());
        }
        if (!$event && !this.selectedCompany) {
            this.getCompanies();
        }
    }

    updateCurrentCompany(event: any): void {
        this.selectedCompany =
            event === '*' ? event : this.companyHash[event].name;
        localStorage.setItem('cmp', this.selectedCompany);
        this.cs.currentScope = event === '*' ? event : this.companyHash[event];
        this.cs.selectedSiteChanged.next(event);
    }

    showTimeAgo: boolean;

    private filterCompanies(): void {
        if (!this.companies) {
            return;
        }
        // get the search keyword
        let search = this.companyFilterCtrl.value;
        if (!search) {
            this.companies = this.allComp.slice();
            this._changeDetectorRef.markForCheck();
            return;
        } else {
            search = search.toLowerCase();
        }
        this.getCompanies(search);
    }

    getCompanies(search?: string): void {
        if (
            !this._bs.user() ||
            !this._bs.user().email
        ) {
            setTimeout(() => {
                this.getCompanies(search);
            }, 1000);
            return;
        }
        this.searching = true;
        const condition = search
            ? { condition: "name ilike '%" + search + "%'" }
            : {
                condition: true,
                skip: 0,
                limit: 50,
                order_by: 'created desc'
            };
        this._bs
            .doRequest('/r/company/companies', 'get', null, condition)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                if (result.status) {
                    result.data.forEach((c: any) => {
                        this.companyHash[c.id] = c;
                    });
                    if (!search) {
                        this.allComp = result.data;
                    }
                    this.companies = result.data;
                    this._changeDetectorRef.markForCheck();
                    this.searching = false;
                }
            });
    }

    save($event: any) {
        this.loaderService.display(true);
        this._bs.doRequest('/w/company/companies', 'post', { data: $event })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => {
            this.loaderService.display(false);
            if (result.status) {
                localStorage.setItem('isNew', 'yes');
                this.toast.sToast('success', `Company ${$event.name} created successfully!`);
                this.company = {}; this.companyElements = []; this.company = { name: '', description: '' };
                this.companyElements = this.cs.processFormElements(this.formElements);
                this.cs.currentScope = { id: result.id, name: $event.name };
                $event.id = result.id;
                this.cs.companyHash[result.id] = $event;
                localStorage.setItem('cmp', $event.name);
                this.cs.newCompanyCall.emit({});
                this._changeDetectorRef.markForCheck();
                setTimeout(() => {
                    this.cs.dynamicSettingsToggle.emit(true);
                    this._router.navigateByUrl(`/overview/company/${result.id}`);
                }, 3000);
            } else {
                const data = (result.message)? result.message : result.data;
this.toast.sToast('error', data);
            }
        })
    }
}

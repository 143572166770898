import {
  CommonModule,
  NgFor,
  NgClass,
  NgSwitch,
  NgSwitchCase,
  NgIf,
  DatePipe,
} from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDrawer, MatSidenavModule } from "@angular/material/sidenav";
import { FuseScrollbarDirective } from "@fuse/directives/scrollbar";
import { TranslocoModule } from "@ngneat/transloco";
import { ButtonModule } from "@syncfusion/ej2-angular-buttons";
import { AppFilterPipeModule } from "app/_filters/app.filter-pipe.module";
import { BaseRequestService } from "app/_services/base.service";
import { LoaderService } from "app/_services/loader.service";
import { ModalService } from "app/_services/modal.service";
import { MyToastrService } from "app/_services/toastr.service";
import { MaterialModule } from "app/material.module";
import { TableComponent } from "app/modules/shared/table/table.component";
import { Subject, Subscription, takeUntil } from "rxjs";
import { TimelineComponent } from "../../../shared/timeline/timeline.component";
import { AsmResultsComponent } from "../../attack-surface-mapper/asm-results/asm-results.component";
import { Router } from "@angular/router";
import { ShowMoreLessComponent } from "app/modules/shared/show-more-less/show-more-less.component";
import { CommonService } from "app/_services/common.service";
import { DynamicSettingsService } from "app/layout/common/dynamic-settings/dynamic-settings.service";
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { P } from "@angular/cdk/keycodes";

@Component({
  selector: "app-job-view",
  // changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ShowMoreLessComponent,
    TranslocoModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    NgFor,
    NgClass,
    NgSwitch,
    NgSwitchCase,
    AsmResultsComponent,
    FuseScrollbarDirective,
    NgIf,
    ButtonModule,
    AppFilterPipeModule,
    MaterialModule,
    TableComponent,
    TimelineComponent,
  ],
  templateUrl: "./job-view.component.html",
  styleUrls: ["./job-view.component.scss"],
})
export class JobViewComponent implements OnInit, OnDestroy, AfterViewInit {
  cView: any = "jobs";
  @ViewChild("drawer") drawer: MatDrawer;
  @Input() jobCondition: any;
  @Input() title: any;
  @Input() isShowHyperLink: any;
  @Input() actionMenuItems: any = [];
  searchFilter: any = "";
  checkCompleted: boolean = false;
  checkInProcess: boolean = false;
  checkFailed: boolean = false;
  drawerPosition: "start" | "end" = "end";
  drawerMode: "over" | "side" = "over";
  drawerOpened: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  currentData: any = {};
  currentDataCheck: boolean = false;
  reportCurrentDataCheck: boolean = false;
  reportCurrentData: any = [];
  showtable: boolean = false;
  objectKeys = Object.keys;
  scanTableOptions: any = {};
  suppressiontable: any = {};
  tableData: any = {};
  pObj: any = {};
  private action: Subscription;
  jobEnum: any = {
    pii: `type='PIISCAN'`,
    assets: `type IN ('FULLSCAN','NETWORKSCAN','ADSCAN', 'REMOTEINSTALL', 'COMPLIANCE')`,
    scheduler: `type IN ('FULLSCAN','NETWORKSCAN','ADSCAN')`,
    azure: `type='AZUREADSYNC'`,
    firewalls: `type='FIREWALLSCAN'`,
    "external-assets": `type='EXTERNALSCAN'`,
    "attack-surface-mapper": `type='ATTACKSURFACESCAN'`,
    "standard-reports": `type='StandardReports'`,
    "report-builder": `type='CustomReports'`,
    "compliance-standards": `type='COMPLIANCE'`,
    "compliance-check-master": `type='COMPLIANCE'`,
  };

  /**
   * Constructor
   */
  constructor(
    public modalService: ModalService,
    private _bs: BaseRequestService,
    public cs: CommonService,
    private ls: LoaderService,
    private toast: MyToastrService,
    private router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private _ds: DynamicSettingsService,
    public confirmDialog: FuseConfirmationService
  ) {
    this._ds.reportEmitter
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        const tableName = this.scanTableOptions.tableOptions.title;
        if (res.target === "details") {
          this.searchFilter = "";
          if (
            this.cs.selectedTableRows[tableName] &&
            this.cs.selectedTableRows[tableName].length
          ) {
            if (this.cs.selectedTableRows[tableName].length > 1) {
              this.toast.sToast("info", "Please select only one record.");
            } else {
              this.currentData = {};
              this.viewJobDeatils(this.cs.selectedTableRows[tableName][0]);
              this._changeDetectorRef.detectChanges();
            }
          } else {
            this.toast.sToast("info", "Please select at least one record.");
          }
        }
      });
  }

  ngOnInit(): void {
    this.initTable();
  }
  ngAfterViewInit(): void {
    this._changeDetectorRef.detectChanges();
  }
  initTable(): void {
    const isExternal = this.isShowHyperLink;
    const router = this.router.url.split("/");
    this.jobCondition =
      router.length > 0 ? this.jobEnum[router[router.length - 1]] : "";
    const showLink = this.title ? false : true;
    this.scanTableOptions = {
      columns: [
        {
          header: "Created",
          columnDef: "created",
          filter: "utcToLocale",
          isHyperlink: showLink,
          cell: "(element: any) => ${element.created}",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Updated",
          columnDef: "updated",
          filter: "utcToLocale",
          isHyperlink: showLink,
          cell: "(element: any) => ${element.updated}",
          order: 0,
          visible: false,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Scan Type",
          columnDef: "type",
          cType: "string",
          filter: "enumString",
          cell: "(element: any) => `${element.type}`",
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Report Name",
          columnDef: "report_name",
          cType: "",
          cell: "(element: any) => `${element.report_name}`",
          order: 1,
          visible: true,
          isNoVisible: this.title ? false : true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: "Report Type",
          isColoredCntrl: true,
          isCustomText: true,
          isCustomClass: true,
          isNoVisible: this.title ? false : true,
          columnDef: "report_type",
          cType: "string",
          filter: "",
          cell: "(element: any) => `${element.report_type}`",
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Agent Name",
          columnDef: "agent_host_name",
          cType: "string",
          filter: "",
          cell: "(element: any) => `${element.agent_host_name}`",
          order: 2,
          visible: true,
          isNoVisible: !isExternal,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Job Id",
          columnDef: "job_id",
          cType: "string",
          filter: "",
          cell: "(element: any) => `${element.job_id}`",
          order: 2,
          visible: false,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Job Status",
          isColoredCntrl: true,
          isCustomText: true,
          isCustomClass: true,
          columnDef: "status",
          cType: "string",
          filter: "",
          cell: "(element: any) => `${element.status}`",
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Description",
          columnDef: "description",
          cType: "string",
          filter: "",
          width: "200px",
          cell: "(element: any) => `${element.description}`",
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Download",
          columnDef: "download",
          filter: "",
          cell: "(element: any) => `${element.download}`",
          order: 0,
          align: "center",
          isHyperlink: true,
          visible: true,
          isNoVisible: this.title ? false : true,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isClass: true,
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: true,
          imgPath: "",
          iconPath: "mat_solid:download_for_offline",
          iconPathCondition: {
            key: 'status', value: 'Completed'
          },
          isSort: false,
          iscolumnSearch: false,
        },
      ],
      sortOptions: { active: "updated", direction: "desc" },
      _pageData: [],
      tableOptions: {
        title: this.title ? this.title : "Scan Job(s)",
        isServerSide: false,
        selectText: "job(s)",
        loading: true,
        floatingFilter: true,
        rowSelection:
          this.actionMenuItems && this.actionMenuItems.length ? true : false,
        showAction: false,
        actionMenuItems: this.actionMenuItems,
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 5,
        search: false,
        showFilter: true,
        showTagFilter: false,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: false,
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        dataMapping: [
          {
            s: "job_details",
            d: "report_name",
            filter: "statstovuls:displayReportName",
          },
          {
            s: "job_details",
            d: "report_type",
            filter: "statstovuls:fileType",
          },
        ],
        serverSide: {
          url: this.title
            ? "r/company/report_jobs_view"
            : "r/company/jobs_view",
          condition: this.jobCondition,
        },
      },
      customText: [
        {
          status: "initiated",
          DisplayText: "Initiated",
          class: "bg-teal-200 text-teal-800 dark:bg-teal-600 dark:text-teal-50",
        },
        {
          status: "started",
          DisplayText: "Started",
          class:
            "bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50",
        },
        {
          status: "inprogress",
          DisplayText: "In-progress",
          class:
            "bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50",
        },
        {
          status: "success",
          DisplayText: "Success",
          class:
            "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50",
        },
        {
          status: "completed",
          DisplayText: "Completed",
          class:
            "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50",
        },
        {
          status: "failed",
          DisplayText: "Failed",
          class: "bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50",
        },
        {
          status: "pushedtoqueue",
          DisplayText: "Pushed To Queue",
          class:
            "bg-slate-200 text-slate-800 dark:bg-slate-600 dark:text-slate-50",
        },
        {
          status: "Terminated",
          DisplayText: "Terminated",
          class: "bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50",
        },
        {
          status: "pdf",
          DisplayText: "PDF",
          class: "bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50",
        },
        {
          status: "xlsx",
          DisplayText: "XLSX",
          class:
            "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50",
        },
        {
          status: "docx",
          DisplayText: "DOCX",
          class:
            "bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50",
        },
        {
          status: "pptx",
          DisplayText: "PPTX",
          class:
            "bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50",
        },
      ],
      changeValue: new Subject<any>(),
    };
    this._changeDetectorRef.detectChanges();
  }
  trackByFn(index: number, item: any): any {
    return (item && item.id) || index;
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.objectKeys(this.pObj).forEach((obj: any) => {
      if (obj) {
        this.pObj[obj].unsubscribe();
      }
    });

    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
  linkClick($event: any) {
    if ($event.col === "download") {
      const params = {
        job_id: $event.row.job_id,
      };
      this.ls.display(true);
      this._bs
        .doRequest(`report_builder/get_report_link`, "get", null, params)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: any) => {
          this.ls.display(false);
          if (res.status) {
            window.open(res.message, "_blank");
          } else {
            const data = res.message ? res.message : res.data;
            this.toast.sToast("error", data);
          }
        });
    } else {
      this.ls.display(true);
      this.pObj.jobs = this._bs
        .doRequest(`r/company/jobs`, "get", null, {
          condition: `id=${$event.row.id}`,
          skip: 0,
          limit: 1,
        })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: any) => {
          this.ls.display(false);
          if (res.status) {
            if (res.data && res.data.length) {
              if ($event.row.type === "ATTACKSURFACESCAN") {
                const condition = {
                  condition: "company_id='" + this.cs.currentScope.id + "'",
                };
                if (res.data[0].job_details && res.data[0].job_details.length) {
                  condition.condition +=
                    " and " +
                    "website='" +
                    res.data[0].job_details[0].domain +
                    "'";
                }
                this.currentData = null;
                this.pObj.asm = this._bs
                  .doRequest(
                    "/r/company/attack_surface_results",
                    "get",
                    null,
                    condition
                  )
                  .pipe(takeUntil(this._unsubscribeAll))
                  .subscribe((result: any) => {
                    if (result.status) {
                      try {
                        if (result.data && result.data.length) {
                          if (!result.data[0].mx.error) {
                            this.currentData = result.data[0];
                            this.cView = "results";
                            this._changeDetectorRef.detectChanges();
                          } else {
                            this.toast.sToast("error", result.data[0].mx.error);
                          }
                        } else {
                          this.toast.sToast("error", "No results found.");
                        }
                      } catch (e) {
                        console.log(e);
                      }
                    }
                  });
              } else {
                this.viewDetails(res.data[0]);
                this._changeDetectorRef.detectChanges();
              }
            }
          } else {
            const data = res.message ? res.message : res.data;
            this.toast.sToast("error", data);
          }
        });
    }
  }

  viewDetails(job: any): void {
    this.drawerOpened = true;
    this.currentDataCheck = true;
    if (!this.drawer.opened) {
      this.drawer.toggle();
      this.currentData = job;
      this._changeDetectorRef.detectChanges();
    }
  }
  viewJobDeatils(job: any) {
    this.searchFilter = "";
    this.reportCurrentDataCheck = true;
    this.drawerOpened = true;
    if (!this.drawer.opened) {
      this.drawer.toggle();
      this.reportCurrentData.push(job);
    }
    this._changeDetectorRef.detectChanges();
  }
  toggleChange($event: any): void {
    this.cView = "jobs";
  }
  closeCallBack($event: any): void {
    this.cView = "jobs";
  }
  actionCall(idata: any): void {
    if (idata.action.text === "Download Report") {
      if (idata.row.status.toLowerCase() !== "completed") {
        this.toast.sToast(
          "error",
          `Job status: ${idata.row.status}. Report download unavailable at the moment.`
        );
        return;
      }
      const params = {
        job_id: idata.row.job_id,
      };
      this.ls.display(true);
      this._bs
        .doRequest(`report_builder/get_report_link`, "get", null, params)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: any) => {
          this.ls.display(false);
          if (res.status) {
            window.open(res.message, "_blank");
            setTimeout(() => this.ls.tableProgress(false));
          } else {
            const data = res.message ? res.message : res.data;
            this.toast.sToast("error", data);
          }
        });
    }
  }
  closeCurrentData() {
    this.currentDataCheck = false;
    this.drawer.toggle();
  }
  closeReportCurrentData() {
    this.reportCurrentDataCheck = false;
    this.reportCurrentData = [];
    this.drawer.toggle();
  }
}

<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<dynamic-settings #dynamicSettings="dynamicSettings"  *ngIf="_cs.isChecked === 'left'"></dynamic-settings>
<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation
        class="dark bg-gray-900 print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation.default"
        [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8">
                    <img
                    class="max-w-[12rem] h-[2.5rem] object-contain"
                    [src]="_bs.whitelabel().logo" aria-label="dark"
                    alt="Logo image">
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div #_wrapper_ id="_wrapper_" class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-card">

    <!-- Header -->
    <div class="relative flex flex-col flex-0 justify-center w-full h-14 sm:h-14 md:h-36 overflow-hidden z-49 shadow dark:shadow-none print:hidden">
        <!-- Top bar -->
        <div class="relative dark flex flex-auto justify-center w-full px-4 md:px-8 bg-gray-800 dark:bg-gray-900">
            <div class="flex items-center w-full max-w-full h-14 sm:h-14">
                <!-- Logo -->
                <ng-container *ngIf="!isScreenSmall">
                    <div class="flex items-center">
                        <img
                            class="max-w-[12rem] h-[2.5rem] object-contain"
                            [src]="_bs.whitelabel().logo" aria-label="dark"
                            alt="Logo image">
                    </div>
                </ng-container>
                <!-- Navigation toggle button -->
                <ng-container *ngIf="isScreenSmall">
                    <button
                        mat-icon-button
                        (click)="toggleNavigation('mainNavigation')">
                        <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
                    </button>
                </ng-container>
                <!-- Components -->
                <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
                    <languages></languages>
                    <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
                    <app-theme-switch class="hidden md:block"></app-theme-switch>
                    <!-- <search [appearance]="'bar'"></search> -->

                    <!--<new-company></new-company>-->
                    <scope></scope>
                    <!-- <button
                        class="lg:hidden"
                        mat-icon-button
                        (click)="dynamicSettings.toggle()">
                        <mat-icon [svgIcon]="'heroicons_outline:adjustments-horizontal'"></mat-icon>
                    </button> -->
                    <!--<messages></messages>-->
                    <!--<notifications></notifications>-->
                    <!--<button
                        class="lg:hidden"
                        mat-icon-button
                        (click)="quickChat.toggle()">
                        <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
                    </button>-->
                    <user></user>
                </div>
            </div>
        </div>
        <!-- Bottom bar -->
        <ng-container *ngIf="!isScreenSmall">
            <div class="flex flex-auto justify-center px-4 md:px-8 bg-card dark:bg-gray-700">
                <div class="relative flex items-center w-full max-w-full h-16">
                    <fuse-horizontal-navigation
                        class="-mx-4"
                        [name]="'mainNavigation'"
                        [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
                </div>
            </div>
        </ng-container>
    </div>

    <!-- Content -->
    <div class="flex flex-auto justify-center w-full sm:p-6 md:p-8">
        <div class="flex flex-col flex-auto w-full sm:max-w-full sm:shadow-lg sm:rounded-lg sm:overflow-hidden bg-default">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <!--<router-outlet *ngIf="true"></router-outlet>-->
            <div class="flex flex-row items-center gap-2 text-gray-400 px-6 pt-4" *ngIf="currentPath && currentPath.length">
                <i (click)="redirectPage('/dashboard')" class="fas fa-home cursor-pointer hover:text-primary"></i>
                <span (click)="redirectPage('/metrics')"
                      class="font-semibold uppercase hover:text-primary ng-star-inserted rounded border cursor-pointer text-md bg-green-100 px-1
                 dark:bg-gray-800 dark:text-white py-0.5 text-green-900">
                    <ng-container *ngIf="_cs.currentScope === '*'">GLOBAL</ng-container>
                    <ng-container *ngIf="_cs.currentScope !== '*'">{{_cs.currentScope.name}}</ng-container>
                </span>
                <span *ngFor="let s of currentPath; let last = last"> 
                    {{navHash[s]|uppercase}} 
                    <i *ngIf="!last" class="ml-1 fas fa-chevron-right"></i>
                </span>
            </div>
            <router-outlet *ngIf="_bs.user().email && !_cs.isComLoading">

            </router-outlet>
            <app-skeleton *ngIf="_cs.isComLoading">

            </app-skeleton>
        </div>
    </div>

    <!-- Footer -->
    <div class="relative flex flex-0 justify-center w-full px-6 md:px-8 z-49 border-t bg-card print:hidden">
        <div class="flex items-center w-full max-w-full h-14 sm:h-20">
            <span class="font-medium text-secondary">ConnectSecure &copy; {{currentYear}}</span>
        </div>
    </div>

</div>

<dynamic-settings #dynamicSettings="dynamicSettings"  *ngIf="_cs.isChecked === 'right'"></dynamic-settings>

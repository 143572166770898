<div class="flex flex-wrap pt-4 gap-2 items-center justify-start">
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'timezone'" [ngClass]="{'bg-primary text-white': cView === 'timezone'}">Timezone Settings
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'dateformat'" [ngClass]="{'bg-primary text-white': cView === 'dateformat'}">Custom
        Dateformat
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'session-timeout'" [ngClass]="{'bg-primary text-white': cView === 'session-timeout'}">
        Session Timeout
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'port'" [ngClass]="{'bg-primary text-white': cView === 'port'}">Ports Policy</div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'deprecation'" [ngClass]="{'bg-primary text-white': cView === 'deprecation'}">Deprecation Days
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'white-label'" [ngClass]="{'bg-primary text-white': cView === 'white-label'}">White Label
        Settings
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'edr-application'" [ngClass]="{'bg-primary text-white': cView === 'edr-application'}">EDR
        Application
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'backupSoftware'" [ngClass]="{'bg-primary text-white': cView === 'backupSoftware'}">Backup
        Software
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'compliance-scan'" [ngClass]="{'bg-primary text-white': cView === 'compliance-scan'}">Compliance Scan
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'lwa-interval'" [ngClass]="{'bg-primary text-white': cView === 'lwa-interval'}">
        LW Agent Scan Interval
    </div>
    <!-- <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'patching-status'" [ngClass]="{'bg-primary text-white': cView === 'patching-status'}">
        Patching Status
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'login-audit'" [ngClass]="{'bg-primary text-white': cView === 'login-audit'}">Login Audit
        Status
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'default-role'" [ngClass]="{'bg-primary text-white': cView === 'default-role'}">Default
        Role Settings
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'default-tag'" [ngClass]="{'bg-primary text-white': cView === 'default-tag'}">Default Tags
        Status
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'manage-agent-version'"
        [ngClass]="{'bg-primary text-white': cView === 'manage-agent-version'}">Manage Agent Version
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'agent-performance-management'"
        [ngClass]="{'bg-primary text-white': cView === 'agent-performance-management'}">Agent Performance Management
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'disable_roaming_applications'"
        [ngClass]="{'bg-primary text-white': cView === 'disable_roaming_applications'}">Disable Roaming Applications
    </div> -->
</div>
<div class="border-t border-blue-400 my-4"></div>
<div class="mt-4 w-full" *ngIf="cView === 'timezone'">
    <div class="bg-card border border-gray-200 rounded-lg p-4 dark:bg-gray-800 dark:border-gray-700">
        <mat-form-field class="w-1/2" [subscriptSizing]="'dynamic'">
            <mat-label>Timezone</mat-label>
            <mat-select [(ngModel)]="_timezone.value.value" name="Timezone" id="Timezone">
                <mat-option>
                    <ngx-mat-select-search [formControl]="timezoneSearchControl"
                        [placeholderLabel]="'Search application'"
                        [noEntriesFoundLabel]="'No matching application found'"
                        [searching]="searching"></ngx-mat-select-search>
                </mat-option>
                <mat-option [matTooltip]="zone"
                    *ngFor="let zone of cs.timeZoneList | search:timezoneSearchControl.value " [value]="zone">
                    {{zone}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                [matTooltip]="'Save'" (click)="saveTimezone()">
                Save
            </button>
        </div>
    </div>
</div>
<div class="mt-4" *ngIf="cView === 'dateformat'">
    <div class="bg-card border border-gray-200 rounded-lg p-4 dark:bg-gray-800 dark:border-gray-700">
        <mat-form-field class="w-1/2">
            <mat-label>Set Dateformat</mat-label>
            <mat-select [multiple]="false" [(ngModel)]="_date_format.value.value" placeholder="Choose Dateformat">
                <mat-option *ngFor="let format of dateFormatList" [id]="format"
                    [value]="format.format">{{format.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                [matTooltip]="'Save'" (click)="saveCustomDateFormat()">
                Save
            </button>
        </div>
    </div>
</div>
<div class="w-full" *ngIf="cView === 'session-timeout'">
    <div class="mt-4 bg-card border border-gray-200 rounded-lg p-4 dark:bg-gray-800 dark:border-gray-700">
        <div class="grid grid-cols-3 gap-3">
            <mat-form-field>
                <mat-label>Idle for</mat-label>
                <input matInput placeholder="Idle (minutes)" type="number" [(ngModel)]="_session_timeout.value.idle"
                    autocomplete="off" name="Idlefor">
                <mat-hint>If Idle for {{_session_timeout.value.idle}} minutes</mat-hint>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Wait for</mat-label>
                <input matInput placeholder="Countdown (minutes)" type="number"
                    [(ngModel)]="_session_timeout.value.timeout" autocomplete="off" name="Waitfor">
                <mat-hint>wait for another {{_session_timeout.value.timeout}} minutes</mat-hint>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Logout in</mat-label>
                <input matInput placeholder="Logout (minutes)" type="number" [disabled]="true"
                    [value]="_session_timeout.value.idle + _session_timeout.value.timeout" autocomplete="off"
                    name="Logout">
                <mat-hint>logout in {{_session_timeout.value.idle + _session_timeout.value.timeout}} minutes.</mat-hint>
            </mat-form-field>
        </div>
        <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick [matTooltip]="'Save'"
                (click)="saveSessionSettings()">
                Save
            </button>
        </div>
    </div>
</div>
<div class="mt-4" *ngIf="cView === 'port'">
    <div class="my-2">
        <fuse-alert [appearance]="'border'" [type]="'info'">
            <div class="flex flex-col gap-2">
                <div> Set Excluded, Insecure, Allowed and Denied ports for scanning</div>
            </div>
        </fuse-alert>
    </div>
    <app-dynamic-form [listOfFormElements]="ports" [spanLayout]="2" [Valuesoutput]="portsParams"
        (saveCallBack)="saveSettings(portsParams)" [isSaveBtn]="false" [isCloseBtn]="false">
    </app-dynamic-form>
    <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
        <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick [matTooltip]="'Save'"
            (click)="saveSettings(portsParams)">
            Save
        </button>
    </div>
</div>
<div class="mt-4" *ngIf="cView === 'lwa-interval'">
    <div class="bg-card border border-gray-200 rounded-lg p-4 dark:bg-gray-800 dark:border-gray-700">
        <mat-form-field class="w-1/2">
            <mat-label>Set Lightweight Agent Scan Interval</mat-label>
            <mat-select [multiple]="false" [(ngModel)]="_lwa_interval.value.value" placeholder="Choose Interval">
                <mat-option value="15">15 Minutes</mat-option>
                <mat-option value="30">30 Minutes</mat-option>
                <mat-option value="60">60 Minutes</mat-option>
                <mat-option value="90">90 Minutes</mat-option>
                <mat-option value="120">120 Minutes</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                [matTooltip]="'Save'" (click)="saveLWAInterval()">
                Save
            </button>
        </div>
    </div>
</div>
<div class="mt-4" *ngIf="cView === 'deprecation'">
    <div class="my-2">
        <fuse-alert [appearance]="'border'" [type]="'info'">
            <div class="flex flex-col gap-2">
                <div>Set Asset and Agents Deprecation days for the assets and agents which are offline and not scanned
                    for x number of days.</div>
                <div>The Microsoft OS vulnerabilities for the respective assets are suppressed for the number of days
                    entered here, starting from the day after the initial release.</div>
            </div>
        </fuse-alert>
    </div>
    <app-dynamic-form [listOfFormElements]="deprecation_days" [spanLayout]="2" [Valuesoutput]="deprecationDaysParams"
        (saveCallBack)="saveSettings(deprecationDaysParams)" [isSaveBtn]="false" [isCloseBtn]="false">
    </app-dynamic-form>
    <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
        <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick [matTooltip]="'Save'"
            (click)="saveSettings(deprecationDaysParams)">
            Save
        </button>
    </div>
</div>
<div class="mt-4 w-full" *ngIf="cView === 'login-audit'">
    <div class="my-2 bg-card border border-gray-200 rounded-lg px-4 py-6 dark:bg-gray-800 dark:border-gray-700">
        <mat-slide-toggle class="" [color]="'primary'" matTooltip="Enable Login Audit Status" name="loginAuditStatus"
            [(ngModel)]="_adaudit_status.value.value">
            Enable Login Audit
        </mat-slide-toggle>
        <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick [matTooltip]="'Save'"
                (click)="enableLoginAuditStatus()">
                Save
            </button>
        </div>
    </div>

</div>
<div class="mt-8 w-full" *ngIf="cView === 'patching-status'">
    <div class="my-2">
        <mat-slide-toggle class="" [color]="'primary'" matTooltip="Enable Patching Status" name="patchingStatus"
            [(ngModel)]="_enable_patching.value.patching_status">
            Enable Patching Status
        </mat-slide-toggle>
    </div>
    <div class=" mt-2 h-[40vh]" *ngIf="_enable_patching.value.patching_status" fuseScrollbar>
        <fuse-alert [appearance]="'border'" [type]="'info'">
            <div [innerHTML]="patchingContent| safeHTML"></div>
        </fuse-alert>
    </div>
    <mat-checkbox name="agreePatchCond" *ngIf="_enable_patching.value.patching_status" id="agreePatchCond" class="mt-3"
        [(ngModel)]="_enable_patching.value.is_eula_accepted" value="" color="primary">I ACCEPT
    </mat-checkbox>
    <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
        <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
            [matTooltip]="'Save'" (click)="enablePatchingStatus()"
            [disabled]="_enable_patching.value.patching_status && !_enable_patching.value.is_eula_accepted">
            Save
        </button>
    </div>
</div>
<div class="mt-4 w-full" *ngIf="cView === 'default-tag'">
    <div class="my-2">
        <fuse-alert [appearance]="'border'" [type]="'info'">
            <div>Disable Default Tags removes the default tags applied for all the assets.</div>
        </fuse-alert>
    </div>
    <div class="mt-4">
        <mat-slide-toggle class="" [color]="'primary'" matTooltip="Enable Default Tags"
            [(ngModel)]="_default_tags.value.value" name="defaultTagsStatus">
            Enable Default Tags
        </mat-slide-toggle>
        <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick [matTooltip]="'Save'"
                (click)="enableDefaultTagStatus()">
                Save
            </button>
        </div>
    </div>
</div>
<div class="mt-4 w-full" *ngIf="cView === 'default-role'">
    <div class="my-2">
        <fuse-alert [appearance]="'border'" [type]="'info'">
            The present Default Role for new users which are created through authentication providers has been
            designated as "Norole". If the default role is changed, all the newly created users via
            authentication providers will be assigned to the new Default Role set..
        </fuse-alert>
    </div>
    <div class="mt-4">
        <mat-form-field class="w-1/2 mb-4">
            <mat-label>Set Default Role</mat-label>
            <mat-select [multiple]="false" name="roles" [(ngModel)]="_default_role.value.value" required
                placeholder="Select default role">
                <mat-option *ngFor="let role of roleList" [value]="role">{{role | uppercase}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
                [matTooltip]="'Save'" (click)="saveDefaultRole()" [disabled]="!_default_role.value.value">
                Save
            </button>
        </div>
    </div>
</div>
<div *ngIf="cView === 'white-label'">
    <app-white-label> </app-white-label>
</div>
<div *ngIf="cView === 'edr-application'">
    <app-edr-app> </app-edr-app>
</div>
<div *ngIf="cView === 'backupSoftware'">
    <app-backup-softwares> </app-backup-softwares>
</div>
<div *ngIf="cView === 'manage-agent-version'">
    <app-manage-agent-version> </app-manage-agent-version>
</div>
<div *ngIf="cView === 'agent-performance-management'">
    <app-agent-performance-management> </app-agent-performance-management>
</div>
<div *ngIf="cView === 'disable_roaming_applications'">
    <app-disable-roaming-app> </app-disable-roaming-app>
</div>
<div class="mt-4" *ngIf="cView === 'compliance-scan'">
    <div class="bg-card border border-gray-200 rounded-lg px-4 py-6 dark:bg-gray-800 dark:border-gray-700">
        <div class=" flex flex-col">
            <span class="font-semibold mb-2">Choose Compliance Types</span>
            <div class="flex flex-col gap-2 ring-1 rounded-lg">
                <mat-checkbox value="" (change)="selectAllToggle($event, 'complianceListAll')"
                    [checked]="isAllSelected('complianceListAll')"
                    [indeterminate]="hasSelected('complianceListAll') && !isAllSelected('complianceListAll')"
                    [color]="'primary'">
                    Select All
                </mat-checkbox>
                <div class="border-t grid grid-cols-1 sm:grid-cols-4 gap-2 p-4">
                    <div *ngFor="let type of complianceListAll; let i = index">
                        <mat-checkbox class="mb-2" [color]="'primary'" [(ngModel)]="type.selected" name="selected{{i}}">
                            {{type.name |uppercase |camelToHuman:true}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick [matTooltip]="'Save'"
                (click)="updateComplianceType()">
                Save
            </button>
        </div>
    </div>
</div>

<div class="flex-auto -mt-px" *ngIf="cView === 'jobs'">
    <div class="w-full">
        <mat-drawer-container class="flex-auto h-screen">
            <!-- Drawer sm:w-96 -->
            <mat-drawer class="dark:bg-gray-900 z-999" [ngClass]="{'w-full lg:w-1/2': drawerOpened}"
                [autoFocus]="true" [position]="drawerPosition" [mode]="drawerMode" [opened]="drawerOpened" #drawer>
                <!-- Main -->
                <div class="flex-auto" *ngIf="currentDataCheck">
                    <ng-container *ngIf="currentData">
                        <div class="relative my-2 flex h-full flex-col rounded-2xl shadow-lg">
                            <div class="border-b p-6">
                                <div class="flex flex-row flex-wrap items-center">
                                    <div class="flex flex-row items-center">
                                        <h6 class="text-xl font-semibold">{{currentData.type | enumString}}</h6>
                                        <p class="ml-2 text-sm font-light">
                                            <span *ngIf="currentData.status"
                                                class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                                                [ngClass]="{
                                                    'bg-teal-200 text-teal-800 dark:bg-teal-600 dark:text-teal-50': currentData.status.toLowerCase() == 'initiated',
                                                    'bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50': currentData.status.toLowerCase() === 'started',
                                                    'bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50': currentData.status.toLowerCase() === 'inprogress',
                                                    'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': currentData.status.toLowerCase() === 'completed'
                                                     || currentData.status.toLowerCase() === 'success',
                                                    'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': currentData.status.toLowerCase() === 'failed',
                                                }">
                                                <span class="leading-relaxed whitespace-nowrap">{{currentData.status |
                                                    enumString}}</span>
                                            </span>
                                        </p>
                                    </div>
                                    <!-- (click)="drawer.toggle(); -->
                                    <button class="ml-auto" mat-icon-button (click)="closeCurrentData()">
                                        <mat-icon class="text-current"
                                            [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="flex-auto h-full p-6">
                                <div class="relative flex flex-col justify-center">
                                    <div class="absolute left-4 h-full border-r-2"></div>
                                    <div class="relative mb-4"
                                        *ngFor="let item of currentData.job_details; trackBy: trackByFn ">
                                        <span
                                            class="absolute inline-flex h-6 w-6 items-center justify-center rounded-full p-4 text-center text-base font-semibold text-white shadow"
                                            [ngClass]="{
                                            'bg-blue-500': !item.type || item.type.toLowerCase() === 'info',
                                            'bg-orange-500': item.type && item.type.toLowerCase() === 'warning',
                                             'bg-red-500': item.type && item.type.toLowerCase() === 'error'
                                             }">
                                            <i class="far fa-clock"></i>
                                        </span>
                                        <div class="ml-12 w-auto pt-1">
                                            <h6 class="text-sm font-semibold">
                                                <span *ngIf="item.protocol"
                                                    class="inline-flex font-bold rounded text-xs text-gray-900 bg-blue-200 px-1.5 py-0.5 mr-2">{{item.protocol
                                                    | enumString}}</span>
                                                {{item.description | enumString: true}}
                                            </h6>
                                            <p class="mt-1 text-xs" *ngIf="item.reason">
                                                {{item.reason | enumString: true}}
                                            </p>
                                            <p class="mt-1 text-xs text-gray-500" *ngIf="item.job_time">
                                                <!-- {{item.job_time|iso8601ToLocalTime}} -->
                                                {{item.job_time | utcToLocaleRDate}}
                                            </p>
                                            <div class="flex flex-wrap gap-1 w-auto pt-1"
                                                *ngIf="item.ip_address && item.ip_address.length">
                                                <show-more-less [items]="item.ip_address" [showAllItems]="false"
                                                    [type]="'list'" [bgColor]="'bg-yellow-200'"
                                                    [color]="'text-blue-900'" [maxItemsToShow]="10"
                                                    [showOrder]="'span'"></show-more-less>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="flex-auto" *ngIf="reportCurrentDataCheck">
                    <ng-container *ngIf="reportCurrentData">
                        <div class="relative my-2 flex h-full flex-col rounded-2xl shadow-lg">
                            <div class="border-b p-6">
                                <div class="flex flex-row flex-wrap items-center">
                                    <div class="flex flex-row items-center">
                                        <h6 class="text-xl font-semibold">
                                            {{reportCurrentData[0].job_details[0].displayReportName
                                            }}
                                        </h6>
                                        <p class="ml-2 text-sm font-light">
                                            <span *ngIf="reportCurrentData[0].status"
                                                class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                                                [ngClass]="{
                                                  'bg-teal-200 text-teal-800 dark:bg-teal-600 dark:text-teal-50': reportCurrentData[0].status.toLowerCase() == 'initiated',
                                                  'bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50': reportCurrentData[0].status.toLowerCase() === 'started',
                                                  'bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50': reportCurrentData[0].status.toLowerCase() === 'inprogress',
                                                   'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': reportCurrentData[0].status.toLowerCase() === 'completed'
                                                   || reportCurrentData[0].status.toLowerCase() === 'success',
                                                    'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': reportCurrentData[0].status.toLowerCase() === 'failed',
                                                    'bg-slate-200 text-slate-800 dark:bg-slate-600 dark:text-slate-50': reportCurrentData[0].status.toLowerCase() === 'pushedtoqueue'
                                                 }">
                                                <span
                                                    class="leading-relaxed whitespace-nowrap">{{reportCurrentData[0].status
                                                    |
                                                    enumString}}</span>
                                            </span>
                                        </p>
                                    </div>
                                    <button class="ml-auto" mat-icon-button (click)="closeReportCurrentData()">
                                        <mat-icon class="text-current"
                                            [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="flex-auto max-h-screen h-full p-6">
                                <div class="w-auto py-4 rounded-2xl">
                                    <div class="p-4 bg-default border rounded-2xl mt-3">
                                        <div class="max-w-full prose prose-sm mt-4 bg-card px-6 py-2 rounded-xl"
                                            fuseScrollbar>
                                            <h3>Report Job Status</h3>
                                            <table class="table table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th class="dark:text-white">Report Name</th>
                                                        <th class="dark:text-white">Report Type</th>
                                                        <th class="dark:text-white">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container
                                                        *ngFor="let report of reportCurrentData|search:searchFilter; trackBy: trackByFn">
                                                        <tr>
                                                            <td>{{report.job_details[0].displayReportName}}</td>
                                                            <td>{{report.type}}</td>
                                                            <td class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                                                                [ngClass]="{
                                                  'bg-teal-200 text-teal-800 dark:bg-teal-600 dark:text-teal-50': reportCurrentData[0].status.toLowerCase() == 'initiated',
                                                  'bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50': reportCurrentData[0].status.toLowerCase() === 'started',
                                                  'bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50': reportCurrentData[0].status.toLowerCase() === 'inprogress',
                                                   'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': reportCurrentData[0].status.toLowerCase() === 'completed'
                                                   || reportCurrentData[0].status.toLowerCase() === 'success',
                                                    'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': reportCurrentData[0].status.toLowerCase() === 'failed',
                                                     'bg-slate-200 text-slate-800 dark:bg-slate-600 dark:text-slate-50': reportCurrentData[0].status.toLowerCase() === 'pushedtoqueue',
                                                 }">{{report.status}}</td>
                                                        </tr>
                                                    </ng-container>
                                                    <div class="ml-6 mt-6"
                                                        *ngIf="!reportCurrentData || !reportCurrentData.length">
                                                        <div> No data available!</div>
                                                    </div>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ng-container>
                </div>
            </mat-drawer>
            <!-- Drawer content -->
            <mat-drawer-content class="flex flex-col">
                <div class="w-auto py-4 rounded-2xl">
                    <stable aria-label="Scan Job Table" role="table" *ngIf="scanTableOptions"
                        (actionCallback)="actionCall($event)" [sTableOptions]="scanTableOptions"
                        (hyperlinkCallback)="linkClick($event)"></stable>
                </div>
            </mat-drawer-content>
        </mat-drawer-container>
    </div>
</div>
<div *ngIf="cView === 'results'">
    <app-asm-results (closeCallback)="closeCallBack($event)" [currentJob]="currentData"></app-asm-results>
</div>

<div class="flex flex-col w-full h-full pl-0 py-6">
    <div class="w-full">
        <!-- Title -->
        <div class="text-2xl font-extrabold tracking-tight leading-none">{{props.title}}</div>
        <div class="mt-1.5 text-lg text-secondary">{{props.description}}</div>
        <div class="mt-4" *ngIf="( timelineData$ | async) as tData">
            <ol *ngIf="props && props.timelineCount && tData && tData.length; else noActivity">
                <!-- Activities -->
                <ng-container
                    *ngFor="let activity of tData; let i = index; let first = first; let last = last; trackBy: trackByFn">
                    <!-- Date separator -->
                    <ng-container *ngIf="first || !isSameDay(activity.date, tData[i - 1].date)">
                        <li class="relative flex py-4">
                            <div
                                class="relative py-2 px-8 text-md font-medium leading-5 rounded-full bg-primary text-on-primary">
                                {{getRelativeFormat(activity.date) | titlecase}}
                            </div>
                        </li>
                    </ng-container>

                    <!-- Activity -->
                    <li class="relative flex py-2">
                        <!-- Line -->
                        <ng-container *ngIf="!last && isSameDay(activity.date, tData[i + 1].date)">
                            <div class="absolute top-7 left-5 w-0.5 h-full -ml-px bg-gray-300 dark:bg-gray-600">
                            </div>
                        </ng-container>

                        <div class="relative flex flex-auto">
                            <!-- Icon -->
                            <ng-container *ngIf="activity.icon && !activity.image">
                                <div
                                    class="flex shrink-0 items-center justify-center w-10 h-10 mr-4 rounded-full bg-gray-400">
                                    <mat-icon class="icon-size-5 text-white" [svgIcon]="activity.icon">
                                    </mat-icon>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="activity.img && !activity.icon">
                                <img
                                    class="shrink-0 w-10 h-10 mr-4 rounded-full overflow-hidden object-cover object-center"
                                    [src]="activity.img" [alt]="'Activity image'">
                            </ng-container>

                            <!-- Image -->
                            <ng-container *ngIf="activity.image">
                                <img
                                    class="shrink-0 w-10 h-10 mr-4 rounded-full overflow-hidden object-cover object-center"
                                    [src]="activity.image" [alt]="'Activity image'">
                            </ng-container>

                            <!-- Content -->
                            <div class="flex flex-col flex-auto items-start text-sm">
                                <!-- Title -->
                                <ng-container *ngIf="activity.title">
                                    <div class="font-semibold text-base">{{activity.title | cleanString }}</div>
                                </ng-container>
                                <!-- Date -->
                                <div class="flex flex-row items-center gap-2 text-secondary">
                                    <span>{{activity.date | utcToLocaleRDate}}</span>
                                    <span
                                        class="ml-1 text-xs font-bold me-2 px-1.5 py-0.5 rounded-full bg-red-200 text-red-900"
                                        *ngIf="activity.status && activity.status.toLowerCase() === 'open'">{{activity.status}}</span>
                                    <span
                                        class="ml-1 text-xs font-bold me-2 px-1.5 py-0.5 rounded-full bg-green-200 text-green-900"
                                        *ngIf="activity.status && activity.status.toLowerCase() === 'closed'">{{activity.status}}</span>
                                </div>
                                <!-- Description -->
                                <ng-container *ngIf="activity.description">
                                    <div
                                        class="break-all whitespace-pre-line">{{activity.description | cleanString }}</div>
                                </ng-container>
                                <div
                                    class="flex flex-col sm:flex-row sm:items-center mt-2 sm:mt-1 sm:space-x-2 text-xs leading-5">
                                    <!-- Linked content -->
                                    <ng-container *ngIf="activity.linkedContent">
                                        <div class="hidden sm:block">&bull;</div>
                                        <!-- Internal link -->
                                        <ng-container *ngIf="activity.useRouter">
                                            <a class="cursor-pointer text-primary" [routerLink]="activity.link">
                                                {{activity.linkedContent}}
                                            </a>
                                        </ng-container>
                                        <!-- External link -->
                                        <ng-container *ngIf="!activity.useRouter">
                                            <a class="cursor-pointer text-primary" [href]="activity.link"
                                               target="_blank">
                                                {{activity.linkedContent}}
                                            </a>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <!-- Tickets View-->
                                <ng-container *ngIf="activity.tickets && activity.tickets.length">
                                    <div class="flex flex-row items-center gap-2 flex-wrap w-full py-2">
                                        <!-- <span class="font-semibold text-base mr-1">Tickets: </span> <span
                                        *ngFor="let t of activity.tickets; let i = index;"
                                        [matTooltip]="t.integration_name" [ngClass]="colours[i]">{{t.ticket_id}}
                                        </span> -->
                                        <span class="font-semibold text-base mr-1">Tickets: </span>
                                        <ng-container *ngFor="let t of activity.tickets; let i = index;">
                                                <span [matTooltip]="t.integration_name"
                                                      class="ml-1 text-xs font-bold me-2 ring-1 px-1.5 py-0.5 rounded-full bg-yellow-200 text-blue-800"
                                                      *ngIf="t.ticket_status && t.ticket_status.toLowerCase() === 'open'">{{t.ticket_number || t.ticket_id}}
                                                    -{{t.ticket_status}}</span>
                                            <span [matTooltip]="t.integration_name"
                                                  class="ml-1 text-xs font-bold me-2 ring-1 px-1.5 py-0.5 rounded-full bg-green-200 text-blue-800"
                                                  *ngIf="t.ticket_status && t.ticket_status.toLowerCase() === 'closed'">{{t.ticket_number || t.ticket_id}}
                                                -{{t.ticket_status}}</span>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <!-- Extra content -->
                                <ng-container *ngIf="activity.extraContent">
                                    <div class="mt-1 py-1 px-2 text-sm rounded-lg bg-gray-200 dark:bg-gray-800">
                                        {{activity.extraContent | camelToHuman:true}}</div>
                                </ng-container>
                            </div>
                        </div>
                    </li>
                </ng-container>
                <li class="relative flex py-4">
                    <div class="flex items-center justify-center mx-auto rounded-full shadow-lg bg-primary">
                        <button class="flex-0" mat-flat-button [color]="'primary'" appPreventMultiClick
                                (click)="goToPreviousStep()">
                            <span class="inline-flex items-center">
                                <mat-icon class="mr-2" [svgIcon]="'heroicons_outline:arrow-narrow-left'"></mat-icon>
                                <span class="mr-1">Prev</span>
                            </span>
                        </button>
                        <div class="flex items-center justify-center mx-2.5 font-medium leading-5 text-on-primary">
                            <span *ngIf="props.timelineCount >0">{{currentStep + 1}}</span>
                            <span *ngIf="props.timelineCount ===0">0</span>
                            <span class="mx-0.5 text-hint">/</span>
                            <span>{{totalSteps}}</span>
                        </div>
                        <button class="flex-0" mat-flat-button [color]="'primary'" appPreventMultiClick
                                (click)="goToNextStep()">
                            <span class="inline-flex items-center">
                                <span class="ml-1">Next</span>
                                <mat-icon class="ml-2" [svgIcon]="'heroicons_outline:arrow-narrow-right'"></mat-icon>
                            </span>
                        </button>
                    </div>
                </li>
            </ol>
            <ng-template #noActivity>
                <div class="w-full">
                    <div class="flex flex-col items-center justify-center gap-y-2">
                        <img ngSrc="/assets/images/timeline.png" alt="" class="w-20" height="512" width="512">
                        <div> No data available!</div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<ng-container *ngIf="!showData">
 <app-skeleton></app-skeleton>
</ng-container>

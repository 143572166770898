export const settings = [
  {
    id: "overview",
    settings: [
      {
        name: "New Company",
        id: "new-company",
        icon: "heroicons_outline:folder-plus",
        target: "new-company",
        api: "companies",
      },
      {
        name: "Delete Companies",
        id: "delete-company",
        icon: "mat_outline:domain_disabled",
        target: "delete-company",
        api: "companies",
      },
      /*{
                name: 'Agents',
                id: 'global-agent',
                icon: 'mat_outline:connected_tv',
                target: 'global-agent',
                api: 'agent_discovery_credentials',
                allowed: ['reader'],
            },*/
      {
        name: "Integrations",
        id: "integrations",
        icon: "heroicons_outline:squares-plus",
        target: "integrations",
        api: "get_integrations",
      },
      {
        name: "Application Baseline",
        id: "application_base_rule",
        icon: "heroicons_mini:clipboard-document-check",
        target: "application_base_rule",
        api: "application_baseline_rules",
      },
      {
        name: "Settings",
        id: "global-settings",
        icon: "settings",
        target: "global-settings",
        api: "settings",
        allowed: ["writer"],
      },
      {
        name: "Profile",
        id: "profile",
        icon: "heroicons_outline:user",
        target: "profile",
      },
      {
        name: "User Management",
        id: "user-management",
        icon: "heroicons_outline:users",
        target: "user-management",
      },
      {
        name: "Plan and Billing",
        id: "plan-and-billing",
        icon: "heroicons_solid:currency-dollar",
        target: "plan-and-billing",
      },
      {
        name: "Getting Started",
        id: "getting-started",
        icon: "heroicons_solid:information-circle",
        target: "getting-started",
      },
      {
        name: "Search CVE",
        id: "search-cve",
        icon: "mat_outline:search",
        target: "search-cve",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "metrics",
    settings: [
      {
        name: "New Company",
        id: "new-company",
        icon: "heroicons_outline:folder-plus",
        target: "new-company",
        api: "companies",
      },
      {
        name: "Delete Companies",
        id: "delete-company",
        icon: "mat_outline:domain_disabled",
        target: "delete-company",
        api: "companies",
      },
      /*{
                name: 'Agents',
                id: 'global-agent',
                icon: 'mat_outline:connected_tv',
                target: 'global-agent',
                api: 'agent_discovery_credentials',
                allowed: ['reader'],
            },*/
      {
        name: "Integrations",
        id: "integrations",
        icon: "heroicons_outline:squares-plus",
        target: "integrations",
        api: "get_integrations",
      },
      {
        name: "Application Baseline",
        id: "application_base_rule",
        icon: "heroicons_mini:clipboard-document-check",
        target: "application_base_rule",
        api: "application_baseline_rules",
      },
      {
        name: "Settings",
        id: "global-settings",
        icon: "settings",
        target: "global-settings",
        api: "settings",
        allowed: ["writer"],
      },
      {
        name: "Profile",
        id: "profile",
        icon: "heroicons_outline:user",
        target: "profile",
      },
      {
        name: "User Management",
        id: "user-management",
        icon: "heroicons_outline:users",
        target: "user-management",
      },
      {
        name: "Plan and Billing",
        id: "plan-and-billing",
        icon: "heroicons_solid:currency-dollar",
        target: "plan-and-billing",
      },
      {
        name: "Getting Started",
        id: "getting-started",
        icon: "heroicons_solid:information-circle",
        target: "getting-started",
      },
      {
        name: "Search CVE",
        id: "search-cve",
        icon: "mat_outline:search",
        target: "search-cve",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "company",
    settings: [
      {
        name: "Tags Rules",
        id: "tags",
        icon: "heroicons_mini:tag",
        target: "tags",
        api: "tag_rules",
      },
      {
        name: "Company Settings",
        id: "company-settings",
        icon: "settings",
        target: "company-settings",
        api: "settings",
      },
      {
        name: "Update Company Details",
        id: "update-company",
        icon: "feather:database",
        target: "update-company",
        api: "companies",
      },
      {
        name: "Application Baseline",
        id: "application_base_rule",
        icon: "heroicons_mini:clipboard-document-check",
        target: "application_base_rule",
        api: "application_baseline_rule",
      },
      /*{
                name: 'Reset Agent Configuration',
                id: 'reset-configuration',
                icon: 'mat_outline:restart_alt',
                target: 'reset-configuration',
                api: 'reset_agents',
                emtterAction: 'agentEmitter'
            },*/
    ],
    allowed: ["writer"],
  },
  {
    id: "agents",
    settings: [
      {
        name: "Discovery Settings and Credentials",
        id: "discovery-settings",
        icon: "feather:list",
        target: "discovery-settings",
        api: "discovery_settings",
      },
      {
        name: "Manage Mapping",
        id: "manage-mapping",
        icon: "feather:check-square",
        target: "manage-mapping",
        api: "agent_discovery_settings",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "external-assets-config",
    settings: [
      {
        name: "Add Configuration",
        id: "add-config",
        icon: "heroicons_outline:folder-plus",
        target: "add-config",
        emtterAction: "exConfigEmitter",
        api: "external_scan",
      },
      {
        name: "Edit Configuration",
        id: "edit-config",
        icon: "feather:edit",
        target: "edit-config",
        emtterAction: "exConfigEmitter",
        api: "external_scan",
      },
      {
        name: "Remove Configuration",
        id: "remove-config",
        icon: "mat_outline:delete_outline",
        target: "remove-config",
        emtterAction: "exConfigEmitter",
        api: "external_scan",
      },
      {
        name: "Scan Now",
        id: "scan-config",
        icon: "mat_outline:track_changes",
        target: "scan-config",
        emtterAction: "exConfigEmitter",
        api: "external_scan",
      },
      {
        name: "Jobs",
        id: "jobs",
        icon: "mat_outline:hourglass_top",
        target: "jobs",
        api: "jobs",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "application-baseline",
    settings: [
      {
        name: "Add Rule",
        id: "add-rule",
        icon: "heroicons_outline:folder-plus",
        target: "add-rule",
        emtterAction: "appBaseEmitter",
        api: "external_scan",
      },
      {
        name: "Edit Rule",
        id: "edit-rule",
        icon: "feather:edit",
        target: "edit-rule",
        emtterAction: "appBaseEmitter",
        api: "external_scan",
      },
      {
        name: "Remove Rule",
        id: "remove-rule",
        icon: "mat_outline:delete_outline",
        target: "remove-rule",
        emtterAction: "appBaseEmitter",
        api: "external_scan",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "external-assets-profile",
    settings: [
      {
        name: "Add Profile",
        id: "add-profile",
        icon: "heroicons_outline:folder-plus",
        target: "add-profile",
        emtterAction: "exConfigEmitter",
        api: "external_scan",
      },
      {
        name: "Edit Profile",
        id: "edit-profile",
        icon: "feather:edit",
        target: "edit-profile",
        emtterAction: "exConfigEmitter",
        api: "external_scan",
      },
      {
        name: "Remove Profile",
        id: "remove-profile",
        icon: "mat_outline:delete_outline",
        target: "remove-profile",
        emtterAction: "exConfigEmitter",
        api: "external_scan",
      },
      {
        name: "Jobs",
        id: "jobs",
        icon: "mat_outline:hourglass_top",
        target: "jobs",
        api: "jobs",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "report-templates",
    settings: [
    ],
    allowed: ["writer"],
  },
  {
    id: "generated-templates",
    settings: [
      {
        name: "Terminate",
        id: "generated-terminate",
        icon: "stop_circle",
        target: "generated-terminate",
        api: "",
        emtterAction: "customReportEmitter",
      },
      {
        name: "Regenerate Job",
        id: "generated-recreate-job",
        icon: "autorenew",
        target: "generated-recreate-job",
        api: "",
        emtterAction: "customReportEmitter",
      },
      {
        name: "Download",
        id: "generated-report-download",
        icon: "download",
        target: "generated-report-download",
        api: "",
        emtterAction: "customReportEmitter",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "external-assets",
    settings: [
      {
        name: "Jobs",
        id: "jobs",
        icon: "mat_outline:hourglass_top",
        target: "jobs",
        api: "jobs",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "assets",
    settings: [
      {
        name: "Asset Timeline",
        id: "asset-time-line",
        icon: "feather:clock",
        target: "asset-time-line",
        api: "system_time_line",
      },
      {
        name: "Add Asset",
        id: "new-asset",
        icon: "heroicons_outline:folder-plus",
        target: "new-asset",
        emtterAction: "assetEmitter",
        api: "assets",
      },
      {
        name: "Remove Asset",
        id: "remove-asset",
        icon: "mat_outline:delete_outline",
        target: "remove-asset",
        emtterAction: "assetEmitter",
        api: "assets",
      },
      {
        name: "Deprecate Asset",
        id: "deprecate-asset",
        icon: "mat_outline:repeat",
        target: "deprecate-asset",
        emtterAction: "assetEmitter",
        api: "assets",
      },
      {
        name: "Scan Now",
        id: "scan-config",
        icon: "mat_outline:track_changes",
        target: "scan-asset",
        emtterAction: "assetEmitter",
        api: "asset_scan",
      },
      {
        name: "Problems/Solution Suppress",
        id: "problem_solution_suppress",
        icon: "feather:alert-triangle",
        target: "problem_solution_suppress",
        api: "createexception",
        emtterAction: "assetEmitter",
      },
      {
        name: "Problems/Solution Records",
        id: "asset_suppressed_records",
        icon: "feather:alert-circle",
        target: "asset_suppressed_records",
        api: "suppress_vulnerability",
      },
      {
        name: "Remediated Records",
        id: "asset_remediated_record",
        icon: "mat_outline:featured_play_list",
        target: "asset_remediated_record",
        api: "remediated",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "firewalls",
    settings: [
      {
        name: "Asset Timeline",
        id: "asset-time-line",
        icon: "feather:clock",
        target: "asset-time-line",
        api: "system_time_line",
      },
      {
        name: "Remove Asset",
        id: "remove-asset",
        icon: "mat_outline:delete_outline",
        target: "remove-asset",
        emtterAction: "assetEmitter",
        api: "assets",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "deprecated-assets",
    settings: [
      {
        name: "Asset Timeline",
        id: "asset-time-line",
        icon: "feather:clock",
        target: "asset-time-line",
        api: "system_time_line",
      },
      {
        name: "Remove Asset",
        id: "remove-asset",
        icon: "mat_outline:delete_outline",
        target: "remove-asset",
        emtterAction: "assetEmitter",
        api: "assets",
      },
      {
        name: "Restore Asset",
        id: "restore-asset",
        icon: "mat_outline:repeat",
        target: "restore-asset",
        emtterAction: "assetEmitter",
        api: "assets",
      },
      {
        name: "Problems/Solution Suppress",
        id: "problem_solution_suppress",
        icon: "feather:alert-triangle",
        target: "problem_solution_suppress",
        api: "createexception",
        emtterAction: "assetEmitter",
      },
      {
        name: "Problems/Solution Records",
        id: "asset_suppressed_records",
        icon: "feather:alert-circle",
        target: "asset_suppressed_records",
        api: "suppress_vulnerability",
      },
      {
        name: "Remediated Records",
        id: "asset_remediated_record",
        icon: "mat_outline:featured_play_list",
        target: "asset_remediated_record",
        api: "remediated",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "vulnerabilities",
    settings: [
      {
        name: "Vulnerability Suppress",
        id: "vulnerability_suppress",
        icon: "feather:alert-triangle",
        target: "vulnerability_suppress",
        isActionOnly: true,
        api: "createexception",
      },
      {
        name: "Vulnerability Suppressed Records",
        id: "vulnerability_suppressed_records",
        icon: "feather:alert-circle",
        target: "vulnerability_suppressed_records",
        isActionOnly: false,
        api: "suppress_vulnerability",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "problems",
    settings: [
      {
        name: "Problems Suppress",
        id: "problem_suppress",
        icon: "feather:alert-triangle",
        target: "problem_suppress",
        isActionOnly: true,
        api: "createexception",
      },
      {
        name: "Problems Suppressed Records",
        id: "problem_suppress_record",
        icon: "feather:alert-circle",
        target: "problem_suppress_record",
        isActionOnly: false,
        api: "suppress_vulnerability",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "standard-reports",
    settings: [
      {
        name: "Report Settings",
        id: "report-settings",
        icon: "settings",
        target: "report-settings",
        api: "settings",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "standard-report-job",
    settings: [
      {
        name: "Report Settings",
        id: "report-settings",
        icon: "settings",
        target: "report-settings",
        api: "settings",
      },
      {
        name: "Details",
        id: "details",
        icon: "info",
        target: "details",
        emtterAction: "reportEmitter",
      },
      {
        name: "Terminate",
        id: "terminate",
        icon: "stop_circle",
        target: "terminate",
        api: "",
        emtterAction: "reportEmitter",
      },
      {
        name: "Regenerate Job",
        id: "recreate-job",
        icon: "autorenew",
        target: "recreate-job",
        api: "",
        emtterAction: "reportEmitter",
      },
      {
        name: "Download",
        id: "report-download",
        icon: "download",
        target: "report-download",
        api: "",
        emtterAction: "reportEmitter",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "compliance-standards",
    settings: [
      {
        name: "Upload Evidence",
        id: "upload-evidence",
        icon: "feather:upload",
        target: "upload-evidence",
        emtterAction: "complianceEmitter",
        api: "upload_evidence",
      },
      {
        name: "Jobs",
        id: "jobs",
        icon: "mat_outline:hourglass_top",
        target: "jobs",
        api: "jobs",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "compliance-check-master",
    settings: [
      {
        name: "Jobs",
        id: "jobs",
        icon: "mat_outline:hourglass_top",
        target: "jobs",
        api: "jobs",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "azure",
    settings: [
      {
        name: "Sync Now",
        id: "azure-sync",
        icon: "feather:sliders",
        target: "azure-sync",
        emtterAction: "azureSyncEmitter",
        api: "sync_now",
      },
      {
        name: "Jobs",
        id: "jobs",
        icon: "mat_outline:hourglass_top",
        target: "jobs",
        api: "jobs",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "microsoft",
    settings: [
      {
        name: "Sync Now",
        id: "azure-sync",
        icon: "feather:sliders",
        target: "azure-sync",
        emtterAction: "microSyncEmitter",
        api: "sync_now",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "pii-scan-profile",
    settings: [
      {
        name: "Add Scan Profile",
        id: "add-scan-profile",
        icon: "heroicons_outline:folder-plus",
        target: "add-scan-profile",
        emtterAction: "scanProfileEmitter",
        api: "pii_scan_settings",
      },
      {
        name: "Initiate Scan",
        id: "scan",
        icon: "mat_outline:track_changes",
        target: "scan",
        emtterAction: "scanProfileEmitter",
        api: "pii_scan",
      },
      {
        name: "Edit Scan Profile",
        id: "edit-scan-profile",
        icon: "feather:edit",
        target: "edit-scan-profile",
        emtterAction: "scanProfileEmitter",
        api: "pii_scan_settings",
      },
      {
        name: "Remove Scan Profile",
        id: "remove-scan-profile",
        icon: "mat_outline:delete_outline",
        target: "remove-scan-profile",
        emtterAction: "scanProfileEmitter",
        api: "pii_scan_settings",
      },
      {
        name: "Jobs",
        id: "jobs",
        icon: "mat_outline:hourglass_top",
        target: "jobs",
        api: "jobs",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "scheduler",
    settings: [
      {
        name: "Add Scheduler",
        id: "add-scan-scheduler",
        icon: "heroicons_outline:folder-plus",
        target: "add-scan-scheduler",
        emtterAction: "schedulerEmitter",
        // api: 'schedulers'
      },
      {
        name: "Edit Scheduler",
        id: "edit-scan-scheduler",
        icon: "feather:edit",
        target: "edit-scan-scheduler",
        emtterAction: "schedulerEmitter",
        // api: 'schedulers'
      },
      {
        name: "Remove Scheduler",
        id: "remove-scan-scheduler",
        icon: "mat_outline:delete_outline",
        target: "remove-scan-scheduler",
        emtterAction: "schedulerEmitter",
      },
    ],
  },
  {
    id: "view-report-scheduler",
    settings: [
      {
        name: "Add Scheduler",
        id: "add-rep-scheduler",
        icon: "heroicons_outline:folder-plus",
        target: "add-rep-scheduler",
        emtterAction: "schedulerEmitter",
        // api: 'schedulers'
      },
      {
        name: "Edit Scheduler",
        id: "edit-rep-scheduler",
        icon: "feather:edit",
        target: "edit-rep-scheduler",
        emtterAction: "schedulerEmitter",
        // api: 'schedulers'
      },
      {
        name: "Remove Scheduler",
        id: "remove-rep-scheduler",
        icon: "mat_outline:delete_outline",
        target: "remove-rep-scheduler",
        emtterAction: "schedulerEmitter",
      },
    ],
  },
  {
    id: "view-patch-scheduler",
    settings: [
      {
        name: "Add Scheduler",
        id: "add-patch-scheduler",
        icon: "heroicons_outline:folder-plus",
        target: "add-patch-scheduler",
        emtterAction: "schedulerEmitter",
        // api: 'schedulers'
      },
      {
        name: "Edit Scheduler",
        id: "edit-patch-scheduler",
        icon: "feather:edit",
        target: "edit-patch-scheduler",
        emtterAction: "schedulerEmitter",
        // api: 'schedulers'
      },
      {
        name: "Remove Scheduler",
        id: "remove-patch-scheduler",
        icon: "mat_outline:delete_outline",
        target: "remove-patch-scheduler",
        emtterAction: "schedulerEmitter",
      },
    ],
  },
  {
    id: "view-report-builder-scheduler",
    settings: [
      {
        name: "Add Scheduler",
        id: "add-rep-build-scheduler",
        icon: "heroicons_outline:folder-plus",
        target: "add-rep-build-scheduler",
        emtterAction: "schedulerEmitter",
        // api: 'schedulers'
      },
      {
        name: "Edit Scheduler",
        id: "edit-rep-build-scheduler",
        icon: "feather:edit",
        target: "edit-rep-build-scheduler",
        emtterAction: "schedulerEmitter",
        // api: 'schedulers'
      },
      {
        name: "Remove Scheduler",
        id: "remove-rep-build-scheduler",
        icon: "mat_outline:delete_outline",
        target: "remove-rep-build-scheduler",
        emtterAction: "schedulerEmitter",
      },
    ],
  },
  {
    id: "attack-surface-mapper",
    settings: [
      {
        name: "Add Domain",
        id: "add-asm",
        icon: "heroicons_outline:folder-plus",
        target: "add-asm",
        emtterAction: "asmEmitter",
      },
      {
        name: "Edit Domain",
        id: "edit-asm",
        icon: "feather:edit",
        target: "edit-asm",
        emtterAction: "asmEmitter",
      },
      {
        name: "Remove Domain",
        id: "remove-asm",
        icon: "mat_outline:delete_outline",
        target: "remove-asm",
        emtterAction: "asmEmitter",
      },
      {
        name: "Scan Now",
        id: "scan-asm",
        icon: "mat_outline:track_changes",
        target: "scan-asm",
        emtterAction: "asmEmitter",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "pii-scan-result",
    settings: [
      {
        name: "Jobs",
        id: "jobs",
        icon: "mat_outline:hourglass_top",
        target: "jobs",
        api: "jobs",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "remediation-plan",
    settings: [
      {
        name: "Suppress",
        id: "suppress",
        icon: "feather:alert-triangle",
        target: "suppress",
        isActionOnly: true,
        api: "createexception",
      },
      {
        name: "Suppressed Records",
        id: "suppressed_records",
        icon: "feather:alert-circle",
        target: "suppressed_records",
        isActionOnly: false,
        api: "suppress_vulnerability",
      },
      {
        name: "Remediated Records",
        id: "remediated_record",
        icon: "mat_outline:featured_play_list",
        target: "remediated_record",
        api: "remediated",
      },
    ],
    allowed: ["writer"],
  },
];

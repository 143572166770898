import { ElementRef, EventEmitter, Injectable, ViewChild } from '@angular/core';
import {BehaviorSubject, Subject, Subscribable, Subscription, takeUntil} from 'rxjs';
import { BaseRequestService } from './base.service';
import { MyToastrService } from './toastr.service';
import { FuseConfirmationService } from "../../@fuse/services/confirmation";
import { LoaderService } from "./loader.service";
import jwt_encode from 'jwt-encode';
import sign from 'jwt-encode';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    currentScreen: any;
    checkLayout="centered";
    public companyHash: any = { '*': { name: 'Global' } };
    previousLink = '';
    totalCompany = 0;
    allComp: any = [];
    pgc: any;
    pgcHash: any = {};
    criticalId: any;
    highId: any;
    mediumId: any;
    lowId: any;
    cisaId: any;
    epssId: any;
    sConfig: any = { 'category': 'settings', 'sub_category': 'layout', 'name': '', 'value': [], };
    cHash: any;
    companiesList: any = [];
    modifiedDiscoverItems: any;
    patchingStatus = false;
    tcve: any;
    isEulaAccepted = false;
    Objectkeys = Object.keys;
    complianceMaster: any = {};
    complianceList: any = [];
    complianceOsList: any = [];
    securityMatrix: any = {};
    complianceMatrix: any = {};
    scanProfileName: any = {};
    psaList: any = [];
    selectedRows = new EventEmitter();
    updateSourceCompany = new EventEmitter();
    isChecked = 'right';
    isCheckedChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    angularEditorChange: Subject<object> = new Subject<object>();
    bwListChange: Subject<object> = new Subject<object>();
    captureScreenChange = new EventEmitter();
    initCaptureChange: Subject<object> = new Subject<object>();
    openSideNavChange: Subject<object> = new Subject<object>();
    selectedSiteChange: Subject<any> = new Subject<any>();
    rmRefreshEvent: Subject<any> = new Subject<any>();
    selectedNode = {};
    networkDeviceReloadChange: Subject<any> = new Subject<any>();
    addCompanyChange: Subject<any> = new Subject<any>();
    addSiteChange: Subject<any> = new Subject<any>();
    checkNode = {};
    timeZoneList: any;
    currentTimezone: any;
    unauth: any;
    currentCompany: any;
    ccId: any;
    integrations: any = [];
    modalHolder: string[] = [];
    currentAssessment: any;
    currentCompanyView: string;
    problem: any = {};
    public logo: any = '/assets/images/logo/cs_logo_light.png';
    selctedAsset: any = '';
    public currentScope: any = '*';
    getScope = new BehaviorSubject<boolean>(false);
    cScope$ = this.getScope.asObservable();
    cspChange: Subject<object> = new Subject<object>();
    public dynamicScrollTop = new EventEmitter();
    public openSuppress = new EventEmitter();
    public problemSuppress = new EventEmitter();
    public remediationSuppress = new EventEmitter();
    public uploadEvidence = new EventEmitter();
    public selectedSiteChanged = new EventEmitter();
    public noCompanyEvent = new EventEmitter();
    public selectedTagChange = new EventEmitter();
    public reloadServerSideTable = new EventEmitter();
    public updateCompanyCall = new EventEmitter();
    public openDiscoverySettings = new EventEmitter();
    public newCompanyCall = new EventEmitter();
    public dynamicSettingsToggle = new EventEmitter();
    public newScanProfileCall = new EventEmitter();
    public newAgentCall = new EventEmitter();
    public newTagCall = new EventEmitter();
    public newDiscoverySettingCall = new EventEmitter();
    public newCredentialCall = new EventEmitter();
    public formValidationEVE = new EventEmitter();
    public onboardingCom = new EventEmitter();
    public showHideCom = new EventEmitter();
    public piiResultShow = new EventEmitter();
    public openSearchCVESettings = new EventEmitter();
    public selectedTableRows: any = {};
    public selectedTableName: any = '';
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    subs: Subscription;
    rowSub: Subscription;
    isComLoading = true;
    reason: any = '';
    portInfo: any = {
        20: {
            protocol: 'FTP',
            description: 'File Transfer Protocol data transfer'
        },
        21: {
            protocol: 'FTP',
            description: 'File Transfer Protocol control'
        },
        22: {
            protocol: 'SSH',
            description: 'Secure Shell'
        },
        23: {
            protocol: 'Telnet',
            description: 'Telnet remote communication protocol'
        },
        25: {
            protocol: 'SMTP',
            description: 'Simple Mail Transfer Protocol'
        },
        53: {
            protocol: 'DNS',
            description: 'Domain Name System'
        },
        67: {
            protocol: 'DHCP',
            description: 'Dynamic Host Configuration Protocol server'
        },
        68: {
            protocol: 'DHCP',
            description: 'Dynamic Host Configuration Protocol client'
        },
        80: {
            protocol: 'HTTP',
            description: 'Hypertext Transfer Protocol'
        },
        110: {
            protocol: 'POP3',
            description: 'Post Office Protocol version 3'
        },
        119: {
            protocol: 'NNTP',
            description: 'Network News Transfer Protocol'
        },
        123: {
            protocol: 'NTP',
            description: 'Network Time Protocol'
        },
        143: {
            protocol: 'IMAP',
            description: 'Internet Message Access Protocol'
        },
        161: {
            protocol: 'SNMP',
            description: 'Simple Network Management Protocol'
        },
        179: {
            protocol: 'BGP',
            description: 'Border Gateway Protocol'
        },
        389: {
            protocol: 'LDAP',
            description: 'Lightweight Directory Access Protocol'
        },
        443: {
            protocol: 'HTTPS',
            description: 'HTTP Secure'
        },
        465: {
            protocol: 'SMTPS',
            description: 'Simple Mail Transfer Protocol Secure'
        },
        514: {
            protocol: 'Syslog',
            description: 'System Log'
        },
        587: {
            protocol: 'SMTP',
            description: 'Simple Mail Transfer Protocol (submission)'
        },
        636: {
            protocol: 'LDAPS',
            description: 'LDAP Secure'
        },
        993: {
            protocol: 'IMAPS',
            description: 'IMAP Secure'
        },
        995: {
            protocol: 'POP3S',
            description: 'POP3 Secure'
        },
        1433: {
            protocol: 'MS SQL',
            description: 'Microsoft SQL Server'
        },
        1521: {
            protocol: 'Oracle',
            description: 'Oracle database default listener'
        },
        1723: {
            protocol: 'PPTP',
            description: 'Point-to-Point Tunneling Protocol'
        },
        2082: {
            protocol: 'cPanel',
            description: 'cPanel default'
        },
        2083: {
            protocol: 'cPanel',
            description: 'cPanel default SSL'
        },
        2086: {
            protocol: 'WHM',
            description: 'WHM default'
        },
        2087: {
            protocol: 'WHM',
            description: 'WHM default SSL'
        },
        2095: {
            protocol: 'Webmail',
            description: 'cPanel webmail'
        },
        2096: {
            protocol: 'Webmail',
            description: 'cPanel webmail SSL'
        },
        2222: {
            protocol: 'DirectAdmin',
            description: 'DirectAdmin default'
        },
        3306: {
            protocol: 'MySQL',
            description: 'MySQL database system'
        },
        3389: {
            protocol: 'RDP',
            description: 'Remote Desktop Protocol'
        },
        5432: {
            protocol: 'PostgreSQL',
            description: 'PostgreSQL database system'
        },
        5900: {
            protocol: 'VNC',
            description: 'Virtual Network Computing'
        },
        8000: {
            protocol: 'HTTP',
            description: 'HTTP alternative port'
        },
        8080: {
            protocol: 'HTTP',
            description: 'HTTP alternative port'
        },
        8443: {
            protocol: 'HTTPS',
            description: 'HTTPS alternative port'
        },
        8888: {
            protocol: 'HTTP',
            description: 'HTTP alternative port'
        },
        9000: {
            protocol: 'HTTP',
            description: 'HTTP alternative port'
        },
        9090: {
            protocol: 'HTTP',
            description: 'HTTP alternative port'
        },
        9200: {
            protocol: 'Elasticsearch',
            description: 'Elasticsearch HTTP REST API'
        },
        9300: {
            protocol: 'Elasticsearch',
            description: 'Elasticsearch transport protocol'
        },
        10000: {
            protocol: 'Webmin',
            description: 'Webmin default'
        },
        11211: {
            protocol: 'Memcached',
            description: 'Memcached caching system'
        },
        27017: {
            protocol: 'MongoDB',
            description: 'MongoDB database system'
        },
        28017: {
            protocol: 'MongoDB',
            description: 'MongoDB web interface'
        }
    };
    public arrayToObject : any = (array: { [key: string]: any }[], key: string, value: string): { [key: string]: any } =>
  array.reduce((obj, item) => ({ ...obj, [item[key]]: item[value] }), {});

    // @ts-ignore
    constructor(
        private toast: MyToastrService, private loaderService: LoaderService,
        private _bs: BaseRequestService, public confirmDialog: FuseConfirmationService
    ) {
        this.subs = this.selectedSiteChanged.subscribe((com: any) => {
            this.getScope.next(com);
        });

        this.rowSub = this.selectedRows.subscribe((res: any) => {
            this.selectedTableRows[res.table] = res.row;
            this.selectedTableName = res.table;
        });
    }

    objectToQueryString(obj: any): string {
        const queryParams = [];
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                queryParams.push(key + '=' + obj[key]);
            }
        }
        return queryParams.join('&');
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.subs.unsubscribe();
        this.rowSub.unsubscribe();
    }

    getProblemGroup(): void {
        try {
            const pgc = localStorage.getItem('pgc');
            if (pgc) {
                this.pgc = JSON.parse(pgc);
                this.pgc.forEach((o: any) => {
                    if (o.problem_group_name.toLowerCase() === 'critical vulnerabilities') {
                        this.criticalId = o.id;
                    }
                    if (o.problem_group_name.toLowerCase() === 'high severity vulnerabilities') {
                        this.highId = o.id;
                    }
                    if (o.problem_group_name.toLowerCase() === 'medium severity vulnerabilities') {
                        this.mediumId = o.id;
                    }
                    if (o.problem_group_name.toLowerCase() === 'low severity vulnerabilities') {
                        this.lowId = o.id;
                    }
                    if (o.problem_group_name.toLowerCase() === 'cisa notified vulnerabilities') {
                        this.cisaId = o.id;
                    }
                    if (o.problem_group_name.toLowerCase() === 'epss >= 0.95') {
                        this.epssId = o.id;
                    }
                    this.pgcHash[o.id] = o;
                });
            } else {
                this.loaderService.display(true);
                this._bs.doRequest(`/r/company/problem_groups`, 'get', null, {
                    condition: true,
                    skip: 0,
                    limit: 2000
                }).pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((res: any) => {
                        this.loaderService.display(false);
                        if (res.status && res.data && res.data.length) {
                            res.data.forEach((o: any) => {
                                if (o.problem_group_name.toLowerCase() === 'critical vulnerabilities') {
                                    this.criticalId = o.id;
                                }
                                if (o.problem_group_name.toLowerCase() === 'high severity Vulnerabilities') {
                                    this.highId = o.id;
                                }
                                if (o.problem_group_name.toLowerCase() === 'medium severity vulnerabilities') {
                                    this.mediumId = o.id;
                                }
                                if (o.problem_group_name.toLowerCase() === 'low severity vulnerabilities') {
                                    this.lowId = o.id;
                                }
                                if (o.problem_group_name.toLowerCase() === 'cisa notified vulnerabilities') {
                                    this.cisaId = o.id;
                                }
                                if (o.problem_group_name.toLowerCase() === 'epss >= 0.95') {
                                    this.epssId = o.id;
                                }
                                this.pgcHash[o.id] = o;
                            });
                            localStorage.setItem('pgc', JSON.stringify(res.data));
                        } 
                        // else {
                        //     this.toast.sToast('error', 'Problem Groups Not Found! Please contact support!');
                        // }
                    });
            }
        } catch (e) {
            console.log(e);
        }
    }

    fuzzyFilterArray(input: string, array: any[]): any[] {
      const searchTerm = input.toLowerCase();
      return array.filter((item) => {
        // Check all properties for a match
        return Object.values(item).some((propertyValue) => {
          if (typeof propertyValue === 'string' || typeof propertyValue === 'number') {
            const stringValue = String(propertyValue).toLowerCase();
            return stringValue.includes(searchTerm);
          }
          return false;
        });
      });
    }

    generateToken(payload: any): any {
        const signing_key: string = "wJV9goRXg6qbpcoRNSxUMD+6LYUAgLcF0Urf655ONAjAghLIl0Ab8qE1AWqWwQPQ";
        const minutesToAdd = 120;
        payload.exp = Math.floor((Date.now() + 120 * 60 * 1000) / 1000);
        return sign(payload, signing_key, { alg: 'HS256' });
    }

    setCSP(): void {
        this.cspChange.next({ csp: true });
    }

    captureScreen(element: any): void {
        const canvas = document.createElement('canvas');
        canvas.width = element.offsetWidth;
        canvas.height = element.offsetHeight;
        const context = canvas.getContext('2d');
        context.drawImage(element, 0, 0, canvas.width, canvas.height);
        this.currentScreen = canvas.toDataURL('image/png');
    }

    getMaturityLevels(maturity: any): any {
        const arrayOfItems = maturity.split(',');
        const formattedArray = arrayOfItems.map((item: any) => {
            return '%' + item + '%';
        });
        return formattedArray.join(',');
    }

    async processComponent(tab: any): Promise<any> {
        const condition =
            this.currentScope === '*'
                ? { condition: true, skip: 0, limit: 50 }
                : { condition: "company_id='" + this.currentScope.id + "'" };
        if (tab.props?.path && this.currentScope === '*') {
            condition.condition = tab.props?.path.condition;
        } else if (tab.props?.path) {
            condition.condition += ' and ' + tab.props?.path.condition;
        }
        this._bs
            .doRequest(tab.tablename, 'get', null, condition)
            .subscribe((result: any) => {
                if (result.status) {
                    try {
                        tab.props.name = tab.name;
                        tab.inputs = {
                            props: tab.props,
                            inputData: result,
                            dataMapping: tab.mapping,
                        };
                        tab.inputData = result;
                    } catch (e) {
                        console.log(e);
                    }
                }
            });
    }

    getNestedProperty(obj: any, path: string): any {
        return path.split('.').reduce((acc, curr) => {
            return acc ? acc[curr] : undefined;
        }, obj);
    }

    getRiskScore(asset: any): any {
        let rs = (!asset.vul_stats && !asset.vul_stats.max_risk_score && !asset.last_vul_scan_time) ? -1 : asset.vul_stats.max_risk_score;
        let riskScore: any;
        if (!rs && rs !== 0 || rs < 0) {
            riskScore = '-';
        } else if (rs >= 0 && rs <= 40) {
            riskScore = 'A';
        } else if (rs > 40 && rs <= 45) {
            riskScore = 'B';
        } else if (rs > 45 && rs <= 60) {
            riskScore = 'C';
        } else if (rs > 60 && rs <= 75) {
            riskScore = 'D';
        } else if (rs > 75 && rs <= 90) {
            riskScore = 'E';
        } else if (rs > 90) {
            riskScore = 'F';
        }
        return riskScore;
    }

    async deleteObject(url: string, message?: string): Promise<any> {
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: (message) ? message : `Are you sure you want to delete ?`,
            actions: {
                confirm: {
                    label: 'Yes'
                }
            }
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this.loaderService.display(true);
                this._bs.doRequest(url, 'delete')
                    .subscribe((result: any) => {
                        this.loaderService.display(false);
                        if (result.status) {
                            this.toast.sToast('success', 'Removed successfully');
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        })
    }

    getTimeZone(): void {
        const offset = (new Date()).getTimezoneOffset();
        const timezones = {
            '-12': 'Pacific/Kwajalein',
            '-11': 'Pacific/Samoa',
            '-10': 'Pacific/Honolulu',
            '-9': 'America/Juneau',
            '-8': 'America/Los_Angeles',
            '-7': 'America/Denver',
            '-6': 'America/Mexico_City',
            '-5': 'America/New_York',
            '-4': 'America/Caracas',
            '-3.5': 'America/St_Johns',
            '-3': 'America/Argentina/Buenos_Aires',
            '-2': 'Atlantic/Azores',
            '-1': 'Atlantic/Azores',
            0: 'Europe/London',
            1: 'Europe/Paris',
            2: 'Europe/Helsinki',
            3: 'Europe/Moscow',
            3.5: 'Asia/Tehran',
            4: 'Asia/Baku',
            4.5: 'Asia/Kabul',
            5: 'Asia/Karachi',
            5.5: 'Asia/Calcutta',
            6: 'Asia/Colombo',
            7: 'Asia/Bangkok',
            8: 'Asia/Singapore',
            9: 'Asia/Tokyo',
            9.5: 'Australia/Darwin',
            10: 'Pacific/Guam',
            11: 'Asia/Magadan',
            12: 'Asia/Kamchatka'
        };
        // @ts-ignore
        this.currentTimezone = timezones[-offset / 60];
        const timeZone = localStorage.getItem('_timeZones');
        if (timeZone && timeZone.length > 0) {
            this.timeZoneList = JSON.parse(timeZone);
        } else {
            this._bs.doRequest(`/r/company/jsonconfigs/timezones`, 'get').subscribe((result: any) => {
                this.timeZoneList = result.data;
            });
        }
    }

    processFormElements(formElements: any): any {
        const elementList: any = [];
        formElements.forEach((obj: any) => {
            obj.schema = (obj.schema && obj.schema.type) ? obj.schema : { type: obj.schema_type };
            elementList.push({
                label: obj.description,
                key: obj.name,
                required: obj.required,
                example: obj.example,
                default: obj.default,
                callback: obj.callback,
                title: obj.title,
                type: obj.schema.type,
                min: obj.min, max: obj.max,
                alertType: obj.alertType,
                condition: obj.condition,
                appearance: obj.appearance,
                span: obj.schema.span,
                radioList: (obj.schema.type === 'custom-radiogroup' && obj.schema.radioList) ? obj.schema.radioList : undefined,
                dKey: (obj.schema.type === 'dropdown' && obj.schema.key) ? obj.schema.key : (obj.dKey) ? obj.dKey : undefined,
                dValue: (obj.schema.type === 'dropdown' && obj.schema.value) ? obj.schema.value : (obj.dValue) ? obj.dValue : undefined,
                allcompany: obj.allcompany ? obj.allcompany : false,
                ishtml: obj.ishtml,
                help_url: obj.help_url,
                options: obj?.options,
            });
        });
        return elementList;
    }


    extractValue(obj: any, fieldName: string): any {
        /*const fieldParts = fieldName.split('.');
        let currentObj = obj;
        for (const fieldPart of fieldParts) {
            if (currentObj.hasOwnProperty(fieldPart)) {
                currentObj = currentObj[fieldPart];
            } else {
                return undefined; // Field not found
            }
        }*/
        let parts: any[];
        let property: string;
        parts = fieldName.split('.');
        const length = parts.length;
        let i: number;
        property = obj;
        for (i = 0; i < length; i++) {
            // @ts-ignore
            try {
                property = property[parts[i]];
            } catch (e) {
                return property;
            }
        }
        return property;
    }

    async sortFn(arr: any, key?: string, order?: any): Promise<any> {
        if (key) {
            return await arr.sort((a: any, b: any) => {
                const c = a[key];
                const d = b[key];
                if (!order || (order && order === -1)) {
                    if (c < d) {
                        return -1;
                    } else if (c > d) {
                        return 1;
                    } else {
                        return 0;
                    }
                } else if (!order || (order && order === 1)) {
                    if (c > d) {
                        return -1;
                    } else if (c < d) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
        }
        return await arr.sort((c: any, d: any) => {
            if (c < d) {
                return -1;
            } else if (c > d) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    changeHeader(header: any): any {
        return (header) ? header.replace(/\s+/g, '_').toLowerCase() : null;
    }

    compare(a: number | string, b: number | string, isAsc: boolean): any {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    executeAction(param: any): Promise<any> {
        const params: any = {
            integration_id: param.integration_id,
            integration_name: param.integration_name,
            action_name: param.action_name,
            params: param.requestparams
        };
        return this._bs.doRequest(`w/integration/execute_action`, 'post', params).toPromise();
    }

    copyClip(cmd: string): void {
        const el = document.createElement('textarea');
        el.value = cmd;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.toast.sToast('success', 'Copied to clipboard');
    }

    async processFieldsParams(result: any, updated_result: any, integration_name: any, integration_id: any, action_data: any): Promise<any> {
        result = result.filter((x: any) => x.schema_type !== 'hidden');
        const tmpAP: {
            label: any;
            key: any;
            required: any;
            example?: any;
            type: any;
        }[] = [];
        result.forEach(async (obj: any) => {
            const tmpFormField = updated_result.filter((x: any) => x.key === obj.name);
            const tFF: any = {
                label: obj.description,
                key: obj.name,
                required: obj.required,
                example: obj.example,
                default: obj.default,
                type: obj.schema_type,
                allcompany: obj.allcompany ? obj.allcompany : false,
                ishtml: obj.ishtml,
                help_url: obj.help_url,
                options: obj.options
            };
            tFF.callback = obj.callback;
            if (obj.source && !obj.depends) {
                if (
                    tmpFormField &&
                    tmpFormField.length &&
                    tmpFormField[0].options &&
                    tmpFormField[0].options.length
                ) {
                    tFF.options = tmpFormField[0].options;
                    tFF.search_element = tmpFormField[0]?.search_element;
                } else {
                    const requestParams: any = {};
                    obj.subParams = result;
                    if (obj.parameters) {
                        obj.parameters.forEach((pObj: any) => {
                            requestParams[pObj.name] = pObj.example || pObj.default;
                        });
                    }
                    this.loaderService.display(true, `Executing ${obj.name}`);
                    const params: any = {
                        integration_id: integration_id,
                        integration_name: integration_name,
                        action_name: obj.source.name,
                        requestparams: requestParams
                    };
                    this.executeAction(params).then((subOut: any) => {
                        this.loaderService.display(false);
                        if (subOut.status === false) {
                            this.toast.sToast('error', subOut.message);
                        } else {
                            obj.subOut = subOut;
                            tFF.options = [];
                            subOut.data.forEach((val: any) => {
                                tFF.options.push({
                                    key: val['name'] + '',
                                    value: val['id'] + '',
                                });
                            });
                        }
                    });
                }
            } else if (obj.source && obj.depends && this.getDependSource(obj.depends, action_data)) {
                let requestParams: any = {};
                obj.subParams = result;
                if (obj.parameters) {
                    obj.parameters.forEach((pObj: any) => {
                        requestParams[pObj.name] = pObj.example || pObj.default;
                    });
                }
                const action_params = (obj.source.parameter && obj.source.parameter.length) ? obj.source.parameter.reduce((result: any, key: any, index: any) => {
                    result[key] = action_data[obj.depends[index]];
                    return result;
                }, {}) : {};

                requestParams = { ...requestParams, ...action_params };
                this.loaderService.display(true, `Executing ${obj.name}`);
                const params: any = {
                    integration_id: integration_id,
                    integration_name: integration_name,
                    action_name: obj.source.name,
                    requestparams: requestParams
                };
                this.executeAction(params).then((subOut: any) => {
                    this.loaderService.display(false);
                    if (subOut.status === false && obj.required) {
                        this.toast.sToast('error', subOut.message);
                    }
                    obj.subOut = subOut.data;
                    tFF.options = [];
                    subOut.data.forEach((val: any) => {
                        tFF.options.push({ key: val['name'] + '', value: val['id'] + '' });
                    });

                });
            }
            tmpAP.push(tFF);
            if (obj.name === 'notesType') {
                action_data.notesType = 1;
            }
        });
        return tmpAP;
    }

    private getDependSource(depends: any, parameter: any): any {
        if (Object.keys(parameter).length === 0) {
            return false
        }
        ;
        const dataSource = depends.every((dependency: any) => {
            return parameter.hasOwnProperty(dependency) && parameter[dependency] !== undefined && parameter[dependency] !== '';
        });
        return dataSource;
    }

    transformArray(inputArray: any): any {
        const transformedArray = inputArray.map((item: any) => {
            return { key: (item.name) ? item.name : item.host_name, value: item.id };
        });
        return transformedArray;
    }

    async getAgents(search?: any, transform?: any): Promise<any> {
        const condition = search
            ? { condition: "name ilike '%" + search + "%'" }
            : {
                condition: true,
                skip: 0,
                limit: 50,
                order_by: 'created desc'
            };

        if (this.currentScope !== '*') {
            condition.condition += ' and ' + "company_id='" + this.currentScope.id + "'"
        }
        try {
            const response = await this._bs.doRequest("r/company/agents", "get", null, condition).toPromise();
            if (response.status && response.data.length) {
                response.data.sort((a: any, b: any) => {
                    const c = a.name ? a.name.toLowerCase() : a.host_name.toLowerCase();
                    const d = b.name ? b.name.toLowerCase() : b.host_name.toLowerCase();
                    if (c < d) {
                        return -1;
                    } else if (c > d) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }
            return (transform) ? await this.transformArray(response.data) : response.data;
        } catch (error) {
            // Handle errors here
            throw error;
        }

    }

    async getCompanies(search?: string, transform?: any): Promise<any> {
        const condition = search
            ? { condition: "name ilike '%" + search + "%'" }
            : {
                condition: true,
                skip: 0,
                limit: 5000,
                order_by: 'created desc'
            };
        try {
            const response = await this._bs.doRequest('/r/company/companies', 'get', null, condition).toPromise();
            if (response.status && response.data.length) {
                response.data.sort((a: any, b: any) => {
                    const c = a.name ? a.name.toLowerCase() : "";
                    const d = b.name ? b.name.toLowerCase() : "";
                    if (c < d) {
                        return -1;
                    } else if (c > d) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }
            return (transform) ? await this.transformArray(response.data) : response.data;
        } catch (error) {
            // Handle errors here
            throw error;
        }
    }

    pickObjectKeys(obj: any, keysToPick: any) {
        const pickedObject = {};

        keysToPick.forEach((key: any) => {
            if (obj.hasOwnProperty(key)) {
                pickedObject[key] = obj[key];
            }
        });

        return pickedObject;
    }

    // Function to recursively replace empty strings with null
    replaceEmptyWithNull(obj: any) {
        for (const key in obj) {
            if (typeof obj[key] === "object") {
                this.replaceEmptyWithNull(obj[key]); // Recursively process nested objects
            } else if (obj[key] === "") {
                obj[key] = null; // Replace empty strings with null
            }
        }
        return obj;
    }

    percentCalculator(x: number, y: number): number {
        return Math.floor((y / x) * 100);
    }

    dateFormatChange(date: any, add: any): any {
        if (!date) {
            return '-'
        }
        ;
        const utcDate = new Date(date);
        (add) ? utcDate.setUTCHours(utcDate.getUTCHours() + 5, utcDate.getUTCMinutes() + 30) : null;
        // const localDate = new Date(utcDate.toLocaleString())

        const months = [
            "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
            "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
        ];

        const month = months[utcDate.getMonth()];
        const day = utcDate.getDate();
        const year = utcDate.getFullYear();
        let hour = utcDate.getHours();
        const minute = utcDate.getMinutes();
        const ampm = hour >= 12 ? "PM" : "AM";

        // Convert the hour to 12-hour format
        if (hour > 12) {
            hour -= 12;
        }

        const formattedDate = `${month} ${day} ${year} ${hour}:${minute.toString().padStart(2, '0')} ${ampm}`;
        return formattedDate;
    }

    objectKeySort(list: any): any {
        return this.Objectkeys(list).sort((a: any, b: any) => list[b] - list[a]);
    }

    isValidPort(port: string): boolean {
        const portPattern = /^(\d+|\d+-\d+)$/;
        if (portPattern.test(port)) {
            if (port.includes('-')) {
                const [start, end] = port.split('-').map(Number);
                return start >= 0 && start <= 65535 && end >= 0 && end <= 65535 && start <= end;
            } else {
                const portNumber = Number(port);
                return portNumber >= 0 && portNumber <= 65535;
            }
        } else {
            return false;
        }
    }
    getLastDay(day: number, today?: any): any {
        if (!day) {
            return new Date().toLocaleDateString('en-CA') + 'T23:59:00';
        }
        const date = (today) ? new Date(today) : new Date();
        const nthday = new Date(date.getFullYear(), date.getMonth(), date.getDate() + day);
        // if (new Date(nthday).getTime() > new Date().getTime()) {
        //     return new Date().toLocaleDateString('en-CA') + 'T23:59:00';
        // }
        return new Date(nthday).toLocaleDateString('en-CA') + 'T23:59:00';
    }

    processTable(data: any) {
        data.forEach((rec: any, index: number) => {
            const a = rec.split(' : ');
            if (!a[1] && a[1] !== '') {
                a[0] = '';
                a[1] = '-';
            }
            if (a[1].trim() === '') {
                if (data[index + 1] && data[index + 1].indexOf(' : ') === -1) {
                    a[1] = data[index + 1];
                }
            }
            if (rec.indexOf(' : ') > -1) {
                data[index] = a;
            } else {
                a[0] = '';
                data[index] = a;
            }
        });
        return data;
    }

    toLocale(date: string): any {
        if (date === null || !date || date === '' || date === '-') {
            return date;
        }
        if (date.indexOf('T') > -1 && date.indexOf('Z') === -1) {
            return date += 'Z';
        } else {
            return date
        }
    }

    hasDuplicates(array: any[], keys: any): boolean {
        const seen = new Set();

        for (const obj of array) {
            const values = keys.map((key: any) => obj[key]);
            const objString = values.join(',');

            if (seen.has(objString)) {
                return true; // Duplicates found
            }

            seen.add(objString);
        }

        return false; // No duplicates found
    }
    dateChange(dateS: any, type: any) {
        const date = new Date(dateS);
        const currentTimeUTC = new Date().toUTCString();
        // Extract hours, minutes, and seconds from the UTC time
        const [_, hours, minutes, seconds] = currentTimeUTC.match(/(\d{2}):(\d{2}):(\d{2})/);
        const nthday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        if (type === 'start') {
            return new Date(nthday).toLocaleDateString('en-CA') + `T${hours}:${minutes}:${seconds}`;
        } else {
            return new Date(nthday).toLocaleDateString('en-CA') + `T${hours}:${minutes}:${seconds}`;
        }
    }
}

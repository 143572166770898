import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { Subscription, Subject, takeUntil } from 'rxjs';
import { TableComponent } from 'app/modules/shared/table/table.component';
import { MaterialModule } from 'app/material.module';
import { DynamicFormComponent } from 'app/modules/shared/forms/dynamic-form/dynamic-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MyToastrService } from 'app/_services/toastr.service';
import { DirectivesModule } from 'app/-directives/-directives.module';
import { LoaderService } from 'app/_services/loader.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';

@Component({
  selector: 'integration-profile',
  standalone: true,
  imports: [CommonModule, DirectivesModule, TableComponent, MaterialModule, DynamicFormComponent, FormsModule, MatFormFieldModule, ReactiveFormsModule],
  templateUrl: './integration-profile.component.html',
  styleUrls: ['./integration-profile.component.scss']
})
export class IntegrationProfileComponent implements OnInit, OnDestroy {
  @Input() currentIntegration: any;
  @Input() integrationCred: any = [];
  @Input() triggerNew: any;
  @Output() tiggerFn = new EventEmitter();
  subs: Subscription;
  formValid: Subscription;
  companyId: any;
  credentialId: any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  cView: any = 'table';
  intProfileTableOptions: any = {
    columns: [
      { "header": "Name", "columnDef": "name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      {
        "header": "Is Default", "columnDef": "default", "cType": "string", "filter": "", "cell": "(element: any) => `${element.default}`", "order": 1, "visible": true,
        isColoredCntrl: true,
        isCustomText: true,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      },
    ],
    sortOptions: { active: 'name', direction: 'asc' },
    _pageData: [],
    tableOptions: {
      title: 'Integration Rules',
      id: 'integration_rules',
      isServerSide: false,
      selectText: '',
      loading: false,
      floatingFilter: true,
      rowSelection: false,
      showAction: true,
      actionMenuItems: [
        {
          text: 'Edit',
          id: 'edit',
          icon: 'edit',
          callback: 'editFn',
          hideLocal: false,
          isGlobal: false,
        },
        {
          text: 'Delete',
          id: 'delete',
          icon: 'delete',
          callback: 'deleteFn',
          hideLocal: false,
          isGlobal: false,
        }
      ],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showhideList: true,
      refreshData: true,
      showFilter: true,
      showTagFilter: false,
      exportExcel: true,
      add: true,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: '/r/integration/integration_rules',
        dUrl: '/d/integration/integration_rules',
        condition: '',
        isGlobal: true,
      },
    },
    customText: [
      {
        status: true,
        DisplayText: 'Yes',
        color: '#16a34a',
        'text-color': '#000000'
      },
      {
        status: false,
        DisplayText: 'No',
        color: '#e48f8f',
        'text-color': '#000000'
      },
    ],
    changeValue: new Subject<any>(),

  };
  formElements: any = [];
  profile: any = {};
  create_ticket: any = [];
  disabledSubmit = false;
  constructor(private cs: CommonService, private _bs: BaseRequestService, private _changeDetectorRef: ChangeDetectorRef,
    private toast: MyToastrService, private _ls: LoaderService, public confirmDialog: FuseConfirmationService) {
    this.subs = this.cs.selectedSiteChanged.subscribe((res) => {
      this.companyId = (res === '*') ? undefined : res; console.log(res);
    });
    this.formValid = this.cs.formValidationEVE.subscribe(
      (res: any) => {
        this.disabledSubmit = res;
      }
    );
  }

  ngOnInit(): void {
    if (this.cs.currentScope !== '*') {
      this.companyId = this.cs.currentScope.id;
    }
    this.create_ticket = this.currentIntegration.actions.filter((x: any) => x.name === 'create_ticket' || x.name === 'send_message' || x.name === 'send_email');
    if (this.create_ticket && this.create_ticket.length) {
      this.create_ticket[0].parameters = this.create_ticket[0].parameters.filter(
        (x: any) => x.notification && x.requiredforglobal !== false
      );
      const isName = this.create_ticket[0].parameters.filter(
        (x: any) => x.name === 'name'
      );
      if (!isName || !isName.length) {
        const name: any = {
          callback: null, default: '', depends: null, description: 'Name',
          name: 'name', options: null, required: true, schema_type: 'text', source: null
        }
        this.create_ticket[0].parameters.unshift(name);
      }
      const isEvent = this.create_ticket[0].parameters.filter(
        (x: any) => x.name === 'event_set_id'
      );
      if (!isEvent || !isEvent.length) {
        this._bs.doRequest(`/r/company/event_set`, 'get', null,
          { condition: true, skip: 0, limit: 1000, order_by: 'name asc' })
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            const transformedData = result.data.map((item: any) => ({
              value: item.id,
              key: item.name
            }));
            const eventset: any = {
              callback: null, default: '', depends: null, description: 'Select Event Set', "requiredforglobal": null,
              name: 'event_set_id', options: transformedData, required: true, source: null, schema_type: 'dropdown'
            }
            this.create_ticket[0].parameters.push(eventset);
          })

      }
      if (this.triggerNew && this.triggerNew.show) {
        setTimeout(() => { this.addTableData({ event_set_id: this.triggerNew.id, name: '' }); this.triggerNew = { show: false, id: null }; }, 2000);
      }
    }
    this.intProfileTableOptions.tableOptions.serverSideparams = { integration_name: this.currentIntegration.name, credential_id: this.credentialId }
    this.credentialId = '';
    if (this.integrationCred && this.integrationCred.length) {
      this.credentialId = this.integrationCred[0].id;
      this.initProfile(this.integrationCred[0].id);
    }
  }

  initProfile($event: any): void {
    this.intProfileTableOptions = {
      columns: [
        { "header": "Name", "columnDef": "name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "Created On", "columnDef": "created", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.created}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        /*{
          "header": "Is Default", "columnDef": "default", "cType": "string", "filter": "", "cell": "(element: any) => `${element.default}`", "order": 1, "visible": true,
          isColoredCntrl: true,
          isCustomText: true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },*/
      ],
      sortOptions: { active: 'name', direction: 'asc' },
      _pageData: [],
      tableOptions: {
        title: 'Integration Rules',
        id: 'integration_rules',
        isServerSide: false,
        selectText: '',
        loading: false,
        floatingFilter: true,
        rowSelection: false,
        showAction: true,
        actionMenuItems: [
          {
            text: 'Edit',
            id: 'edit',
            icon: 'edit',
            callback: 'editFn',
            hideLocal: false,
            isGlobal: false,
          },
          {
            text: 'Delete',
            id: 'delete',
            icon: 'delete',
            callback: 'deleteFn',
            hideLocal: false,
            isGlobal: false,
          }
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 5,
        search: false,
        showhideList: true,
        refreshData: true,
        showFilter: true,
        showTagFilter: false,
        exportExcel: true,
        add: true,
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        serverSide: {
          url: '/r/integration/integration_rules',
          dUrl: '/d/integration/integration_rules',
          condition: "integration_name='" + this.currentIntegration.name + "'" + ' and ' + "credential_id='" + $event + "'",
        },
      },
      customText: [
        {
          status: true,
          DisplayText: 'Yes',
          color: '#16a34a',
          'text-color': '#000000'
        },
        {
          status: false,
          DisplayText: 'No',
          color: '#e48f8f',
          'text-color': '#000000'
        },
      ],
      changeValue: new Subject<any>(),

    };

    // setTimeout(() => this.intProfileTableOptions.changeValue.next(true));
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this.subs.unsubscribe();
    this.formValid.unsubscribe();
  }

  addTableData($event?: any): void {
    if (this.create_ticket && this.create_ticket.length) {
      this.formElements = [];
      if (this.create_ticket[0].getparamsfunc) {
        const params: any = {
          integration_id: this.credentialId,
          integration_name: this.currentIntegration.name,
          action_name: this.create_ticket[0].getparamsfunc,
          requestparams: {}
        };
        this._ls.display(true);
        this.cs.executeAction(params).then((res: any) => {
          if (res.status) {
            this._ls.display(false);
            this.cs.processFieldsParams(this.create_ticket[0].parameters, this.formElements, this.currentIntegration.name, this.credentialId, this.profile).then((subOut: any) => {
              this.formElements = subOut;
              this.profile = {};
              if ($event) { this.profile = { ...this.profile, ...$event } };
              this.cView = 'add';
            });
          }
        });
      } else {
        this.cs.processFieldsParams(this.create_ticket[0].parameters, this.formElements, this.currentIntegration.name, this.credentialId, this.profile).then((subOut: any) => {
          this.formElements = subOut;
          this.profile = this.profile = {};
          if ($event) { this.profile = { ...this.profile, ...$event } };
          this.cView = 'add';
        });
      }
    }
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  /**
     * Filter by category
     *
     * @param change
     */

  filterByCred(change: MatSelectChange): void {
    if (this.cView === 'add') {
      this.credentialId = change.value;
      this.intProfileTableOptions.tableOptions.serverSide.condition = "integration_name='" + this.currentIntegration.name + "'" + ' and ' + "credential_id='" + change.value + "'";
      this.addTableData();
    } else {
      this.credentialId = change.value;
      this.intProfileTableOptions.tableOptions.serverSide.condition = "integration_name='" + this.currentIntegration.name + "'" + ' and ' + "credential_id='" + change.value + "'";
      this.intProfileTableOptions.changeValue.next(true);
    }

  }

  closeFn(): void {
    this.formElements = [];
    this.profile = {};
    this.cView = 'table';
    this.cs.dynamicScrollTop.next({});
  }

  saveFn(): void {
    const $event: any = this.profile
    delete $event["[object Object]"];
    const reqData: any = {
      data: {
        name: $event.name,
        event_set_id: $event.event_set_id,
        integration_name: this.currentIntegration.name,
        credential_id: this.credentialId,
        integration_params: $event
      }
    };
    const msg = $event.id ? 'Updated' : 'Saved';
    const method = $event.id ? 'patch' : 'post';
    ($event.id) ? reqData.id = $event.id : null;
    $event.id ? delete reqData.data.integration_params.id : null;
    if (!$event.id) {
      const confirmation = this.confirmDialog.open({
        title: "Confirmation",
        message: "Would you like to create Company Mapping",
        icon: { show: false, name: "", color: "accent" },
        actions: {
          confirm: { show: true, label: "Yes", color: "primary" },
          cancel: { show: true, label: "Save Integration Rule" }
        },
        dismissible: false
      });
      confirmation.afterClosed().subscribe((result) => {
        this._ls.display(true);
        this._bs.doRequest('/w/integration/integration_rules', method, reqData)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res: any) => {
            this._ls.display(false);
            if (res.status) {
              try {
                this.toast.sToast('success', `${msg} successfully`);
                this.closeFn();
                setTimeout(() => this.intProfileTableOptions.changeValue.next(false));
                this._changeDetectorRef.detectChanges();
                if (result === 'confirmed') {
                  setTimeout(() => { this.tiggerFn.emit({ eventset: false, id: parseInt(res.id) }); }, 1000);
                }
              } catch (e) {
                console.log(e);
              }
            }
          });
      })
    } else {
      this._ls.display(true);
      this._bs.doRequest('/w/integration/integration_rules', method, reqData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => {
          this._ls.display(false);
          if (result.status) {
            try {
              this.toast.sToast('success', `${msg} successfully`);
              this.closeFn();
              setTimeout(() => this.intProfileTableOptions.changeValue.next(false));
              this._changeDetectorRef.detectChanges();
            } catch (e) {
              console.log(e);
            }
          }
        });
    }
  }

  actionChange($event: any): void {
    if ($event && $event !== '') {
      this.cs.processFieldsParams(this.create_ticket[0].parameters, this.formElements, this.currentIntegration.name, this.credentialId, this.profile).then((subOut: any) => {
        this.formElements = []; this.formElements = subOut;
      });
    }
  }

  actionCall($event: any): void {
    if ($event && $event !== '') {
      if ($event.action.text == 'Edit') {
        this.profile = {
          name: $event.row.name,
          id: $event.row.id,
          ...$event.row.integration_params
        };
        this.formElements = [];
        if (this.create_ticket[0].getparamsfunc) {
          const params: any = {
            integration_id: this.credentialId,
            integration_name: this.currentIntegration.name,
            action_name: this.create_ticket[0].getparamsfunc,
            requestparams: {}
          };
          this._ls.display(true);
          this.cs.executeAction(params).then((res: any) => {
            if (res.status) {
              this._ls.display(false);
              this.cs.processFieldsParams(this.create_ticket[0].parameters, this.formElements, this.currentIntegration.name, this.credentialId, this.profile).then((subOut: any) => {
                this.formElements = subOut;
                this.cView = 'add';
              });
            }
          });
        } else {
          this.cs.processFieldsParams(this.create_ticket[0].parameters, this.formElements, this.currentIntegration.name, this.credentialId, this.profile).then((subOut: any) => {
            this.formElements = subOut;
            this.cView = 'add';
          });
        }
      }
    }
  }
}

<ng-container *transloco="let t">
    <div class=" mt-4 w-[94vw]">
        <ng-container *ngIf="aView === 'agent'">
            <div class="px-4">
                <fuse-alert [appearance]="'border'" [type]="'info'">
                    {{t(cs.changeHeader('Initially the agent will be a'))}} <em>{{t(cs.changeHeader('Lightweight'))}}</em>  {{t(cs.changeHeader('agent. Once the credentials are mapped under the Discovery Settings the agent will be converted into a'))}} <em>{{t(cs.changeHeader('Probe.'))}} </em>{{t(cs.changeHeader('This process will take 2 to 3 minutes.'))}}
                </fuse-alert>
            </div>
            <mat-tab-group class="stab-normal px-4 mt-4" mat-stretch-tabs="false" mat-align-tabs="start"
                [selectedIndex]="selectedIndex" (selectedIndexChange)="tabFn($event)">
                <mat-tab class="py-1" label="Probe Agents">
                    <ng-template mat-tab-label>
                        <i class="fas fa-desktop mx-2"></i>{{t(cs.changeHeader('Probe Agents'))}}
                    </ng-template>
                    <ng-template matTabContent>
                        <stable *ngIf="selectedIndex === 0 && selectedIndex !== null && agenTableOptions.tableOptions" aria-label="Agents Table" role="table"
                            (addCallback)="newAgentCall()" [sTableOptions]="agenTableOptions" (globalActionCallback)="globalActionCall($event)"
                            (actionCallback)="actioncallback($event)" [loadtable]="loadtable">
                        </stable>
                    </ng-template>
                </mat-tab>
                <mat-tab class="py-1" label="LightWeight Agents">
                    <ng-template mat-tab-label>
                        <i class="fas fa-desktop mx-2"></i>{{t(cs.changeHeader('LightWeight Agents'))}}
                    </ng-template>
                    <ng-template matTabContent>
                        <stable *ngIf="selectedIndex === 1 && selectedIndex !== null && agenTableOptions.tableOptions" aria-label="Agents Table" role="table"
                            (addCallback)="newAgentCall()" [sTableOptions]="agenTableOptions" (globalActionCallback)="globalActionCall($event)"
                            (actionCallback)="actioncallback($event)" [loadtable]="loadtable">
                        </stable>
                    </ng-template>
                </mat-tab>
                <mat-tab class="py-1" label="Deprecated Agents">
                    <ng-template mat-tab-label>
                        <i class="fas fa-desktop mx-2"></i>{{t(cs.changeHeader('Deprecated Agents'))}}
                    </ng-template>
                    <ng-template matTabContent>
                        <stable *ngIf="selectedIndex === 2 && selectedIndex !== null && agenTableOptions.tableOptions" aria-label="Agents Table" role="table"
                            (addCallback)="newAgentCall()" [sTableOptions]="agenTableOptions" (globalActionCallback)="globalActionCall($event)"
                            (actionCallback)="actioncallback($event)" [loadtable]="loadtable">
                        </stable>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
        <install-probes-agents *ngIf="aView === 'download_agent'"
            (closeCallback)="closeCallBack($event)"></install-probes-agents>
    </div>
    <s-modal id="newAgent">
        <mat-card class="modal w-[600px]">
            <mat-card-header>
                <mat-card-title class="text-lg font-medium">{{t('new_agent')}}</mat-card-title>
                <mat-card-subtitle class="text-sm font-medium text-gray-400 dark:text-gray-300">
                    {{t('deploy_probe_lwa_one_time_scan')}} - {{cs.currentScope.name}}
                </mat-card-subtitle>
                <div class="spacer">&nbsp;</div>
                <button class=" mini-form-field" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                    (click)="modalService.close('newAgent');">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="my-4" fuseScrollbar>
                <app-dynamic-form *ngIf="agentConf.elementList" [listOfFormElements]="agentConf.elementList"
                    [Valuesoutput]="agent" (saveCallBack)="downloadAgent($event)" [closeBtnName]="agentConf.closeBtn"
                    (cancelCallBack)="modalService.close('newAgent');" (valueUpdate)="updateCommand($event)"
                    [fnBtnName]="agentConf.saveBtn"></app-dynamic-form>
            </mat-card-content>
            <mat-divider></mat-divider>
            <mat-card-actions align="end" *ngIf="agentConf.showAction">
                <button mat-button color="primary" (click)="downloadAgent($event)">{{t('download')}}</button>
                <button mat-button (click)="modalService.close('newAgent');">{{t('cancel')}}</button>
            </mat-card-actions>
        </mat-card>
    </s-modal>
    <s-modal id="mapdiscoveryandcreds">
        <div class="mx-auto w-full flex max-w-lg flex-col md:max-w-none md:flex-row">
            <div class="w-1/3 h-[80vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
                <p class="mb-10 font-bold tracking-wider">{{t(cs.changeHeader('MAPPING'))}}</p>
                <p class="mb-4 text-xl font-bold md:text-xl md:leading-snug">{{t(cs.changeHeader('Map Agent'))}}<br> {{t(cs.changeHeader('to Discovery Settings/Credentials here,'))}}</p>
                <div class="bg-blue-600/80 rounded-2xl p-3">
                    <p class="mb-3 text-sm text-gray-200">
                        {{t(cs.changeHeader('Mapping Agent to customized Discovery Settings enhances vulnerability scanning efficiency and
                        network security.'))}}      
                    </p>
                </div>
            </div>
            <div class="w-2/3 h-[80vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
                <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('mapdiscoveryandcreds');">
                    <mat-icon>close</mat-icon>
                </button>
                <div class="flex flex-row flex-wrap w-full items-center">
                    <div class="flex-wrap">
                        <h2 class="mb-3 ml-3 text-xl font-bold"> {{t(cs.changeHeader('Discovery Settings/Credentials Mapping'))}} </h2>
                        <fuse-alert class="w-full px-4" [appearance]="'border'" [type]="'info'">
                            <span fuseAlertTitle>{{t(cs.changeHeader('Notification!'))}}</span>
                            {{t(cs.changeHeader('Map Agent'))}} {{currentData.host_name}} {{t(cs.changeHeader('with IP'))}}  {{currentData.ip}} {{t(cs.changeHeader('to Discovery Settings/Credentials.'))}} 
                        </fuse-alert>
                        <app-dynamic-form *ngIf="agentdisco.formElements" [listOfFormElements]="agentdisco.formElements"
                            [Valuesoutput]="agentdiscoverymap" (saveCallBack)="agentdiscosave($event)"
                            [isSaveBtn]="false" [isCloseBtn]="false"
                            (cancelCallBack)="modalService.close('mapdiscoveryandcreds');"
                            [fnBtnName]="agentdisco.saveBtn"></app-dynamic-form>
                        <div class="flex items-center justify-end mt-4 p-2 border-t bg-transparent">
                            <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
                                (click)="modalService.close('mapdiscoveryandcreds');">
                                Cancel
                            </button>
                            <!-- Save -->
                            <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick
                                [matTooltip]="'Save'" (click)="agentDisCredSave()">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </s-modal>
    <s-modal id="mapdiscovery">
        <div class="mx-auto w-full flex max-w-lg flex-col md:max-w-none md:flex-row">
            <div class="w-1/3 h-[50vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
                <p class="mb-10 font-bold tracking-wider">{{t(cs.changeHeader('MAPPING'))}}</p>
                <p class="mb-4 text-2xl font-bold md:text-2xl md:leading-snug">{{t(cs.changeHeader('Map Agent'))}}<br> {{t(cs.changeHeader('to Discovery Settings/Credentials here,'))}} </p>
                <div class="bg-blue-600/80 rounded-2xl p-3">
                    <p class="mb-3 text-sm text-gray-200">
                        {{t(cs.changeHeader('Mapping Agent to customized Discovery Settings enhances vulnerability scanning efficiency and network security.'))}}   
                    </p>
                </div>
            </div>
            <div class="w-2/3 h-[50vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
                <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('mapdiscovery');">
                    <mat-icon>close</mat-icon>
                </button>
                <h2 class="mb-4 text-3xl font-bold">{{t(cs.changeHeader('Discovery Settings Mapping'))}}</h2>
                <fuse-alert class="w-full px-4 py-8" [appearance]="'border'" [type]="'info'">
                    <span fuseAlertTitle>{{t(cs.changeHeader('Notification!'))}}</span>
                    {{t(cs.changeHeader('Map agent'))}} {{currentData.host_name}}  {{t(cs.changeHeader('with ip'))}} {{currentData.ip}} {{t(cs.changeHeader('to discovery settings.'))}} 
                </fuse-alert>
                <app-dynamic-form *ngIf="agentdisco.formElements" [listOfFormElements]="agentdisco.formElements"
                    [Valuesoutput]="agentdiscoverymap" (saveCallBack)="agentdiscosave($event)"
                    [closeBtnName]="agentdisco.closeBtn" (cancelCallBack)="modalService.close('mapdiscovery');"
                    (valueUpdate)="mapupdatedisc($event)" [fnBtnName]="agentdisco.saveBtn"></app-dynamic-form>
            </div>
        </div>
    </s-modal>
    <s-modal id="mapcredentials">
        <div class="mx-auto w-full flex max-w-lg flex-col md:max-w-none md:flex-row">
            <div class="w-1/3 h-[50vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
                <p class="mb-10 font-bold tracking-wider">{{t(cs.changeHeader('MAPPING'))}}</p>
                <p class="mb-4 text-2xl font-bold md:text-2xl md:leading-snug"> {{t(cs.changeHeader('Map host'))}}<br> {{t(cs.changeHeader('to credential mapping here,'))}}</p>
                <div class="bg-blue-600/80 rounded-2xl p-3">
                    <p class="mb-3 text-sm text-gray-200">
                        {{t(cs.changeHeader('Mapping host/IP addresses to credentials for vulnerability scanning enables accurate and secure assessment of each target by providing the necessary authentication information.'))}}               
                    </p>
                </div>
            </div>
            <div class="w-2/3 h-[50vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
                <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('mapcredentials');">
                    <mat-icon>close</mat-icon>
                </button>
                <h2 class="mb-4 text-3xl font-bold">{{t(cs.changeHeader('Credential Mapping'))}} </h2>
                <fuse-alert class="w-full px-4 py-8" [appearance]="'border'" [type]="'info'">
                    <span fuseAlertTitle>{{t(cs.changeHeader('Notification!'))}}</span>
                    {{t(cs.changeHeader('Map agent'))}}  {{currentData.host_name}}  {{t(cs.changeHeader('with ip'))}} {{currentData.ip}} {{t(cs.changeHeader('to credentials.))}} 
                </fuse-alert>
                <app-dynamic-form *ngIf="agentcred.formElements" [listOfFormElements]="agentcred.formElements"
                    [Valuesoutput]="agentcredentialsymap" (saveCallBack)="agentcredsave($event)"
                    [closeBtnName]="agentcred.closeBtn" (cancelCallBack)="modalService.close('mapcredentials');"
                    [fnBtnName]="agentcred.saveBtn"></app-dynamic-form>
            </div>
        </div>
    </s-modal>
    <s-modal id="scanagents">
        <div class="mx-auto w-full flex max-w-lg flex-col md:max-w-none md:flex-row">
            <div class="w-1/3 h-[45vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
                <p class="mb-10 font-bold tracking-wider">{{t(cs.changeHeader('Scan'))}}</p>
                <p class="mb-4 text-2xl font-bold md:text-2xl md:leading-snug"> {{t(cs.changeHeader('Scan agent'))}} <br> {{t(cs.changeHeader('here,'))}} </p>
                <div class="bg-blue-600/80 rounded-2xl p-3">
                    <p class="mb-3 text-sm text-gray-200">
                        {{t(cs.changeHeader('To perform an agent scan, you can choose from various scan types, such as a full scan or an AD scan.'))}}
                    </p>
                </div>
            </div>
            <div class="w-2/3 h-[45vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
                <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('scanagents');">
                    <mat-icon>close</mat-icon>
                </button>
                <h2 class="mb-4 text-3xl font-bold"> {{t(cs.changeHeader('Scan Agent'))}}</h2>
                <fuse-alert class="mb-2" [appearance]="'border'" [type]="'info'" *ngIf="scanassetoutput.scan_type === 'COMPLIANCE'">
                    {{t(cs.changeHeader(' To set up compliance scan preferences, click on the settings icon, choose the compliance scan tab, select the desired compliance types, save your settings, and then initiate the scan.'))}}
                </fuse-alert>
                <app-dynamic-form *ngIf="scanasset.formElements" [listOfFormElements]="scanasset.formElements"
                    [Valuesoutput]="scanassetoutput" (saveCallBack)="scanadd($event)"
                    [closeBtnName]="scanasset.closeBtn" (cancelCallBack)="modalService.close('scanagents');"
                                  [fnBtnName]="'Scan'"></app-dynamic-form>
            </div>
        </div>
    </s-modal>
    <s-modal id="scanagentsMul">
        <div class="mx-auto w-full flex max-w-lg flex-col md:max-w-none md:flex-row">
            <div class="w-1/3 h-[45vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
                <p class="mb-10 font-bold tracking-wider">{{t(cs.changeHeader('Scan'))}}</p>
                <p class="mb-4 text-2xl font-bold md:text-2xl md:leading-snug">{{t(cs.changeHeader('Scan agent'))}} <br>{{t(cs.changeHeader('here,'))}}</p>
                <div class="bg-blue-600/80 rounded-2xl p-3">
                    <p class="mb-3 text-sm text-gray-200">
                        {{t(cs.changeHeader('To perform an agent scan, you can choose from various scan types, such as a full scan or an AD scan.'))}}
                    </p>
                </div>
            </div>
            <div class="w-2/3 h-[45vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
                <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('scanagentsMul');">
                    <mat-icon>close</mat-icon>
                </button>
                <h2 class="mb-4 text-3xl font-bold">{{t(cs.changeHeader('Scan Agent'))}}</h2>
                <app-dynamic-form *ngIf="scanasset.formElements" [listOfFormElements]="scanasset.formElements"
                    [Valuesoutput]="scanassetoutput" (saveCallBack)="scanaddMul($event)"
                    [closeBtnName]="scanasset.closeBtn" (cancelCallBack)="modalService.close('scanagentsMul');"
                                  [fnBtnName]="'Scan'"></app-dynamic-form>
            </div>
        </div>
    </s-modal>
    <s-modal id="resetagent">
        <div class="mx-auto w-full flex max-w-lg flex-col md:max-w-none md:flex-row">
            <div class="w-1/3 h-[55vh] max-w-md rounded-l-3xl
         bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
                <p class="mb-10 font-bold tracking-wider">{{t(cs.changeHeader('Reset Agent Configuration'))}}</p>
                <p class="mb-4 text-2xl font-bold md:text-2xl md:leading-snug"> {{t(cs.changeHeader('Reset configuration'))}} <br>{{t(cs.changeHeader('here,'))}}</p>
                <div class="bg-blue-600/80 rounded-2xl p-3">
                    <p class="mb-3 text-sm text-gray-200">
                        {{t(cs.changeHeader('To reset an agent configuration, you can choose from various configurations.'))}}
                    </p>
                </div>
            </div>
            <div class="w-2/3 h-[55vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
                <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('resetagent');">
                    <mat-icon>close</mat-icon>
                </button>
                <h2 class="mb-4 mt-4 text-2xl font-bold">{{t(cs.changeHeader('Reset Agent Configuration'))}}</h2>
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{t(cs.changeHeader('Choose Reset Configuration'))}}</mat-label>
                    <mat-select name="restConfig" required [(ngModel)]="reset_config">
                        <ng-container *ngFor="let data of configTypes">
                            <mat-option [value]="data.value">{{data.key}} </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <div class="flex items-center mt-4  py-4 pr-4 pl-1 border-t dark:bg-transparent">
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
                        (click)="modalService.close('resetagent');">
                        Cancel
                    </button>
                    <button class="ml-2" mat-flat-button [disabled]="reset_config.length === 0" [color]="'primary'"
                        appPreventMultiClick [matTooltip]="'Save'" (click)="resetConfig()">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </s-modal>
</ng-container>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { ReplaySubject, Subject, Subscription, debounceTime, takeUntil } from 'rxjs';
import { TableComponent } from 'app/modules/shared/table/table.component';
import { MaterialModule } from 'app/material.module';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CommonService } from 'app/_services/common.service';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';

@Component({
  selector: 'app-ad-details',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TableComponent, NgxMatSelectSearchModule, FormsModule, ReactiveFormsModule, MaterialModule, AppFilterPipeModule],
  templateUrl: './ad-details.component.html',
  styleUrls: ['./ad-details.component.scss']
})
export class AdDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('companySelect', { static: true }) companySelect!: MatSelect;
  public companyCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
  public companies: any = [];
  public allComp: any = [];
  totalCompany: any;
  showView: any;
  public searching = false;
  searchTxt: any = 'Search Company';
  public companyHash: any = {};
  isSearchK = false;
  company: any = {};
  isVisible: boolean = false;
  company_id: any;
  currentCompany: any;
  currentNode: any;
  pillCards: any = [];
  protected onDestroySearch = new Subject<void>();
  private subs: Subscription;
  cView: any = 'ou';
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(private _bs: BaseRequestService, private _changeDetectorRef: ChangeDetectorRef,
    private ls: LoaderService, private toast: MyToastrService, private cs: CommonService) {

    this.subs = this.cs.selectedSiteChanged.subscribe((cmp: any) => {
      this.company_id = this.cs.currentScope.id;
      this.updateCurrentCompany(this.cs.currentScope);
      this._changeDetectorRef.markForCheck();
    });
  }

  userTableOptions: any = {
    columns: [
      { "header": "Name", "columnDef": "name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Domain", "columnDef": "domain_name", "cType": "number", "filter": "", "cell": "(element: any) => `${element.domain_name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Description", "columnDef": "description", "cType": "string", "filter": "", "cell": "(element: any) => `${element.description}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Display Name", "columnDef": "display_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.display_name}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Distinguished Name", "columnDef": "distinguished_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.distinguished_name}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Email", "columnDef": "email_address", "cType": "string", "filter": "", "cell": "(element: any) => `${element.email_address}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Department", "columnDef": "department", "cType": "string", "filter": "", "cell": "(element: any) => `${element.department}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Logon Count", badge: true, "columnDef": "logon_count", "cType": "number", "filter": "", "cell": "(element: any) => `${element.logon_count}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Bad Logon Count", badge: true, "columnDef": "bad_logon_count", "cType": "number", "filter": "", "cell": "(element: any) => `${element.bad_logon_count}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Locked Out", "columnDef": "locked_out", "cType": "number", "filter": "", "cell": "(element: any) => `${element.locked_out}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Password Last Set", "columnDef": "password_last_set", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.password_last_set}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Account Disabled", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "account_disabled", "cType": "string", "filter": "", "cell": "(element: any) => `${element.account_disabled}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Password Never Expires", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "password_never_expires", "cType": "number", "filter": "", "cell": "(element: any) => `${element.password_never_expires}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Password Expired", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "password_expired", "cType": "number", "filter": "", "cell": "(element: any) => `${element.password_expired}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Member of", "columnDef": "member_of", "cType": "string", "filter": "", "cell": "(element: any) => `${element.member_of}`", "order": 0, "visible": false, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": false, "iscolumnSearch": false },
      { "header": "Password Not Required", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "password_not_required", "cType": "number", "filter": "", "cell": "(element: any) => `${element.password_not_required}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Cannot Change Password", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "cannot_change_password", "cType": "number", "filter": "", "cell": "(element: any) => `${element.cannot_change_password}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Account Expire Date", "columnDef": "account_expire_date", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.account_expire_date}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Last Logon Date", "columnDef": "last_logon_timestamp", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.last_logon_timestamp}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "When Created", "columnDef": "when_created", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.when_created}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "When Modified", "columnDef": "when_modified", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.when_modified}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },

    ],
    sortOptions: { active: 'name', direction: 'asc' },
    _pageData: [],
    tableOptions: {
      title: 'User Details',
      isServerSide: false,
      selectText: '',
      loading: false,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showhideList: true,
      refreshData: true,
      showFilter: true,
      showTagFilter: false,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: "/report_queries/ad_users_view",
        isGlobal: true,
        condition: `source='ad'`
      }
    },
    customText: [
      {
        status: true,
        DisplayText: 'Yes',
        class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
      },
      {
        status: false,
        DisplayText: 'No',
        class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
      },
    ],
    changeValue: new Subject<any>(),
  };
  compuTableOptions: any = {
    columns: [
      { "header": "Name", "columnDef": "name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Host Name", "columnDef": "host_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.host_name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "DNS Host Name", "columnDef": "dns_host_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.dns_host_name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "OS", "columnDef": "os_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.os_name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "OS Version", "columnDef": "os_version", "cType": "string", "filter": "", "cell": "(element: any) => `${element.os_version}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Distinguished Name", "columnDef": "distinguished_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.distinguished_name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Last Logon Date", "columnDef": "last_logon_timestamp", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.last_logon_timestamp}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Is Critical System Object", isColoredCntrl: true, isCustomText: true, isCustomClass: false, "columnDef": "is_critical_system_object", "cType": "number", "filter": "", "cell": "(element: any) => `${element.is_critical_system_object}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Password Expired", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "password_expired", "cType": "number", "filter": "", "cell": "(element: any) => `${element.password_expired}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Password Never Expires", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "password_never_expires", "cType": "number", "filter": "", "cell": "(element: any) => `${element.password_never_expires}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Locked Out", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "locked_out", "cType": "number", "filter": "", "cell": "(element: any) => `${element.locked_out}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Account Disabled", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "account_disabled", "cType": "number", "filter": "", "cell": "(element: any) => `${element.account_disabled}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Enabled", "columnDef": "enabled", "cType": "number", "filter": "", "cell": "(element: any) => `${element.enabled}`", "order": 10, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, isColoredCntrl: true, isCustomText: true, isCustomClass: true, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "When Created", "columnDef": "when_created", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.when_created}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "When Changed", "columnDef": "when_changed", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.when_changed}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Member of", "columnDef": "member_of", "cType": "string", "filter": "", "cell": "(element: any) => `${element.member_of}`", "order": 0, "visible": false, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": false, "iscolumnSearch": false },
      { "header": "GUID", "columnDef": "object_guid", "cType": "string", "filter": "", "cell": "(element: any) => `${element.object_guid}`", "order": 0, "visible": false, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
    ],
    sortOptions: { active: 'name', direction: 'asc' },
    _pageData: [],
    tableOptions: {
      title: 'Computer Details',
      isServerSide: false,
      selectText: '',
      loading: false,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showhideList: true,
      refreshData: true,
      showFilter: true,
      showTagFilter: false,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: "/report_queries/ad_computers_view",
        isGlobal: true,
        condition: `source='ad'`
      }
    },
    customText: [
      {
        status: true,
        DisplayText: 'Yes',
        class: 'bg-red-200 text-red-800 dark:bg-green-600 dark:text-green-50'
      },
      {
        status: false,
        DisplayText: 'No',
        class: 'bg-green-200 text-green-800 dark:bg-red-600 dark:text-red-50'

      },
    ],
    changeValue: new Subject<any>(),
  }
  gpoTableOptions: any = {
    columns: [
      { "header": "Name", "columnDef": "name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Display Name", "columnDef": "display_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.display_name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Distinguished Name", "columnDef": "distinguished_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.distinguished_name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "When Created", "columnDef": "when_created", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.when_created}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "When Changed", "columnDef": "when_changed", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.when_changed}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Path", "columnDef": "ads_path", "cType": "string", "filter": "", "cell": "(element: any) => `${element.ads_path}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "File Path", "columnDef": "gpc_files_sys_path", "cType": "string", "filter": "cleanString", "cell": "(element: any) => `${element.gpc_files_sys_path}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      {
        "header": "Object GUID",
        "columnDef": "object_guid",
        "cType": "string",
        "filter": "",
        "cell": "(element: any) => `${element.object_guid}`",
        "order": 2,
        "visible": true,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      },
      {
        "header": "Canonical Name",
        "columnDef": "canonical_name",
        "cType": "string",
        "filter": "",
        "cell": "(element: any) => `${element.canonical_name}`",
        "order": 4,
        "visible": true,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      },
      { "header": "DScore propagation data", "columnDef": "dscore_propagation_data", "cType": "string", "filter": "", "cell": "(element: any) => `${element.dscore_propagation_data}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "GPC functionality version", "columnDef": "gpc_functionality_version", "cType": "string", "filter": "", "cell": "(element: any) => `${element.gpc_functionality_version}`", "order": 1, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Is critical system object", "columnDef": "is_critical_system_object", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "cType": "string", "filter": "", "cell": "(element: any) => `${element.is_critical_system_object}`", "order": 9, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Show in advance view only", "columnDef": "show_in_advance_view_only", "cType": "string", "filter": "", "cell": "(element: any) => `${element.show_in_advance_view_only}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "USN changed", "columnDef": "usn_changed", "cType": "number", "filter": "", "cell": "(element: any) => `${element.usn_changed}`", "order": 4, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "USN created", "columnDef": "usn_created", "cType": "number", "filter": "", "cell": "(element: any) => `${element.usn_created}`", "order": 5, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Version number", "columnDef": "version_number", "cType": "number", "filter": "", "cell": "(element: any) => `${element.version_number}`", "order": 6, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false }
    ],
    sortOptions: { active: 'name', direction: 'asc' },
    _pageData: [],
    tableOptions: {
      title: 'GPO Details',
      isServerSide: false,
      selectText: '',
      loading: false,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showhideList: true,
      refreshData: true,
      showFilter: true,
      showTagFilter: false,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: "/report_queries/ad_gpos_view",
        isGlobal: true,
        condition: `source='ad'`
      }
    },
    changeValue: new Subject<any>(),
    customText: [
      {
        status: true,
        DisplayText: 'Yes',
        class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
      },
      {
        status: false,
        DisplayText: 'No',
        class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
      }]
  };

  grouTableOptions: any = {
    columns: /*[
      { "header": "Name", "columnDef": "name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.name}`", "order": 2, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Canonical Name", "columnDef": "canonical_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.canonical_name}`", "order": 2, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Group Type", "columnDef": "group_type", "cType": "string", "filter": "", "cell": "(element: any) => `${element.group_type}`", "order": 2, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": true, isCustomText: true, isCustomClass: true, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Distinguished Name", "columnDef": "distinguished_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.distinguished_name}`", "order": 1, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "SAM Account Name", "columnDef": "sam_account_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.sam_account_name}`", "order": 1, "visible": false, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Is Critical", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "is_critical_system_object", "cType": "string", "filter": "", "cell": "(element: any) => `${element.is_critical_system_object}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "When Created", "columnDef": "when_created", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.when_created}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Is Empty", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "empty", "cType": "string", "filter": "", "cell": "(element: any) => `${element.empty}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "When Changed", "columnDef": "when_changed", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.when_changed}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
    ]*/ [
        { "header": "Canonical name", "columnDef": "canonical_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.canonical_name}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "Description", "columnDef": "description", "cType": "string", "filter": "", "cell": "(element: any) => `${element.description}`", "order": 4, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "Distinguished name", "columnDef": "distinguished_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.distinguished_name}`", "order": 5, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "DScore propagation data", "columnDef": "dscore_propagation_data", "cType": "string", "filter": "", "cell": "(element: any) => `${element.dscore_propagation_data}`", "order": 6, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "Is critical system object", "columnDef": "is_critical_system_object", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "cType": "string", "filter": "", "cell": "(element: any) => `${element.is_critical_system_object}`", "order": 9, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "Is Empty", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "empty", "cType": "string", "filter": "", "cell": "(element: any) => `${element.empty}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "Mail", "columnDef": "mail", "cType": "string", "filter": "", "cell": "(element: any) => `${element.mail}`", "order": 11, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "Mail enabled", "columnDef": "mail_enabled", "cType": "string", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "filter": "", "cell": "(element: any) => `${element.mail_enabled}`", "order": 12, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "Managed by", "columnDef": "managed_by", "cType": "string", "filter": "", "cell": "(element: any) => `${element.managed_by}`", "order": 13, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "Member", "columnDef": "member", "cType": "string", "filter": "", "cell": "(element: any) => `${element.member}`", "order": 14, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "Name", "columnDef": "name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.name}`", "order": 15, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "Object guid", "columnDef": "object_guid", "cType": "string", "filter": "", "cell": "(element: any) => `${element.object_guid}`", "order": 16, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "Object sid", "columnDef": "object_sid", "cType": "string", "filter": "", "cell": "(element: any) => `${element.object_sid}`", "order": 17, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "Proxy addresses", "columnDef": "proxy_addresses", "cType": "string", "filter": "", "cell": "(element: any) => `${element.proxy_addresses}`", "order": 18, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "Resource behavior options", "columnDef": "resource_behavior_options", "cType": "string", "filter": "", "cell": "(element: any) => `${element.resource_behavior_options}`", "order": 19, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "Resource provisioning options", "columnDef": "resource_provisioning_options", "cType": "string", "filter": "", "cell": "(element: any) => `${element.resource_provisioning_options}`", "order": 20, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "SAM account name", "columnDef": "sam_account_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.sam_account_name}`", "order": 21, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "USN changed", "columnDef": "usn_changed", "cType": "number", "filter": "", "cell": "(element: any) => `${element.usn_changed}`", "order": 22, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "USN created", "columnDef": "usn_created", "cType": "number", "filter": "", "cell": "(element: any) => `${element.usn_created}`", "order": 23, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "When changed", "columnDef": "when_changed", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.when_changed}`", "order": 24, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "When created", "columnDef": "when_created", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.when_created}`", "order": 25, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false }],
    sortOptions: { active: 'name', direction: 'asc' },
    _pageData: [],
    tableOptions: {
      title: 'Group Details',
      isServerSide: false,
      selectText: '',
      loading: false,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showhideList: true,
      refreshData: true,
      showFilter: true,
      showTagFilter: false,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: "/report_queries/ad_groups_view",
        condition: `source='ad'`,
        isGlobal: true,
      }
    },
    customText: [
      {
        status: true,
        DisplayText: 'Yes',
        class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
      },
      {
        status: false,
        DisplayText: 'No',
        class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
      },
      {
        status: '-2147483646',
        DisplayText: 'Global Security Group',
        class: 'bg-green-200 text-green-800 dark:bg-green-400 dark:text-green-50'
      },
      {
        status: '-2147483644',
        DisplayText: 'Local Security Group',
        class: 'bg-yellow-200 text-indigo-800 dark:bg-yellow-200 dark:text-indigo-800'
      },
      {
        status: '-2147483643',
        DisplayText: 'BuiltIn Group',
        class: 'bg-orange-200 text-indigo-800 dark:bg-orange-200 dark:text-indigo-800'
      },
      {
        status: '-2147483640',
        DisplayText: 'Universal Security Group',
        class: 'bg-teal-200 text-indigo-800 dark:bg-teal-200 dark:text-indigo-800'
      },
      {
        status: '2',
        DisplayText: 'Global Distribution Group',
        class: 'bg-green-200 text-green-800 dark:bg-green-200  dark:text-green-800'
      },
      {
        status: '4',
        DisplayText: 'Local Distribution Group',
        class: 'bg-ping-200 text-pink-800 dark:bg-pink-600 dark:text-pink-50'
      },
      {
        status: '8',
        DisplayText: 'Universal Distribution Group',
        class: 'bg-purple-200 text-purple-800 dark:bg-purple-600 dark:text-purple-50'
      }
    ],
    changeValue: new Subject<any>(),
  }

  ouTableOptions: any = {
    columns: [
      { "header": "OU Name", "columnDef": "ou_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.ou_name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Distinguished Name", "columnDef": "distinguished_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.distinguished_name}`", "order": 1, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Is Critical System Object", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "is_critical_system_object", "cType": "string", "filter": "", "cell": "(element: any) => `${element.is_critical_system_object}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "When Created", "columnDef": "when_created", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.when_created}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "When Changed", "columnDef": "when_changed", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.when_changed}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
    ],
    sortOptions: { active: 'ou_name', direction: 'asc' },
    _pageData: [],
    tableOptions: {
      title: 'OU Details',
      isServerSide: false,
      selectText: '',
      loading: false,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showhideList: true,
      refreshData: true,
      showFilter: true,
      showTagFilter: false,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: "/report_queries/ad_ous_view",
        isGlobal: true,
        condition: `source='ad'`
      }
    },
    changeValue: new Subject<any>(),
    customText: [
      {
        status: true,
        DisplayText: 'Yes',
        class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
      },
      {
        status: false,
        DisplayText: 'No',
        class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
      }]
  }
  lastScannedTime: any = '';
  ngOnDestroy(): void {
    this.subs.unsubscribe();
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    if (this.cs.currentScope && this.cs.currentScope.id) {
      this.company_id = this.cs.currentScope.id;
      this.updateCurrentCompany(this.cs.currentScope);
    }
  }
  getADScanTime(): void {
    this.lastScannedTime = '';
    this._bs.doRequest(`/report_queries/ad_users_view`, 'get', null, {
      condition: `source='ad' and company_id=${parseInt(this.cs.currentScope.id)}`,
      skip: 0,
      limit: 1,
      order_by: 'updated desc'
    })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        if (res.data && res.data.length) {
          this.lastScannedTime = res.data[0].updated;
        }
      })
  }

  getADStats(): void {
    this.ls.display(false);
    this._bs.doRequest(`/r/company/company_stats`, 'get', null, {
      condition: `company_id=${parseInt(this.cs.currentScope.id)}`,
      skip: 0,
      limit: 1,
      order_by: 'updated desc'
    })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        if (res.data && res.data.length) {
          const result = res.data[0];
          this.lastScannedTime = (result.last_scanned_time && result.last_scanned_time !== '-') ? this.cs.dateFormatChange(result.last_scanned_time, false) : '-';
          this.pillCards = [
            {
              title: 'Users',
              img: '/assets/images/network/users.svg',
              count: result.ad_stats.ad_user || 0,
              icon: 'fa-user-friends',
              border: 'border-blue-600',
              data: [
                { name: 'enabled', value: result.ad_stats.ad_user_enabled || 0 },
                { name: 'disabled', value: result.ad_stats.ad_user_disabled || 0 },
                { name: 'not logged-in > 30d', value: result.ad_stats.user_last_logon_30_days_count || 0 }
              ],
            },
            {
              title: 'Computers',
              img: '/assets/images/network/computers.svg',
              count: result.ad_stats.ad_computer || 0,
              icon: 'fa-desktop',
              border: 'border-amber-600',
              data: [
                { name: 'enabled', value: result.ad_stats.ad_computer_enabled || 0 },
                { name: 'disabled', value: result.ad_stats.ad_computer_disabled || 0 },
                { name: 'not logged-in > 30d', value: result.ad_stats.computers_last_logon_30_days_count || 0 }
              ],
            },
            {
              title: 'Groups',
              img: '/assets/images/network/group.svg',
              count: result.ad_stats.ad_group || 0,
              icon: 'fa-users',
              border: 'border-yellow-600',
              data: [// Security count
                {
                  name: 'Security count',
                  value: (result.ad_stats.ad_group - result.ad_stats.ad_security_not_enabled ) || 0,
                },
                {
                  name: 'Non Security count',
                  value:  result.ad_stats.ad_security_not_enabled || 0,
                },
                {
                  name: 'Empty Groups',
                  value: result.ad_stats.ad_empty_group|| 0,
                },

              ],
            },
            {
              title: 'GPO',
              img: '/assets/images/network/gpo.svg',
              icon: 'fa-file-alt',
              count: result.ad_stats.ad_gpo || 0,
              border: 'border-teal-600',
              data: [{ name: 'active policy', value: (result.ad_stats.ad_gpo - result.ad_stats.ad_inactive_policy) || 0 },
              { name: 'inactive policy', value: result.ad_stats.ad_inactive_policy || 0 }]
            },
          ];
          this._changeDetectorRef.detectChanges();
        }
      })
  }

  linkCall($event: any): void {

  }

  dataParams(pdata: any, condition: any): any {
    const data = Object.assign({}, pdata);
    pdata.pageData = []; pdata.tableOptions.pageTotal = 0;
    data.pageData = []; data.tableOptions.pageTotal = 0;
    data.tableOptions.serverSide.condition = condition;
    return data;
  }

  updateCurrentCompany(event: any): void {
    this.company = {}; this.isVisible = false; this.currentNode = undefined;
    setTimeout(() => {
      this.company_id = event.id;
      this.company = Object.assign({}, event);
      this.isVisible = true;
      this.getADStats(); this.getADScanTime();
      this.ouTableOptions = this.dataParams(this.ouTableOptions, `source='ad' and company_id=${parseInt(this.company_id)}`);
      this.grouTableOptions = this.dataParams(this.grouTableOptions, `source='ad' and company_id=${parseInt(this.company_id)}`);
      this.userTableOptions = this.dataParams(this.userTableOptions, `source='ad' and company_id=${parseInt(this.company_id)}`);
      this.compuTableOptions = this.dataParams(this.compuTableOptions, `source='ad' and company_id=${parseInt(this.company_id)}`);
      this.gpoTableOptions = this.dataParams(this.gpoTableOptions, `source='ad' and company_id=${parseInt(this.company_id)}`);
    });
  }
}

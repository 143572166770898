import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { TableComponent } from '../table/table.component';
import {
    FormsModule,
    NgForm,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { MaterialModule } from 'app/material.module';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { FuseValidators } from '@fuse/validators';
import { DirectivesModule } from 'app/-directives/-directives.module';
import { ModalService } from 'app/_services/modal.service';
import { ModalComponent } from '../modal.component';
import { CommonService } from 'app/_services/common.service';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { FuseAlertComponent } from '@fuse/components/alert';

@Component({
    selector: 'app-user-management',
    standalone: true,
    imports: [CommonModule, TableComponent, AppFilterPipeModule, FuseAlertComponent, NgxMatSelectSearchModule, DirectivesModule, FormsModule, MaterialModule, ReactiveFormsModule, ModalComponent],
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit, OnDestroy {

    userTableOption: any = {
        columns: [
            {
                "header": "First Name",
                "columnDef": "first_name",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => ${element.first_name}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Last Name",
                "columnDef": "last_name",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => ${element.last_name}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Email",
                "columnDef": "email",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => ${element.email}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "User Name",
                "columnDef": "user_name",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => ${element.user_name}",
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Role",
                "columnDef": "roles",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => ${element.roles}",
                "order": 0,
                "width": "400px",
                textBadge: true,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "isColoredCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
            {
                "header": "Company Access",
                "columnDef": "included",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => `${element.included}`",
                "order": 3,
                "visible": true,
                cHyperLink: true,
                conditions: ['included', 'excluded'],
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": false,
                "iscolumnSearch": false
            },
            {
                "header": "State",
                "columnDef": "state",
                "cType": "string",
                "filter": "",
                "cell": "(element: any) => ${element.state}",
                isColoredCntrl: true,
                isCustomClass: true,
                isCustomText: true,
                "order": 0,
                "visible": true,
                "isToolTip": false,
                "isToolTipCol": "",
                "hasMultiData": false,
                "class": "",
                "color": "",
                "isProgressCntrl": false,
                "colList": [],
                "isfaicon": false,
                "isAddingText": false,
                "addingText": "",
                "img": false,
                "imgPath": "",
                "isSort": true,
                "iscolumnSearch": false
            },
        ], sortOptions: { active: 'created', direction: 'desc' },
        _pageData: [],
        tableOptions: {
            title: 'Users',
            isServerSide: false,
            selectText: 'Users',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: true,
            actionMenuItems: [
                {
                    text: 'Edit User',
                    id: 'edit',
                    icon: 'edit',
                    callback: 'editFn',
                    hideLocal: false,
                    isGlobal: false,
                },
                {
                    text: 'Reset MFA',
                    icon: 'restore',
                    callback: 'deleteFn',
                    isGlobal: true
                },
                {
                    text: 'Delete',
                    id: 'delete',
                    icon: 'delete',
                    callback: 'editFunction',
                    hideLocal: false,
                    isGlobal: false,
                }],
            pagination: false,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: false,
            showFilter: true,
            showTagFilter: false,
            showhideList: true,
            refreshData: true,
            isDefaultSearch: true,
            exportExcel: true,
            add: true,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/r/user/get_users',
                condition: '',
                type: 'post',
                isGlobal: true,
                params: { customer: false }
            },
            id: 'users'
        },
        changeValue: new Subject<any>(),
        customText: [
            {
                status: true,
                DisplayText: 'Yes',
                class: 'ring-1 rounded text-xs px-1 py-0.5'
            },
            {
                status: false,
                DisplayText: 'No',
                class: 'ring-1 rounded text-xs px-1 py-0.5'
            },
            {
                status: 'USER_STATE_INITIAL',
                DisplayText: 'Initial',
                class: 'bg-blue-200 text-blue-800 dark:bg-blue-600 dark:text-blue-50'
            },
            {
                status: 'USER_STATE_ACTIVE',
                DisplayText: 'Active',
                class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
            },
            {
                status: 'USER_STATE_LOCKED',
                DisplayText: 'Locked',
                class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
            },
            {
                status: 'USER_STATE_INACTIVE',
                DisplayText: 'Inactive',
                class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
            },
        ],
    }

    uView: any = 'table';
    contactForm: UntypedFormGroup;
    @ViewChild('updateForm') updateForm: NgForm;
    roles: any = [];
    currentUser: any = {};

    includeControl: UntypedFormControl = new UntypedFormControl();
    public searching = false;
    sourceCompany: any = [];
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _formBuilder: UntypedFormBuilder, public _cs: CommonService,
        private _bs: BaseRequestService, public modalService: ModalService,
        private ls: LoaderService, private toast: MyToastrService,
        public confirmDialog: FuseConfirmationService, private _changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    linkCall($event: any) {
        if ($event.col === 'included') {
            this.actioncallback({ action: { text: 'Edit User' }, row: $event.row });
        }
    }

    /**
     * On init
     */
    ngOnInit(): void {
        // Create the contact form
        this.contactForm = this._formBuilder.group({
            first_name: ['', [Validators.required]],
            last_name: ['', [Validators.required]],
            email: ['', [Validators.required]],
            mobile: ['', [Validators.required]],
            roles: [[], [Validators.required]],
            companies: [['*']],
            company_type: ['include'],

            // password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[@#%$!&*])[a-zA-Z0-9@#%$!&*]{8,50}$')]],
            // passwordConfirm: ['', Validators.required]
        },
            // {
            //   validators: FuseValidators.mustMatch('password', 'passwordConfirm')
            // }
        );

        this.contactForm.get('roles').valueChanges
            .pipe(debounceTime(500))
            .subscribe((val) => {
                if (val && val.indexOf('admin') === -1) {
                    this.contactForm.get('company_type').setValidators([Validators.required]);
                    this.contactForm.get('companies').setValidators([Validators.required]);
                } else {
                    this.contactForm.get('company_type').clearValidators();
                    this.contactForm.get('companies').clearValidators();
                }
                this.contactForm.get('company_type').updateValueAndValidity();
                this.contactForm.get('companies').updateValueAndValidity();
            });

        this.contactForm.get('companies').valueChanges
            .pipe(debounceTime(500))
            .subscribe((val) => {
                if (val && val.indexOf('*') === -1) {
                    this.contactForm.get('companies').setValue(val);
                } else {
                    this.contactForm.get('companies').setValue(['*']);
                }
                this.contactForm.get('companies').updateValueAndValidity();
            });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    newUserCall(): void {
        this.contactForm = this._formBuilder.group({
            first_name: ['', [Validators.required]],
            last_name: ['', [Validators.required]],
            email: ['', [Validators.required]],
            mobile: ['', [Validators.required]],
            roles: [[], [Validators.required]],
            companies: [['*']],
            company_type: ['include'],
        }),
            this.uView = 'add';
        this.getRoles();
        this.getComp();
    }

    actioncallback($event: any): void {
        if ($event.action.text == 'Edit User') {
            this.getRoles();
            this.getComp();
            const sInclude: any = $event.row.included?.split(',').filter((x: any) => x !== '' && x !== '*');
            const sExclude: any = $event.row.excluded?.split(',').filter((x: any) => x !== '');
            sInclude.forEach((a: any, i: any) => {
                sInclude[i] = +a;
            });
            sExclude.forEach((a: any, i: any) => {
                sExclude[i] = +a;
            });
            this.currentUser = {
                roles: $event.row.roles,
                email: $event.row.email,
                user_id: $event.row.id,
                first_name: $event.row.first_name,
                last_name: $event.row.last_name,
                company_type: (sInclude && sInclude.length) ? 'include' : (sExclude && sExclude.length) ? 'exclude' : 'include',
                companies: (sInclude && sInclude.length) ? sInclude : (sExclude && sExclude.length) ? sExclude : ['*'],
            }
            this.modalService.open('userRoleEdit');
        } else if ($event.action.text == 'Delete') {
            this.deleteUser($event.row)
        } else if ($event.action.text == 'Reset MFA') {
            this.resetMFAConfirmationDialog($event.row)
        }

    }

    resetMFAConfirmationDialog($event: any): void {
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: 'Are you sure you want to reset MFA of this user ' + $event.first_name + '?',
            icon: { show: false, name: "", color: "accent" },
            actions: {
                confirm: { show: true, label: "Yes", color: "primary" },
                cancel: { show: true, label: "No" }
            }
        });

        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this.ls.display(true);
                this._bs.doRequest(`d/company/reset_mfa/${$event.id}`, 'delete')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        this.ls.display(false);
                        if (result.status) {
                            this.toast.sToast('success', result.message);
                            setTimeout(() => this.showHideTable(), 1000);
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        });
    }
    async getComp() {
        this.sourceCompany = await this._cs.getCompanies('');
    }

    getRoles(): void {
        this.ls.display(true);
        this._bs.doRequest(`r/user/get_roles`, 'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status) {
                    if (res.data && res.data.length) {
                        this.roles = res.data;
                    } else {
                        this.roles = [];
                    }
                } else {
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                }
            });
    }

    saveUser(): void {
        const user: any = this.contactForm.getRawValue();
        user.included = '';
        user.excluded = '';
        if (user.roles.indexOf('admin') === -1) {
            if (user.company_type === 'include') {
                user.included = user.companies.toString();
            } else {
                user.excluded = user.companies.toString();
            }
        }
        delete user.passwordConfirm;
        user.mobile = user.mobile.toString();
        delete user.company_type;
        delete user.companies;
        user.password = '';
        this.ls.display(true);
        this._bs.doRequest(`w/user/create_user`, 'post', user)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status) {
                    this.toast.sToast('success', `User created successfully. An initialization email is sent to the created user's Email Address to set up the user.`);
                    setTimeout(() => this.showHideTable());
                    this.uView = 'table';
                } else {
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                }
            });
    }

    updateUserRoles(): void {
        const user: any = Object.assign({}, this.currentUser);
        delete user.first_name;
        delete user.last_name;
        user.included = '';
        user.excluded = '';
        if (user.roles.indexOf('admin') === -1) {
            if (user.company_type === 'include') {
                user.included = user.companies.toString();
            } else {
                user.excluded = user.companies.toString();
            }
        }
        delete user.company_type;
        delete user.companies;
        delete user.email;
        this.ls.display(true);
        this._bs.doRequest(`w/user/update_role`, 'post', user)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status) {
                    this.toast.sToast('success', 'User updated successfully!');
                    this.modalService.close('userRoleEdit');
                    setTimeout(() => this.showHideTable());
                } else {
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                }
            });
    }

    deleteUser($event: any): void {
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: `Are you sure you want to delete this user ?`,
            actions: {
                confirm: {
                    label: 'Delete'
                }
            }
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this.ls.display(true);
                this._bs.doRequest(`/d/user/delete_user/${$event.id}`, 'delete')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        this.ls.display(false);
                        if (result.status) {
                            this.toast.sToast('success', 'User removed successfully');
                            setTimeout(() => this.showHideTable(), 1000);
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        });
    }

    showHideTable(): void {
        const data = Object.assign({}, this.userTableOption);
        this.userTableOption = {};
        this._changeDetectorRef.detectChanges();
        data.pageData = [];
        data.tableOptions.pageTotal = 0;
        this.userTableOption = data;
        this.userTableOption.pageData = [];
        this.userTableOption.tableOptions.pageTotal = 0;
        this._changeDetectorRef.detectChanges();
    }
}

import { ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { ModalService } from 'app/_services/modal.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { UserService } from 'app/core/user/user.service';
import { TableComponent } from 'app/modules/shared/table/table.component';

@Component({
  selector: 'app-remediated-details',
  standalone: true,
  imports: [CommonModule, TableComponent],
  templateUrl: './remediated-details.component.html',
  styleUrls: ['./remediated-details.component.scss']
})
export class RemediatedDetailsComponent {
  remediatedTableOptions: any = {}
  private subs: Subscription;
  asset_id: any;
  /**
   * Constructor
   */
  constructor(public cs: CommonService,) {
    this.subs = this.cs.selectedSiteChanged.subscribe((cmp: any) => {

    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subs.unsubscribe();
  }
  ngOnInit(): void {
    if (this.cs.selctedAsset) {
      this.asset_id = this.cs.selctedAsset;
    } else if (this.cs.selectedTableRows['Assets'] && this.cs.selectedTableRows['Assets'].length){
      this.asset_id = this.cs.selectedTableRows['Assets'][0].id;
    }
    this.initTable();
  }
  initTable(): void {
    this.remediatedTableOptions = {
      columns: [
        {
          header: "Name",
          columnDef: "name",
          "cType": "string",
          filter: "",
          cell: "(element: any) => ${element.name}",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Full Name",
          columnDef: "full_name",
          "cType": "string",
          filter: "",
          cell: "(element: any) => ${element.full_name}",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: "Version",
          columnDef: "version",
          "cType": "string",
          filter: "",
          cell: "(element: any) => ${element.version}",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: true,
          iscolumnSearch: false,
        },
        {
          "header": "Total Vulnerabilities",
          "columnDef": "vul_count",
          "cType": "number",
          "filter": "",
          "cell": "(element: any) => `${element.vul_count}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          isClass: true,
          "class": "bg-red-600 text-white",
          "color": "",
          "isProgressCntrl": false,
          isColoredCntrl: false,
          isCustomText: false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
      },
      {
          "header": "Critical",
          "columnDef": "critical_vuls_count",
          "cType": "number",
          "filter": "",
          "cell": "(element: any) => `${element.critical_vuls_count}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          isClass: true,
          "class": "bg-red-600 text-white",
          "color": "",
          "isProgressCntrl": false,
          isColoredCntrl: false,
          isCustomText: false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
      },
      {
          "header": "High",
          "columnDef": "high_vuls_count",
          "cType": "number",
          "filter": "",
          "cell": "(element: any) => `${element.high_vuls_count}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          isClass: true,
          "class": "bg-orange-600 text-white",
          "color": "",
          "isProgressCntrl": false,
          isColoredCntrl: false,
          isCustomText: false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
      },
      {
          "header": "Medium",
          "columnDef": "medium_vuls_count",
          "cType": "number",
          "filter": "",
          "cell": "(element: any) => `${element.medium_vuls_count}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          isClass: true,
          "class": "bg-yellow-600 text-white",
          "color": "",
          "isProgressCntrl": false,
          isColoredCntrl: false,
          isCustomText: false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
      },
      {
          "header": "Low",
          "columnDef": "low_vuls_count",
          "cType": "number",
          "filter": "",
          "cell": "(element: any) => `${element.low_vuls_count}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          isClass: true,
          "class": "bg-blue-600 text-white",
          "color": "",
          "isProgressCntrl": false,
          isColoredCntrl: false,
          isCustomText: false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
      },
      { "header": "Remediated On", "columnDef": "updated", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.created}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      ],
      sortOptions: { active: "name", direction: "asc" },
      _pageData: [],
      tableOptions: {
        title: "Remediated Records",
        isServerSide: false,
        selectText: "remediated",
        loading: true,
        floatingFilter: true,
        rowSelection: false,
        showAction: false,
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 5,
        search: false,
        showFilter: true,
        showTagFilter: false,
        actionMenuItems: [],
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: false,
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        serverSide: {
          url: "r/company/remediated",
          condition: `${(this.asset_id) ? `asset_id =  ${this.asset_id}`: true}`,
        }
      },
      changeValue: new Subject<any>(),
    }
  }


}

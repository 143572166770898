<div class="flex flex-col w-full min-w-0" *ngIf="cView === 'table'">
    <fuse-alert class="w-full p-4 pb-0" [appearance]="'border'" [type]="'info'">
        <span>Here a set of Events are to be set to get notified. Those events are categorized under Agent, Company,
            Asset, Ports, Problems and Solutions.</span>
    </fuse-alert>
    <stable [sTableOptions]="alertTableOptions" (addCallback)="addTableData()" (actionCallback)="actionCall($event)" *ngIf="alertTableOptions.tableOptions">
    </stable>
</div>

<div class="flex flex-col w-full min-w-0" *ngIf="cView === 'add'">
    <ng-container>
        <div class="flex flex-row items-center">
            <div class="text-2xl font-semibold tracking-tight">Event Set</div>
            <button class="ml-auto" mat-icon-button (click)="cView='table'">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
        <form class="overflow-hidden" #eventNgForm="ngForm">
            <div class="flex  mt-6">
                <mat-form-field class="w-1/2" [subscriptSizing]="'dynamic'">
                    <mat-label>Name</mat-label>
                    <input matInput [placeholder]="'Enter Name'" name="name" required [(ngModel)]="cEventSet.name">
                </mat-form-field>
                <div class="w-1/2 ml-6">
                    <span class="">Notify By</span>
                    <mat-radio-group class="flex flex-row" [color]="'primary'" required [(ngModel)]="cEventSet.group_by"
                        name="groupby">
                        <mat-radio-button [value]="'company'">
                            Company
                        </mat-radio-button>
                        <mat-radio-button class="ml-2" [value]="'asset'">
                            Asset
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="grid grid-cols-2 sm:grid-cols-2 gap-3 mt-2">
                <ng-container *ngFor="let item of alertRule; let i = index;">
                    <mat-accordion>
                        <mat-expansion-panel class="mb-3" (opened)="panelOpenState = true"
                            (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="tprimary">
                                    <span class=""><b>{{item.type|camelToHuman:true}}</b></span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ng-container *ngFor="let it of item.event_rules;">
                                <ng-container *ngFor="let event of it.events">
                                    <mat-list class="w-full"
                                        *ngIf="!event.close_for">
                                        <mat-list-item class="bb-1">
                                            <span class="mr-2">
                                                <mat-checkbox [(ngModel)]="event.selected" color="primary"
                                                    [id]="event.id" [name]="event.id">{{event.name}}</mat-checkbox>
                                            </span>
                                        </mat-list-item>
                                    </mat-list>
                                </ng-container>
                                <ng-container *ngFor="let event of it.eventGroups let i = index">
                                    <div class="mb-3 mt-1">
                                        <p *ngIf="item.name === 'remediation'"
                                            class="badge badge-info tbg-info text-primary py-2 fw6 fs--8 px-3 mb-2">
                                            <b *ngIf="i===0">Remediation Group</b> <b *ngIf="i===1"> Remediation
                                                Filters</b>
                                        </p>
                                        <div class="notes notes1 mt-1 mb-1" *ngIf="i===1">
                                            <span><b>Note:</b><br>
                                                <span class="ml-2">Please select any one of the event set from the
                                                    Remediation
                                                    Group list, to be able to select the event set from the Remediation
                                                    filter
                                                    list.</span>
                                            </span>
                                        </div>
                                        <mat-radio-group [(ngModel)]="event.value" *ngIf="event.name"
                                            [name]="event.name" [disabled]="i===1 && !it.eventGroups[0]?.value">
                                            <ng-container *ngFor="let eve of event.events">
                                                <mat-list class="w-full"
                                                    *ngIf="!eve.close_for && !eve.isCheckbox">
                                                    <mat-list-item class="bb-1">
                                                        <span class="mr-2">
                                                            <mat-radio-button color="primary" [value]="eve.id"
                                                                [id]="eve.id">
                                                            </mat-radio-button>
                                                        </span>
                                                        <span class="mb-2 ml-2"
                                                            [matTooltip]="it.name">{{eve.name}}</span>
                                                    </mat-list-item>
                                                </mat-list>
                                            </ng-container>
                                        </mat-radio-group>
                                        <ng-container *ngFor="let eve of event.events">
                                            <mat-list class="w-full"
                                                *ngIf="!eve.close_for && eve.isCheckbox">
                                                <mat-list-item class="bb-1">
                                                    <span class="mr-2">
                                                        <mat-checkbox [(ngModel)]="eve.selected"
                                                            color="primary" [id]="eve.id"
                                                            [disabled]="i===1 && !it.eventGroups[0]?.value"
                                                            [name]="eve.id">{{eve.name}}</mat-checkbox>
                                                    </span>
                                                </mat-list-item>
                                            </mat-list>
                                        </ng-container>
                                    </div>
                                    <div class="mt-3 mb-5">
                                        <button mat-raised-button color="primary" class="float-right"
                                            (click)="event.value=''">Clear</button>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </mat-expansion-panel>
                    </mat-accordion>
                </ng-container>
            </div>
            <!-- <p class="fw3 pt-1 mat-small">Please select either 'Remediation By Company' or 'Remediation By Asset' or
                'Remediation By Product' or 'Remediation By Asset And Product'</p> -->

            <div class="flex items-center mt-10  py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t">
                <button class="ml-auto" mat-button [matTooltip]="'Cancel'" (click)="cView='table'">
                    Cancel
                </button>
                <button class=" ml-2" mat-flat-button [color]="'primary'" [disabled]="eventNgForm.invalid"
                    appPreventMultiClick [matTooltip]="'Save'" (click)="saveEventSet()">
                    Save
                </button>
            </div>
        </form>
    </ng-container>
</div>


<div class="p-2">
    <div class="mt-4 flex flex-col items-start gap-6">
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg w-[33vw] h-[60vh]">
            <div class="flex flex-col">
                <div class="mb-4 text-sm flex flex-row font-medium tracking-tight leading-6 truncate">
                        <div class="">Enable / Disable at global level:</div>
                        <mat-slide-toggle class="ml-4" [color]="'primary'" matTooltip="Enable Roaming"
                            name="globalDisRoamingApp" (change)="toggleGlobalLevel($event)" >
                        </mat-slide-toggle>
                        <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick [matTooltip]="'Save'"
                            (click)="updateGlobalDisableAppStatus(globalRoamingStatus)">
                            Update
                        </button>
                </div>
                <div class="mb-4 text-sm flex flex-row font-medium tracking-tight leading-6 truncate">
                    <div class="">Enable / Disable at company level:</div>
                    <mat-slide-toggle class="ml-4" [color]="'primary'" matTooltip="Enable Roaming"
                        name="cmpDisRoamingApp" (change)="toggleCompanyLevel($event)" >
                    </mat-slide-toggle>
                    <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick [matTooltip]="'Save'"
                        (click)="updateCompanyDisableAppStatus(companyRoamingStatus)">
                        Update
                    </button>
            </div>
                <table class="w-full text-sm text-left rtl:text-right">
                    <thead class="text-medium font-medium border-b tracking-tight leading-6  bg-card">
                        <tr>
                            <th>
                                <mat-checkbox color="primary" (change)="$event ? masterToggle() : null" id="_selectDeselectAllCompanies"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                            </th>
                            <th scope="col" class="pl-4 py-2">
                                Company Name
                            </th>
                            <th scope="col" class="pl-4 py-2">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody fuseScrollbar>
                        <tr *ngFor="let comp of allCompanies.data">
                            <td>
                                <mat-checkbox color="primary" (click)="$event.stopPropagation()"  [id]="comp._id"
                                (change)="$event ? rowToggle(comp) : null" [checked]="selection.isSelected(comp)"></mat-checkbox>
                            </td>
                            <td scope="row" class="py-1 pl-4">
                                {{comp.name}}
                            </td>
                            <td class="py-1 pl-4">
                                status
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
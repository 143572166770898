import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/reports'
    { path: '', pathMatch: 'full', redirectTo: 'overview' },

    // Redirect signed-in user to the '/reports'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    /*{
        path: 'signed-in-redirect',
        pathMatch: 'full',
        redirectTo: 'overview',
    },*/

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.routes'),
            },
            {
                path: 'maintenance',
                loadChildren: () =>
                    import('app/modules/shared/maintenance/maintenance.routes'),
            },
            {
                path: 'unauthorized',
                loadChildren: () =>
                    import('app/modules/auth/unauthorized/unauthorized.routes'),
            },
            {
                path: 'login',
                loadChildren: () => import('app/modules/oauth2/oauth2-routing.module').then(m => m.Oauth2RoutingModule)
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.routes'),
            },
            {
                path: 'success',
                loadChildren: () =>
                    import('app/modules/auth/auth-grant/auth-grant.routes'),
            },
            {
                path: 'auth-grant',
                loadChildren: () =>
                    import('app/modules/auth/auth-grant/auth-grant.routes'),
            },
            {
                path: 'approveSuppression',
                loadChildren: () =>
                    import('app/modules/shared/approve-reject-suppression/approve-reject-suppression.routes'),
            },
            {
                path: 'rejectSuppression',
                loadChildren: () =>
                    import('app/modules/shared/approve-reject-suppression/approve-reject-suppression.routes'),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.routes'),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            /*{
                path: 'overview',
                data: { breadcrumb: { alias: 'Overview' } },
                loadChildren: () =>
                    import('app/modules/shared/summary-view/summary-view.routes'),
                runGuardsAndResolvers: 'always'
            },*/
            {
                path: 'overview',
                data: { breadcrumb: { alias: 'Dashboard' } },
                loadChildren: () =>
                    import('app/modules/shared/dashboard/dashboard.routes'),
                runGuardsAndResolvers: 'always'
            },
            {
                path: 'metrics',
                data: { breadcrumb: { alias: 'Metrics' } },
                loadChildren: () =>
                    import('app/modules/shared/summary-view/summary-view.routes'),
                runGuardsAndResolvers: 'always'
            },
            {
                path: 'agents',
                data: { breadcrumb: { alias: 'Agents' } },
                loadChildren: () =>
                    import('app/modules/admin/companies/agents/agents.routes'),
                runGuardsAndResolvers: 'always'
            },
            {
                path: 'dashboard',
                data: { breadcrumb: { alias: 'Dashboard' } },
                loadChildren: () =>
                    import('app/modules/shared/dashboard/dashboard.routes'),
                runGuardsAndResolvers: 'always'
            },
            {
                path: 'vulnerabilities',
                data: { breadcrumb: { alias: 'Vulnerabilities' } },
                loadChildren: () =>
                    import('app/modules/admin/vulnerabilities/overview/overview.routes'),
            },
            {
                path: 'application-vulnerabilities',
                data: { breadcrumb: { alias: 'Application Vulnerabilities' } },
                loadChildren: () =>
                    import(
                        'app/modules/admin/app-vulnerabilities/app-vulnerabilities.routes'
                    ),
            },
            {
                path: 'network-scan-findings',
                data: { breadcrumb: { alias: 'Network Scan Findings' } },
                loadChildren: () =>
                    import(
                        'app/modules/shared/network-scan-findings/network-scan-findings.routes'
                    ),
            },
            {
                path: 'pii',
                data: { breadcrumb: { alias: 'PII' } },
                loadChildren: () =>
                    import('app/modules/shared/scan/pii-scan/pii-scan.routes'),
            },
            {
                path: 'patching',
                data: { breadcrumb: { alias: 'Patching' } },
                loadChildren: () =>
                    import('app/modules/shared/patching/patching.routes'),
            },
            {
                path: 'onboarding',
                data: { breadcrumb: { alias: 'Company Onboarding' } },
                loadChildren: () =>
                    import('app/modules/admin/companies/company-onboarding/company-onboarding.routes'),
            },
            {
                path: 'attack-surface-mapper',
                data: { breadcrumb: { alias: 'Attack Surface Mapper' } },
                loadChildren: () =>
                    import('app/modules/admin/attack-surface-mapper/attack-surface-mapper.routes'),
            },
            {
                path: 'company',
                data: { breadcrumb: { alias: 'Company' } },
                loadChildren: () =>
                    import('app/modules/admin/company-overview/company-overview.routes'),
            },
            {
                path: 'problems-solutions',
                data: { breadcrumb: { alias: 'Problems' } },
                loadChildren: () =>
                    import('app/modules/admin/problems/problems.routes'),
            },
            {
                path: 'report-builder',
                data: { breadcrumb: { alias: 'Report Builder' } },
                loadChildren: () =>
                    import('app/modules/shared/report-builder/report-builder.routes'),
            },
            {
                path: 'integrations',
                data: { breadcrumb: { alias: 'Integrations' } },
                loadChildren: () =>
                    import('app/modules/admin/integrations/integrations.routes'),
            },
            {
                path: 'network-scan-findings',
                data: { breadcrumb: { alias: 'Network Scan Findings' } },
                loadChildren: () =>
                    import(
                        'app/modules/shared/network-scan-findings/network-scan-findings.routes'
                    ),
            },
            {
                path: 'standard-reports',
                data: { breadcrumb: { alias: 'Standard Reports' } },
                loadChildren: () =>
                    import('app/modules/shared/standard-reports/standard-reports.routes'),
            },
            {
                path: 'report-builder',
                data: { breadcrumb: { alias: 'Report Builder' } },
                loadChildren: () =>
                    import('app/modules/shared/report-builder/report-builder.routes'),
            },
            {
                path: 'active-directory',
                data: { breadcrumb: { alias: 'Active Directory' } },
                children: [
                    {
                        path: 'problems-overview',
                        data: { breadcrumb: { alias: 'Overview' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ad/overview/overview.routes'
                            ),
                    },
                    {
                        path: 'summary',
                        data: { breadcrumb: { alias: 'Summary' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ad/summary/summary.routes'
                            ),
                    },
                    {
                        path: 'azure-summary',
                        data: { breadcrumb: { alias: 'Azure Summary' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ad/azure-summary/azure-summary.routes'
                            ),
                    },
                    {
                        path: 'ad-details',
                        data: { breadcrumb: { alias: 'Active Directory Details' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ad/ad-details/ad-details.routes'
                            ),
                    },
                    {
                        path: 'ad-audit',
                        data: { breadcrumb: { alias: 'Audit' } },
                        loadChildren: () =>
                            import(
                                'app/modules/shared/ad-audit/ad-audit.routes'
                            ),
                    },
                    {
                        path: 'azure',
                        data: { breadcrumb: { alias: 'Azure Details' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ad/azure-ad-details/azure-ad-details.routes'
                            ),
                    },
                    {
                        path: 'microsoft',
                        data: { breadcrumb: { alias: 'Microsoft Secure Score' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ad/microsoft-secure-score/microsoft-secure-score.routes'
                            ),
                    },
                ],
            },
            {
                path: 'asset',
                data: { breadcrumb: { alias: 'Assets' } },
                children: [
                    {
                        path: 'assets',
                        data: { breadcrumb: { alias: 'Assets', is_deprecated: false } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/assets/assets.routes'
                            ),
                    },
                    {
                        path: 'firewalls',
                        data: { breadcrumb: { alias: 'Firewalls', is_deprecated: false } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/firewalls.routes'
                            ),
                    },
                    {
                        path: 'problems',
                        data: { breadcrumb: { alias: 'Problems' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/problems/problems.routes'
                            ),
                    },
                    {
                        path: 'remediation-plan',
                        data: { breadcrumb: { alias: 'Solutions' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/remediation-plan/remediation-plan.routes'
                            ),
                    },
                    {
                        path: 'patch-compliance',
                        data: { breadcrumb: { alias: 'Pending OS Patches' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/patch-compliance/patch-compliance.routes'
                            ),
                    },
                    {
                        path: 'ports',
                        data: { breadcrumb: { alias: 'Ports' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ports/ports.routes'
                            ),
                    },
                    {
                        path: 'external-assets',
                        data: { breadcrumb: { alias: 'External Assets' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/external-scan/external-scan.routes'
                            ),
                    },
                    {
                        path: 'certificates',
                        data: { breadcrumb: { alias: 'Certificates' } },
                        loadChildren: () =>
                            import(
                                'app/modules/shared/all-certs/all-certs.routes'
                            ),
                    },
                    {
                        path: 'deprecated-assets',
                        data: { breadcrumb: { alias: 'Deprecated Assets', is_deprecated: true } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/assets/assets.routes'
                            ),
                    },
                    {
                        path: 'application-baseline-results',
                        data: { breadcrumb: { alias: 'Application Baseline Results' } },
                        loadChildren: () =>
                            import(
                                'app/modules/shared/application-baseline/results/results.routes'
                            ),
                    },
                    {
                        path: 'scheduler',
                        data: { breadcrumb: { alias: 'Scheduler' } },
                        loadChildren: () =>
                            import('app/modules/admin/scheduler/scheduler.routes'),
                    }
                ],
            },
            {
                path: 'compliance',
                data: { breadcrumb: { alias: 'Compliance' } },
                children: [
                    {
                        path: 'compliance-check-master',
                        data: { breadcrumb: { alias: 'Compliance Check Master' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/compliance/compliance-check-master/compliance-check-master.routes'
                            ),
                    },
                    {
                        path: 'compliance-standards',
                        data: { breadcrumb: { alias: 'Compliance Standards' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/compliance/compliance-overview/compliance-overview.routes'
                            ),
                    },
                    {
                        path: 'compliance-report',
                        data: { breadcrumb: { alias: 'Compliance Report' } },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/compliance/compliance-report/compliance-report.routes'
                            ),
                    },
                ],
            },
            {
                path: 'jobs',
                data: { breadcrumb: { alias: 'Jobs' } },
                loadChildren: () =>
                    import('app/modules/admin/jobs/job-overview/job-overview.routes'),
            },
            {
                path: 'approve_suppression',
                data: { breadcrumb: { alias: 'Approve Suppression' } },
                loadChildren: () =>
                    import('app/modules/shared/approve-reject-suppression/approve-reject-suppression.routes'),
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'overview'
    }
];

import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AsyncPipe, CommonModule, NgFor, NgForOf, NgIf } from '@angular/common';
import { MaterialModule } from 'app/material.module';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { CommonService } from 'app/_services/common.service';
import { FormControl, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { TableComponent } from 'app/modules/shared/table/table.component';
import { Subject, ReplaySubject, debounceTime, takeUntil, map, filter } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { v4 as uuidv4 } from 'uuid';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { ModalComponent } from 'app/modules/shared/modal.component';
import { ModalService } from 'app/_services/modal.service';
import { DynamicFormComponent } from 'app/modules/shared/forms/dynamic-form/dynamic-form.component';
import { DirectivesModule } from 'app/-directives/-directives.module';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'nonpsa-company-mapping',
  standalone: true,
  animations: fuseAnimations,
  imports: [CommonModule, MaterialModule, TableComponent, FormsModule, NgFor, NgIf, NgForOf,
    ReactiveFormsModule, AppFilterPipeModule, FuseAlertComponent, AsyncPipe, MatSidenavModule,
    MatFormFieldModule, ModalComponent, DynamicFormComponent,
    NgxMatSelectSearchModule, DirectivesModule,
    MatInputModule,
    MatButtonModule],
  templateUrl: './nonpsa-company-mapping.component.html',
  styleUrls: ['./nonpsa-company-mapping.component.scss']
})
export class NonpsaCompanyMappingComponent implements OnInit, OnDestroy {
  @Input() currentIntegration: any;
  @Input() integrationCred: any = [];
  @Input() triggerNew: any;
  @Output() tiggerFn = new EventEmitter();
  selectedCred: any = null;
  mappedComTableOptions: any = {};
  cView: any = 'table';
  aView: any = 'type';
  selectedType: any = '';
  destCompany: any = [];
  sourceCompany: any = [];
  mapCompanyList: any = [];
  public searching = false;
  protected onDestroySearch = new Subject<void>();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public filteredSourceCompany: ReplaySubject<any> = new ReplaySubject<any>(1);


  searchSourceControl: UntypedFormControl = new UntypedFormControl();
  searchDestControl: UntypedFormControl = new UntypedFormControl();
  searchSourceImpControl: UntypedFormControl = new UntypedFormControl();
  comMapForm: UntypedFormGroup;
  comMapImpForm: any = {
    company_id: []
  };
  @ViewChild('comMapNgForm') comMapNgForm: NgForm;

  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };
  showAlert: boolean = false;
  isConstentProvided: boolean = false;
  importProvideConsent: any = {};
  getCount = 0;

  editMapForm: UntypedFormGroup;
  @ViewChild('editMapNgForm') editMapNgForm: NgForm;
  updateCompany: any = {};

  editDestControl: UntypedFormControl = new UntypedFormControl();
  edit_update_company: any = {};
  allComp: any = [];
  allDestComp: any = [];
  companyActionParams: any = {};
  companyParams: any = {};
  companyFormElements: any = [];
  integrationProfile: any = [];
  selectedProfile: any = '';
  integrationSite: any[];
  selectedImportcompany: any = [];
  /**
     * Constructor
     */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef, private _ls: LoaderService,
    private _bs: BaseRequestService, public _cs: CommonService, private _router: Router,
    private toast: MyToastrService, private _formBuilder: UntypedFormBuilder,
    public _mS: ModalService, public confirmDialog: FuseConfirmationService
  ) {
  }

  ngOnInit(): void {
    if (this.integrationCred && this.integrationCred.length) {
      this.selectedCred = this.integrationCred[0].id;
      this.initMappedCompany(this.integrationCred[0].id);
    }
    this.comMapForm = this._formBuilder.group({
      company_id: [{}, Validators.required],
      dest_company_id: [{}, Validators.required],
      integration_rules: [[]],
      sites: [{}],
      no_notification: [false],
      configuration: [false]
    }
    );

    const get_conpany = this.currentIntegration.actions.filter((x: any) => x.name === 'get_companies');
    if (get_conpany && get_conpany.length) {
      this.companyActionParams = get_conpany[0];
    }
    this.selectedProfile = '';
    if (this.triggerNew && this.triggerNew.show) {
      this._ls.display(true);
      setTimeout(() => {
        this.comMapForm = this._formBuilder.group({
          company_id: [{}, Validators.required],
          dest_company_id: [{}, Validators.required],
          integration_rules: [[]],
          sites: [{}],
          no_notification: [false],
          configuration: [false]
        }
        );
        this.addTableData();

        this.triggerNew = false;
        this._ls.display(false);
      }, 3000);
      this.selectedProfile = this.triggerNew.id;
      setTimeout(() => { this.addTableData(); this.triggerNew = { show: false, id: null }; }, 2000);
    }
    setTimeout(() => {
      const router = this._router.url.split('/');
      if (router.indexOf('onboarding') > -1) {
        if (this.mappedComTableOptions.pageData && this.mappedComTableOptions.pageData.length) {
          this._cs.onboardingCom.emit(true);
        }
      }
    }, 5000);

    this.searchSourceControl.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(this._unsubscribeAll),
        map((value) => {
          return value;
        }),
        filter(value => value && value.length >= 3),
      )
      .subscribe((value) => {
        this.filterCompanies();
      })
    this.searchDestControl.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(this._unsubscribeAll),
        map((value) => {
          return value;
        }),
        filter(value => value && value.length >= 3),
      )
      .subscribe((value) => {
        this.filterDestCompanies();
      })
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();

    this.onDestroySearch.next();
    this.onDestroySearch.complete();
  }
  private filterCompanies(): void {
    if (!this.sourceCompany) {
      return;
    }
    // get the search keyword
    let search = this.searchSourceControl.value;
    if (!search) {
      this.sourceCompany = this.allComp.slice();
      this._changeDetectorRef.markForCheck();
      return;
    } else {
      search = search.toLowerCase();
    }
    this.getCompanies(search);
  }

  closeCurrentCompany($event: boolean) {
    this.searching = false;
    if (this.allComp) {
      this.sourceCompany = this.allComp.slice();
      this._changeDetectorRef.markForCheck();
    }
    // if (!$event) {
    //   this.getCompanies();
    // }
  }

  getCompanies(search?: string): void {
    if (
      !this._bs.user() ||
      !this._bs.user().email
    ) {
      setTimeout(() => {
        this.getCompanies(search);
      }, 1000);
      return;
    }
    this.searching = true;
    const condition = search
      ? { condition: "name ilike '%" + search + "%'" }
      : {
        condition: true,
        skip: 0,
        limit: 100,
        order_by: 'created desc'
      };
    this._bs
      .doRequest('/r/company/companies', 'get', null, condition)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (result.data.length) {
          this.searching = false;
          result.data.sort((a: any, b: any) => {
            const c = a.name ? a.name.toLowerCase() : "";
            const d = b.name ? b.name.toLowerCase() : "";
            if (c < d) {
              return -1;
            } else if (c > d) {
              return 1;
            } else {
              return 0;
            }
          });

          if (!search) {
            this.allComp = result.data;
          } else {
            this.allComp = Array.from(new Map([...this.allComp, ...result.data].map(obj => [obj.id, obj])).values());
          }
          this.sourceCompany = result.data.slice();
          this._changeDetectorRef.markForCheck();
        } else {
          this.sourceCompany = [];
        }
      }, (error: any) => {
        this.searching = false;
      }
      );
  }

  initMappedCompany($event: any): void {
    this.mappedComTableOptions = {
      columns: [
        {
          "header": "Source Company Name",
          "columnDef": "source_company_name",
          "cType": "string",
          "filter": "",
          "cell": "(element: any) => `${element.source_company_name}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
        {
          "header": "Destination Company Name",
          "columnDef": "dest_company_name",
          "filter": "",
          "cell": "(element: any) => `${element.dest_company_name}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
        {
          "header": "Create Ticket",
          "columnDef": "no_notification",
          "filter": "",
          "cell": "(element: any) => `${element.no_notification}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          isColoredCntrl: true, isCustomText: true, isCustomClass: true,
          "isProgressCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
        {
          "header": "Event Set",
          "columnDef": "event_set_name",
          "filter": "",
          "cell": "(element: any) => `${element.event_set_name}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
        {
          "header": "Integration Rules",
          "columnDef": "integration_rules_names",
          "filter": "",
          "cell": "(element: any) => `${element.integration_rules_names}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
      ],
      sortOptions: { active: 'updated', direction: 'asc' },
      _pageData: [],
      tableOptions: {
        title: 'Mapped Company',
        isServerSide: false,
        selectText: 'MappedCompany',
        loading: false,
        floatingFilter: true,
        rowSelection: false,
        showAction: true,
        actionMenuItems: [
          {
            text: 'Edit',
            id: 'edit',
            icon: 'edit',
            callback: 'editFu',
            isGlobal: false,
          },
          {
            text: 'Delete',
            id: 'delete',
            icon: 'delete',
            callback: 'editFu',
            isGlobal: false,
          }
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 5,
        search: false,
        showhideList: true,
        refreshData: true,
        showFilter: true,
        showTagFilter: false,
        exportExcel: true,
        add: true,
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        id: 'MappedCompany',
        serverSide: {
          condition: "integration_name='" + this.currentIntegration.name + "'" + ' and ' + "credential_id='" + $event + "'",
          // url: '/r/integration/company_mappings',
          url: '/r/integration/company_mapping_view',
          type: 'get',
          isGlobal: true,
          params: {}
        },
      },
      customText: [
        {
          status: true,
          DisplayText: 'No',
          class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'
        },
        {
          status: false,
          DisplayText: 'Yes',
          class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
        },
      ],
      changeValue: new Subject<any>(),
    }
    /*if (this.currentIntegration.name !== 'AzureADNonCSP') {
      const isCmp = this.mappedComTableOptions.tableOptions.actionMenuItems.filter(
        (x: any) => x.text === 'Edit'
      );
      if (!isCmp || !isCmp.length) {
        this.mappedComTableOptions.tableOptions.actionMenuItems.unshift(

        )
      }
    }*/
    // setTimeout(() => this.mappedComTableOptions.changeValue.next(true));

  }


  /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  /**
     * Filter by category
     *
     * @param change
     */
  filterByCred(change: MatSelectChange): void {
    const data = Object.assign({}, this.mappedComTableOptions);
    this.mappedComTableOptions = {}; this._changeDetectorRef.detectChanges();
    data.pageData = []; data.tableOptions.pageTotal = 0;
    data.tableOptions.serverSide.condition = "integration_name='" + this.currentIntegration.name + "'" + ' and ' + "credential_id='" + change.value + "'";
    this.mappedComTableOptions = data; this._changeDetectorRef.detectChanges();
    if (this.currentIntegration.notificationfunction) {
      this.getIntegrationProfile();
    }
  }

  deleteRecord(data: any) {
    const confirmation = this.confirmDialog.open({
      title: 'Confirmation',
      message: `Are you sure you want to delete ?`,
      actions: {
        confirm: {
          label: 'Yes'
        }
      }
    });
    confirmation.afterClosed().subscribe((result: any) => {
      if (result === 'confirmed') {
        this._bs.doRequest(`/d/integration/company_mappings/${data.id}`, 'delete')
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              this.toast.sToast('success', 'Removed successfully');
              if (this.currentIntegration.integrationtype === "azure ad") {
                this.deleteAzureScheduler(data.company_id);
              }
              this.initMappedCompany(this.selectedCred); this._changeDetectorRef.detectChanges();
            } else {
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
            }
          });
      }
    })
  }

  addTableData(): void {
    this.mapCompanyList = [];
    setTimeout(() => {
      this.comMapForm = this._formBuilder.group({
        company_id: [{}, Validators.required],
        dest_company_id: [{}, Validators.required],
        integration_rules: [[]],
        sites: [{}],
        no_notification: [false],
        configuration: [false]
      });

      this.comMapImpForm = {
        company_id: []
      };
    })
    if (this.currentIntegration.import_companies) {
      this.companyFormElements = this._cs.processFormElements(this.companyActionParams.parameters);
      this.companyFormElements.map((x: any) => { (x.name) ? this.companyParams[x.name] = x.required ? '' : x.default : null; })
      this.selectedType = ''; this.aView = 'type';
    } else {
      this.selectedType = 'exist'; this.aView = 'exist'; this.getCompanies();
    }
    this.cView = 'add';
    if (this.currentIntegration.notificationfunction) {
      this.comMapForm.get('no_notification').setValue(true);
      this.getIntegrationProfile();
    }
    this._changeDetectorRef.detectChanges();
  }

  actionCall($event: any): void {
    if ($event.action.text == 'Edit') {
      const data = Object.assign({}, $event.row);
      data.integration_rules = (data.integration_rules) ? data.integration_rules.split(',').map((x: any) => x = parseInt(x)) : [];
      data.no_notification = (data.no_notification) ? false : true;
      this.updateCompany = data;
      if (this.currentIntegration.notificationfunction) {
        this.getIntegrationProfile();
      }
      if (this.currentIntegration.import_companies) {
        const params: any = {
          integration_id: this.selectedCred,
          integration_name: this.currentIntegration.name,
          action_name: 'get_companies',
          requestparams: {}
        }
        this._ls.display(true);
        this._cs.executeAction(params).then((item: any) => {
          this._ls.display(false);
          if (item.status) {
            this.destCompany = item.data;
            this.searching = false;
            const com = this.destCompany.filter((x: any) => parseInt(x.id) === parseInt(data.dest_company_id));
            if (com && com.length) {
              this.edit_update_company = com[0];
              if (this.currentIntegration.import_sites) {
                this.updateCompany.site_id = (data.site_id) ? parseInt(data.site_id) : '';
                this.getIntegrationSite(com[0]);
              }
              this._mS.open('editNonPsaInt');
            } else {
              this._ls.display(true);
              params.requestparams.name = data.dest_company_name;
              this._cs.executeAction(params).then((item: any) => {
                this._ls.display(false);
                if (item.status) {
                  this.destCompany = Array.from(new Map([...this.destCompany, ...item.data].map(obj => [obj.id, obj])).values());
                  this.searching = false;
                  const com = this.destCompany.filter((x: any) => parseInt(x.id) === parseInt(data.dest_company_id));
                  this.edit_update_company = com[0];
                  if (this.currentIntegration.import_sites) {
                    this.updateCompany.site_id = (data.site_id) ? parseInt(data.site_id) : '';
                    this.getIntegrationSite(com[0]);
                  }
                  this._mS.open('editNonPsaInt');
                }
              })
            }
          }
        });
      } else {
        this._mS.open('editNonPsaInt');
      }

    }
    if ($event.action.text == 'Delete') {
      this.deleteRecord($event.row);
    }
  }

  getCompany($event?: any): void {
    if (this.selectedType === 'exist') { this.getCompanies(); };
    if (!this.currentIntegration.import_companies) { return; }
    const params: any = {
      integration_id: this.selectedCred,
      integration_name: this.currentIntegration.name,
      action_name: 'get_companies',
      requestparams: {}
    };
    (this.companyParams) ? params.requestparams = this.companyParams : {};
    this.comMapForm.reset();
    this._ls.display(true);
    this._cs.executeAction(params).then((item: any) => {
      this._ls.display(false);
      if (item.status && item.data.length) {
        this.destCompany = item.data;
        this.comMapForm.get('dest_company_id').setValue(item.data[0]);
        if (this.currentIntegration.import_sites) {
          this.getIntegrationSite(item.data[0]);
        }
      } else {
        this.destCompany = [];
      }
    });
  }

  getIntegrationSite($event: any): void {
    if (!this.currentIntegration.import_sites) {
      return;
    }
    const params: any = {
      integration_id: this.selectedCred,
      integration_name: this.currentIntegration.name,
      action_name: 'get_sites',
      requestparams: {
        company_id: $event.id
      }
    };
    this._ls.display(true);
    this._cs.executeAction(params).then((item: any) => {
      this._ls.display(false);
      if (item.status && item.data.length) {
        this.integrationSite = item.data;
      } else {
        this.integrationSite = [];
      }
    });
  }
  addToMapList(): void {
    const shortcut = this.comMapForm.value;
    this.alert = { type: 'error', message: '' };
    const srcCmp = this.mapCompanyList.filter((x: any) => x.company_id === shortcut.company_id.id);
    if (srcCmp.length) {
      this.alert.message = `${shortcut.company_id.name} company already added.`; this.showAlert = true;
      setTimeout(() => { this.showAlert = false; this.alert = null; }, 4000);
      return;
    }
    if (this.currentIntegration.import_companies) {
      const destCmp = this.mapCompanyList.filter((x: any) => x.dest_company_id === shortcut.dest_company_id.id);
      if (destCmp.length) {
        this.alert.message = `${shortcut.dest_company_id.name} company already added.`; this.showAlert = true;
        setTimeout(() => { this.showAlert = false; this.alert = null; }, 4000);
        return;
      }
    }
    const integrationData: any = { integration_name: this.currentIntegration.name, credential_id: this.selectedCred };
    let integartionProfileId: any; let integartionProfileName: any; let siteName: any; let siteId: any;
    if (this.currentIntegration.notificationfunction) {
      if ((!shortcut.integration_rules || !shortcut.integration_rules.length) && shortcut.no_notification) {
        this.toast.sToast(
          'error',
          `Please select Integration Rule`
        );
        return;
      }
      integartionProfileId = (shortcut.integration_rules) ? shortcut.integration_rules.map((x: any) => x.id) : [];
      integartionProfileName = (shortcut.integration_rules) ? shortcut.integration_rules.map((x: any) => x.name) : [];
    }
    if (this.currentIntegration.import_sites) {
      siteId = (shortcut.sites) ? shortcut.sites.id : null;
      siteName = (shortcut.sites) ? shortcut.sites.name : null;
    }
    this.mapCompanyList.push(
      {
        ...integrationData,
        ...{
          company_id: shortcut.company_id.id,
          source_company_name: shortcut.company_id.name,
          dest_company_id: (this.currentIntegration.import_companies) ? shortcut.dest_company_id.id : '',
          dest_company_name: (this.currentIntegration.import_companies) ? shortcut.dest_company_id.name : '*',
          site_id: (this.currentIntegration.import_sites) ? siteId : undefined,
          site_name: (this.currentIntegration.import_sites) ? siteName : undefined,
          integration_rules: integartionProfileId,
          no_notification: shortcut.no_notification || false,
          configuration: shortcut.configuration || false,
          integartionProfileName: integartionProfileName?.toString(),

        }
      }
    );
    this.comMapForm.reset();
  }


  provideConsent(dest_company_id?: any, copyLink?: any): void {
    const rToken = uuidv4();
    const stateVar = btoa(
      `${window.location.host}@${rToken}@${this.selectedCred}`
    ).replace(/=/g, '');
    const applicationId = this.integrationCred.filter((x: any) => x.id === this.selectedCred);
    //edit
    // const destId = (dest_company_id) ? dest_company_id : (this.cmpMapping.destinationCompany.id) ? this.cmpMapping.destinationCompany.id + '' : this.cmpMapping.destinationCompany.id + '';
    const destId = dest_company_id;
    const baseURL = (applicationId[0] && applicationId[0].params.auth_endpoint) ? applicationId[0].params.auth_endpoint : 'https://login.microsoftonline.com';
    let url: any = `${baseURL}/${destId}/v2.0/adminconsent?client_id=${applicationId[0].params.client_id}&response_mode=query&response_type=code&redirect_uri=https://authccns.mycybercns.com&nonce={{nonce}}&scope=https://api.partnercenter.microsoft.com/.default`;
    url = url.replace("{{nonce}}", rToken);
    const urlCSP = `${url}&state=${stateVar}`;

    if (copyLink) {
      this._cs.copyClip(urlCSP);
    } else {
      localStorage.removeItem('oAuthC');
      localStorage.setItem('ncsp', JSON.stringify({}));
      this._bs.o365window = window.open(
        urlCSP,
        'popup',
        'width=600,height=600'
      )
      this.isConstentProvided = false;
      this.importProvideConsent[dest_company_id] = false;
      this.checkIsOAuthCodeCSP(dest_company_id);
    }
  }

  checkIsOAuthCodeCSP(dest_company_id?: any, type?: any): void {
    const oAuthC = localStorage.getItem('oAuthC');
    if (oAuthC) {
      let ncsp: any;
      try {
        ncsp = JSON.parse(localStorage.getItem('ncsp') as string);
        console.log(ncsp);
      } catch (e) {
        console.log('Error parsing AD CSP config');
      }
      this.importProvideConsent[dest_company_id] = true;
      //edit
      // (dest_company_id && ) ? this.updateCompanyMapping() : null;
      localStorage.removeItem('oAuthC');
      localStorage.removeItem('ncsp');
      return;
    }
    setTimeout(() => { this.getCount = this.getCount + 1; this.checkIsOAuthCodeCSP(); }, 1000);
  }

  azureScheduler(id: any): void {
    const reqData =
    {
      schedule_type: "azure_ad_sync",
      settings: {},
      data: {
        company_id: id,
        credential_id: this.selectedCred,
        integration_name: this.currentIntegration.name,
      }
    }
    this._bs.doRequest(`w/integration/create_schedule`, 'post', reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => { });
  }

  deleteAzureScheduler(id: any): void {
    const reqData =
    {
      schedule_type: "azure_ad_sync",
      settings: {},
      data: {
        company_id: id,
        credential_id: this.selectedCred,
        integration_name: this.currentIntegration.name,
      }
    }
    this._bs.doRequest(`w/integration/remove_schedule`, 'post', reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => { });
  }
  mapSelectedCompanies(): void {
    this.mapCompanyList.forEach((item: any, index: number) => {
      const obj: any = cloneDeep(item);
      (this.currentIntegration.name === 'AzureADCSP') ? obj.consent_enabled = this.importProvideConsent[obj.dest_company_id] : null;
      obj.no_notification = (obj.no_notification) ? false : true;
      delete obj.integartionProfileName;
      const reqData: any = { data: obj };
      this._ls.display(true);
      this._bs.doRequest(`/w/integration/company_mappings`, 'post', reqData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => {
          if (result.status) {
            if (this.currentIntegration.integrationtype === "azure ad") {
              this.azureScheduler(obj.company_id);
            }
            if (index === this.mapCompanyList.length - 1) {
              this._ls.display(false);
              this.cView = 'table';
              setTimeout(() => { this.tiggerFn.emit({ eventset: null }); }, 1000);
              this.initMappedCompany(this.selectedCred); this._changeDetectorRef.detectChanges();
            }
          } else {
            this._ls.display(false);
            const data = (result.message) ? result.message : result.data;
            this.toast.sToast('error', data);
          }
        });
    });
  }


  updateComMapping(): void {
    const shortcut = this.edit_update_company;
    if ((!this.updateCompany.integration_rules || !this.updateCompany.integration_rules.length) && this.updateCompany.no_notification) {
      this.toast.sToast(
        'error',
        `Please select Integration Rule`
      );
      return;
    }
    this.updateCompany.dest_company_id = shortcut.id; this.updateCompany.dest_company_name = shortcut.name;
    if (this.currentIntegration.import_sites && this.updateCompany.site_id) {
      const data = this.integrationSite.filter((x: any) => x.id === this.updateCompany.site_id);
      if (data && data.length) {
        this.updateCompany.site_name = data[0].name; this.updateCompany.site_id = data[0].id;
      }
    }
    delete this.updateCompany.hovered; delete this.updateCompany.integration_rules_names; delete this.updateCompany.event_set_name;
    this.updateCompany.no_notification = (this.updateCompany.no_notification) ? false : true;
    const reqData = { data: this.updateCompany, id: this.updateCompany.id };
    this._ls.display(true);
    this._bs.doRequest(`/w/integration/company_mappings`, 'patch', reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this._ls.display(false);
        if (result.status) {
          this.toast.sToast('success', 'Updated successfully');
          this._mS.close('editNonPsaInt');
          this.initMappedCompany(this.selectedCred); this._changeDetectorRef.detectChanges();
        }
      });
  }


  importSelectedCompanies(): void {
    if (this.comMapImpForm.company_id.length) {
      const notickets = this.comMapImpForm.company_id.filter((x: any) =>
        (x.no_notification && (!x.integration_rules || !x.integration_rules.length))
      );

      if (notickets && notickets.length) {
        this.toast.sToast(
          'error',
          `Please select Integration Rule for the company ${notickets[0].name}`
        );
        return;
      } else {
        this.comMapImpForm.company_id.forEach((obj: any, index: any) => {
          this._ls.display(true, `Importing ${obj.name}`);
          const reqData = { data: { name: obj.name, description: null } };
          this._bs.doRequest('/w/company/companies', 'post', reqData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
              const router = this._router.url.split('/');
              if (router.indexOf('onboarding') !== -1) {
                localStorage.setItem('onboardCmp', result.id);
                this._cs.currentScope = { id: parseInt(result.id), name: obj.name };
                this._cs.onboardingCom.emit(this._cs.currentScope);
              }
              if (result.status) {
                let site = [];
                if (this.currentIntegration.import_sites) {
                  site = obj.integrationSites.filter((x: any) => parseInt(x.id) === parseInt(obj.site_id));
                }
                const integrationData: any = { integration_name: this.currentIntegration.name, credential_id: this.selectedCred };
                const requestD: any = {
                  data: {
                    ...integrationData,
                    ...{
                      company_id: parseInt(result.id),
                      source_company_name: obj.name,
                      dest_company_id: obj.id,
                      dest_company_name: obj.name,
                      integration_rules: obj.integration_rules,
                      site_id: (site && site.length) ? site[0].id : null,
                      site_name: ((site && site.length)) ? site[0].name : null,
                      configuration: (this.currentIntegration.enable_configuration) ? obj.configuration : false,
                      no_notification: (obj.no_notification) ? false : true,
                    }
                  }
                }
                this._bs.doRequest(`/w/integration/company_mappings`, 'post', requestD)
                  .pipe(takeUntil(this._unsubscribeAll))
                  .subscribe((result: any) => {
                    if (result.status) {
                      if (this.currentIntegration.integrationtype === "azure ad") {
                        this.azureScheduler(parseInt(result.id));
                      }
                      if (index === this.comMapImpForm.company_id.length - 1) {
                        this._ls.display(false);
                        this.cView = 'table';
                        this.initMappedCompany(this.selectedCred); this._changeDetectorRef.detectChanges();
                      }
                    } else {
                      this._ls.display(false);
                      const data = (result.message) ? result.message : result.data;
                      this.toast.sToast('error', data);
                    }
                  });
              } else {
                const data = (result.message) ? result.message : result.data;
                this.toast.sToast('error', data);
              }
            })
        });
      }
    }
  }

  getIntegrationProfile(): void {
    this._bs.doRequest(`/r/integration/integration_rules`, 'get', null,
      { condition: `integration_name='${this.currentIntegration.name}' and credential_id=${this.selectedCred}`, skip: 0, limit: 1000, order_by: 'name asc' })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (result.status) {
          this.integrationProfile = result.data;
        }
      })
  }

  updateSelected($event: any): void {
    // Reflect select deselect
    const newSelectedIds = $event.map((obj: any) => obj.id);
    for (let i = this.comMapImpForm.company_id.length - 1; i >= 0; i--) {
      const company = this.comMapImpForm.company_id[i];
      if (!newSelectedIds.includes(company.id)) {
        this.comMapImpForm.company_id.splice(i, 1);
        this.removeSelected(i);
      }
    }
    $event.forEach((obj: any, index: number) => {
      if (!this.comMapImpForm.company_id.some((x: any) => x.id === obj.id)) {
        this.comMapImpForm.company_id.push($event[index]);
        if (this.currentIntegration.notificationfunction) {
          obj.integration_rules = [];
          obj.no_notification = false;
        }
        if (this.currentIntegration.enable_configuration) {
          obj.configuration = false;
        }
        if (this.currentIntegration.import_sites) {
          obj.site_id = '';
          obj.integrationSites = [];
          this.getImportIntegrationSites($event[index], index);
        }
      }
    });
  }

  getImportIntegrationSites(cmp: any, idx: any): void {
    const params: any = {
      integration_id: this.selectedCred,
      integration_name: this.currentIntegration.name,
      action_name: 'get_sites',
      requestparams: {
        company_id: cmp.id
      }
    };
    this._ls.display(true);
    this._cs.executeAction(params).then((item: any) => {
      this._ls.display(false);
      if (item.status && item.data.length) {
        this.comMapImpForm.company_id[idx].integrationSites = item.data;
      } else {
        this.comMapImpForm.company_id[idx].integrationSites = [];
      }
    });
  }

  removeSelected(i: number): void {
    this.selectedImportcompany = this.selectedImportcompany.filter((obj: any) => obj.id !== this.comMapImpForm.company_id[i].id);
    this.comMapImpForm.company_id.splice(i, 1);
  }
  private filterDestCompanies(): void {
    if (!this.destCompany) {
      return;
    }
    // get the search keyword
    let search = this.searchDestControl.value;
    if (!search) {
      this.destCompany = this.allDestComp.slice();
      this._changeDetectorRef.markForCheck();
      return;
    } else {
      search = search.toLowerCase();
    }
    this.getDestCompanies(search);
  }

  getDestCompanies(search: any): void {
    const params: any = {
      integration_id: this.selectedCred,
      integration_name: this.currentIntegration.name,
      action_name: 'get_companies',
      requestparams: {}
    };
    (this.companyParams) ? params.requestparams = this.companyParams : {};
    params.requestparams.name = search;
    this._ls.display(true);
    this._cs.executeAction(params).then((item: any) => {
      this._ls.display(false);
      if (item.status && item.data.length) {
        if (!search) {
          this.allDestComp = item.data;
        } else {
          this.allDestComp = Array.from(new Map([...this.allDestComp, ...item.data].map(obj => [obj.id, obj])).values());
        }
        this.destCompany = item.data.slice();
        this.comMapForm.get('dest_company_id').setValue(item.data[0]);
        if (this.currentIntegration.import_sites) {
          this.getIntegrationSite(item.data[0]);
        }
        this._changeDetectorRef.markForCheck();
      } else {
        this.destCompany = [];
      }
    });
  }
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule, CurrencyPipe, NgClass, NgFor, NgIf, NgOptimizedImage} from '@angular/common';
import {FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {FuseAlertComponent} from "../../../../@fuse/components/alert";
import {MatRadioModule} from "@angular/material/radio";
import {MatIconModule} from "@angular/material/icon";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatOptionModule} from "@angular/material/core";
import {MatButtonModule} from "@angular/material/button";
import {BaseRequestService} from "../../../_services/base.service";
import {CommonService} from "../../../_services/common.service";
import {Subject, Subscription, takeUntil} from "rxjs";
import {AppFilterPipeModule} from "../../../_filters/app.filter-pipe.module";
import {MaterialModule} from "../../../material.module";
import {PaginatorWidgetComponent} from "../paginator-widget/paginator-widget.component";
import {LoaderService} from "../../../_services/loader.service";
import {MyToastrService} from "../../../_services/toastr.service";

@Component({
    selector: 'app-plan-and-billing',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, FuseAlertComponent, MatRadioModule,
        NgFor, NgClass, NgIf, MatIconModule, MatFormFieldModule, MatInputModule, MatSelectModule,
        MatOptionModule, MatButtonModule, CurrencyPipe, AppFilterPipeModule, NgOptimizedImage,
        MaterialModule, PaginatorWidgetComponent],
    templateUrl: './plan-and-billing.component.html',
    styleUrls: ['./plan-and-billing.component.scss']
})
export class PlanAndBillingComponent implements OnInit, OnDestroy {
    planBillingForm: UntypedFormGroup;
    plans: any[];
    searching = false;
    allComp: any;
    companies: any;
    sortReverse = true;
    sortType: any = 'total_assets';
    search: any;
    pageSize: number = 100;
    pageTotal: number; assetTotal: number;
    currentPageIndex: any = 0;
    stats: any = [
        {
            icon: 'fa-desktop',
            title: 'ASSET COUNT',
            text: 'ASSET COUNT',
            key: 'total_assets',
        },
        {
            icon: 'fa-dollar-sign',
            title: 'ACCRUED COST',
            text: 'ACCRUED COST',
            key: 'accrued_cost',
        }
    ];
    costPlan: any = [
        {name: 'UPTO 2500', count: 2500, cost: 299},
        {name: 'UPTO 5000', count: 5000, cost: 499},
        {name: 'UPTO 10000', count: 10000, cost: 999},
    ];
    cmpHash: any = {};
    costHash: any = {
        2500: 299, 5000: 499, 10000: 999
    }
    assetCount: any;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _formBuilder: UntypedFormBuilder, private _bs: BaseRequestService,
        private cs: CommonService, private _changeDetectorRef: ChangeDetectorRef,
        private ls: LoaderService, private toast: MyToastrService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Create the form
        this.planBillingForm = this._formBuilder.group({
            plan: ['']
        });

        // Setup the plans
        this.plans = [
            {
                value: 'basic',
                label: 'Up to 2,500 Devices',
                details: 'Manage up to 2.5k devices',
                price: '299',
            },
            {
                value: 'team',
                label: 'Up to 5,000 Devices',
                details: 'Manage up to 5k devices',
                price: '499',
            },
            {
                value: 'enterprise',
                label: 'Up to 10,000 Devices',
                details: 'Manage up to 10k devices',
                price: '999',
            },
        ];
        this.getCompaniesStats();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    getCompaniesStats(search?: any): void {
        if (!this.cs.allComp || !this.cs.allComp.length) {
            setTimeout(() => this.getCompaniesStats(), 1000);
        }
        this.cmpHash = {};
        this.cs.allComp.forEach((c: any) => {
            this.cmpHash[c.id] = c;
        });
        this._bs.doRequest(`/r/company/company_stats`, 'get', null, {
            condition: true,
            skip: 0,
            limit: 5000,
            order_by: 'updated desc'
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                this.assetTotal = 0;
                if (res.status && res.data && res.data.length) {
                    this.pageTotal = this.cs.allComp.length;
                    res.data.forEach((c: any) => {
                        c.name = (this.cmpHash[c.company_id] && this.cmpHash[c.company_id].name) ? this.cmpHash[c.company_id].name : '';
                        this.assetTotal += c.total_assets;
                    });
                    res.data.sort((a: any, b: any) => {
                        return this.cs.compare(a['total_assets'], b['total_assets'], false);
                    });
                    let cost = 299;
                    if (this.assetTotal <= 2500) {
                        cost = +(2500/299).toFixed(2);
                        this.planBillingForm.setValue({plan: 'basic'})
                    } else if (this.assetTotal > 2500 && this.assetTotal <= 5000) {
                        cost = +(5000/499).toFixed(2);
                        this.planBillingForm.setValue({plan: 'team'})
                    } else if (this.assetTotal > 5000) {
                        cost = +(10000/999).toFixed(2);
                        this.planBillingForm.setValue({plan: 'enterprise'})
                    }
                    res.data.forEach((c: any) => {
                        c.accrued_cost = +(c.total_assets / cost).toFixed(2);
                    });
                    this.allComp = res.data;
                    this._changeDetectorRef.markForCheck();
                    this.searching = false;
                } else {
                    this.toast.sToast('info', 'Processing... Please come back after sometime!')
                }
            });
    }

    getCompanies(search?: string): void {
        this.searching = true;
        const order = (this.sortReverse) ? 'desc' : 'asc';
        const condition: any = search
            ? {condition: "name ilike '%" + search + "%'"}
            : {
                condition: true,
                order_by: `${this.sortType} ${order} nulls last`
            };
        if (condition.condition === true && this.cs.currentScope !== '*') {
            condition.condition = "id='" + this.cs.currentScope.id + "'";
        }
        condition.is_assessment = false;
        condition.skip = this.pageSize * this.currentPageIndex;
        condition.limit = 5000;
        this._bs.doRequest(`/report_queries/overview`,
            'get', null, condition)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
            if (result.status) {
                this.pageTotal = result.total;
                this.assetTotal = 0
                result.data.forEach((c: any) => {
                    this.assetTotal += c.total_assets;
                });
                let cost = 299;
                if (this.assetTotal <= 2500) {
                    cost = +(2500/299).toFixed(2);
                    this.planBillingForm.setValue({plan: 'basic'})
                } else if (this.assetTotal > 2500 && this.assetTotal <= 5000) {
                    cost = +(5000/499).toFixed(2);
                    this.planBillingForm.setValue({plan: 'team'})
                } else if (this.assetTotal > 5000) {
                    cost = +(10000/999).toFixed(2);
                    this.planBillingForm.setValue({plan: 'enterprise'})
                }
                result.data.forEach((c: any) => {
                    c.accrued_cost = +(c.total_assets / cost).toFixed(2);
                });
                if (!search) {
                    this.allComp = result.data;
                }
                this.companies = result.data;
                this._changeDetectorRef.markForCheck();
                this.searching = false;
            }
        });
    }

    getSummaryView(): void {
        // /report_queries/insights?&condition=true
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    sortFn(key: any): void {
        this.sortType = key;
        this.sortReverse = !this.sortReverse;
        this.getCompaniesStats();
    }

    pageChanged(event: any): void {
        this.currentPageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.getCompaniesStats();
    }
}

<ng-container *ngIf="type === 'list'">
  <div *ngIf="showOrder === 'list' && darkBg">
    <ul>
      <li *ngFor="let item of items | search: searchInput; let i = index"
        [class.hidden]="i >= maxItemsToShow && !showAllItems">
        {{ item }}
      </li>
    </ul>
    <button (click)="toggleShowItems()">
      {{ showAllItems ? 'Show Less' : 'Show More' }}
    </button>
  </div>

  <div *ngIf="showOrder === 'span' && darkBg">
    <div class="flex flex-row flex-wrap items-center gap-2 mt-2" *ngIf="items">
      <span *ngFor="let p of items | search: searchInput;; let i = index"
        [class.hidden]="i >= maxItemsToShow && !showAllItems" class="{{bgColor}} {{color}}
        inline-flex text-xs items-center font-bold px-1.5 py-0.5 rounded-lg {{darkBg}} {{darkColor}}">{{p}}</span>
    </div>
    <button (click)="toggleShowItems()" class="mt-2 text-sm text-blue-600"
      *ngIf="items && items.length > maxItemsToShow">
      {{ showAllItems ? 'Show Less' : 'Show More' }}
    </button>
  </div>

  <div *ngIf="showOrder === 'href' && darkBg">
    <div class="flex flex-row flex-wrap items-center mt-2" *ngIf="items">
      <span *ngFor="let p of items | search: searchInput;; let i = index" (click)="clickOnDomain(p)"
        [class.hidden]="i >= maxItemsToShow && !showAllItems" class="cursor-pointer bg-blue-100 text-black text-md font-semibold ml-0 mb-2 me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-white border border-blue-400 inline-flex items-center justify-center">{{p}}</span>
    </div>
    <button (click)="toggleShowItems()" class="mt-2 text-sm text-blue-600"
      *ngIf="items && items.length > maxItemsToShow">
      {{ showAllItems ? 'Show Less' : 'Show More' }}
    </button>
  </div>

  <div *ngIf="showOrder === 'asm' && darkBg">
    <div class="flex flex-row flex-wrap items-center mt-2" *ngIf="items">
      <span *ngFor="let p of items | search: searchInput;; let i = index"
        [class.hidden]="i >= maxItemsToShow && !showAllItems" class="bg-blue-100 text-black text-md font-semibold ml-0 mb-2 me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-white border border-blue-400 inline-flex items-center justify-center">{{p}}</span>
    </div>
    <button (click)="toggleShowItems()" class="mt-2 text-sm text-blue-600"
      *ngIf="items && items.length > maxItemsToShow">
      {{ showAllItems ? 'Show Less' : 'Show More' }}
    </button>
  </div>
</ng-container>
<ng-container *ngIf="type === 'string'">
    <div class="text-md">
      {{ inputString.length > firstCount ? (inputString | slice: 0:counter) + '..' : inputString }}
      <span class="text-blue-500 cursor-pointer" *ngIf="inputString.length > 100" (click)="toggleSkil($event)">
        {{ showTxt }}
      </span>
    </div>
</ng-container>
import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FuseAlertComponent } from '@fuse/components/alert';
import { MaterialModule } from 'app/material.module';

@Component({
  selector: 'app-agent-performance-management',
  templateUrl: './agent-performance-management.component.html',
  standalone: true,
  imports: [CommonModule, FuseAlertComponent, FormsModule, NgIf, NgFor, MaterialModule],
  styleUrls: ['./agent-performance-management.component.scss']
})
export class AgentPerformanceManagementComponent {
  @Output() checkBoxCallback = new EventEmitter();
  isMultiple = true;
  initSelectedValues = [];
  selection = new SelectionModel<any>(this.isMultiple, this.initSelectedValues);
  numSelected!: number;
  selectedCompanies: any = [];
  selectedVersion: any;
  globalPerCount: any;
  compPerCount: any;
  allCompanies: any = {
    "data": [
      {
        "c": "2023-11-23T07:22:48.447606",
        "name": "!!!!!!!!!!!!!!!All",
        "_id": "d1737726-3ebd-42d5-adc3-a5f06cc05824"
      },
      {
        "c": "2023-11-22T12:17:01.238101",
        "name": "!!!!!!all pii test",
        "_id": "afc2d1ba-521c-4d4f-a7c4-0914653973ca"
      },
      {
        "c": "2023-12-04T06:32:33.812163",
        "name": "!!!!!!aye",
        "_id": "f96f1df9-ed4c-45dc-9e21-afed5d789fcf"
      },
      {
        "c": "2023-11-04T05:28:03.965448",
        "name": "!!!!!Agent",
        "_id": "453afcc8-77bb-4baf-b247-c6b54158f684"
      },
      {
        "c": "2023-11-22T11:54:52.106702",
        "name": "!!!!*Touch Me Not*!!!!",
        "_id": "4c5341b0-9d3d-4df9-bcab-7c6e8e6905a7"
      },
      {
        "c": "2023-12-08T04:25:34.399077",
        "name": "!!!ACWTEST",
        "_id": "ac747f01-af21-455c-adf4-6cae99c74e8b"
      },
    ],
    "total": 830,
    "count": 830
  };

  updateGlobalPerformanceCount(count: any): void {
    // const prevCount = this.currentPerformanceCount;
    // const titleName = 'Confirmation';
    // const message =
    //     `Are you sure you want to update, global performance count from  ${prevCount} to ${count}?`;
    // const cancelText = 'No';
    // const acceptText = 'Yes';
    // const companies: any = [];
    // this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    // this.confirmDialog.dialogResult.subscribe(res => {
    //   if (res) {
    //     const url: any = `/api/manageagentupdates/global_agentperformancecount`;
    //     const param: any = {maxThreadCount: count};
    //     this.loaderService.display(true);
    //     this.baseService.doRequest(url, 'post', param).subscribe((result: any) => {
    //       this.loaderService.display(false);
    //       if (result[0]) {
    //         this.toast.sToast('success', 'Updated successfully');
    //         setTimeout(() => {
    //           this.getAllCompany();
    //         }, 3000);
    //       } else {
    //        this.toast.sToast('error', result[1]);
    //       }
    //     });
    //   } else {
    //     this.globalperformanceCount = prevCount;
    //   }
    // });
  }


  updateComapanyPerformanceCount(company?: any) {
    // const titleName = 'Confirmation';
    // const message = (company)
    //     ? `Are you sure you want to update, ${company.name} Performance count ${this.companyperformanceCount}?`
    //     : `Are you sure you want to update, all the selected company(ies) Performance Count  ${this.companyperformanceCount}?`;
    // const cancelText = 'No';
    // const acceptText = 'Yes';
    // this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    // this.confirmDialog.dialogResult.subscribe(res => {
    //   if (res) {
    //     const companies: any = [];
    //     if (company) {
    //       company.maxThreadCount =  this.companyperformanceCount;
    //       companies.push(company);
    //     } else {
    //       this.selectedCompanies.forEach((obj: any) => {
    //         obj.maxThreadCount =  this.companyperformanceCount;
    //         companies.push(obj);
    //       });
    //     }
    //     this.loaderService.display(true);
    //     this.baseService.doRequest(`/api/manageagentupdates/companymanageagentcountupdates`,
    //         'post', companies).subscribe((result: any) => {
    //           this.loaderService.display(false);
    //           if (result[0]) {
    //             this.toast.sToast('success', 'Updated successfully');
    //             if (this.cmpSelected) {
    //               this.closePerformanceModal();
    //             }
    //             setTimeout(() => {
    //               this.getAllCompany();
    //             }, 3000);
    //           } else {
    //            this.toast.sToast('error', result[1]);
    //           }
    //     });
    //   }
    // });
  }

  clearSelection(): void {
    this.selection.clear();
    // @ts-ignore
    this.numSelected = undefined;
  }

  isAllSelected(): any {
    this.numSelected = this.selection.selected.length;
    const numRows = this.allCompanies.data.length;
    console.log("numSelected---->", this.numSelected);
    return this.numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.allCompanies.data.forEach(row => this.selection.select(row));
    console.log("selected companies--->", this.allCompanies.data);
  }

  rowToggle(row: any): void {
    this.selection.toggle(row);
    console.log("row--->", this.selection);
  }
}

import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';

@Component({
    selector: 'show-more-less',
    standalone: true,
    imports: [CommonModule, AppFilterPipeModule],
    templateUrl: './show-more-less.component.html',
    styleUrls: ['./show-more-less.component.scss']
})
export class ShowMoreLessComponent {
    @Input() items: string[] = [];
    @Input() maxItemsToShow = 3; // Adjust this value as needed
    @Input() showAllItems = false;
    @Input() showOrder: 'list' | 'span' = 'span';
    @Input() bgColor: string = 'bg-yellow-200';
    @Input() color: string = 'text-blue-900';
    @Input() searchInput: string = '';
    @Input() type: 'list' | 'string' = 'list';
    @Input() inputString: string = '';
    darkBg = '';
    darkColor = '';
    last_index = 100;
    counter = 100;
    firstCount = 100;
    showTxt = 'Show More';

    constructor() {
        this.darkBg = 'dark:' + this.bgColor;
        this.darkColor = 'dark:' + this.color;
    }

    ngOnInit() {
        if (this.type === 'string') {
            this.last_index = this.inputString.substring(0, 100).lastIndexOf(' ');
            if (this.last_index > 100) { this.last_index = 100; }
            this.counter = this.last_index;
        }

    }

    toggleShowItems() {
        this.showAllItems = !this.showAllItems;
    }



    toggleSkil() {
        if (this.counter < 101) {
            this.counter = this.inputString.length;
            this.showTxt = 'Show less';
        } else {
            this.counter = this.last_index;
            this.showTxt = 'Show More';
        }
    }

    clickOnDomain(url: any): void {
        url = (url.startsWith('http://') || url.startsWith('https://')) ? url : `https://${url}`;
        window.open(url, '_blank');
      }
}

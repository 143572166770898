<div class="flex flex-col w-full min-w-0 sm:absolute sm:inset-0 sm:overflow-hidden">
    <mat-drawer-container class="flex-auto sm:h-full">
        <!-- Drawer sm:w-96 -->
        <mat-drawer class="dark:bg-gray-900" fuseScrollbar [ngClass]="{'w-full lg:w-1/2': drawerOpened}"
            [autoFocus]="false" [position]="drawerPosition" [mode]="drawerMode" [opened]="drawerOpened" #drawer>
            <!-- Main -->
            <ng-container *ngIf="currentData && selectedType ==='user'">
                <div class="p-4 bg-default">
                    <div class="flex flex-row items-center">
                        <div class="flex flex-col">
                            <div class="text-2xl font-semibold tracking-tight">{{currentData.name}}</div>
                            <div class="text-base font-semibold tracking-tight text-secondary">
                                {{currentData.email_address}}
                            </div>
                        </div>
                        <button class="ml-auto" mat-icon-button (click)="drawer.toggle();">
                            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                        </button>
                    </div>
                    <mat-divider class="my-4"></mat-divider>
                    <div class="flex flex-col items-end" fuseScrollbar>
                        <mat-form-field appearance="fill" class="w-1/2">
                            <mat-label>Search</mat-label>
                            <input type="text" matInput name="Search" [(ngModel)]="searchFilter">
                        </mat-form-field>
                    </div>
                    <mat-tab-group mat-stretch-tabs="false" [animationDuration]="'0'"
                        (selectedIndexChange)="searchFilter=''">
                        <mat-tab label="AssignedPlans">
                            <ng-template mat-tab-label>
                                <span id="AssignedPlans"> Assigned Plans </span>
                                <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                    {{userLicense.assigned_plans.length || 0}} </span>
                            </ng-template>
                            <ng-template matTabContent>
                                <div class="max-w-full prose prose-sm mt-4 bg-card px-6 py-2 rounded-xl" fuseScrollbar>
                                    <table class="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th class="dark:text-white">Service</th>
                                                <th class="dark:text-white">Capability Status</th>
                                                <th class="dark:text-white">Service Plan ID</th>
                                                <th class="dark:text-white">Assigned Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container
                                                *ngFor="let plan of userLicense.assigned_plans | search: searchFilter;">
                                                <tr>
                                                    <td>{{plan.service}}</td>
                                                    <td>
                                                        <span
                                                            class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                                                            [ngClass]="{'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': plan.capability_status === 'Disabled',
                                                    'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': plan.capability_status === 'Enabled'}">
                                                            <span
                                                                class="leading-relaxed whitespace-nowrap">{{plan.capability_status}}</span>
                                                        </span>
                                                    </td>
                                                    <td>{{plan.service_plan_id}}</td>
                                                    <td>{{plan.assigned_date_time | utcToLocale}}</td>
                                                </tr>
                                            </ng-container>
                                            <div class="ml-6 mt-6"
                                                *ngIf="!userLicense.assigned_plans || !userLicense.assigned_plans.length">
                                                <div> No data available!</div>
                                            </div>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="ProvisionedPlans">
                            <ng-template mat-tab-label>
                                <span id="ProvisionedPlans"> Provisioned Plans </span>
                                <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                    {{userLicense.provisioned_plans.length|| 0}} </span>
                            </ng-template>
                            <ng-template matTabContent>
                                <div class="max-w-full prose prose-sm mt-4 bg-card px-6 py-2 rounded-xl" fuseScrollbar>
                                    <table class="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th class="dark:text-white">Service</th>
                                                <th class="dark:text-white">Capability Status</th>
                                                <th class="dark:text-white">Provisioning Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container
                                                *ngFor="let plan of userLicense.provisioned_plans | search: searchFilter;">
                                                <tr>
                                                    <td>{{plan.service}}</td>
                                                    <td>
                                                        <span
                                                            class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                                                            [ngClass]="{'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': plan.capability_status === 'Disabled',
                                                    'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': plan.capability_status === 'Enabled'}">
                                                            <span
                                                                class="leading-relaxed whitespace-nowrap">{{plan.capability_status}}</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span
                                                            class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                                                            [ngClass]="{'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': plan.provisioning_status !== 'Success',
                                                    'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': plan.provisioning_status === 'Success'}">
                                                            <span
                                                                class="leading-relaxed whitespace-nowrap">{{plan.provisioning_status}}</span>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <div class="ml-6 mt-6"
                                                *ngIf="!userLicense.provisioned_plans || !userLicense.provisioned_plans.length">
                                                <div> No data available!</div>
                                            </div>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="AssignedLicenses">
                            <ng-template mat-tab-label>
                                <span id="AssignedLicenses"> Assigned Licenses </span>
                                <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                    {{userLicense.assigned_licenses.length|| 0}} </span>
                            </ng-template>
                            <ng-template matTabContent>
                                <div class="py-2 grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2" fuseScrollbar>
                                    <div class="flex flex-row items-center bg-card rounded"
                                        *ngFor="let plan of userLicense.assigned_licenses | search: searchFilter">
                                        <div class="flex flex-wrap px-3">
                                            <i class="fas fa-user-shield text-2xl"></i>
                                        </div>
                                        <div class="flex flex-col w-full  px-2 py-2">
                                            <div class="flex flex-row justify-between flex-wrap">
                                                <div class="text-sm font-semibold" matTooltip="License Friendly Name">
                                                    {{plan.license_friendly_name}}</div>
                                            </div>
                                            <div class="flex flex-wrap items-center text-xs font-mono w-full break-all"
                                                matTooltip="SKU ID">
                                                {{plan.sku_id}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ml-6 mt-6"
                                        *ngIf="!userLicense.assigned_licenses || !userLicense.assigned_licenses.length">
                                        <div> No data available!</div>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>

            </ng-container>
            <ng-container *ngIf="currentData && selectedType ==='logs'">
                <div class="p-4 bg-default">
                    <div class="flex flex-row items-center">
                        <div class="text-2xl font-semibold tracking-tight mr-1">{{currentData.activity_display_name}}
                        </div>
                        <span
                            class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                            [ngClass]="{'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': currentData.result.toLowerCase() !== 'success',
                                        'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': currentData.result.toLowerCase() === 'success'}">
                            <span class="leading-relaxed whitespace-nowrap">{{currentData.result}}</span>
                        </span>
                        <button class="ml-auto" mat-icon-button (click)="drawer.toggle();">
                            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                        </button>
                    </div>
                    <mat-divider class="my-4"></mat-divider>
                    <mat-tab-group mat-stretch-tabs="false" [animationDuration]="'0'">
                        <mat-tab label="TargetResources">
                            <ng-template mat-tab-label>
                                <span id="TargetResources"> Target Resources
                                    <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                        {{currentData.targetResources.length|| 0}} </span>
                                </span>
                            </ng-template>
                            <ng-template matTabContent>
                                <div class="max-w-full prose prose-sm mt-4 bg-card px-6 py-2 rounded-xl" fuseScrollbar>
                                    <table class="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th class="dark:text-white">Display Name</th>
                                                <th class="dark:text-white">New Value</th>
                                                <th class="dark:text-white">Old Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container
                                                *ngFor="let plan of currentData.targetResources;">
                                                <tr>
                                                    <td>{{plan.displayName}}</td>
                                                    <td>{{plan.newValue}}</td>
                                                    <td>{{plan.oldValue}}</td>
                                                </tr>
                                            </ng-container>
                                            <div class="ml-6 mt-6"
                                                *ngIf="!currentData.targetResources || !currentData.targetResources.length">
                                                <div> No data available!</div>
                                            </div>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="AdditionalDetails">
                            <ng-template mat-tab-label>
                                <span id="AdditionalDetails"> Additional Details
                                    <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                        {{currentData.additional_details.length|| 0}} </span>
                                </span>
                            </ng-template>
                            <ng-template matTabContent>
                                <div class="flex-auto max-w-full p-2 prose prose-sm">
                                    <ng-container
                                        *ngFor="let item of currentData.additional_details;">
                                        <div class="bg-card rounded shadow mt-4">
                                            <div class="px-6 py-3 font-mono text-secondary border-b">
                                                {{item.key}}
                                            </div>
                                            <div class="p-6">
                                                {{item.value}}
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div class="ml-6 mt-6"
                                        *ngIf="!currentData.additional_details || !currentData.additional_details.length">
                                        <div> No data available!</div>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </ng-container>
            <ng-container *ngIf="currentData && selectedType ==='groups'">
                <div class="p-4 bg-default">
                    <div class="flex flex-row items-center">
                        <div class="text-2xl font-semibold tracking-tight">
                            <div class="flex flex-col">
                                <div class="text-2xl font-semibold tracking-tight">{{currentData.name}}
                                    <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                        {{groupMembers.length|| 0}} </span>
                                </div></div>
                                <div class="text-base font-semibold tracking-tight text-secondary">
                                    {{currentData.description}}
                                </div>
                            </div>

                        <button class="ml-auto" mat-icon-button (click)="drawer.toggle();">
                            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                        </button>
                    </div>
                    <mat-divider class="my-4"></mat-divider>
                    <div class="flex flex-col items-end" fuseScrollbar>
                        <mat-form-field appearance="fill" class="w-1/2">
                            <mat-label>Search</mat-label>
                            <input type="text" matInput name="Search" [(ngModel)]="searchFilter">
                        </mat-form-field>
                    </div>
                    <div class="max-w-full prose prose-sm mt-4 bg-card px-6 py-2 rounded-xl" fuseScrollbar>
                        <h3>Member Roles</h3>
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th class="dark:text-white">Display Name</th>
                                    <th class="dark:text-white">Given Name</th>
                                    <th class="dark:text-white">E-mail</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let plan of groupMembers|search:searchFilter;">
                                    <tr>
                                        <td>{{plan.display_name}}</td>
                                        <td>{{plan.name}}</td>
                                        <td>{{plan.email_address}}</td>
                                    </tr>
                                </ng-container>
                                <div class="ml-6 mt-6" *ngIf="!groupMembers || !groupMembers.length">
                                    <div> No data available!</div>
                                </div>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="currentData && selectedType ==='roles'">
                <div class="p-4 bg-default">
                    <div class="flex flex-row items-center">
                        <div class="text-2xl font-semibold tracking-tight">{{currentData.display_name}}
                            <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                {{roleMembers.length|| 0}} </span>
                        </div>
                        <button class="ml-auto" mat-icon-button (click)="drawer.toggle();">
                            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                        </button>
                    </div>
                    <mat-divider class="my-4"></mat-divider>
                    <div class="flex flex-col items-end" fuseScrollbar>
                        <mat-form-field appearance="fill" class="w-1/2">
                            <mat-label>Search</mat-label>
                            <input type="text" matInput name="Search" [(ngModel)]="searchFilter">
                        </mat-form-field>
                    </div>
                    <div class="max-w-full prose prose-sm mt-4 bg-card px-6 py-2 rounded-xl" fuseScrollbar>
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th class="dark:text-white">Display Name</th>
                                    <th class="dark:text-white">Given Name</th>
                                    <th class="dark:text-white">E-mail</th>
                                    <th class="dark:text-white">ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container
                                    *ngFor="let plan of roleMembers| search: searchFilter;">
                                    <tr>
                                        <td>{{plan.user_display_name}}</td>
                                        <td>{{plan.user_name}}</td>
                                        <td>{{plan.user_email_address}}</td>
                                        <td>{{plan.user_id}}</td>
                                    </tr>
                                </ng-container>
                                <div class="ml-6 mt-6" *ngIf="!roleMembers || !roleMembers.length">
                                    <div> No data available!</div>
                                </div>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
        </mat-drawer>
        <!-- Drawer content -->
        <mat-drawer-content class="flex flex-col " fuseScrollbar>
            <div class="flex flex-col w-[94vw]">
                <app-skeleton *ngIf="noCmpMapped === undefined"></app-skeleton>
                <fuse-alert class="w-full p-8" [appearance]="'border'" [type]="'info'"
                    *ngIf="noCmpMapped && noCmpMapped !== undefined">
                    <span fuseAlertTitle>This company is not integrated and mapped with the Azure Active Directory
                        Integration.</span>
                </fuse-alert>
                <ng-container *ngIf="isVisible && company && company.id && !noCmpMapped && noCmpMapped !== undefined">
                    <div class="mt-2 grid  grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4 md:gap-8 lg:mt-16 px-4">
                        <ng-container *ngFor="let d of pillCards">
                            <div class="relative overflow-hidden rounded-xl shadow border-t-4  bg-card"
                                [ngClass]="d.border">
                                <div class="py-6 px-6">
                                    <div class="flex flex-row"><span><i class="fas text-2xl text-secondary ml-2"
                                                [ngClass]="d.icon"></i></span>
                                        <div class="text-lg font-bold tracking-tight leading-6 ml-2 mb-4 truncate">
                                            {{d.title}}
                                        </div>
                                    </div>
                                    <div class="flex items-center">
                                        <h3 class="relative ml-2 inline-block text-2xl font-medium leading-none ml-1 p-2 bg-blue-400 text-black rounded">
                                            {{d.count| shortNumber}}
                                        </h3>
                                        <div class="flex flex-col w-full">
                                            <ng-container *ngFor="let item of d.data">
                                                <div class="flex items-center justify-between ml-3 mb-1">
                                                    <div class="text-base font-medium capitalize" [matTooltip]="">
                                                        {{item.name | camelToHuman:true}}</div>
                                                    <span class="ml-1 px-2 ring-1 test-white rounded py-0.5 text-sm min-w-[42px] max-w-[42px]">
                                                        {{item.value| shortNumber}}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="px-4 mt-4">
                        <div class="text-xl font-extrabold tracking-tight leading-none">Basic Information</div>
                        <div class="mt-2 grid  grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4 md:gap-8 lg:mt-4">
                            <ng-container *ngFor="let info of basic_information;">
                                <div class="relative flex bg-card p-2 flex-auto rounded-lg">
                                    <ng-container>
                                        <div
                                            class="flex shrink-0 items-center justify-center w-10 h-10 mr-4 rounded-full bg-gray-400">
                                            <mat-icon class="icon-size-5 text-white" [svgIcon]="info.icon">
                                            </mat-icon>
                                        </div>
                                    </ng-container>
                                    <div class="flex flex-col flex-auto items-start">
                                        <ng-container>
                                            <div class="text-lg font-bold tracking-tight leading-6">{{info.key}}</div>
                                        </ng-container>
                                        <div class="flex flex-col sm:flex-row sm:items-center mt-2 sm:mt-1 sm:space-x-2 text-md leading-5"
                                            *ngIf="!info.isBolean">
                                            <div class="text-secondary">
                                                {{info.value}}
                                            </div>
                                        </div>
                                        <ng-container *ngIf="info.isBolean">
                                            <span
                                                class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                                                [ngClass]="{'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': info.value.toLowerCase() === 'no',
                                        'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': info.value.toLowerCase() === 'yes'}">
                                                <span class="leading-relaxed whitespace-nowrap">{{info.value}}</span>
                                            </span>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <mat-divider class="my-6"></mat-divider>
                    <div class="flex flex-row px-6 pt-4 gap-2 items-center justify-start">
                        <div class="px-2 py-1 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                            (click)="changeTab('users')" [ngClass]="{'bg-primary text-white': cView === 'users'}">Users</div>
                        <div class="px-2 py-1 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                            (click)="changeTab('computers')" [ngClass]="{'bg-primary text-white': cView === 'computers'}">Computers
                        </div>
                        <div class="px-2 py-1 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                            (click)="changeTab('groups')" [ngClass]="{'bg-primary text-white': cView === 'groups'}">Groups</div>
                        <div class="px-2 py-1 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                            (click)="changeTab('license')" [ngClass]="{'bg-primary text-white': cView === 'license'}">License
                         </div>
                        <div class="px-2 py-1 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                            (click)="changeTab('logs')" [ngClass]="{'bg-primary text-white': cView === 'logs'}">Logs
                         </div>
                        <div class="px-2 py-1 rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
                            (click)="changeTab('roles')" [ngClass]="{'bg-primary text-white': cView === 'roles'}">Roles
                         </div>
                    </div>
                    <stable [sTableOptions]="userTableOptions" *ngIf="cView === 'users'" (hyperlinkCallback)="linkCall($event)"></stable>
                    <stable [sTableOptions]="compuTableOptions" *ngIf="cView === 'computers'" (hyperlinkCallback)="linkCall($event)"></stable>
                    <stable [sTableOptions]="grouTableOptions" *ngIf="cView === 'groups'" (hyperlinkCallback)="linkCall($event)"></stable>
                    <stable [sTableOptions]="licenseTableOptions" *ngIf="cView === 'license'" (hyperlinkCallback)="linkCall($event)"></stable>
                    <stable [sTableOptions]="logsTableOptions" *ngIf="cView === 'logs'" (hyperlinkCallback)="linkCall($event)"></stable>
                    <stable [sTableOptions]="roleTableOptions" *ngIf="cView === 'roles'" (hyperlinkCallback)="linkCall($event)"></stable>
                </ng-container>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>

import {ChangeDetectionStrategy, ChangeDetectorRef, Component,
    OnInit, OnDestroy, ViewEncapsulation} from '@angular/core';
import {CommonModule, NgTemplateOutlet} from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from "@angular/material/icon";
import {Subject, takeUntil} from "rxjs";
import {FuseConfig, FuseConfigService, Scheme} from "../../services/config";
import {CommonService} from "../../../app/_services/common.service";
import {BaseRequestService} from "../../../app/_services/base.service";
import {MyToastrService} from "../../../app/_services/toastr.service";

@Component({
  selector: 'app-theme-switch',
  encapsulation  : ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs       : 'fuseThemeSwitch',
  standalone: true,
  imports: [CommonModule,MatButtonModule, MatTooltipModule, NgTemplateOutlet, MatIconModule],
  templateUrl: './theme-switch.component.html',
  styleUrls: ['./theme-switch.component.scss']
})
export class ThemeSwitchComponent implements OnInit, OnDestroy {
    config: FuseConfig;
    tooltip: string;
    scheme: 'dark' | 'light' | 'auto';
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _fuseConfigService: FuseConfigService,
        private cs: CommonService,
        private _bs: BaseRequestService,
        private _changeDetectorRef: ChangeDetectorRef,
        public toast: MyToastrService,
    )
    {
    }
    ngOnInit() {
        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: FuseConfig) => {
                // Store the config
                this.config = config;
                this.scheme = config.scheme;
                if (config.scheme === 'light') {
                    this.tooltip =  'Toggle Dark Theme';
                } else {
                    this.tooltip =  'Toggle Light Theme';
                }
            });
        if (this.cs.sConfig && !this.cs.sConfig.id) {
            setTimeout(() => this.getLayoutSettings(), 2000);
        }
    }

    setScheme(scheme: Scheme): void {
        this._fuseConfigService.config = { scheme };
        this.scheme = scheme;
        if (scheme === 'light') {
            this.cs.logo = '/assets/images/logo/cs_logo_light.png';
            this.tooltip =  'Toggle Dark Theme';
        } else {
            this.cs.logo = '/assets/images/logo/cs_logo_dark.png';
            this.tooltip =  'Toggle Light Theme';
        }
    }

    getLayoutSettings(): void {
        if (!this._bs.user().email) {
            setTimeout(() => this.getLayoutSettings(), 1000);
            return;
        }
        const params: any = {
            condition: `category='settings' and value IS NOT NULL and sub_category='layout' and name='${this._bs.user().email}'`,
            order_by: `updated desc`
        }
        this._bs.doRequest(`/r/company/settings`, 'get', null, params)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => {
            if (result.status && result.data && result.data.length) {
                this.config = result.data[0].value;
                this.cs.sConfig = result.data[0];
                this.setScheme(this.config.scheme);
                this._changeDetectorRef.detectChanges();
            }
        });
    }

    toggleTheme() {
        if (this.scheme === 'light') {
            this.setScheme('dark');
        } else {
            this.setScheme('light');
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}

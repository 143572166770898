import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AsyncPipe, CommonModule, NgFor, NgForOf, NgIf } from '@angular/common';
import { FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { DirectivesModule } from 'app/-directives/-directives.module';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { MaterialModule } from 'app/material.module';
import { DynamicFormComponent } from 'app/modules/shared/forms/dynamic-form/dynamic-form.component';
import { ModalComponent } from 'app/modules/shared/modal.component';
import { TableComponent } from 'app/modules/shared/table/table.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectChange } from '@angular/material/select';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { ModalService } from 'app/_services/modal.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { Subject, ReplaySubject, takeUntil, debounceTime, map, filter } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-psa-company-mapping',
  animations: fuseAnimations,
  standalone: true,
  imports: [CommonModule, MaterialModule, TableComponent, FormsModule, NgFor, NgIf, NgForOf,
    ReactiveFormsModule, AppFilterPipeModule, FuseAlertComponent, AsyncPipe, MatSidenavModule,
    MatFormFieldModule, ModalComponent, DynamicFormComponent,
    NgxMatSelectSearchModule, DirectivesModule,
    MatInputModule,
    MatButtonModule],
  templateUrl: './psa-company-mapping.component.html',
  styleUrls: ['./psa-company-mapping.component.scss']
})
export class PsaCompanyMappingComponent implements OnInit, OnDestroy {
  @Input() currentIntegration: any;
  @Input() integrationCred: any = [];
  selectedCred: any = null;
  mappedComTableOptions: any = {};
  cView: any = 'table';
  aView: any = 'type';
  selectedType: any = '';
  destCompany: any = [];
  sourceCompany: any = [];
  mapCompanyList: any = [];
  public searching = false;
  protected onDestroySearch = new Subject<void>();

  public filteredSourceCompany: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  searchSourceControl: UntypedFormControl = new UntypedFormControl();
  searchDestControl: UntypedFormControl = new UntypedFormControl();
  searchAgentControl: UntypedFormControl = new UntypedFormControl();
  searchSourceImpControl: UntypedFormControl = new UntypedFormControl();
  comMapForm: UntypedFormGroup;
  comMapImpForm: any = {
    company_id: []
  };
  @ViewChild('comMapNgForm') comMapNgForm: NgForm;

  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };
  showAlert: boolean = false;
  isConstentProvided: boolean = false;
  importProvideConsent: any = {};
  getCount = 0;

  editMapForm: UntypedFormGroup;
  @ViewChild('editMapNgForm') editMapNgForm: NgForm;
  updateCompany: any = {};

  editDestControl: UntypedFormControl = new UntypedFormControl();
  edit_update_company: any = {};


  companyActionParams: any = {};
  companyParams: any = {};
  companyFormElements: any = [];
  integrationProfile: any = [];
  integrationSite: any = [];
  selectedImportcompany: any = [];
  allComp: any = [];
  allDestComp: any = [];
  agentList: any = [];
  /**
     * Constructor
     */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef, private _ls: LoaderService,
    private _bs: BaseRequestService, public _cs: CommonService,
    private toast: MyToastrService, private _formBuilder: UntypedFormBuilder,
    public _mS: ModalService, public confirmDialog: FuseConfirmationService
  ) {
  }

  ngOnInit(): void {
    if (this.integrationCred && this.integrationCred.length) {
      this.selectedCred = this.integrationCred[0].id;
      this.initMappedCompany(this.integrationCred[0].id);
    }

    this.comMapImpForm = this._formBuilder.group({
      company_id: this._formBuilder.array([]),
      sites: [{}],
    }
    );

    this.comMapForm = this._formBuilder.group({
      company_id: [{}, Validators.required],
      dest_company_id: [{}, Validators.required],
      sites: [{}],
    }
    );

    const get_conpany = this.currentIntegration.actions.filter((x: any) => x.name === 'get_companies');
    if (get_conpany && get_conpany.length) {
      this.companyActionParams = get_conpany[0];
    }

    this.searchSourceControl.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(this._unsubscribeAll),
        map((value) => {
          return value;
        }),
        filter(value => value && value.length >= 3),
      )
      .subscribe((value) => {
        this.filterCompanies();
      })
    this.searchDestControl.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(this._unsubscribeAll),
        map((value) => {
          return value;
        }),
        filter(value => value && value.length >= 3),
      )
      .subscribe((value) => {
        this.filterDestCompanies();
      })
  }

  ngOnDestroy(): void {
    this.onDestroySearch.next();
    this.onDestroySearch.complete();
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  getCompanies(search?: string): void {
    if (
      !this._bs.user() ||
      !this._bs.user().email
    ) {
      setTimeout(() => {
        this.getCompanies(search);
      }, 1000);
      return;
    }
    this.searching = true;
    const condition = search
      ? { condition: "name ilike '%" + search + "%'" }
      : {
        condition: true,
        skip: 0,
        limit: 100,
        order_by: 'created desc'
      };
    this._bs
      .doRequest('/r/company/companies', 'get', null, condition)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (result.data.length) {
          this.searching = false;
          result.data.sort((a: any, b: any) => {
            const c = a.name ? a.name.toLowerCase() : "";
            const d = b.name ? b.name.toLowerCase() : "";
            if (c < d) {
              return -1;
            } else if (c > d) {
              return 1;
            } else {
              return 0;
            }
          });
          if (!search) {
            this.allComp = result.data;
          } else {
            this.allComp = Array.from(new Map([...this.allComp, ...result.data].map(obj => [obj.id, obj])).values());
          }
          this.sourceCompany = result.data.slice();
        } else {
          this.sourceCompany = [];
        }
      }, (error: any) => {
        this.searching = false;
      }
      );
  }


  initMappedCompany($event: any): void {
    this.mappedComTableOptions = {
      columns: [
        {
          "header": "Source Company Name",
          "columnDef": "source_company_name",
          "cType": "string",
          "filter": "",
          "cell": "(element: any) => `${element.source_company_name}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },

        {
          "header": "Destination Company Name",
          "columnDef": "dest_company_name",
          "filter": "",
          "cell": "(element: any) => `${element.dest_company_name}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
      ],
      sortOptions: { active: 'updated', direction: 'asc' },
      _pageData: [],
      tableOptions: {
        title: 'Mapped Company',
        isServerSide: false,
        selectText: 'MappedCompany',
        loading: false,
        floatingFilter: true,
        rowSelection: false,
        showAction: true,
        actionMenuItems: [
          {
            text: 'Edit',
            id: 'edit',
            icon: 'edit',
            callback: 'editFu',
            isGlobal: false,
          },
          {
            text: 'Delete',
            id: 'delete',
            icon: 'delete',
            callback: 'editFu',
            isGlobal: false,
          }
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 5,
        search: false,
        showhideList: true,
        refreshData: true,
        showFilter: true,
        showTagFilter: false,
        exportExcel: true,
        add: true,
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        id: 'MappedCompany',
        serverSide: {
          condition: "integration_name='" + this.currentIntegration.name + "'" + ' and ' + "credential_id='" + $event + "'",
          url: '/r/integration/company_mappings',
          type: 'get',
          isGlobal: true,
          params: {}
        },
      },
      changeValue: new Subject<any>(),
    }
    /*if (this.currentIntegration.name !== 'AzureADNonCSP') {
      const isCmp = this.mappedComTableOptions.tableOptions.actionMenuItems.filter(
        (x: any) => x.text === 'Edit'
      );
      if (!isCmp || !isCmp.length) {
        this.mappedComTableOptions.tableOptions.actionMenuItems.unshift(

        )
      }
    }*/
    // setTimeout(() => this.mappedComTableOptions.changeValue.next(true));
  }


  /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  /**
     * Filter by category
     *
     * @param change
     */
  filterByCred(change: MatSelectChange): void {
    const data = Object.assign({}, this.mappedComTableOptions);
    this.mappedComTableOptions = {}; this._changeDetectorRef.detectChanges();
    data.pageData = []; data.tableOptions.pageTotal = 0;
    data.tableOptions.serverSide.condition = "integration_name='" + this.currentIntegration.name + "'" + ' and ' + "credential_id='" + change.value + "'";
    this.mappedComTableOptions = data; this._changeDetectorRef.detectChanges();
  }

  deleteRecord(data: any) {
    const confirmation = this.confirmDialog.open({
      title: 'Confirmation',
      message: `Are you sure you want to delete ?`,
      actions: {
        confirm: {
          label: 'Yes'
        }
      }
    });
    confirmation.afterClosed().subscribe((result: any) => {
      if (result === 'confirmed') {
        if (this.currentIntegration.rmm_action) {
          const params: any = {
            integration_id: this.selectedCred,
            integration_name: this.currentIntegration.name,
            action_name: this.currentIntegration.rmm_action,
            requestparams: {
              companyid: parseInt(data.company_id),
              dest_company_id: data.dest_company_id,
              action: "delete"
            }
          };
          this._ls.display(true);
          this._cs.executeAction(params).then((item: any) => {
            if (item.status) {
              this._bs.doRequest(`/d/integration/company_mappings/${data.id}`, 'delete')
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                  if (result.status) {
                    this.toast.sToast('success', 'Removed successfully');
                    if (this.currentIntegration.integrationtype === "azure ad") {
                      this.deleteAzureScheduler(data.company_id);
                    }
                    if (this.currentIntegration.name === "Meraki") {
                      this.syncFirewall({ company_id: data.company_id, site_id: data.site_id });
                    }
                    setTimeout(() => {
                      this.mappedComTableOptions.changeValue.next(true);
                    }, 3000);
                  } else {
                    const data = (result.message) ? result.message : result.data;
                    this.toast.sToast('error', data);
                  }
                });
            }
          });
        } else {
          this._bs.doRequest(`/d/integration/company_mappings/${data.id}`, 'delete')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
              if (result.status) {
                this.toast.sToast('success', 'Removed successfully');
                if (this.currentIntegration.integrationtype === "azure ad") {
                  this.deleteAzureScheduler(data.company_id);
                }
                if (this.currentIntegration.name === "Meraki") {
                  this.syncFirewall({ company_id: data.company_id, site_id: data.site_id });
                }
                setTimeout(() => {
                  this.mappedComTableOptions.changeValue.next(true);
                }, 3000);
              } else {
                const data = (result.message) ? result.message : result.data;
                this.toast.sToast('error', data);
              }
            });
        }

      }
    })
  }

  addTableData(): void {
    this.mapCompanyList = [];
    if (this.currentIntegration.import_companies) {
      this.selectedType = ''; this.aView = 'type';
    } else {
      this.selectedType = 'exist'; this.aView = 'exist'; this.getCompanies();
    }
    this.comMapImpForm = {
      company_id: [],
      sites: [{}],
    };
    this.cView = 'add';
    this.companyFormElements = this._cs.processFormElements(this.companyActionParams.parameters);
    this.companyFormElements.map((x: any) => { (x.name) ? this.companyParams[x.name] = x.required ? '' : x.default : null; })
    this._changeDetectorRef.detectChanges();

  }

  actionCall($event: any): void {
    if ($event.action.text == 'Edit') {
      this.updateCompany = $event.row;
      if (this.currentIntegration.import_companies) {
        const params = {
          integration_id: this.selectedCred,
          integration_name: this.currentIntegration.name,
          action_name: 'get_companies',
          requestparams: {}
        }
        this._ls.display(true);
        this._cs.executeAction(params).then((item: any) => {
          this._ls.display(false);
          if (item.status) {
            this.destCompany = item.data;
            this.searching = false;
            const com = this.destCompany.filter((x: any) => parseInt(x.id) === parseInt($event.row.dest_company_id));
            this.edit_update_company = com[0];
            if (this.currentIntegration.import_sites) {
              this.updateCompany.site_id = (this.updateCompany.site_id) ? parseInt(this.updateCompany.site_id) : '';
              this.getIntegrationSite(com[0]);
            }
            if (this.currentIntegration.import_agents) {
              this.updateCompany.site_id = (this.updateCompany.site_id) ? parseInt(this.updateCompany.site_id) : '';
              this.getAgents({ id: $event.row.company_id });
            }
            this._mS.open('editNonPsaInt');
          }
        });
      } else {
        this._mS.open('editNonPsaInt');
      }
    }
    if ($event.action.text == 'Delete') {
      this.deleteRecord($event.row);
    }
  }

  getCompany($event?: any): void {
    if (this.selectedType === 'exist') { this.getCompanies(); };
    if (!this.currentIntegration.import_companies) { return; }
    const params: any = {
      integration_id: this.selectedCred,
      integration_name: this.currentIntegration.name,
      action_name: 'get_companies',
      requestparams: {}
    };
    (this.companyParams) ? params.requestparams = this.companyParams : {};
    this.comMapForm.reset();
    this._cs.executeAction(params).then((item: any) => {
      if (item.status) {
        if (item.status && item.data.length) {
          this.destCompany = item.data;
          this.comMapForm.get('dest_company_id').setValue(item.data[0]);
          if (this.currentIntegration.import_sites) {
            this.getIntegrationSite(item.data[0]);
          }
          if (this.currentIntegration.import_agents) {
            this.getAgents(item.data[0]);
          }
        } else {
          this.destCompany = [];
        }
      }
    });
  }

  getIntegrationSite($event: any): void {
    if (!this.currentIntegration.import_sites) {
      return;
    }
    const params: any = {
      integration_id: this.selectedCred,
      integration_name: this.currentIntegration.name,
      action_name: 'get_folders',
      requestparams: {
        company_id: $event.id
      }
    };
    this._ls.display(true);
    this._cs.executeAction(params).then((item: any) => {
      this._ls.display(false);
      if (item.status && item.data.length) {
        this.integrationSite = item.data;
      } else {
        this.integrationSite = [];
      }
    });
  }

  addToMapList(): void {
    const shortcut = this.comMapForm.value;
    this.alert = { type: 'error', message: '' };
    const srcCmp = this.mapCompanyList.filter((x: any) => x.company_id === shortcut.company_id.id);
    if (srcCmp.length) {
      this.alert.message = `${shortcut.company_id.name} company already added.`; this.showAlert = true;
      setTimeout(() => { this.showAlert = false; this.alert = null; }, 4000);
      return;
    }
    if (this.currentIntegration.import_companies) {
      const destCmp = this.mapCompanyList.filter((x: any) => x.dest_company_id === shortcut.dest_company_id.id);
      if (destCmp.length) {
        this.alert.message = `${shortcut.dest_company_id.name} company already added.`; this.showAlert = true;
        setTimeout(() => { this.showAlert = false; this.alert = null; }, 4000);
        return;
      }
    }
    let siteName: any; let siteId: any;
    if (this.currentIntegration.import_sites || this.currentIntegration.import_agents) {
      siteId = (shortcut.sites) ? shortcut.sites.id : null;
      siteName = (shortcut.sites) ? shortcut.sites.name : null;
    }
    const integrationData: any = { integration_name: this.currentIntegration.name, credential_id: this.selectedCred };
    this.mapCompanyList.push(
      {
        ...integrationData,
        ...{
          company_id: shortcut.company_id.id,
          source_company_name: shortcut.company_id.name,
          dest_company_id: (this.currentIntegration.import_companies) ? shortcut.dest_company_id.id : '',
          dest_company_name: (this.currentIntegration.import_companies) ? shortcut.dest_company_id.name : '*',
          site_id: (this.currentIntegration.import_sites || this.currentIntegration.import_agents) ? siteId : undefined,
          site_name: (this.currentIntegration.import_sites || this.currentIntegration.import_agents) ? siteName : undefined,
        }
      }
    );

    this.comMapNgForm.reset();
  }


  mapSelectedCompanies(): void {
    if (this.currentIntegration.rmm_action) {
      this.mapCompanyList.forEach((obj: any, index: number) => {
        const reqData: any = { data: obj };
        delete reqData.integartionProfileName;
        const params: any = {
          integration_id: this.selectedCred,
          integration_name: this.currentIntegration.name,
          action_name: this.currentIntegration.rmm_action,
          requestparams: {
            companyid: obj.company_id,
            dest_company_id: obj.dest_company_id,
            action: "create"
          }
        };
        this._ls.display(true);
        this._cs.executeAction(params).then((item: any) => {
          if (item.status) {
            this._bs.doRequest(`/w/integration/company_mappings`, 'post', reqData)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((result: any) => {
                if (result.status) {
                  if (this.currentIntegration.integrationtype === "azure ad") {
                    this.azureScheduler(parseInt(obj.company_id));
                  }
                  if (this.currentIntegration.name === "Meraki") {
                    this.syncFirewall(obj);
                  }
                  if (index === this.mapCompanyList.length - 1) {
                    this._ls.display(false);
                    this.cView = 'table';
                    this.initMappedCompany(this.selectedCred); this._changeDetectorRef.detectChanges();
                  }
                } else {
                  this._ls.display(false);
                  const data = (result.message) ? result.message : result.data;
                  this.toast.sToast('error', data);
                }
              });
          }
        })
      });
    } else {
      this.mapCompanyList.forEach((obj: any, index: number) => {
        const reqData: any = { data: obj };
        (this.currentIntegration.name === 'AzureADCSP') ? obj.consent_enabled = this.importProvideConsent[obj.dest_company_id] : null;
        delete reqData.integartionProfileName;
        this._ls.display(true);
        this._bs.doRequest(`/w/integration/company_mappings`, 'post', reqData)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            if (result.status) {
              if (this.currentIntegration.integrationtype === "azure ad") {
                this.azureScheduler(parseInt(obj.company_id));
              }
              if (this.currentIntegration.name === "Meraki") {
                this.syncFirewall(obj);
              }
              if (index === this.mapCompanyList.length - 1) {
                this._ls.display(false);
                this.cView = 'table';
                this.initMappedCompany(this.selectedCred); this._changeDetectorRef.detectChanges();
              }
            } else {
              this._ls.display(false);
              const data = (result.message) ? result.message : result.data;
              this.toast.sToast('error', data);
            }
          });
      });
    }

  }
  azureScheduler(id: any): void {
    const reqData =
    {
      schedule_type: "azure_ad_sync",
      settings: {},
      data: {
        company_id: id,
        credential_id: this.selectedCred,
        integration_name: this.currentIntegration.name,
      }
    }
    this._bs.doRequest(`w/integration/create_schedule`, 'post', reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => { });
  }
  syncFirewall(data: any): void {
    const reqData =
    {
      company_id: [parseInt(data.company_id)],
      agent_id: parseInt(data.site_id)
    }
    this._bs.doRequest(`w/company/sync_firewall`, 'post', reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => { });
  }
  deleteAzureScheduler(id: any): void {
    const reqData =
    {
      schedule_type: "azure_ad_sync",
      settings: {},
      data: {
        company_id: id,
        credential_id: this.selectedCred,
        integration_name: this.currentIntegration.name,
      }
    }
    this._bs.doRequest(`w/integration/remove_schedule`, 'post', reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => { });
  }
  updateComMapping(): void {
    const shortcut = this.edit_update_company;
    this.updateCompany.dest_company_id = shortcut.id; this.updateCompany.dest_company_name = shortcut.name;
    if ((this.currentIntegration.import_sites || this.currentIntegration.import_agents) && this.updateCompany.site_id) {
      const data = this.integrationSite.filter((x: any) => x.id === this.updateCompany.site_id);
      if (data && data.length) {
        this.updateCompany.site_name = data[0].name; this.updateCompany.site_id = data[0].id;
      }
    }
    delete this.updateCompany.hovered;
    const reqData = { data: this.updateCompany, id: this.updateCompany.id };
    if (this.currentIntegration.rmm_action) {
      const params: any = {
        integration_id: this.selectedCred,
        integration_name: this.currentIntegration.name,
        action_name: this.currentIntegration.rmm_action,
        requestparams: {
          companyid: parseInt(this.updateCompany.company_id),
          dest_company_id: this.updateCompany.dest_company_id,
          action: "update"
        }
      };
      this._ls.display(true);
      this._cs.executeAction(params).then((item: any) => {
        if (item.status) {
          this._ls.display(true);
          this._bs.doRequest(`/w/integration/company_mappings`, 'patch', reqData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
              this._ls.display(false);
              if (result.status) {
                this.toast.sToast('success', 'Updated successfully');
                this._mS.close('editNonPsaInt');
                if (this.currentIntegration.name === "Meraki") {
                  this.syncFirewall({ company_id: this.updateCompany.company_id, site_id: this.updateCompany.site_id });
                }
                this.initMappedCompany(this.selectedCred); this._changeDetectorRef.detectChanges();
              }
            });
        }
      })
    } else {
      this._ls.display(true);
      this._bs.doRequest(`/w/integration/company_mappings`, 'patch', reqData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => {
          this._ls.display(false);
          if (result.status) {
            this.toast.sToast('success', 'Updated successfully');
            this._mS.close('editNonPsaInt');
            if (this.currentIntegration.name === "Meraki") {
              this.syncFirewall({ company_id: this.updateCompany.company_id, site_id: this.updateCompany.site_id });
            }
            this.initMappedCompany(this.selectedCred); this._changeDetectorRef.detectChanges();
          }
        });
    }

  }

  updateSelected($event: any): void {
    // Reflect select deselect
    const newSelectedIds = $event.map((obj: any) => obj.id);
    for (let i = this.comMapImpForm.company_id.length - 1; i >= 0; i--) {
      const company = this.comMapImpForm.company_id[i];
      if (!newSelectedIds.includes(company.id)) {
        this.comMapImpForm.company_id.splice(i, 1);
        this.removeSelected(i);
      }
    }
    $event.forEach((obj: any, index: number) => {
      if (!this.comMapImpForm.company_id.some((x: any) => x.id === obj.id)) {
        this.comMapImpForm.company_id.push($event[index]);
        if (this.currentIntegration.import_sites) {
          obj.site_id = '';
          obj.integrationSites = [];
          this.getImportIntegrationSites($event[index], index);
        }
        if (this.currentIntegration.import_agents) {
          obj.site_id = '';
          obj.integrationSites = [];
          this.getImportAgents($event[index], index);
        }
      }
    });
  }

  getImportAgents(cmp: any, idx: any): void {
    this._ls.display(true, "Getting agent data...");
    const condition = {
      condition: `agent_type='PROBE' and is_deprecated=FALSE and company_id=${cmp.id}`,
      skip: 0,
      limit: 1000,
    };
    this._bs
      .doRequest("r/company/agents", "get", null, condition)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this._ls.display(false);
        if (result.status) {
          if (result.data && result.data.length) {
            result.data.map((x: any) => {
              x.name = (x.name) ? x.name : x.host_name;
            });
            this.comMapImpForm.company_id[idx].integrationSites = result.data;
          } else {
            this.comMapImpForm.company_id[idx].integrationSites = [];
          }
        } else {
          this.toast.sToast("error", result.message);
        }
      });
  }
  getImportIntegrationSites(cmp: any, idx: any): void {
    const params: any = {
      integration_id: this.selectedCred,
      integration_name: this.currentIntegration.name,
      action_name: 'get_folders',
      requestparams: {
        company_id: cmp.id
      }
    };
    this._ls.display(true);
    this._cs.executeAction(params).then((item: any) => {
      this._ls.display(false);
      if (item.status && item.data.length) {
        this.comMapImpForm.company_id[idx].integrationSites = item.data;
      } else {
        this.comMapImpForm.company_id[idx].integrationSites = [];
      }
    });
  }

  removeSelected(i: number): void {
    this.selectedImportcompany = this.selectedImportcompany.filter((obj: any) => obj.id !== this.comMapImpForm.company_id[i].id);
    this.comMapImpForm.company_id.splice(i, 1);
  }

  importSelectedCompanies(): void {
    this.comMapImpForm.company_id.forEach((obj: any, index: any) => {
      this._ls.display(true, `Importing ${obj.name}`);
      const reqData = { data: { name: obj.name, description: null } };
      this._bs.doRequest('/w/company/companies', 'post', reqData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => {
          if (result.status) {
            const site = (obj.integrationSites && obj.integrationSites.length) ? obj.integrationSites.filter((x: any) => parseInt(x.id) === parseInt(obj.site_id)) : [];
            const integrationData: any = { integration_name: this.currentIntegration.name, credential_id: this.selectedCred };
            const requestD: any = {
              data: {
                ...integrationData,
                ...{
                  company_id: parseInt(result.id),
                  source_company_name: obj.name,
                  dest_company_id: obj.id,
                  dest_company_name: obj.name,
                  site_id: (site && site.length) ? site[0].id : null,
                  site_name: ((site && site.length)) ? site[0].name : null,
                }
              }
            }
            if (this.currentIntegration.rmm_action) {
              const reqData: any = { data: obj };
              delete reqData.integartionProfileName;
              const params: any = {
                integration_id: this.selectedCred,
                integration_name: this.currentIntegration.name,
                action_name: this.currentIntegration.rmm_action,
                requestparams: {
                  companyid: parseInt(result.id),
                  dest_company_id: obj.id,
                  action: "create"
                }
              };
              this._ls.display(true);
              this._cs.executeAction(params).then((item: any) => {
                if (item.status) {
                  this._bs.doRequest(`/w/integration/company_mappings`, 'post', requestD)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((res: any) => {
                      if (res.status) {
                        if (this.currentIntegration.integrationtype === "azure ad") {
                          this.azureScheduler(parseInt(res.id));
                        }
                        if (this.currentIntegration.name === "Meraki") {
                          this.syncFirewall({ company_id: res.id, site_id: site[0].id });
                        }
                        if (index === this.comMapImpForm.company_id.length - 1) {
                          this._ls.display(false);
                          this.cView = 'table';
                          this.initMappedCompany(this.selectedCred); this._changeDetectorRef.detectChanges();
                        }
                      } else {
                        this._ls.display(false);
                        const data = (res.message) ? res.message : res.data;
                        this.toast.sToast('error', data);
                      }
                    });
                }
              })
            } else {
              this._bs.doRequest(`/w/integration/company_mappings`, 'post', requestD)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((res: any) => {
                  if (res.status) {
                    if (this.currentIntegration.integrationtype === "azure ad") {
                      this.azureScheduler(parseInt(res.id));
                    }
                    if (this.currentIntegration.name === "Meraki") {
                      this.syncFirewall({ company_id: res.id, site_id: site[0].id });
                    }
                    if (index === this.comMapImpForm.company_id.length - 1) {
                      this._ls.display(false);
                      this.cView = 'table';
                      this.initMappedCompany(this.selectedCred); this._changeDetectorRef.detectChanges();
                    }
                  } else {
                    this._ls.display(false);
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                  }
                });
            }
          } else {
            const data = (result.message) ? result.message : result.data;
            this.toast.sToast('error', data);
          }
        })
    });
  }
  private filterCompanies(): void {
    if (!this.sourceCompany) {
      return;
    }
    // get the search keyword
    let search = this.searchSourceControl.value;
    if (!search) {
      this.sourceCompany = this.allComp.slice();
      this._changeDetectorRef.markForCheck();
      return;
    } else {
      search = search.toLowerCase();
    }
    this.getCompanies(search);
  }
  closeCurrentCompany($event: boolean) {
    this.searching = false;
    if (this.allComp) {
      this.sourceCompany = this.allComp.slice();
      this._changeDetectorRef.markForCheck();
    }
    // if (!$event) {
    //   this.getCompanies();
    // }
  }

  private filterDestCompanies(): void {
    if (!this.destCompany) {
      return;
    }
    // get the search keyword
    let search = this.searchDestControl.value;
    if (!search) {
      this.destCompany = this.allDestComp.slice();
      this._changeDetectorRef.markForCheck();
      return;
    } else {
      search = search.toLowerCase();
    }
    this.getDestCompanies(search);
  }

  getDestCompanies(search: any): void {
    const params: any = {
      integration_id: this.selectedCred,
      integration_name: this.currentIntegration.name,
      action_name: 'get_companies',
      requestparams: {}
    };
    (this.companyParams) ? params.requestparams = this.companyParams : {};
    params.requestparams.name = search;
    this._ls.display(true);
    this._cs.executeAction(params).then((item: any) => {
      this._ls.display(false);
      if (item.status && item.data.length) {
        if (!search) {
          this.allDestComp = item.data;
        } else {
          this.allDestComp = Array.from(new Map([...this.allDestComp, ...item.data].map(obj => [obj.id, obj])).values());
        }
        this.destCompany = item.data.slice();
        this.comMapForm.get('dest_company_id').setValue(item.data[0]);
        if (this.currentIntegration.import_sites) {
          this.getIntegrationSite(item.data[0]);
        }
        if (this.currentIntegration.import_agents) {
          this.getAgents(item.data[0]);
        }
        this._changeDetectorRef.markForCheck();
      } else {
        this.destCompany = [];
      }
    });
  }
  getAgents(event: any): void {
    this._ls.display(true);
    const condition = {
      condition: `agent_type='PROBE' and is_deprecated=FALSE and company_id=${event.id}`,
      skip: 0,
      limit: 1000,
    };
    this._bs
      .doRequest("r/company/agents", "get", null, condition)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this._ls.display(false);
        if (result.status) {
          result.data.map((x: any) => {
            x.name = (x.name) ? x.name : x.host_name;
          });
          this.agentList = result.data;
          this._changeDetectorRef.detectChanges();
        } else {
          const data = (result.message) ? result.message : result.data;
          this.toast.sToast('error', data);
        }
      });
  }
  updateSourceCompany(event: any): void {
    if (this.currentIntegration.import_agents) {
      this.getAgents(event);
    }
  }

  provideConsent(dest_company_id?: any, copyLink?: any): void {
    const rToken = uuidv4();
    const stateVar = btoa(
      `${window.location.host}@${rToken}@${this.selectedCred}`
    ).replace(/=/g, '');
    const applicationId = this.integrationCred.filter((x: any) => x.id === this.selectedCred);
    //edit
    // const destId = (dest_company_id) ? dest_company_id : (this.cmpMapping.destinationCompany.id) ? this.cmpMapping.destinationCompany.id + '' : this.cmpMapping.destinationCompany.id + '';
    const destId = dest_company_id;
    const baseURL = (applicationId[0] && applicationId[0].params.auth_endpoint) ? applicationId[0].params.auth_endpoint : 'https://login.microsoftonline.com';
    let url: any = `${baseURL}/${destId}/v2.0/adminconsent?client_id=${applicationId[0].params.client_id}&response_mode=query&response_type=code&redirect_uri=https://authccns.mycybercns.com&nonce={{nonce}}&scope=https://api.partnercenter.microsoft.com/.default`;
    url = url.replace("{{nonce}}", rToken);
    const urlCSP = `${url}&state=${stateVar}`;

    if (copyLink) {
      this._cs.copyClip(urlCSP);
    } else {
      localStorage.removeItem('oAuthC');
      localStorage.setItem('ncsp', JSON.stringify({}));
      this._bs.o365window = window.open(
        urlCSP,
        'popup',
        'width=600,height=600'
      )
      this.isConstentProvided = false;
      this.importProvideConsent[dest_company_id] = false;
      this.checkIsOAuthCodeCSP(dest_company_id);
    }
  }
  checkIsOAuthCodeCSP(dest_company_id?: any, type?: any): void {
    const oAuthC = localStorage.getItem('oAuthC');
    if (oAuthC) {
      let ncsp: any;
      try {
        ncsp = JSON.parse(localStorage.getItem('ncsp') as string);
        console.log(ncsp);
      } catch (e) {
        console.log('Error parsing AD CSP config');
      }
      this.importProvideConsent[dest_company_id] = true;
      //edit
      // (dest_company_id && ) ? this.updateCompanyMapping() : null;
      localStorage.removeItem('oAuthC');
      localStorage.removeItem('ncsp');
      return;
    }
    setTimeout(() => { this.getCount = this.getCount + 1; this.checkIsOAuthCodeCSP(); }, 1000);
  }
}

<div class="flex flex-wrap pt-4 gap-2 items-center justify-start">
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'port'" [ngClass]="{'bg-primary text-white': cView === 'port'}">Ports Policy</div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'deprecation'" [ngClass]="{'bg-primary text-white': cView === 'deprecation'}">Deprecation
        Days
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'edr-application'" [ngClass]="{'bg-primary text-white': cView === 'edr-application'}">EDR
        Application
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'backupSoftware'" [ngClass]="{'bg-primary text-white': cView === 'backupSoftware'}">Backup
        Software
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'compliance-scan'" [ngClass]="{'bg-primary text-white': cView === 'compliance-scan'}">
        Compliance Scan
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'remote-agent-install'"
        [ngClass]="{'bg-primary text-white': cView === 'remote-agent-install'}">Remote Agent Install
    </div>
    <!-- <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'patching-status'" [ngClass]="{'bg-primary text-white': cView === 'patching-status'}">
        Patching Status
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'exc-secreport-card'" [ngClass]="{'bg-primary text-white': cView === 'exc-secreport-card'}">
        Exclude component(s) from Security
        Report Card
    </div>
    <div class="px-1.5 py-0.5 text-md font-semibold rounded ring-1 hover:bg-purple-400 hover:text-white cursor-pointer"
        (click)="cView = 'audit-scan'" [ngClass]="{'bg-primary text-white': cView === 'audit-scan'}">AD Audit Scan
    </div> -->

</div>
<div class="border-t border-blue-400 my-4"></div>
<div class="mt-4" *ngIf="cView === 'port'">
    <div class="my-2">
        <fuse-alert [appearance]="'border'" [type]="'info'">
            <div class="flex flex-col gap-2">
                <div>Set Excluded, Insecure, Allowed and Denied ports for scanning</div>
            </div>
        </fuse-alert>
    </div>
    <app-dynamic-form [listOfFormElements]="ports" [spanLayout]="2" [Valuesoutput]="portsParams"
        (saveCallBack)="saveSettings(portsParams)" [isSaveBtn]="false" [isCloseBtn]="false">
    </app-dynamic-form>
    <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
        <!-- Save -->
        <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick [matTooltip]="'Save'"
            (click)="saveSettings(portsParams)">
            Save
        </button>
    </div>
</div>
<div class="mt-4" *ngIf="cView === 'deprecation'">
    <div class="my-2">
        <fuse-alert [appearance]="'border'" [type]="'info'">
            <div class="flex flex-col gap-2">
                <div>Set Asset and Agents Deprecation days for the assets and agents which are offline and not
                    scanned for x number of days.</div>
                <div>The Microsoft OS vulnerabilities for the respective assets are suppressed for the number of
                    days entered here, starting from the day after the initial release.</div>
            </div>
        </fuse-alert>
    </div>
    <app-dynamic-form [listOfFormElements]="deprecation_days" [spanLayout]="2" [Valuesoutput]="deprecationDaysParams"
        (saveCallBack)="saveSettings($deprecationDaysParams)" [isSaveBtn]="false" [isCloseBtn]="false">
    </app-dynamic-form>
    <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
        <!-- Save -->
        <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick [matTooltip]="'Save'"
            (click)="saveSettings(deprecationDaysParams)">
            Save
        </button>
    </div>
</div>
<div class="mt-8 w-full" *ngIf="cView === 'patching-status'">
    <div class="my-2">
        <mat-slide-toggle class="" [color]="'primary'" matTooltip="Enable Patching Status" name="patchingStatus"
            [(ngModel)]="_enable_patching.value.patching_status">
            Enable Patching Status
        </mat-slide-toggle>
    </div>
    <div class=" mt-2 h-[40vh]" *ngIf="_enable_patching.value.patching_status" fuseScrollbar>
        <fuse-alert [appearance]="'border'" [type]="'info'">
            <div [innerHTML]="patchingContent| safeHTML"></div>
        </fuse-alert>
    </div>
    <mat-checkbox name="agreePatchCond" *ngIf="_enable_patching.value.patching_status" id="agreePatchCond" class="mt-3"
        [(ngModel)]="_enable_patching.value.is_eula_accepted" value="" color="primary">I ACCEPT
    </mat-checkbox>
    <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
        <button class="ml-2" mat-flat-button [color]="'primary'" type="button" appPreventMultiClick
            [matTooltip]="'Save'" (click)="enablePatchingStatus()"
            [disabled]="_enable_patching.value.patching_status && !_enable_patching.value.is_eula_accepted">
            Save
        </button>
    </div>
</div>
<div class="mt-4 w-full" *ngIf="cView === 'default-tag'">
    <div class="my-2">
        <fuse-alert [appearance]="'border'" [type]="'info'">
            <div>Disable Default Tags removes the default tags applied for all the assets.</div>
        </fuse-alert>
    </div>
    <div class="mt-4">
        <mat-slide-toggle class="" [color]="'primary'" matTooltip="Enable Default Tags"
            [(ngModel)]="_default_tags.value.value" name="defaultTagsStatus">
            Enable Default Tags
        </mat-slide-toggle>
        <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick [matTooltip]="'Save'"
                (click)="enableDefaultTagStatus()">
                Save
            </button>
        </div>
    </div>
</div>
<div *ngIf="cView === 'edr-application'">
    <app-edr-app> </app-edr-app>
</div>
<div class="mt-4" *ngIf="cView === 'backupSoftware'">
    <app-backup-softwares></app-backup-softwares>
</div>

<div class="mt-4 w-full" *ngIf="cView === 'audit-scan'">
    <div class="my-2 bg-card border border-gray-200 rounded-lg px-4 py-6 dark:bg-gray-800 dark:border-gray-700">
        <mat-slide-toggle class="" [color]="'primary'" matTooltip="Enable AD Audit Scan"
            [(ngModel)]="_ad_audit_scan.value.value" name="adAuditScan">
            AD Audit Scan
        </mat-slide-toggle>
        <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick [matTooltip]="'Save'"
                (click)="enableAdAuditScan()">
                Save
            </button>
        </div>
    </div>
</div>
<div class="mt-4 w-full" *ngIf="cView === 'remote-agent-install'">
    <div class="my-2 bg-card border border-gray-200 rounded-lg px-4 py-6 dark:bg-gray-800 dark:border-gray-700">
        <div class="mt-2 mb-6">
            <fuse-alert [appearance]="'border'" [type]="'info'">
                <div>By Enabling Remote Agent Install you can install Lightweight Agents on the Probe Discovered Assets.</div>
                <div class="my-1 font-semibold">Please Note: It is supported for,</div>
                <div class="flex flex-row items-center"><span class="flex w-2 h-2 me-2 rounded-full bg-gray-600"></span>Linux Asset users with Privileged Access and with Enabled SSH port (Based on the port mapped in the Asset credentials).</div>
                <div class="flex flex-row items-center"><span class="flex w-2 h-2 me-2 rounded-full bg-gray-600"></span>Windows Assets where SMB is Enabled.</div>
            </fuse-alert>
        </div>
        <mat-slide-toggle class="" [color]="'primary'" matTooltip="Enable Remote Agent Install"
            [(ngModel)]="_remote_agent_install.value.value" name="RemoteAgentInstall">
            Enable Remote Agent Install
        </mat-slide-toggle>
        <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick [matTooltip]="'Save'"
                (click)="enableRemoteAgentInstall()">
                Save
            </button>
        </div>
    </div>
</div>
<div class="mt-4" *ngIf="cView === 'exc-secreport-card'">
    <div class="bg-card border border-gray-200 rounded-lg px-4 py-6 dark:bg-gray-800 dark:border-gray-700">
        <div class=" flex flex-col">
            <span class="font-semibold mb-2">Select All</span>
            <div class="flex flex-col">
                <mat-checkbox class="mb-2" value="" (change)="selectAllToggle($event, 'excludeReportListAll')"
                    [checked]="isAllSelected('excludeReportListAll')"
                    [indeterminate]="hasSelected('excludeReportListAll') && !isAllSelected('excludeReportListAll')"
                    [color]="'primary'">
                    Select All
                </mat-checkbox>
                <div class="ml-4 flex flex-col" *ngFor="let type of excludeReportListAll; let i = index">
                    <mat-checkbox class="mb-2" [color]="'primary'" [(ngModel)]="type.selected" name="selected{{i}}">
                        {{type.name}}
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick [matTooltip]="'Save'"
                (click)="updateExcludeReport()">
                Save
            </button>
        </div>
    </div>
</div>
<div class="mt-4" *ngIf="cView === 'compliance-scan'">
    <div class="bg-card border border-gray-200 rounded-lg px-4 py-6 dark:bg-gray-800 dark:border-gray-700">
        <div class=" flex flex-col">
            <span class="font-semibold mb-2">Choose Compliance Types</span>
            <div class="flex flex-col gap-2 ring-1 rounded-lg">
                <mat-checkbox value="" (change)="selectAllToggle($event, 'complianceListAll')"
                    [checked]="isAllSelected('complianceListAll')"
                    [indeterminate]="hasSelected('complianceListAll') && !isAllSelected('complianceListAll')"
                    [color]="'primary'">
                    Select All
                </mat-checkbox>
                <div class="border-t grid grid-cols-1 sm:grid-cols-4 gap-2 p-4">
                    <div *ngFor="let type of complianceListAll; let i = index">
                        <mat-checkbox class="mb-2" [color]="'primary'" [(ngModel)]="type.selected" name="selected{{i}}">
                            {{type.name|uppercase |camelToHuman:true}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent">
            <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick [matTooltip]="'Save'"
                (click)="updateComplianceType()">
                Save
            </button>
        </div>
    </div>
</div>

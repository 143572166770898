import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonService } from 'app/_services/common.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { TimelineComponent } from '../timeline/timeline.component';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';

@Component({
  selector: 'asset-time-line',
  standalone: true,
  imports: [CommonModule, TimelineComponent],
  templateUrl: './asset-time-line.component.html',
  styleUrls: ['./asset-time-line.component.scss']
})
export class AssetTimeLineComponent {
  inputData: any = [];
  dataMapping: any = [
    {
      field: 'columns',
      isArray: true,
      isStatic: false,
      mapTo: 'data',
      objectMapping: [
        {
          destKey: 'id',
          sourceKey: 'id',
        },
        {
          destKey: 'icon',
          sourceKey: 'icon',
          type: 'icon',
          valueMap: {
            time: 'mat_solid:access_time',
            star: 'heroicons_solid:star',
            _default_: 'mat_solid:access_time',
          },
        },
        {
          destKey: 'identifier',
          sourceKey: 'description',
        },
        {
          destKey: 'collection',
          sourceKey: 'extraContent',
        },
        {
          destKey: 'action_time',
          sourceKey: 'date',
        },
      ],
      objectRules: [],
      value: '',
    },
  ];
  asset_id: any = '';
  props: any = {
    title: 'Asset Timeline',
    tablename: '/r/company/system_time_line',
    isServerSide: true,
    description: '',
    orderBy: {direction: 'desc', columnName: 'updated'}
  };

  constructor(private cs: CommonService, private toast: MyToastrService,) { }
  ngOnInit(): void {
    if (this.cs.selctedAsset) {
      this.asset_id = this.cs.selctedAsset;
      this.props.path = {condition: "asset_id='" + this.asset_id + "'"}
    } else if (this.cs.selectedTableRows['Assets'] && this.cs.selectedTableRows['Assets'].length){
      this.asset_id = this.cs.selectedTableRows['Assets'][0].id;
      this.props.path = {condition: "asset_id='" + this.asset_id + "'"}
    } else {
      this.toast.sToast('info', 'Please click on the IP address to redirect to the Asset and then select Asset Timeline');
    }
  }

}

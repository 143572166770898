import { ChangeDetectorRef, Component, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TableComponent } from "app/modules/shared/table/table.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { FuseAlertComponent } from "@fuse/components/alert";
import { MaterialModule } from "app/material.module";
import { ExpandTableComponent } from "app/modules/shared/expand-table/expand-table.component";
import { DynamicFormComponent } from "app/modules/shared/forms/dynamic-form/dynamic-form.component";
import { GridsComponent } from "app/modules/shared/grids/grids.component";
import { ModalComponent } from "app/modules/shared/modal.component";
import { CommonService } from "app/_services/common.service";
import { BaseRequestService } from "app/_services/base.service";
import { LoaderService } from "app/_services/loader.service";
import { ModalService } from "app/_services/modal.service";
import { UserService } from "app/core/user/user.service";
import { Subject, Subscription, takeUntil } from "rxjs";
import { FuseScrollbarDirective } from "../../../../../@fuse/directives/scrollbar";
import { MyToastrService } from 'app/_services/toastr.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { DirectivesModule } from "app/-directives/-directives.module";
import { CredentialsComponent } from "../credentials/credentials.component";
import { CompanyOnboardingComponent } from "../company-onboarding/company-onboarding.component";


@Component({
    selector: "discovery-settings",
    standalone: true,
    imports: [CommonModule, CompanyOnboardingComponent, DirectivesModule, CredentialsComponent, ReactiveFormsModule, TableComponent, CommonModule, GridsComponent, ModalComponent, MaterialModule, FormsModule, FuseAlertComponent, DynamicFormComponent, ExpandTableComponent, TableComponent, FuseScrollbarDirective],
    templateUrl: "./discovery-settings.component.html",
    styleUrls: ["./discovery-settings.component.scss"],
})
export class DiscoverySettingsComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    discoverySettings: any = {
        name: '',
        address_type: '',
        address: '',
    };
    openDS: Subscription;
    loadtable: boolean = false
    user: any;
    form!: FormGroup;
    addressTypes: any = [
        {
            value: 'STATICIP',
            label: 'Static IP'
        },
        {
            value: 'CIDR',
            label: 'CIDR'
        },
        {
            value: 'DOMAIN',
            label: 'Domain'
        },
        {
            value: 'IPRANGE',
            label: 'IP Range'
        }
    ];
    private subs: Subscription;
    discoveryTableOptions: any = {};
    currentdisc: any = {}
    credForm: FormGroup;
    selectedIndex = 0;

    /**
     * Constructor
     */
    constructor(public cs: CommonService, private _bs: BaseRequestService,
        private _changeDetectorRef: ChangeDetectorRef, public confirmDialog: FuseConfirmationService,
        public toast: MyToastrService, private _formBuilder: FormBuilder,
        private uS: UserService, public modalService: ModalService, private loaderService: LoaderService) {
        this.subs = this.cs.selectedSiteChanged.subscribe((cmp: any) => {
            this.discoverySettings.companyId = this.cs.currentScope.id;
            this._changeDetectorRef.markForCheck();
        });
        this.cs.newDiscoverySettingCall.subscribe((trigger: any) => {
            this.discoverySettings = {
                name: '',
                address_type: '',
                address: '',
            };
            this._changeDetectorRef.markForCheck();
            this.modalService.open('newDiscoverySetting');
        });
        this.openDS = this.cs.openDiscoverySettings.subscribe((res: any) => {
            setTimeout(() => {
                this.discoverySettings = {
                    name: '',
                    address_type: '',
                    address: '',
                };
                this._changeDetectorRef.markForCheck();
                this.init();
                this.modalService.open('newDiscoverySetting');
            }, 3000);
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.openDS.unsubscribe();
        this.subs.unsubscribe();
    }

    init(): void {
        this.uS.userd$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: any) => {
            this.user = user;
            this._changeDetectorRef.markForCheck();
        });
        this.initialTable();
        this.credForm = this._formBuilder.group({
            name: ['', [Validators.required]],
            address_type: ['', [Validators.required]],
            address: ['', [Validators.required]],
        });
    }

    ngOnInit(): void {
        this.init();
    }

    initialTable(): void {
        this.discoveryTableOptions = {}; this._changeDetectorRef.detectChanges();
        this.discoveryTableOptions = {
            columns: [
                {
                    header: "Name",
                    columnDef: "name",
                    "cType": "string",
                    filter: "",
                    cell: "(element: any) => ${element.name}",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Address",
                    columnDef: "address",
                    "cType": "string",
                    filter: "",
                    cell: "(element: any) => ${element.address}",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Address Type",
                    columnDef: "address_type",
                    "cType": "string",
                    filter: "",
                    cell: "(element: any) => ${element.address_type}",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Company ID",
                    columnDef: "company_id",
                    "cType": "number",
                    filter: "",
                    cell: "(element: any) => ${element.company_id}",
                    order: 0,
                    visible: false,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Created On",
                    columnDef: "created",
                    "cType": "date",
                    filter: "utcToLocale",
                    cell: "(element: any) => ${element.created}",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                },
                {
                    header: "Updated On",
                    columnDef: "updated",
                    "cType": "date",
                    filter: "utcToLocale",
                    cell: "(element: any) => ${element.updated}",
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: "",
                    hasMultiData: false,
                    class: "",
                    color: "",
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: "",
                    img: false,
                    imgPath: "",
                    isSort: true,
                    iscolumnSearch: false,
                }
            ],
            sortOptions: { active: "updated", direction: "desc" },
            _pageData: [],
            tableOptions: {
                title: "Discovery Settings",
                isServerSide: false,
                selectText: "discovery",
                loading: true,
                floatingFilter: true,
                rowSelection: false,
                showAction: true,
                pagination: true,
                pageOptions: [5, 10, 25, 100],
                pageSize: 5,
                search: false,
                showFilter: true,
                showTagFilter: false,
                actionMenuItems: [
                    {
                        text: 'Edit',
                        id: 'edit',
                        icon: 'edit',
                        callback: 'editFunction',
                        hideLocal: false,
                        isGlobal: false,
                    },
                    {
                        text: 'Delete',
                        id: 'delete',
                        icon: 'delete',
                        callback: 'deleteFunction',
                        hideLocal: false,
                        isGlobal: false,
                    }],
                showhideList: true,
                refreshData: true,
                exportExcel: true,
                add: true,
                columnSearch: false,
                compareData: false,
                filterDownload: false,
                serverSide: {
                    url: "r/company/discovery_settings",
                    dUrl: 'd/company/discovery_settings',
                    condition: "discovery_settings_type='assetscan'",
                },
                id: 'discovery'
            },
            changeValue: new Subject<any>(),
        }
        this._changeDetectorRef.detectChanges();
    }

    isIPInRange(ipAddress: any, startRange: any, endRange: any) {
        const ipParts = ipAddress.split('.');
        const startRangeParts = startRange.split('.');
        const endRangeParts = endRange.split('.');

        const ipInt = ipParts.map(Number);
        const startRangeInt = startRangeParts.map(Number);
        const endRangeInt = endRangeParts.map(Number);

        if (ipParts.length == 4) {
            for (let i = 0; i < 4; i++) {
                const Pattern = /^\d+$/
                if (!Pattern.test(ipInt[i]) || ipInt[i] < startRangeInt[i] || ipInt[i] > endRangeInt[i]) {
                    return false;
                }
            }
        } else {
            return false
        }

        return true;
    }

    checkIP(data: any) {
        if (data.address_type.toLowerCase() == 'domain') {
            const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const valid = domainPattern.test(data.address);
            if (!valid) {
                this.toast.sToast('error', 'Invalid value for Domain');
                return;
            }
        } else if (data.address_type.toLowerCase() == 'staticip') {
            const valid = this.isIPInRange(data.address, "0.0.0.0", "255.255.255.255")
            if (!valid) {
                this.toast.sToast('error', 'Invalid value for IP');
                return;
            }
        } else if (data.address_type.toLowerCase() == 'iprange') {
            var ips = data.address.split("-")
            if (ips.length == 2) {
                let invalidlen = []
                ips.forEach((ele: any) => {
                    const valid = this.isIPInRange(ele, "0.0.0.0", "255.255.255.255")
                    if (!valid) {
                        this.toast.sToast('error', 'Invalid value for IP Range');
                        return;
                    }
                })
                if (invalidlen.length > 0) {
                    this.toast.sToast('error', 'Invalid value for IP Range');
                    return;
                }
            } else {
                this.toast.sToast('error', 'Invalid value for IP Range');
                return;
            }
        } else if (data.address_type.toLowerCase() == 'cidr') {
            let invalidlen = []
            const ipParts = data.address.split('/');
            const valid = this.isIPInRange(ipParts[0], "0.0.0.0", "255.255.255.255")
            if (!valid) {
                invalidlen.push('invalid')
            }
            const Pattern = /^\d+$/
            if (!Pattern.test(ipParts[1]) || parseInt(ipParts[1]) < 1 || parseInt(ipParts[1]) > 31) {
                invalidlen.push('invalid')
            }
            if (invalidlen.length > 0) {
                this.toast.sToast('error', 'Invalid value for CIDR');
                return;
            }
        }
        return true
    }

    save(): void {
        const scan = this.credForm.getRawValue();
        const request = this.checkIP(scan);
        if (request) {
            scan.company_id = parseInt(this.cs.currentScope.id);
            delete scan["[object Object]"];
            var api = '/w/company/discovery_settings'
            var method = "post"
            var postdata: any = { data: scan }
            if (this.discoverySettings && this.discoverySettings.id) {
                delete scan["undefined"]
                delete scan["created"]
                delete scan["updated"]
                delete scan["id"]
                method = "patch"
                api = '/w/company/update_discovery_settings'
                postdata = { id: this.discoverySettings.id, data: scan };
            }
            this._bs
                .doRequest(api, method, postdata)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    if (result.status) {
                        this.toast.sToast('success', 'Added Successfully');
                        this.modalService.close('newDiscoverySetting');
                        this.credForm.reset();
                        try {
                            setTimeout(() => this.initialTable(), 3000);
                        } catch (e) {
                            console.log(e);
                        }
                    } else {
                        const data = (result.message)? result.message : result.data;
this.toast.sToast('error', data);
                    }
                });
        }
    }

    addTableData(): void {
        this.modalService.open('newDiscoverySetting');
        this.credForm.reset(); this.discoverySettings = {
            name: '',
            address_type: '',
            address: '',
        };
        this._changeDetectorRef.detectChanges();
    }

    discactionCall(data: any) {
        if (data.action.text == 'Edit') {
            this.credForm.setValue({
                name: data.row.name,
                address_type: data.row.address_type,
                address: data.row.address,
            });
            this.discoverySettings = data.row;
            this.modalService.open('newDiscoverySetting');
        } else if (data.action.text == 'Delete') {
            this.deletetag(data);
        }
    }

    deletetag(data: any) {
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: 'Are you sure you want to delete this Discovery Setting?',
            actions: {
                confirm: {
                    label: 'Yes'
                }
            }
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this._bs.doRequest(`d/company/discovery_settings/${data.row.id}`, 'delete')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        if (result) {
                            this.toast.sToast('success', 'Removed successfully');
                            setTimeout(() => {
                                this.initialTable();
                            }, 3000);
                        } else {
                            const data = (result.message)? result.message : result.data;
this.toast.sToast('error', data);
                        }
                    });
            }
        })
    }
}

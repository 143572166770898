<div class="flex flex-col w-full">
    <div class="flex flex-row items-center w-full gap-2">
        <div class="mt-3 text-xl font-bold truncate mb-4">White Label Settings</div>
        <ng-container>
            <button *ngIf="!editMode" mat-stroked-button (click)="toggleEditMode(true)" class="ml-auto pr-4">
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:pencil-alt'"></mat-icon>
                <span class="ml-2">Edit</span>
            </button>
            <!-- (click)="deleteContact()" -->
            <button *ngIf="editMode" class="ml-auto pr-4" mat-stroked-button [color]="'warn'" [matTooltip]="'Delete'"
            (click)="resetDefaultImages()">
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                <span class="ml-2">Delete</span>
            </button>
        </ng-container>
    </div>
    <div class="px-6 mt-2">
        <fuse-alert [appearance]="'border'" [type]="'info'">
            <div class="flex flex-col gap-2">
                <div>The Uploaded Logo will reflect at the Login page, as the favicon logo for the tab and inside the portal at top left.</div>
                <div>The maximum file format size is limited to 524kB.</div>
                <div>The file formats supported here are jpeg, jpg, png.</div>
            </div>
        </fuse-alert>
    </div>
    <form [formGroup]="whiteLabelForm">
        <ng-container>
            <div class="relative flex flex-col flex-auto items-center p-6">
                <div class="w-full">
                    <div class="flex flex-row items-center bg-card rounded p-2">
                        <mat-icon class="icon-size-5 mr-2" [svgIcon]="'heroicons_solid:moon'"></mat-icon>
                        <h4 class="font-semibold">Dark Mode</h4>
                    </div>
                    <div class="grid grid-cols-2 sm:grid-cols-2 gap-2 sm:gap-4 w-full">
                        <div class="">
                            <div class="py-4 font-semibold">Logo</div>
                            <div
                                class="relative flex items-center justify-center h-32 rounded-xl overflow-hidden ring-4 ring-bg-card">
                                <ng-container *ngIf="editMode">
                                    <div class="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
                                    <div class="absolute inset-0 flex items-center justify-center z-20"
                                        *ngIf="editMode">
                                        <div>
                                            <input id="logo_dark"
                                                class="absolute h-0 w-0 opacity-0 invisible pointer-events-none"
                                                type="file" [multiple]="false" [accept]="'image/jpeg, image/png'"
                                                (change)="uploadImage($event, 'logo_dark')">
                                            <label
                                                class="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer hover:bg-hover"
                                                for="logo_dark" matRipple>
                                                <mat-icon class="text-white"
                                                    [svgIcon]="'heroicons_outline:camera'"></mat-icon>
                                            </label>
                                        </div>
                                        <div>
                                            <button
                                                mat-icon-button
                                                (click)="removeImage('logo_dark')">
                                                <mat-icon
                                                    class="text-white"
                                                    [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <img class="object-cover p-2 w-[128px]" [src]="images.logo_dark" alt="">
                            </div>
                        </div>
                        <div class="">
                            <div class="py-4 font-semibold">Icon</div>
                            <div
                                class="relative flex items-center justify-center w-32 h-32 rounded-xl overflow-hidden ring-4 ring-bg-card">
                                <ng-container *ngIf="editMode">
                                    <div class="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
                                    <div class="absolute inset-0 flex items-center justify-center z-20">
                                        <div>
                                            <input id="icon_dark"
                                                class="absolute h-0 w-0 opacity-0 invisible pointer-events-none"
                                                type="file" [multiple]="false" [accept]="'image/jpeg, image/png'"
                                                (change)="uploadImage($event, 'icon_dark')">
                                            <label class="flex items-center justify-center w-10 h-10 rounded-full
                                                 cursor-pointer hover:bg-hover" for="icon_dark" matRipple>
                                                <mat-icon class="text-white"
                                                    [svgIcon]="'heroicons_outline:camera'"></mat-icon>
                                            </label>
                                        </div>
                                        <div>
                                            <button
                                                mat-icon-button
                                                (click)="removeImage('icon_dark')">
                                                <mat-icon
                                                    class="text-white"
                                                    [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <img class="object-cover p-2 w-[128px]" [src]="images.icon_dark" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row w-full py-4"></div>
                    <div class="flex flex-row items-center bg-card rounded p-2">
                        <mat-icon class="icon-size-5 mr-2" [svgIcon]="'heroicons_solid:sun'"></mat-icon>
                        <h4 class="font-semibold">Light Mode</h4>
                    </div>
                    <div class="grid grid-cols-2 sm:grid-cols-2 gap-2 sm:gap-4 w-full">
                        <div class="">
                            <div class="py-4 font-semibold">Logo</div>
                            <div
                                class="relative flex items-center justify-center h-32 rounded-xl overflow-hidden ring-4 ring-bg-card">
                                <ng-container *ngIf="editMode">
                                    <div class="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
                                    <div class="absolute inset-0 flex items-center justify-center z-20">
                                        <div>
                                            <input id="logo"
                                                class="absolute h-0 w-0 opacity-0 invisible pointer-events-none"
                                                type="file" [multiple]="false" [accept]="'image/jpeg, image/png'"
                                                (change)="uploadImage($event, 'logo')">
                                            <label
                                                class="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer hover:bg-hover"
                                                for="logo" matRipple>
                                                <mat-icon class="text-white"
                                                    [svgIcon]="'heroicons_outline:camera'"></mat-icon>
                                            </label>
                                        </div>
                                        <div>
                                            <button
                                                mat-icon-button
                                                (click)="removeImage('logo')">
                                                <mat-icon
                                                    class="text-white"
                                                    [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <img class="object-cover p-2 w-[128px]" [src]="images.logo" alt="">
                            </div>
                        </div>
                        <div class="">
                            <div class="py-4 font-semibold">Icon</div>
                            <div
                                class="relative flex items-center justify-center w-32 h-32 rounded-xl overflow-hidden ring-4 ring-bg-card">
                                <ng-container *ngIf="editMode">
                                    <div class="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
                                    <div class="absolute inset-0 flex items-center justify-center z-20">
                                        <div>
                                            <input id="icon"
                                                class="absolute h-0 w-0 opacity-0 invisible pointer-events-none"
                                                type="file" [multiple]="false" [accept]="'image/jpeg, image/png'"
                                                (change)="uploadImage($event, 'icon')">
                                            <label
                                                class="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer hover:bg-hover"
                                                for="icon" matRipple>
                                                <mat-icon class="text-white"
                                                    [svgIcon]="'heroicons_outline:camera'"></mat-icon>
                                            </label>
                                        </div>
                                        <div>
                                            <button
                                                mat-icon-button
                                                (click)="removeImage('icon')">
                                                <mat-icon
                                                    class="text-white"
                                                    [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <img class="object-cover p-2 w-[128px]" [src]="images.icon" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex items-center mt-4 py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t bg-gray-50 dark:bg-transparent">
                <ng-container *ngIf="editMode">
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'" (click)="toggleEditMode(false)">
                        Cancel
                    </button>
                    <button class="ml-2" mat-flat-button [color]="'primary'"
                        [matTooltip]="'Save'" (click)="saveImage()">
                        Save
                    </button>
                </ng-container>
    
            </div>
        </ng-container>
    </form>
</div>
<ng-container *transloco="let t">
    <mat-card class="bg-card m-4 rounded-2xl border-1 w-full h-full overflow-hidden" *ngIf="isLoading">
        <mat-card-content>
            <p><i class="fa fa-spin fa-spinner fa-lg"></i> Loading...</p>
        </mat-card-content>
    </mat-card>
    <mat-card class="bg-card m-4 rounded-2xl border-1 overflow-hidden" *ngIf="!isLoading">
        <mat-drawer-container class="flex-auto sm:h-full">
            <!-- Drawer -->
            <mat-drawer class="sm:w-96 dark:bg-gray-900" [autoFocus]="false" [mode]="drawerMode" [opened]="drawerOpened"
                position="end" #drawer>
                <!-- Header -->
                <div class="flex items-center flex-row my-2 mx-2 md:my-10">
                    <!-- Title -->
                    <div class="text-2xl font-bold tracking-tight leading-none">Settings</div>
                    <div class="spacer"></div>
                    <!-- Close button -->
                    <div class="sm:hidden">
                        <button mat-icon-button (click)="drawer.close()">
                            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                        </button>
                    </div>
                </div>
                <!-- Panel links -->
                <div class="flex flex-col divide-y border-t border-b" *ngIf="tableOptions.floatingFilter">
                    <div class="navigation-items gap-[15px]">
                        <form class="flex flex-col items-center justify-center">
                            <mat-form-field class="pl20" style="padding: 10px;font-size: 15px;"
                                *ngIf="tableOptions.dropdwn">
                                <mat-select [multiple]="false" placeholder="Asset"
                                    (selectionChange)="filterDrpLstChange($event.value)" [formControl]="assetDrpCtrl">
                                    <mat-option *ngFor="let item of tableOptions.filterDrpLstFilteredList"
                                        [value]="item._id">
                                        {{item.ip}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field floatLabel="never"
                                class="mini-form-field fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-[64px]">
                                <input matInput class="form-control" [(ngModel)]="filterText" type="text" trim="blur"
                                    name="filterText{{tableOptions.id}}" [id]="tableOptions.id"
                                    (ngModelChange)="filterUpdate.next($event)" placeholder="Search">
                                <button class="gray" matSuffix mat-icon-button *ngIf="!filterText || filterText === ''"
                                    color="primary">
                                    <mat-icon>search</mat-icon>
                                </button>
                                <button class="gray" matSuffix mat-icon-button *ngIf="filterText && filterText !== ''"
                                    (click)="filterText = ''; doFilter(filterText);" color="primary">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
                            <div class="flex flex-row items-end" *ngIf="tableOptions.refreshData">
                                <button class="gray" matSuffix mat-icon-button matTooltip="Reload Table Data"
                                    (click)="RefreshTableData();" color="primary">
                                    <mat-icon id="rfrbtn{{tableOptions.title}}"
                                        svgIcon="mat_outline:refresh"></mat-icon>
                                </button>
                            </div>
                            <div *ngIf="tableOptions.refreshData">
                                <mat-form-field
                                    class="mini-form-field min-w-[64px] fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded">
                                    <mat-select [(value)]="selectedTimer" (selectionChange)="timerCallData()"
                                        matTooltip="Refresh Interval">
                                        <mat-option value="0" selected>No Refresh</mat-option>
                                        <mat-option value="60" id="RefreshOnemin">1 Min</mat-option>
                                        <mat-option value="300" id="RefreshFivemin">5 Min</mat-option>
                                        <mat-option value="1800" id="RefreshThirtymin">30 Min</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <button mat-icon-button menuOpen="false" matTooltip="Filter List"
                                *ngIf="tableOptions.showColFilter && _sTableOptions.columns && _sTableOptions.columns.length"
                                [matMenuTriggerFor]="filterMenu" #filterMenuTrigger="matMenuTrigger">
                                <mat-icon>filter_list</mat-icon>
                            </button>
                            <mat-menu #filterMenu="matMenu" class="col-filters">
                                <mat-card class="filter-card" (click)="$event.stopPropagation();">
                                    <mat-card-header>
                                        <mat-card-title>Filter</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <form>
                                            <ng-container *ngFor="let column of _sTableOptions.columns">
                                                <mat-form-field *ngIf="column.iscolumnSearch" class="w-full">
                                                    <input matInput type="text" trim="blur" [id]="column.id"
                                                        placeholder="Search {{column.header}}"
                                                        *ngIf="!column.selectFilter" name="{{column.columnDef}}"
                                                        [(ngModel)]="colfilter[column.columnDef]"
                                                        (input)='columnFilter($any($event.target).value,column.columnDef)'
                                                        [disabled]="(column.iscolumnSearch === undefined || column.iscolumnSearch===null) ? 'false' : !column.iscolumnSearch ">
                                                    <mat-select placeholder="Choose filter value"
                                                        name="{{column.columnDef}}" *ngIf="column.selectFilter"
                                                        [(ngModel)]="colfilter[column.columnDef]"
                                                        [disabled]="(column.iscolumnSearch === undefined || column.iscolumnSearch===null) ? 'false' : !column.iscolumnSearch "
                                                        (selectionChange)="columnFilter($event.value, column.columnDef)">
                                                        <mat-option value="">All</mat-option>
                                                        <mat-option *ngFor="let val of column.selectFilterArr"
                                                            [value]="val.value" [id]="val.id">{{val.name}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>
                                        </form>
                                    </mat-card-content>
                                </mat-card>
                            </mat-menu>
                            <button mat-icon-button menuOpen="false" (click)="openListColumns()"
                                #listMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="belowMenu" color="primary"
                                id="view{{tableOptions.id}}" matTooltip="View Column, Reorder Columns"
                                *ngIf="tableOptions.showhideList">
                                <mat-icon>view_column</mat-icon>
                            </button>
                            <mat-menu #belowMenu="matMenu" [hasBackdrop]="false" yPosition="below">
                                <div cdkDropList (cdkDropListDropped)="drop($event)">
                                    <div class="flex flex-row items-center w-full"
                                        *ngFor="let item of showhideList; let i = index" cdkDrag>
                                        <i class="material-icons color_icon mr-1 mt-2 cursor-move"> drag_handle </i>
                                        <mat-checkbox color="primary" [checked]="item.visible == true ? item :''"
                                            (click)="$event.stopPropagation()" (change)="showHideColumn(i, item)"
                                            class="left-align" [id]="item.header"> {{item.header}}
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </mat-menu>
                            <button mat-icon-button menuOpen="false" matTooltip="Filter List" [matTooltipPosition]="'right'"
                                *ngIf="sTableOptions.cFilter" color="primary" [matMenuTriggerFor]="filterMenu"
                                #filterMenuTrigger="matMenuTrigger">
                                <mat-icon class="">filter_list</mat-icon>
                            </button>
                            <mat-menu #filterMenu="matMenu" class="col-filters">
                                <mat-card class="filter-card" (click)="$event.stopPropagation();">
                                    <mat-card-header>
                                        <mat-card-title>Filter</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <form #filterForm="ngForm" role="form">
                                            <mat-form-field
                                                *ngIf="sTableOptions.columns && sTableOptions.columns.length"
                                                class="w-full" appearance="outline">
                                                <mat-label>Choose filter column</mat-label>
                                                <mat-select placeholder="Choose filter column" name="chooseFilter"
                                                    required [(ngModel)]="cFilter.selectedCol">
                                                    <mat-option value="">Choose filter column</mat-option>
                                                    <mat-option *ngFor="let val of sTableOptions.columns"
                                                        [value]="val.col" [id]="val.id">{{val.header}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field *ngIf="cFilter.selectedCol" class="w-full"
                                                appearance="outline">
                                                <mat-label>Search {{colHash[cFilter.selectedCol].header}}</mat-label>
                                                <input matInput type="text" trim="blur"
                                                    *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'text'"
                                                    placeholder="Search {{colHash[cFilter.selectedCol].header}}"
                                                    required name="{{colHash[cFilter.selectedCol].col}}s"
                                                    [(ngModel)]="colHash[cFilter.selectedCol].val">
                                                <mat-select placeholder="Search {{colHash[cFilter.selectedCol].header}}"
                                                    name="{{colHash[cFilter.selectedCol].col}}s"
                                                    *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'select'"
                                                    required [(ngModel)]="colHash[cFilter.selectedCol].val">
                                                    <mat-option
                                                        *ngFor="let val of colHash[cFilter.selectedCol].colFilters.options"
                                                        [value]="val.value">{{val.name}}</mat-option>
                                                </mat-select>
                                                <ng-container
                                                    *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'range'">
                                                    <mat-date-range-input [rangePicker]="picker">
                                                        <input matStartDate name="startDate"
                                                            [(ngModel)]="colHash[cFilter.selectedCol].start"
                                                            placeholder="Start date">
                                                        <input matEndDate name="startDate"
                                                            [(ngModel)]="colHash[cFilter.selectedCol].end"
                                                            placeholder="End date">
                                                    </mat-date-range-input>
                                                    <mat-datepicker-toggle matSuffix
                                                        [for]="picker"></mat-datepicker-toggle>
                                                    <mat-date-range-picker #picker></mat-date-range-picker>
                                                </ng-container>
                                            </mat-form-field>
                                            <button (click)="columnFilterxlsx(colHash[cFilter.selectedCol])"
                                                [disabled]="!filterForm.form.valid" color="primary"
                                                mat-raised-button="">Add Filter
                                            </button>
                                            <button class="ml-2" (click)="cFilter.selectedCol = ''"
                                                mat-stroked-button="">Clear
                                            </button>
                                        </form>
                                    </mat-card-content>
                                </mat-card>
                            </mat-menu>
                            <button id="gbtn{{sTableOptions.title}}" (click)="apiDownload('type')" color="primary"
                                matSuffix mat-icon-button *ngIf="tableOptions.apiDownload" matTooltip="Download as XLSx"
                                aria-label="download xlsx menu">
                                <mat-icon class="mat-24">file_download</mat-icon>
                            </button>
                            <button id="gbtn{{sTableOptions.title}}" color="primary" matSuffix mat-icon-button
                                *ngIf="!tableOptions.hideDownload" matTooltip="Download as XLSx"
                                [matMenuTriggerFor]="xlsMenu" aria-label="download xlsx menu">
                                <mat-icon class="mat-24">file_download</mat-icon>
                            </button>
                            <mat-menu #xlsMenu="matMenu">
                                <button mat-menu-item id="currentSelection" *ngIf="tableOptions.filterDownload"
                                    (click)="downloadAsXls('filtered')">
                                    <mat-icon class="mat-24">filter_list</mat-icon>
                                    <span>Filtered Data</span>
                                </button>
                                <button mat-menu-item id="fullData" (click)="downloadAsXls('full')">
                                    <mat-icon class="mat-24">file_download</mat-icon>
                                    <span>Full Data</span>
                                </button>
                            </mat-menu>
                            <button mat-icon-button *ngIf="tableOptions.compareData" matTooltip="Compare Data"
                                color="primary" (click)="CompareData(compSelection.selected)">
                                <mat-icon>compare</mat-icon>
                            </button>
                            <button mat-icon-button matTooltip="Save Settings" id="save{{tableOptions.id}}"
                                (click)="SaveSettings()" color="primary">
                                <mat-icon>save</mat-icon>
                            </button>
                            <button mat-raised-button color="primary" (click)="publish()" *ngIf="tableOptions.publish">
                                Publish
                            </button>
                            <a mat-icon-button matTooltip="Help Link" [href]="baseService.helpLinks[tableOptions.id]"
                                target="_blank" color="primary"
                                *ngIf="baseService.helpLinks && baseService.helpLinks[tableOptions.id] && baseService.helpLinks[tableOptions.id] !== ''">
                                <mat-icon>help</mat-icon>
                            </a>
                            <!--<button mat-icon-button menuOpen="false" (click)="openListExport()" matTooltip="Download"
                              #exportMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="exportMenu" *ngIf="tableOptions.exportExcel">
                              <mat-icon>cloud_download</mat-icon>
                            </button>-->
                            <button *ngFor="let pbtn of tableOptions.parentCalls" mat-icon-button [matTooltipPosition]="'right'"
                                [matTooltip]="pbtn.tooltip" [color]="pbtn.color" (click)="ParentCalls(pbtn.id)">
                                <mat-icon>{{pbtn.icon}}</mat-icon>
                            </button>

                            <mat-menu #exportMenu="matMenu" [hasBackdrop]="false" yPosition="below">
                                <!--<div>
                                  <button mat-button (click)="ExportToExcel()">
                                    Export to Excel
                                  </button></div>
                                <div>
                                  <button mat-button (click)="exportAs('pdf', 'l','current')">
                                    Export Current Page to PDF
                                  </button>
                                </div>
                                <div>
                                  <button mat-button (click)="exportAs('pdf', 'l','whole')">
                                    Export Whole Data to PDF
                                  </button>
                                </div>-->
                                <div>
                                    <button mat-button (click)="exportAllData()">
                                        Export All Data
                                    </button>
                                </div>
                            </mat-menu>
                        </form>
                    </div>
                </div>
            </mat-drawer>
            <mat-drawer-content>
                <mat-toolbar class="bg-card border-b h-[47px]" role="heading" fuseScrollbar>
                    <span class="text-lg font-medium tracking-tight leading-6" *ngIf="!tableOptions.hideTitle">
                        {{t(changeHeader(tableOptions.title)) }}
                        <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm "
                            *ngIf="tableOptions.pageTotal !== 0 && tableOptions.title !== '' ">
                            {{tableOptions.pageTotal}} </span>
                    </span>
                    <button class="px-6 font-semibold text-lg mini-form-field" mat-stroked-button
                        *ngIf="tableOptions.add && !tableOptions.addText" type="button" matTooltip="Add New Record"
                        color="primary" (click)="AddTableRecord()" id="addbtn{{tableOptions.title}}">
                        <!--<mat-icon class="mat-18 mb-1">add</mat-icon>-->
                        Add
                    </button>
                    <button class="px-1 mini-form-field" mat-stroked-button
                        *ngIf="tableOptions.add && tableOptions.addText" type="button" [matTooltipPosition]="'right'"
                        [matTooltip]="tableOptions.addText" color="primary" (click)="AddTableRecord()"
                        id="addbtn{{tableOptions.title}}">
                        <mat-icon class="mat-18" *ngIf="tableOptions.addIcon">{{tableOptions.addIcon}}</mat-icon>
                        <mat-icon class="mat-18" *ngIf="!tableOptions.addIcon">add</mat-icon>
                        <span class="mr-1"> {{tableOptions.addText}}</span>
                    </button>
                    <div class="spacer"></div>
                    <!-- For small screen -->
                    <div class="flex flex-row items-end" *ngIf="tableOptions.floatingFilter">
                        <button class="sm:hidden -ml-2" mat-icon-button (click)="drawer.toggle()">
                            <!-- <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon> -->
                            <mat-icon color="primary">settings</mat-icon>
                        </button>
                        <div class="flex gap-[15px] hidden sm:inline-flex">
                            <form class="flex flex-row items-center justify-center">
                                <mat-form-field class="pl20" style="padding: 10px;font-size: 15px;"
                                    *ngIf="tableOptions.dropdwn">
                                    <mat-select [multiple]="false" placeholder="Asset"
                                        (selectionChange)="filterDrpLstChange($event.value)"
                                        [formControl]="assetDrpCtrl">
                                        <mat-option *ngFor="let item of tableOptions.filterDrpLstFilteredList"
                                            [value]="item._id">
                                            {{item.ip}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!--<div class="flex flex-row items-end" *ngIf="tableOptions.add"></div>-->
                                <mat-form-field floatLabel="never"
                                    class="mini-form-field fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-[200px]">
                                    <input matInput class="form-control" [(ngModel)]="filterText" type="text"
                                        trim="blur" name="filterText{{tableOptions.id}}" [id]="tableOptions.id"
                                        (ngModelChange)="filterUpdate.next($event)" placeholder="Search">
                                    <button class="gray" matSuffix mat-icon-button type="button"
                                        *ngIf="!filterText || filterText === ''" color="primary">
                                        <mat-icon>search</mat-icon>
                                    </button>
                                    <button class="gray" matSuffix mat-icon-button type="button"
                                        *ngIf="filterText && filterText !== ''"
                                        (click)="filterText = ''; doFilter(filterText);" color="primary">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                                <div class="flex flex-row items-end" *ngIf="tableOptions.refreshData">
                                    <button class="gray" matSuffix mat-icon-button matTooltip="Reload Table Data"
                                        (click)="RefreshTableData();" color="primary">
                                        <mat-icon id="rfrbtn{{tableOptions.title}}"
                                            svgIcon="mat_outline:refresh"></mat-icon>
                                    </button>
                                </div>
                                <div class="flex flex-row items-end" *ngIf="tableOptions.showFilter">
                                    <button class="gray" matSuffix mat-icon-button matTooltip="Filter Table Data"
                                        (click)="FilterTableData();" color="primary">
                                        <mat-icon id="rfrbtn{{tableOptions.title}}">filter_alt</mat-icon>
                                    </button>
                                </div>
                                <div class="flex flex-row items-end" *ngIf="tableOptions.showTagFilter">
                                    <button class="gray" matSuffix mat-icon-button matTooltip="Filter by Tags"
                                        (click)="FilterTagTableData();" color="primary">
                                        <mat-icon id="rfrbtn{{tableOptions.title}}"
                                            svgIcon="heroicons_solid:tag"></mat-icon>
                                    </button>
                                </div>
                                <div *ngIf="tableOptions.refreshData">
                                    <mat-form-field
                                        class="mini-form-field min-w-[64px] fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded">
                                        <mat-select [(value)]="selectedTimer" (selectionChange)="timerCallData()"
                                            matTooltip="Refresh Interval">
                                            <mat-option value="0" selected>No Refresh</mat-option>
                                            <mat-option value="60" id="RefreshOnemin">1 Min</mat-option>
                                            <mat-option value="300" id="RefreshFivemin">5 Min</mat-option>
                                            <mat-option value="1800" id="RefreshThirtymin">30 Min</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <button mat-icon-button menuOpen="false" matTooltip="Filter List"
                                    *ngIf="tableOptions.showColFilter && _sTableOptions.columns && _sTableOptions.columns.length"
                                    [matMenuTriggerFor]="filterMenu" #filterMenuTrigger="matMenuTrigger">
                                    <mat-icon>filter_list</mat-icon>
                                </button>
                                <mat-menu #filterMenu="matMenu" class="col-filters">
                                    <mat-card class="filter-card" (click)="$event.stopPropagation();">
                                        <mat-card-header>
                                            <mat-card-title>Filter</mat-card-title>
                                        </mat-card-header>
                                        <mat-card-content>
                                            <form>
                                                <ng-container *ngFor="let column of _sTableOptions.columns">
                                                    <mat-form-field *ngIf="column.iscolumnSearch" class="w-full">
                                                        <input matInput type="text" trim="blur" [id]="column.id"
                                                            placeholder="Search {{column.header}}"
                                                            *ngIf="!column.selectFilter" name="{{column.columnDef}}"
                                                            [(ngModel)]="colfilter[column.columnDef]"
                                                            (input)='columnFilter($any($event.target).value,column.columnDef)'
                                                            [disabled]="(column.iscolumnSearch === undefined || column.iscolumnSearch===null) ? 'false' : !column.iscolumnSearch ">
                                                        <mat-select placeholder="Choose filter value"
                                                            name="{{column.columnDef}}" *ngIf="column.selectFilter"
                                                            [(ngModel)]="colfilter[column.columnDef]"
                                                            [disabled]="(column.iscolumnSearch === undefined || column.iscolumnSearch===null) ? 'false' : !column.iscolumnSearch "
                                                            (selectionChange)="columnFilter($event.value, column.columnDef)">
                                                            <mat-option value="">All</mat-option>
                                                            <mat-option *ngFor="let val of column.selectFilterArr"
                                                                [value]="val.value"
                                                                [id]="val.id">{{val.name}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </ng-container>
                                            </form>
                                        </mat-card-content>
                                    </mat-card>
                                </mat-menu>
                                <button mat-icon-button menuOpen="false" (click)="openListColumns()"
                                    #listMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="belowMenu" color="primary"
                                    id="view{{tableOptions.id}}" matTooltip="View Column, Reorder Columns"
                                    *ngIf="tableOptions.showhideList">
                                    <mat-icon>view_column</mat-icon>
                                </button>
                                <mat-menu #belowMenu="matMenu" [hasBackdrop]="true" yPosition="below" class="p-4">
                                    <div cdkDropList (cdkDropListDropped)="drop($event)">
                                        <div class="flex flex-row items-center w-full"
                                            *ngFor="let item of showhideList; let i = index" cdkDrag>
                                            <i class="material-icons color_icon mr-1 mt-2 cursor-move"> drag_handle </i>
                                            <mat-checkbox color="primary" [checked]="item.visible"
                                                (click)="$event.stopPropagation()" (change)="showHideColumn(i, item)"
                                                class="left-align" [id]="item.header">
                                                {{item.header}} </mat-checkbox>
                                        </div>
                                    </div>
                                </mat-menu>
                                <button mat-icon-button menuOpen="false" matTooltip="Filter List"
                                    *ngIf="sTableOptions.cFilter" color="primary" [matMenuTriggerFor]="filterMenu"
                                    #filterMenuTrigger="matMenuTrigger">
                                    <mat-icon class="">filter_list</mat-icon>
                                </button>
                                <mat-menu #filterMenu="matMenu" class="col-filters">
                                    <mat-card class="filter-card" (click)="$event.stopPropagation();">
                                        <mat-card-header>
                                            <mat-card-title>Filter</mat-card-title>
                                        </mat-card-header>
                                        <mat-card-content>
                                            <form #filterForm="ngForm" role="form">
                                                <mat-form-field
                                                    *ngIf="sTableOptions.columns && sTableOptions.columns.length"
                                                    class="w-full" appearance="outline">
                                                    <mat-label>Choose filter column</mat-label>
                                                    <mat-select placeholder="Choose filter column" name="chooseFilter"
                                                        required [(ngModel)]="cFilter.selectedCol">
                                                        <mat-option value="">Choose filter column</mat-option>
                                                        <mat-option *ngFor="let val of sTableOptions.columns"
                                                            [value]="val.col" [id]="val.id">{{val.header}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-form-field *ngIf="cFilter.selectedCol" class="w-full"
                                                    appearance="outline">
                                                    <mat-label>
                                                        Search {{colHash[cFilter.selectedCol].header}}</mat-label>
                                                    <input matInput type="text" trim="blur"
                                                        *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'text'"
                                                        placeholder="Search {{colHash[cFilter.selectedCol].header}}"
                                                        required name="{{colHash[cFilter.selectedCol].col}}s"
                                                        [(ngModel)]="colHash[cFilter.selectedCol].val">
                                                    <mat-select
                                                        placeholder="Search {{colHash[cFilter.selectedCol].header}}"
                                                        name="{{colHash[cFilter.selectedCol].col}}s"
                                                        *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'select'"
                                                        required [(ngModel)]="colHash[cFilter.selectedCol].val">
                                                        <mat-option
                                                            *ngFor="let val of colHash[cFilter.selectedCol].colFilters.options"
                                                            [value]="val.value">{{val.name}}</mat-option>
                                                    </mat-select>
                                                    <ng-container
                                                        *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'range'">
                                                        <mat-date-range-input [rangePicker]="picker">
                                                            <input matStartDate name="startDate"
                                                                [(ngModel)]="colHash[cFilter.selectedCol].start"
                                                                placeholder="Start date">
                                                            <input matEndDate name="startDate"
                                                                [(ngModel)]="colHash[cFilter.selectedCol].end"
                                                                placeholder="End date">
                                                        </mat-date-range-input>
                                                        <mat-datepicker-toggle matSuffix
                                                            [for]="picker"></mat-datepicker-toggle>
                                                        <mat-date-range-picker #picker></mat-date-range-picker>
                                                    </ng-container>
                                                </mat-form-field>
                                                <button (click)="columnFilterxlsx(colHash[cFilter.selectedCol])"
                                                    [disabled]="!filterForm.form.valid" color="primary"
                                                    mat-raised-button="">Add Filter
                                                </button>
                                                <button class="ml-2" (click)="cFilter.selectedCol = ''"
                                                    mat-stroked-button="">Clear
                                                </button>
                                            </form>
                                        </mat-card-content>
                                    </mat-card>
                                </mat-menu>
                                <button id="gbtn{{sTableOptions.title}}" (click)="apiDownload('type')" color="primary"
                                    matSuffix mat-icon-button *ngIf="tableOptions.apiDownload"
                                    matTooltip="Download as XLSx" aria-label="download xlsx menu">
                                    <mat-icon class="mat-24">file_download</mat-icon>
                                </button>
                                <button id="gbtn{{sTableOptions.title}}" color="primary" matSuffix mat-icon-button
                                    *ngIf="!tableOptions.hideDownload" matTooltip="Download as XLSx"
                                    aria-label="download xlsx menu" (click)="downloadAsXls('full')">
                                    <mat-icon class="mat-24">file_download</mat-icon>
                                </button>
                                <mat-menu #xlsMenu="matMenu">
                                    <button mat-menu-item id="currentSelection" *ngIf="tableOptions.filterDownload"
                                        (click)="downloadAsXls('filtered')">
                                        <mat-icon class="mat-24">filter_list</mat-icon>
                                        <span>Filtered Data</span>
                                    </button>
                                    <button mat-menu-item id="fullData" (click)="downloadAsXls('full')">
                                        <mat-icon class="mat-24">file_download</mat-icon>
                                        <span>Full Data</span>
                                    </button>
                                </mat-menu>
                                <button mat-icon-button *ngIf="tableOptions.compareData" matTooltip="Compare Data"
                                    color="primary" (click)="CompareData(compSelection.selected)">
                                    <mat-icon>compare</mat-icon>
                                </button>
                                <button mat-icon-button matTooltip="Save Settings" id="save{{tableOptions.id}}"
                                    color="primary" [matMenuTriggerFor]="saveMenu">
                                    <mat-icon>save</mat-icon>
                                </button>
                                <mat-menu #saveMenu="matMenu">
                                    <button mat-menu-item id="saveSettingT" (click)="SaveSettings()">
                                        <mat-icon class="mat-24">save_alt</mat-icon>
                                        <span>Save Table Settings</span>
                                    </button>
                                    <button mat-menu-item id="restSetting" (click)="ResetSettings()"
                                        *ngIf="sessionData && sessionData.id">
                                        <mat-icon class="mat-24">refresh</mat-icon>
                                        <span>Reset Table Settings</span>
                                    </button>
                                </mat-menu>
                                <button mat-raised-button color="primary" (click)="publish()"
                                    *ngIf="tableOptions.publish">
                                    Publish
                                </button>
                                <a mat-icon-button matTooltip="Help Link"
                                    [href]="baseService.helpLinks[tableOptions.id]" target="_blank" color="primary"
                                    *ngIf="baseService.helpLinks && baseService.helpLinks[tableOptions.id] && baseService.helpLinks[tableOptions.id] !== ''">
                                    <mat-icon>help</mat-icon>
                                </a>
                                <!--<button mat-icon-button menuOpen="false" (click)="openListExport()" matTooltip="Download"
                                  #exportMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="exportMenu" *ngIf="tableOptions.exportExcel">
                                  <mat-icon>cloud_download</mat-icon>
                                </button>-->
                                <button *ngFor="let pbtn of tableOptions.parentCalls" mat-icon-button
                                    [matTooltip]="pbtn.tooltip" [matTooltipPosition]="'right'" [color]="pbtn.color" (click)="ParentCalls(pbtn.id)">
                                    <mat-icon>{{pbtn.icon}}</mat-icon>
                                </button>

                                <mat-menu #exportMenu="matMenu" [hasBackdrop]="false" yPosition="below">
                                    <!--<div>
                                      <button mat-button (click)="ExportToExcel()">
                                        Export to Excel
                                      </button></div>
                                    <div>
                                      <button mat-button (click)="exportAs('pdf', 'l','current')">
                                        Export Current Page to PDF
                                      </button>
                                    </div>
                                    <div>
                                      <button mat-button (click)="exportAs('pdf', 'l','whole')">
                                        Export Whole Data to PDF
                                      </button>
                                    </div>-->
                                    <div>
                                        <button mat-button (click)="exportAllData()">
                                            Export All Data
                                        </button>
                                    </div>
                                </mat-menu>
                            </form>
                        </div>
                    </div>
                </mat-toolbar>
                <mat-toolbar class="select-tools h-[47px]"
                    *ngIf="selection.selected.length > 0 && tableOptions.isHideGlobal">
                    <span>{{selection.selected.length}} {{tableOptions.selectText}} selected.</span>
                    <div class="flex flex-1 place-content-end">
                        <ng-container>
                            <button mat-flat-button color="primary" id="gbtnSelction{{tableOptions.title}}"
                                mat-raised-button [matMenuTriggerFor]="gactionSMenu"
                                [disabled]="selection.selected.length === 0" aria-label="global action with a menu">
                                Global Actions
                            </button>
                            <mat-menu #gactionSMenu="matMenu">
                                <ng-container *ngFor="let item of tableOptions.actionMenuItems">
                                    <a id="ganchsel{{item.text}}" class="mat-primary" *ngIf="item.isGlobal"
                                        mat-menu-item (click)="globalActionCall(selection.selected, item)">
                                        <mat-icon *ngIf=item.icon>{{item.icon}}</mat-icon>
                                        <mat-icon *ngIf=!item.icon>arrow_forward_ios</mat-icon>
                                        <span>{{ item.text }}</span>
                                    </a>
                                </ng-container>
                            </mat-menu>
                        </ng-container>
                        <button class="gray" matSuffix mat-icon-button (click)="clearSelection();">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </mat-toolbar>
                <filter-widget [showFilter]="showFilter" [sColumns]="sColumns" (resetRulesFn)="setFilterRules($event)"
                    (toggleFilterFn)="toggleFilterRules($event)" (setRulesFn)="setFilterRules($event)"></filter-widget>
                <app-filter *ngIf="showTagFilter" [showTagFilter]="showTagFilter"
                    (toggleTagFilterFn)="toggleTagFilterRules($event)"
                    (setTagRulesFn)="setTagFilterRules($event)"></app-filter>
                <mat-card-content [ngClass]="{'mt-2': colFilters.length}" class="mb-0 p-0">
                    <mat-progress-bar mode="indeterminate" *ngIf="tableOptions.loading"></mat-progress-bar>
                    <div #MAINTABLEDIV class="w-full h-full bg-card py-2" fuseScrollbar>
                        <table id="mytable" class="w-full" [ngClass]="{'tablePadding': isTablePadding===true}" mat-table
                            [dataSource]="dataSource" *ngIf="dataSource" matSort #MainTable
                            [matSortActive]="_sTableOptions['sortOptions']['active']"
                            [matSortDirection]="_sTableOptions['sortOptions']['direction']"
                            (matSortChange)="customSort($event)" fixedLayout="true">
                            <ng-container matColumnDef="select" *ngIf="tableOptions.rowSelection" sticky>
                                <th mat-header-cell *matHeaderCellDef class="py-2 dark:bg-gray-700">
                                    <mat-checkbox color="primary" class="stc mini-form-field"
                                        (change)="$event ? masterToggle() : null" id="_selectDeselectAll"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row" class="py-2 ">
                                    <mat-checkbox color="primary" class="stc mini-form-field"
                                        (click)="$event.stopPropagation()" [id]="row._id"
                                        (change)="$event ? rowToggle(row) : null"
                                        [checked]="selection.isSelected(row)"></mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="compare" *ngIf="tableOptions.compareData">
                                <th mat-header-cell *matHeaderCellDef class="py-2">Compare
                                    <!-- <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox> -->
                                </th>
                                <td mat-cell *matCellDef="let row" class="py-2">
                                    <mat-checkbox class="stc mini-form-field" color="primary"
                                        (click)="$event.stopPropagation()"
                                        (change)="$event ? compSelection.toggle(row) : null"
                                        [checked]="compSelection.isSelected(row)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container *ngFor="let column of _sTableOptions.columns"
                                [matColumnDef]="column.columnDef">
                                <th mat-header-cell *matHeaderCellDef [style.width]="column.width"
                                    [style.max-width]="column.width" [style.min-width]="column.width"
                                    class="dark:bg-gray-700 text-sm font-medium">
                                    <mat-label mat-sort-header class=""
                                        [disabled]="(column.isSort === undefined || column.isSort === null) ? 'false' : !column.isSort ">
                                        <span class="text-small font-bold tracking-tight leading-6 truncate">{{
                                            t(changeHeader(column.header)) }}</span></mat-label>
                                </th>
                                <td mat-cell *matCellDef="let row" [ngClass]="{'text-center': column.align === 'center',
                  'text-left': !column.align || column.align === 'left', 'text-right': column.align === 'right'}"
                                    class="wwbreak text-sm" [@fadeInRight]="true" [style.width]="column.width"
                                    [style.vertical-align]="'middle'" [style.max-width]="column.width"
                                    [style.min-width]="column.width">
                                    <span *ngIf="column.filter">
                                        <span *ngIf="column.isHyperlink && !column.isToolTip && !column.rangeColor">
                                            <ng-container
                                                *ngIf="column.isTarget; then thenTemplate; else elseTemplate"></ng-container>
                                            <ng-template #thenTemplate>
                                                <div
                                                    innerHTML="{{getProperty(row, column.columnDef) | formatCell:column.filter}}">
                                                </div>
                                            </ng-template>
                                            <ng-template #elseTemplate>
                                                <a class="cursor-pointer font-base dark:text-blue-400 text-blue-900 hover:text-blue-600"
                                                    (click)="hyperLinkCall(row, column.columnDef)"
                                                    [id]="getProperty(row, column.columnDef)">
                                                    {{getProperty(row, column.columnDef) | formatCell:column.filter}}
                                                </a>
                                            </ng-template>
                                        </span>
                                        <ng-container
                                            *ngIf="!column.isHyperlink && !column.isHtml && !column.rangeColor && !column.htmlIcon">
                                            <span *ngIf="column.isToolTip" matTooltipClass="example-tooltip-red" [matTooltipPosition]="'right'"
                                                matTooltip="{{ getProperty(row, column.isToolTipCol) }}">
                                                {{getProperty(row, column.columnDef) | formatCell:column.filter}}</span>
                                            <span *ngIf="!column.isToolTip">{{getProperty(row, column.columnDef) |
                                                formatCell:column.filter}}</span>
                                        </ng-container>
                                        <span
                                            *ngIf="!column.isHyperlink && column.isHtml && !column.rangeColor && !column.htmlIcon && !column.filter">
                                            <div [innerHTML]="getProperty(row, column.columnDef) | safeHTML"></div>
                                            <!--{{getProperty(row, column.columnDef) | formatCell:column.filter}}-->
                                        </span>
                                        <span
                                            *ngIf="!column.isHyperlink && column.isHtml && column.filter && !column.htmlIcon && !column.rangeColor">
                                            <div [innerHTML]="getProperty(row, column.columnDef) | safeHTML"></div>
                                            <!--{{getProperty(row, column.columnDef) | formatCell:column.filter}}-->
                                        </span>
                                        <span
                                            *ngIf="!column.isHyperlink && column.isHtml && column.filter && column.htmlIcon && !column.rangeColor">
                                            <div
                                                [innerHTML]="getProperty(row, column.columnDef) | formatCell:column.filter">
                                            </div>
                                            <!--{{getProperty(row, column.columnDef) | formatCell:column.filter}}-->
                                        </span>
                                        <ng-container *ngIf="column.rangeColor">
                                            <span class="label"
                                                *ngIf="getProperty(row, column.columnDef) != '-' && !column.isMultiRange"
                                                [ngStyle]="getRangeColor(getProperty(row, column.columnDef))">
                                                {{getProperty(row, column.columnDef)| formatCell:column.filter}}
                                            </span>
                                            <ng-container *ngIf="column.isMultiRange">
                                                <ng-container *ngFor="let colu of sTableOptions.customScore">
                                                    <span class="label mr-2" [matTooltip]='colu.name' [matTooltipPosition]="'right'"
                                                        *ngIf="getProperty(row, colu.key) != '-' && colu.filter"
                                                        [ngStyle]="getRangeColor(getProperty(row, colu.key))">
                                                        {{getProperty(row, colu.key) | formatCell:colu.filter}}
                                                    </span>
                                                    <span class="label mr-2" [matTooltip]='colu.name' [matTooltipPosition]="'right'"
                                                        *ngIf="getProperty(row, colu.key) != '-' && !colu.filter"
                                                        [ngStyle]="getRangeColor(getProperty(row, colu.key))">
                                                        {{getProperty(row, colu.key)}}
                                                    </span>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="column.isSecurityGrade">
                                            <div class="max-w-xs flex ml-6 relative text-white right-1 items-center
                                             justify-center h-4 w-4 rounded-full p-1 m-1 font-bold text-sm" [ngClass]="{
                                                'bg-yellow-600': (row | formatCell:column.filter) === '-' || (row | formatCell:column.filter) === 'B' || (row | formatCell:column.filter) === 'C',
                                                'bg-green-500': (row | formatCell:column.filter) === 'A',
                                                'cs-medium': (row | formatCell:column.filter) === 'D',
                                                'cs-high': (row | formatCell:column.filter) === 'E',
                                                'cs-critical': (row | formatCell:column.filter) === 'F'
                                                }">
                                                {{(row | formatCell:column.filter)}}
                                            </div>
                                        </ng-container>
                                    </span>

                                    <ng-container *ngIf="column.hasMultiData">
                                        <div class="flex flex-wrap gap-2" *ngFor="let col of column.colList">
                                            <span *ngIf="col.isHyperlink && !col.isToolTip">
                                                <a class="cursor-pointer font-base dark:text-blue-400 text-blue-900 hover:text-blue-600"
                                                    (click)="hyperLinkCall(row, col.col)"
                                                    [id]="getProperty(row, column.columnDef)">
                                                    <span>{{ getProperty(row, col.col)}}</span>
                                                </a>
                                            </span>
                                            <span *ngIf="col.isToolTip && !col.isHyperlink"
                                                matTooltipClass="example-tooltip-red" [matTooltipPosition]="'right'"
                                                matTooltip="{{ getProperty(row, col.isToolTipCol) }}">{{
                                                getProperty(row, col.col) }}</span>
                                            <span *ngIf="col.isHyperlink && col.isToolTip"
                                                matTooltipClass="example-tooltip-red" [matTooltipPosition]="'right'"
                                                matTooltip="{{ getProperty(row, col.isToolTipCol) }}">
                                                <a class="cursor-pointer font-base dark:text-blue-400 text-blue-900 hover:text-blue-600"
                                                    [id]="getProperty(row, column.columnDef)"
                                                    (click)="hyperLinkCall(row, col.col)">
                                                    <span>{{ getProperty(row, col.col)}}</span>
                                                </a>
                                            </span>
                                            <span *ngIf="col.isProgressCntrl">
                                                <div class="mat-elevation-z2" [ngClass]="{'progress-s': getProperty(row, 'warn') > getProperty(row, col.col),
                                        'progress-d':getProperty(row, 'warn') <= getProperty(row, col.col) }">
                                                    <span class="percent-b">{{getProperty(row, col.col)}}%</span>
                                                    <div class="p-bar" [style.width]="getProperty(row, col.col)+'%'">
                                                    </div>
                                                </div>
                                            </span>
                                            <span *ngIf="col.isColoredCntrl">
                                                <span *ngIf="getProperty(row, col.col) == '-'">{{getProperty(row,
                                                    col.col)}}</span>
                                                <span class="label" *ngIf="getProperty(row, col.col) != '-'"
                                                    [ngStyle]="{'background-color':col.color}">{{getProperty(row,
                                                    col.col)}}</span>
                                            </span>
                                            <span *ngIf="col.isfaicon">
                                                <i class="{{col.class }}" [ngStyle]="{color: col.color}"></i>
                                            </span>
                                            <span *ngIf="col.filter">
                                                {{getProperty(row, col.col) | formatCell:col.filter}}
                                            </span>
                                            <span *ngIf="col.isAddingText">
                                                {{col.addingText }}{{ getProperty(row, col.col)}}
                                            </span>
                                            <span *ngIf="col.img && col.imgPath">
                                                <img [src]="col.imgPath+'generic.svg'"
                                                    *ngIf="!getProperty(row, col.col)" alt="generic"
                                                    class="{{col.class}}">
                                                <img [src]="col.imgPath+getProperty(row, col.col)"
                                                    *ngIf="getProperty(row, col.col)" alt="logo" class="{{col.class}}">
                                            </span>
                                            <span *ngIf="col.img && col.iconPath">
                                                <mat-icon [svgIcon]="col.iconPath" *ngIf="!getProperty(row, col.col)"
                                                    class="{{col.class}}"></mat-icon>
                                                <mat-icon [svgIcon]="col.iconPath+getProperty(row, col.col)"
                                                    *ngIf="!getProperty(row, col.col)" class="{{col.class}}"></mat-icon>
                                            </span>
                                            <span
                                                *ngIf="!col.isHyperlink && !col.isProgressCntrl && !col.isColoredCntrl && !col.isToolTip
                                                        && !col.filter && !col.isfaicon && !col.isAddingText && !col.img"
                                                class="ml-1">
                                                {{ getProperty(row, col.col) }}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <ng-container>
                                        <span *ngIf="column.lengthcontrol">
                                            <span *ngIf="(getProperty(row,
                                        column.columnDef)).length>2 || !row.showAllFlag">{{getProperty(row,
                                                column.columnDef) | slice:0:2 }}</span>
                                            <span *ngIf="(getProperty(row,
                                        column.columnDef)).length<2 || row.showAllFlag">{{getProperty(row,
                                                column.columnDef)}}</span>
                                            <button class="text-primary-400" *ngIf="(getProperty(row,
                                        column.columnDef)).length>2 " (click)="showAll(row)">
                                                <p *ngIf="!row.showAllFlag">...</p>
                                                <p class="ml-1" *ngIf="row.showAllFlag"> close</p>
                                            </button>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="column.isProgressCntrl">
                                        <div class="mat-elevation-z2" [ngClass]="{'progress-s': getProperty(row, 'warn') > getProperty(row, column.columnDef),
                          'progress-d':getProperty(row, 'warn') <= getProperty(row, column.columnDef) }">
                                            <span class="percent-b">{{getProperty(row, column.columnDef)}}%</span>
                                            <div class="p-bar" [style.width]="getProperty(row, column.columnDef)+'%'">
                                            </div>
                                        </div>
                                    </ng-container>

                                    <!-- <ng-container *ngIf="column.isDynamicCntrl">
                                          <div [innerHTML]="getProperty(row, column.columnDef)"></div>
                                    </ng-container> -->

                                    <ng-container *ngIf="column.isColoredCntrl">
                                        <ng-container
                                            *ngIf="column.isCustomText; then thenTemplate; else elseTemplate"></ng-container>
                                        <ng-template #thenTemplate>
                                            <ng-container *ngIf="column.isCustomClass">
                                                <span
                                                    *ngIf="getProperty(row, column.columnDef) != '-'  && getProperty(row, column.columnDef) !== null"
                                                    class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                                                    [ngClass]="getCustomClass(getProperty(row, column.columnDef))">
                                                    <span class="leading-relaxed whitespace-nowrap">
                                                        {{getCustomText(getProperty(row, column.columnDef)) }}
                                                    </span>
                                                </span>
                                            </ng-container>
                                            <ng-container *ngIf="!column.isCustomClass">
                                                <!-- <span *ngIf="getProperty(row, column.columnDef) == '-'">{{getCustomText(getProperty(row, column.columnDef))}}</span> -->
                                                <span class="label" *ngIf="getProperty(row, column.columnDef) != '-'"
                                                    [ngStyle]="getCustomColor(getProperty(row, column.columnDef))">{{getCustomText(getProperty(row,
                                                    column.columnDef)) }}</span>
                                            </ng-container>
                                        </ng-template>
                                        <ng-template #elseTemplate>
                                            <span *ngIf="getProperty(row, column.columnDef) == '-'">{{getProperty(row,
                                                column.columnDef)}}</span>
                                            <span class="label" *ngIf="getProperty(row, column.columnDef) != '-'"
                                                [ngStyle]="{'background-color':column.color}">{{getProperty(row,
                                                column.columnDef)}}</span>
                                        </ng-template>
                                    </ng-container>
                                    <ng-container *ngIf="column.statusicon">
                                        <img class="vendor-logo {{column.class}}" [src]='getIconPath(row, column)'
                                            *ngIf="column.columnDef !== 'status' && column.columnDef !== 'asset_status' && column.columnDef !== 'updated_status'"
                                            [matTooltip]='getIconTooltip(row, column)' [matTooltipPosition]="'right'"
                                            [alt]='getIconTooltip(row, column)'>
                                        <img [src]='getIconPath(row, column)' alt="Online" class="w-8"
                                            *ngIf="(column.columnDef === 'status' || column.columnDef === 'asset_status' || column.columnDef === 'updated_status') && getProperty(row, column.columnDef) !== 'NoData'"
                                            [matTooltip]='getIconTooltip(row, column)' [matTooltipPosition]="'right'"
                                            [alt]='getIconTooltip(row, column)' height="512" width="512">
                                        <div>
                                            <span hidden [innerHTML]="getIconTooltip(row, column) | safeHTML"></span>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="!column.filter">
                                        <ng-container *ngIf="column.icon">
                                            <ng-container>
                                                <span>
                                                    <ng-container *ngFor="let icon of column.iconList">
                                                        <mat-icon [ngClass]="[icon.class]"
                                                            *ngIf="getProperty(row, column.columnDef) === icon.value">
                                                            {{icon._icon}}
                                                        </mat-icon>
                                                    </ng-container>
                                                </span>
                                            </ng-container>
                                        </ng-container>

                                        <ng-container *ngFor="let img of column.conditionList">
                                            <img class="{{img.class}}" [src]="img._img" [alt]="img.value"
                                                *ngIf="getProperty(row, column.columnDef) === img.value" [matTooltipPosition]="'right'"
                                                [matTooltip]="img.displaytext">
                                            <div>
                                                <span hidden *ngIf="getProperty(row, column.columnDef) === img.value"
                                                    [innerHTML]="getProperty(row, column.columnDef)"></span>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="column.img && column.imgPath && !column.isHyperlink">
                                            <!--[src]="column.imgPath+'generic.svg'" alt="generic"-->
                                            <img [ngSrc]="column.imgPath+'generic.svg'" alt="generic" width="32"
                                                height="32"
                                                *ngIf="getProperty(row, column.columnDef) === null || getProperty(row, column.columnDef) === undefined"
                                                class="{{column.class}}">

                                            <!--[src]="column.imgPath+getProperty(row, column.columnDef)"-->
                                            <img [ngSrc]="column.imgPath+getProperty(row, column.columnDef)"
                                                alt="generic" width="32" height="32"
                                                [alt]="getProperty(row, column.columnDef)"
                                                title="{{column.isToolTipCol === undefined? '': getProperty(row, column.isToolTipCol)}}"
                                                *ngIf="getProperty(row, column.columnDef) !== null &&
                                                   getProperty(row, column.columnDef) !== undefined "
                                                class="{{column.class}}">
                                            <div>
                                                <span hidden [innerHTML]="getProperty(row, column.columnDef)"></span>
                                            </div>

                                        </ng-container>

                                        <!-- <ng-container *ngIf="column.isAddingText">
                                              {{ tableOptions.addingText}}
                                            </ng-container> -->

                                        <ng-container *ngIf="column.isAddingText">
                                            {{ column.addingText }} {{ getProperty(row, column.columnDef) }}
                                        </ng-container>

                                        <span
                                            *ngIf="column.isHyperlink && !column.isToolTip && !column.isTargetLink && !column.isClass">
                                            <ng-container *ngIf="column.hyperLinks && column.hyperLinks.hotLink">
                                                <ng-container *ngIf="getProperty(row, column.columnDef)">
                                                    <ng-container *ngIf="column.hyperLinks.hotLink">
                                                        <!-- *ngIf="getProperty(row, column.columnDef).indexOf(column.hyperLinks.match) > -1" -->
                                                        <a class="cursor-pointer font-base dark:text-blue-400 text-blue-900 hover:text-blue-600"
                                                            *ngIf="getProperty(row, column.columnDef) && getProperty(row, column.columnDef).toString().includes(column.hyperLinks.match)"
                                                            target="_blank"
                                                            [href]="column.hyperLinks.hotLink + getProperty(row, column.columnDef)">
                                                            <span>{{getProperty(row, column.columnDef)}}</span>
                                                        </a>
                                                        <div
                                                            *ngIf="getProperty(row, column.columnDef) && !getProperty(row, column.columnDef)?.toString()?.includes(column.hyperLinks.match)">
                                                            <span>{{getProperty(row, column.columnDef)}}</span>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="!column.hyperLinks || !column.hyperLinks.hotLink">
                                                <ng-container
                                                    *ngIf="column.isTarget; then thenTemplate; else elseTemplate"></ng-container>
                                                <ng-template #thenTemplate>
                                                    <div [innerHTML]="getProperty(row, column.columnDef)"></div>
                                                </ng-template>
                                                <ng-template #elseTemplate>
                                                    <a class="cursor-pointer font-base dark:text-blue-400 text-blue-900 hover:text-blue-600"
                                                        (click)="hyperLinkCall(row, column.columnDef)"
                                                        [id]="getProperty(row, column.columnDef)">
                                                        <ng-container *ngIf="column.badge">
                                                            <span class="label"
                                                                [ngClass]="getProperty(row, column.columnDef) === 0 ? 'bg-red-600 text-white' : 'bg-green-600 text-white'"
                                                                *ngIf="getProperty(row, column.columnDef) != '-'">
                                                                {{getProperty(row, column.columnDef)}}
                                                            </span>
                                                        </ng-container>
                                                        <ng-container *ngIf="!column.badge">
                                                            <span>{{
                                                                getProperty(row, column.columnDef) |
                                                                formatCell:column.filter}}</span>
                                                        </ng-container>
                                                    </a>
                                                </ng-template>
                                            </ng-container>
                                        </span>
                                        <span
                                            *ngIf="column.isHyperlink && !column.isToolTip && !column.isTargetLink && column.isClass">
                                            <div class="flex flex-row items-center w-[110px]" [ngClass]="column.width">
                                                <span *ngIf="column.img && column.imgPath && !column.customImg">
                                                    <img [src]="column.imgPath" alt="generic" class="w-[1.5rem]">
                                                </span>
                                                <a class="cursor-pointer ml-2 font-base dark:text-blue-400 text-blue-500 hover:text-blue-600"
                                                    *ngIf="column.img && column.iconPath"
                                                    (click)="hyperLinkCall(row, column.columnDef)">
                                                    <span>
                                                        <ng-container *ngIf="!column.iconPathCondition">
                                                            <mat-icon [svgIcon]="column.iconPath"
                                                                class="w-[1.5rem] dark:text-blue-400 text-blue-500 hover:text-blue-600"
                                                                [ngClass]=" column.class"></mat-icon></ng-container>
                                                        <ng-container *ngIf="column.iconPathCondition">
                                                            <mat-icon
                                                                *ngIf="getProperty(row, column.iconPathCondition.key) === column.iconPathCondition.value"
                                                                [svgIcon]="column.iconPath"
                                                                class="w-[1.5rem] dark:text-blue-400 text-blue-500 hover:text-blue-600"
                                                                [ngClass]=" column.class"></mat-icon>
                                                        </ng-container>
                                                    </span>
                                                </a>

                                                <span *ngIf="column.customImg">
                                                    <img [src]="column.imgPath+'generic.svg'"
                                                        *ngIf="!getProperty(row, column.imgCol)"
                                                        [alt]="getProperty(row, column.imgCol)" class="w-[1.2rem]">
                                                    <img [src]="column.imgPath+getProperty(row, column.imgCol)"
                                                        *ngIf="getProperty(row, column.imgCol)"
                                                        [alt]="getProperty(row, column.imgCol)" class="w-[1.2rem]">
                                                </span>
                                                <a class="cursor-pointer ml-2 font-base dark:text-blue-400 text-blue-900 hover:text-blue-600"
                                                    *ngIf="!column.isCustomClass && !column.iconPath"
                                                    (click)="hyperLinkCall(row, column.columnDef)">
                                                    <span class="" [ngClass]="column.class">
                                                        {{getProperty(row, column.columnDef)}}
                                                    </span>
                                                </a>
                                                <a class="cursor-pointer ml-2 font-base dark:text-blue-400 text-blue-900 hover:text-blue-600"
                                                    *ngIf="column.isCustomClass"
                                                    (click)="hyperLinkCall(row, column.columnDef)">
                                                    <span class="font-bold text-base"
                                                        [ngClass]="{
                                                        'tc-critical':  getProperty(row, column.columnDef) >= 90,
                                                         'tc-low': (getProperty(row, column.columnDef) > 0 && getProperty(row, column.columnDef) < 40),
                                                        'tc-medium': (getProperty(row, column.columnDef) > 40 && getProperty(row, column.columnDef) < 70),
                                                         'tc-high': (getProperty(row, column.columnDef) > 70 && getProperty(row, column.columnDef) < 90),
                                                        'tc-info': !getProperty(row, column.columnDef) || getProperty(row, column.columnDef) === 0,}">
                                                        {{getProperty(row, column.columnDef)}}
                                                    </span>
                                                </a>
                                            </div>
                                        </span>
                                        <span *ngIf="column.isToolTip && !column.isHyperlink"
                                            matTooltipClass="example-tooltip-red" [matTooltipPosition]="'right'"
                                            matTooltip="{{ getProperty(row, column.isToolTipCol) }}">
                                            {{ getProperty(row, column.columnDef) }}
                                        </span>
                                        <span *ngIf="column.isHyperlink && column.isToolTip"
                                            matTooltipClass="example-tooltip-red" [matTooltipPosition]="'right'"
                                            matTooltip="{{ getProperty(row, column.isToolTipCol) }}"
                                        >
                                            <a class="cursor-pointer font-base dark:text-blue-400 text-blue-900 hover:text-blue-600"
                                                (click)="hyperLinkCall(row, column.columnDef)"
                                                [id]="getProperty(row, column.columnDef)">
                                                <span>{{ getProperty(row, column.columnDef)}}</span>
                                            </a>
                                        </span>
                                        <span *ngIf="column.isHyperlink && column.isTargetLink && !column.hyperLinks">
                                            <a class="cursor-pointer font-base dark:text-blue-400 text-blue-900 hover:text-blue-600"
                                                target="_blank" [href]="getProperty(row, column.isTargetLink)">
                                                <span>{{getProperty(row, column.columnDef)}}</span>
                                            </a>
                                        </span>
                                        <span *ngIf="column.isfaicon">
                                            <i class="{{column.class }}" [ngStyle]="{color: column.color}"></i>{{
                                            getProperty(row, column.columnDef) }}
                                        </span>
                                        <ng-container *ngIf="column.isClass && column.class && !column.isHyperlink">
                                            <!-- <span class="{{column.class}}">
                                                {{getProperty(row, column.columnDef) }}
                                            </span> -->
                                            <span class="label" [ngClass]="column.class"
                                                *ngIf="getProperty(row, column.columnDef) != '-'">
                                                {{getProperty(row, column.columnDef)}}
                                            </span>
                                        </ng-container>
                                        <ng-container *ngIf="column.isTags">
                                            <ng-container *ngIf="column.columnDef === 'tags'">
                                                <div class="flex flex-wrap items-center justify-start -mx-1 gap-1"
                                                    [ngClass]="column.width">
                                                    <ng-container
                                                        *ngFor="let label of getProperty(row, column.columnDef) | tags">
                                                        <div
                                                            class="text-xs w-auto m-1 rounded px-1 bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300">
                                                            {{label.key}}: <span
                                                                class="break-all">{{label.value}}</span>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="column.columnDef === 'manual_tags'">
                                                <div class="flex flex-wrap items-center justify-start -mx-1 gap-1"
                                                    [ngClass]="column.width">
                                                    <ng-container
                                                        *ngFor="let label of getProperty(row, column.columnDef) | manualtags">
                                                        <div
                                                            class="text-xs w-auto m-1 rounded px-1 bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300">
                                                            {{label.key}}: <span
                                                                class="break-all">{{label.value}}</span>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="column.textBadge">
                                            <div class="flex flex-wrap items-center justify-start -mx-1 gap-1"
                                                [ngClass]="column.width">
                                                <ng-container
                                                    *ngFor="let label of getProperty(row, column.columnDef) | textbadge">
                                                    <span
                                                        class="text-xs w-auto m-1 rounded px-1 py-0.5 break-all ring-1">
                                                        {{label | uppercase}}
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="column.cHyperLink">
                                            <div class="flex flex-wrap items-center justify-start -mx-1 gap-1"
                                                [ngClass]="column.width">
                                                <ng-container
                                                    *ngIf="getProperty(row, column.conditions[0]) === '*'
                                                 || (!getProperty(row, column.conditions[0]) && !getProperty(row, column.conditions[1]))">
                                                    <a class="cursor-pointer"
                                                        (click)="hyperLinkCall(row, column.columnDef)">
                                                        <span
                                                            class="text-xs w-auto m-1 rounded px-1 py-0.5 break-all ring-1">
                                                            All
                                                        </span>
                                                    </a>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="getProperty(row, column.conditions[0]) !== '*' &&
                                                 (getProperty(row, column.conditions[0]) || getProperty(row, column.conditions[1]))">
                                                    <a class="cursor-pointer"
                                                        (click)="hyperLinkCall(row, column.columnDef)">
                                                        <span
                                                            class="text-xs w-auto m-1 rounded px-1 py-0.5 break-all ring-1">
                                                            Restricted
                                                        </span>
                                                    </a>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="column.isstatuscontrol">
                                            <div class="flex flex-row items-center w-full gap-1">
                                                <div class="flex items-center text-semibold">
                                                    <div class="text-md font-medium truncate">Success:</div>
                                                    <div class="ml-1.5 text-lg font-semibold"
                                                        [ngClass]="{'text-green-600': getProperty(row, column.columnDef)[0] > 0}">
                                                        {{getProperty(row, column.columnDef)[0]}}</div>,
                                                </div>
                                                <div class="flex items-center text-semibold">
                                                    <div class="text-md font-medium truncate">Failed:</div>
                                                    <div class="ml-1.5 text-lg font-semibold"
                                                        [ngClass]="{'text-red-600': getProperty(row, column.columnDef)[1] > 0}">
                                                        {{getProperty(row, column.columnDef)[1]}}</div>,
                                                </div>
                                                <div class="flex items-center text-semibold">
                                                    <div class="text-md font-medium truncate">Pending:</div>
                                                    <div class="ml-1.5 text-lg font-semibold"
                                                        [ngClass]="{'text-orange-600': getProperty(row, column.columnDef)[2] > 0}">
                                                        {{getProperty(row, column.columnDef)[2]}}</div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="!column.icon && !column.img && !column.isHyperlink &&
                                           !column.isProgressCntrl && !column.isColoredCntrl && !column.rangeColor
                                            && !column.hasMultiData && !column.statusicon && !column.isfaicon
                                           && !column.isAddingText  && !column.isToolTip && !column.isClass
                                            && !column.isTags && !column.textBadge && !column.cHyperLink && !column.isSecurityGrade && !column.isstatuscontrol">
                                            <span *ngIf="getProperty(row, column.columnDef)">
                                                {{ getProperty(row, column.columnDef) }}
                                            </span>
                                            <span *ngIf="!getProperty(row, column.columnDef) && column.fillEmpty">{{
                                                getProperty(row, column.fillEmpty) |
                                                formatCell:column.filter}}</span>
                                        </ng-container>
                                        <ng-container *ngIf="column.rangeColor">
                                            <span class="label"
                                                *ngIf="getProperty(row, column.columnDef) != '-' && !column.isMultiRange"
                                                [ngStyle]="getRangeColor(getProperty(row, column.columnDef))">
                                                {{getProperty(row, column.columnDef)}}
                                            </span>
                                            <ng-container *ngIf="column.isMultiRange">
                                                <ng-container *ngFor="let colu of sTableOptions.customScore">
                                                    <span class="label mr-2" [matTooltip]='colu.name' [matTooltipPosition]="'right'"
                                                        *ngIf="getProperty(row, colu.key) != '-' && colu.filter"
                                                        [ngStyle]="getRangeColor(getProperty(row, colu.key))">
                                                        {{getProperty(row, colu.key) | formatCell:colu.filter}}
                                                    </span>
                                                    <span class="label mr-2" [matTooltip]='colu.name' [matTooltipPosition]="'right'"
                                                        *ngIf="getProperty(row, colu.key) != '-' && !colu.filter"
                                                        [ngStyle]="getRangeColor(getProperty(row, colu.key))">
                                                        {{getProperty(row, colu.key)}}
                                                    </span>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="action" *ngIf="tableOptions.showAction" stickyEnd>
                                <th mat-header-cell *matHeaderCellDef class="wwbreak dark:bg-gray-700"
                                    [ngClass]="tableOptions.showAction === true? 'color1' : 'color2' ">
                                    <!--<button id="gbtn{{tableOptions.title}}" mat-icon-button [matMenuTriggerFor]="gactionMenu" [disabled]="selection.selected.length === 0"
                                      aria-label="global action with a menu">
                                      <mat-icon>more_vert</mat-icon>
                                    </button>-->
                                    <span class="text-sm font-medium">Action</span>
                                    <mat-menu #gactionMenu="matMenu">
                                        <ng-container *ngFor="let item of tableOptions.actionMenuItems">
                                            <a id="ganch{{item.text}}" *ngIf=item.isGlobal mat-menu-item
                                                (click)="globalActionCall(selection.selected, item)">
                                                <mat-icon *ngIf=item.icon>{{item.icon}}</mat-icon>
                                                <mat-icon *ngIf=!item.icon>arrow_forward_ios</mat-icon>
                                                <span [id]="item.text">{{ item.text }}</span>
                                            </a>
                                        </ng-container>
                                    </mat-menu>
                                </th>
                                <td mat-cell *matCellDef="let element" class="wwbreak"
                                    [ngClass]="tableOptions.showAction == true? 'color1' : 'color2' ">
                                    <button id="btn{{tableOptions.id}}" mat-icon-button color="primary"
                                        [matMenuTriggerFor]="actionMenu" aria-label="row action with a menu"
                                        [disabled]="selection.selected.length > 0">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #actionMenu="matMenu">
                                        <!-- <ng-container *ngIf="!tableOptions.isActionCondition"> -->
                                        <ng-container *ngFor="let item of tableOptions.actionMenuItems">
                                            <a id="anch{{item.text}}"
                                                *ngIf="item.callback && !item.hideLocal && !item.allowedCondition && ! item.actionConditions"
                                                mat-menu-item (click)="actionCall(element, item)">
                                                <mat-icon *ngIf=item.icon>{{item.icon}}</mat-icon>
                                                <mat-icon *ngIf=!item.icon>arrow_forward_ios</mat-icon>
                                                <span [id]="item.text">{{ item.text }}</span>
                                            </a>
                                            <a id="anch{{item.text}}"
                                                *ngIf="item.callback && !item.hideLocal && item.allowedCondition && item.allowedCondition.indexOf(getPropertyCondition(element, item.actionConditions.key)) !== -1"
                                                mat-menu-item (click)="actionCall(element, item)">
                                                <mat-icon *ngIf=item.icon>{{item.icon}}</mat-icon>
                                                <mat-icon *ngIf=!item.icon>arrow_forward_ios</mat-icon>
                                                <span [id]="item.text">{{ item.text }}</span>
                                            </a>
                                        </ng-container>
                                        <!-- </ng-container> -->
                                        <!-- <ng-container
                                            *ngIf="tableOptions.isActionCondition && tableOptions.actionConditions && tableOptions.actionConditions.key">
                                            <ng-container *ngFor="let item of tableOptions.actionMenuItems;">
                                                <a id="anch{{item.text}}"
                                                    *ngIf="item.callback && !item.hideLocal && item.allowedCondition.indexOf(getPropertyCondition(element, tableOptions.actionConditions.key)) !== -1"
                                                    mat-menu-item (click)="actionCall(element, item)">
                                                    <mat-icon *ngIf=item.icon>{{item.icon}}</mat-icon>
                                                    <mat-icon *ngIf=!item.icon>arrow_forward_ios</mat-icon>
                                                    <span [id]="item.text">{{ item.text }}</span>
                                                </a>
                                            </ng-container>
                                        </ng-container> -->
                                    </mat-menu>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
                                [ngClass]="{hovered: row.hovered, highlighted: row.highlighted}"
                                (click)="rowClick(row, i)" (mouseover)="row.hovered = true"
                                (mouseout)="row.hovered = false"></tr>
                        </table>
                    </div>
                    <div class="mt-2 py-[4%] w-full dark:bg-gray-700 items-center justify-center"
                        *ngIf="!dataSource || !dataSource.data || !dataSource.data.length">
                        <div *ngIf="tableOptions.loading || !sTableOptions.pageData"
                            class="flex flex-col items-center justify-center gap-y-2">
                            <img src="/assets/images/tl.gif" alt="" class="w-20">
                            <div> Please wait!</div>
                        </div>
                        <div *ngIf="!tableOptions.loading && sTableOptions.pageData &&
                         !sTableOptions.pageData.length && tableOptions.pageTotal === 0 && !tableOptions.nomsg"
                            class="flex flex-col items-center justify-center gap-y-2">
                            <img src="/assets/images/empty.png" alt="" class="w-20">
                            <div> No data available!</div>
                        </div>
                        <div *ngIf="!tableOptions.loading && sTableOptions.pageData && !sTableOptions.pageData.length && tableOptions.pageTotal === 0 && tableOptions.nomsg"
                            class="flex flex-col items-center justify-center gap-y-2">
                            <img src="/assets/images/empty.png" alt="" class="w-20">
                            <div>{{tableOptions.nomsg}}</div>
                        </div>
                    </div>
                    <mat-progress-bar mode="indeterminate"
                        *ngIf="tableOptions.loading && dataSource &&  dataSource.data && dataSource.data.length > 25">
                    </mat-progress-bar>
                    <paginator-widget *ngIf="tableOptions.pagination && tableOptions.pageTotal"
                        [pageOptions]="tableOptions.pageOptions" [pageSize]="tableOptions.pageSize"
                        [pageTotal]="tableOptions.pageTotal" (pageChanged)="pageChanged($event)"></paginator-widget>
                </mat-card-content>
            </mat-drawer-content>
        </mat-drawer-container>
    </mat-card>
</ng-container>

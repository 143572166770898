import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { MaterialModule } from 'app/material.module';
import { FormsModule, NgForm } from '@angular/forms';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from 'app/_services/common.service';

@Component({
  selector: 'app-search-cve',
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule, AppFilterPipeModule],
  templateUrl: './search-cve.component.html',
  styleUrls: ['./search-cve.component.scss']
})
export class SearchCveComponent implements OnInit, OnDestroy {
  filterCVE: any;
  cveDetails: any = {};
  tcve: any = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @ViewChild('cveFrm') cveFrm: NgForm;
  constructor(private _bs: BaseRequestService, private _cs: CommonService,
    private ls: LoaderService, private toast: MyToastrService) {
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    if (this._cs.tcve && this._cs.tcve.length) {
      try {
        this.tcve = this._cs.tcve;
        this.filterCVE = this._cs.tcve[0].cve;
        setTimeout(() => { this.cveChange({ value: this._cs.tcve[0].cve }) });
      } catch (e) {
        console.log(e);
      }
    }
  }
  cveChange($event: any): void {
    this.ls.display(true);
    this._bs.doRequest(`/r/company/cve_details`, 'get', null,
      { condition: `cve='CVE-${$event.value.substring(4)}'`, skip: 0, limit: 1 })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        if (res.status) {
          if (res.data && res.data.length) {
            this.cveDetails = res.data[0]
          } else {
            this.cveDetails = {};
          }
        } else {
          const data = (res.message)? res.message : res.data;
this.toast.sToast('error', data);
        }
      });
  }

  processSearchTrendingCVE(result: any): void {

  }

  getCEV(): void {
    this.ls.display(true);
    this._bs.doRequest(`/r/company/cve_details`, 'get', null,
      { condition: `cve='CVE-${this.filterCVE}'`, skip: 0, limit: 1 })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        if (res.status) {
          if (res.data && res.data.length) {
            this.cveDetails = res.data[0]
          } else {
            this.cveDetails = {};
          }
        } else {
          const data = (res.message)? res.message : res.data;
this.toast.sToast('error', data);
        }
      });
  }

  backFn(): void {
    this.filterCVE = ''; this.cveDetails = {}; this.tcve = [];
  }
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import {MatDrawer} from "@angular/material/sidenav";
import {Subject, takeUntil} from "rxjs";
import {FuseMediaWatcherService} from "@fuse/services/media-watcher";
import {LoaderService} from "app/_services/loader.service";
import {BaseRequestService} from "app/_services/base.service";
import {MyToastrService} from "app/_services/toastr.service";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {UserService} from "app/core/user/user.service";
import {CommonService} from "app/_services/common.service";
import {CommonModule} from "@angular/common";
import {MaterialModule} from "app/material.module";
import {AppFilterPipeModule} from "app/_filters/app.filter-pipe.module";
import {FuseScrollbarDirective} from "../../../../@fuse/directives/scrollbar";
import {FuseAlertComponent} from "@fuse/components/alert";
import {DirectivesModule} from "app/-directives/-directives.module";
import {SkeletonComponent} from "../skeleton/skeleton.component";
import {FuseCardComponent} from "../../../../@fuse/components/card";
import {FuseHighlightComponent} from "../../../../@fuse/components/highlight";

@Component({
    selector: "install-probes-agents",
    templateUrl: "./install-probes-agents.component.html",
    styleUrls: ["./install-probes-agents.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule, DirectivesModule, SkeletonComponent, MaterialModule, AppFilterPipeModule, FuseAlertComponent, FuseScrollbarDirective, FuseCardComponent, FuseHighlightComponent,],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstallProbesAgentsComponent implements OnInit {
    @Output() closeCallback = new EventEmitter();
    @Input() showClose = true;
    @ViewChild("drawer") drawer: MatDrawer;
    drawerMode: "over" | "side" = "side";
    drawerOpened: boolean = true;
    panels: any[] = [];
    selectedPanel: string = "probe";
    agentHelp: any = {
        probe: "A Probe Agent is typically installed on any machine in the network and will scan all assets in that defined network.",
        lightweight: "A Lightweight agent is installed on an end user’s system and will only scan that particular asset (useful in work-from-home scenarios when the user is not on the corporate/office network).",
        scan: "Scan Agent will perform the scan only once and return the results without setting it up as a service. A scan agent will help to do a one-time scan.",
    };

    agentHref: any = {
        probe:
            "https://cybercns.atlassian.net/wiki/spaces/Verison2/pages/1631420520/Agent+Configuration#Probe-Agent",
        lightweight:
            "https://cybercns.atlassian.net/wiki/spaces/Verison2/pages/1631420520/Agent+Configuration#Lightweight-Agent",
        scan: "https://cybercns.atlassian.net/wiki/spaces/Verison2/pages/1631420520/Agent+Configuration#Lightweight-Agent",
    };

    apiKey: any = [];
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    currentSecret: any = {};
    agentURL = "";
    agentCommands: any = {
        darwin: "sudo ./cybercnsagent_darwin",
        mac: "sudo ./cybercnsagent_darwin",
        windows: "./cybercnsagent.exe",
        linux: "sudo ./cybercnsagent_linux",
        arm: "sudo ./cybercnsagent_arm",
    };
    showCmd: boolean = false;
    agent = {
        code: "",
        count: 100,
        installationMode: "self",
        installationModeone: "self",
    };

    radioList: any = [
        {
            value: 'probe',
            label: 'Probe',
            svgIcon: "mat_outline:devices",
        },
        {
            value: 'lightweight',
            label: 'Lightweight',
            svgIcon: "mat_outline:laptop_chromebook",
        },
        {
            value: 'scan',
            label: 'Scan',
            svgIcon: "mat_outline:scanner",
        }
    ];
    tenantId: any;
    probeCmd: string;
    lightweightCmd: string;
    scanCmd: string;
    iscopied: boolean;
    agentTypeone: any;
    probeCmdHtml: string;
    lightweightCmdHtml: string;
    scanCmdHtml: string;
    stepsList: any = {};
    private urlHash: any = {};
    osCommands = {};
    private windowsUrl: any;
    private linuxUrl: any;
    private macUrl: any;
    command: any = '';
    msiRequired = false;
    inc = 0;
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _loaderService: LoaderService,
        private _toast: MyToastrService,
        private uS: UserService,
        private _baseService: BaseRequestService,
        public cs: CommonService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Setup available panels
        this.panels = [
            {
                id: "probe",
                icon: "mat_outline:devices",
                title: "Probe",
            },
            {
                id: "lightweight",
                icon: "mat_outline:laptop_chromebook",
                title: "Lightweight",
            },
            {
                id: "scan",
                icon: "mat_outline:scanner",
                title: "Scan",
            },
        ];
        this.uS.userd$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: any) => {
            let s = Object.keys(user["urn:zitadel:iam:org:project:roles"])
            let tenantid = Object.keys(user["urn:zitadel:iam:org:project:roles"][s[0]])[0]
            this.tenantId = tenantid;
            this.updateCommand('windows');
            this._changeDetectorRef.markForCheck();
        });


        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {
                // Set the drawerMode and drawerOpened
                if (matchingAliases.includes("lg")) {
                    this.drawerMode = "side";
                    this.drawerOpened = true;
                } else {
                    this.drawerMode = "over";
                    this.drawerOpened = false;
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Navigate to the panel
     *
     * @param panel
     */
    goToPanel(panel: string): void {
        this.selectedPanel = panel;

        // Close the drawer on 'over' mode
        if (this.drawerMode === "over") {
            this.drawer.close();
        }
    }

    /**
     * Get the details of the panel
     *
     * @param id
     */
    getPanelInfo(id: string): any {
        return this.panels.find((panel) => panel.id === id);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    redirectUrl(selected: any): void {
        window.open(this.agentHref[selected], "_blank");
    }

    installCommand($event: any): void {
        let executePermission: string;
        let executePermissionscan: string;
        const linuxRm = 'cd ..;rm -rf ccnsonetimescan;';
        const windowRm = 'Set-Location ..;Remove-Item ccnsonetimescan -Recurse;';
        if ($event === 'darwin') {
            executePermission = `macurl=$(curl -L -s -g "https://configuration.myconnectsecure.com/api/v4/configuration/agentlink?ostype=darwin" | tr -d '"');sudo mkdir /Applications/CyberCNS/;cd /Applications/CyberCNS; sudo curl -k $macurl -o cybercnsagent_darwin; \r\nsudo chmod +x cybercnsagent_darwin;\n`;
            executePermissionscan = `macurl=$(curl -L -s -g "https://configuration.myconnectsecure.com/api/v4/configuration/agentlink?ostype=darwin" | tr -d '"'); curl -k $macurl -o cybercnsagent_darwin;mkdir ccnsonetimescan;mv cybercnsagent_darwin ccnsonetimescan;cd ccnsonetimescan;\r\nchmod +x cybercnsagent_darwin;\n`;
        } else if ($event === 'linux') {
            executePermission = `linuxurl=$(curl -L -s -g "https://configuration.myconnectsecure.com/api/v4/configuration/agentlink?ostype=linux" | tr -d '"'); curl -k $linuxurl -o cybercnsagent_linux; \r\nchmod +x cybercnsagent_linux;\n`;
            executePermissionscan = `linuxurl=$(curl -L -s -g "https://configuration.myconnectsecure.com/api/v4/configuration/agentlink?ostype=linux" | tr -d '"'); curl -k $linuxurl -o cybercnsagent_linux; mkdir ccnsonetimescan;mv cybercnsagent_linux ccnsonetimescan;cd ccnsonetimescan; \r\nchmod +x cybercnsagent_linux;\n`;
        } else if ($event === 'arm') {
            executePermission = `armurl=$(curl -L -s -g "https://configuration.myconnectsecure.com/api/v4/configuration/agentlink?ostype=arm" | tr -d '"'); curl -k $armurl -o cybercnsagent_arm;  \r\nchmod +x cybercnsagent_arm;\n`;
            executePermissionscan = `armurl=$(curl -L -s -g "https://configuration.myconnectsecure.com/api/v4/configuration/agentlink?ostype=arm" | tr -d '"'); curl -k $armurl -o cybercnsagent_arm; mkdir ccnsonetimescan;mv cybercnsagent_arm ccnsonetimescan;cd ccnsonetimescan;  \r\nchmod +x cybercnsagent_arm;\n`;
        } else if ($event === 'windows') {
            if (!this.msiRequired) {
                executePermission = `[Net.ServicePointManager]::SecurityProtocol = [Net.SecurityProtocolType]::Tls12; \r\n $source = (Invoke-RestMethod -Method "Get" -URI "https://configuration.myconnectsecure.com/api/v4/configuration/agentlink?ostype=windows");\r\n$destination = 'cybercnsagent.exe';\r\nInvoke-WebRequest -Uri $source -OutFile $destination;\r\n`;
                executePermissionscan = `[Net.ServicePointManager]::SecurityProtocol = [Net.SecurityProtocolType]::Tls12; \r\n $source = (Invoke-RestMethod -Method "Get" -URI "https://configuration.myconnectsecure.com/api/v4/configuration/agentlink?ostype=windows");\r\n$destination = 'cybercnsagent.exe';mkdir ccnsonetimescan;cd ccnsonetimescan;\r\nInvoke-WebRequest -Uri $source -OutFile $destination;\r\n`;
            } else {
                executePermission = `msiexec /i "cybercnsagent.msi"  /quiet WRAPPED_ARGUMENTS=`;
                executePermissionscan = `msiexec /i "cybercnsagent.msi"  /quiet WRAPPED_ARGUMENTS=`;
            }
        }

        if ($event !== "windows") {
            this.probeCmd = `${executePermission} ${this.agentCommands[$event]} -c ${this.cs.currentScope.id} -e ${this.tenantId} -i;`;
            this.scanCmd = `${executePermissionscan} ${this.agentCommands[$event]} -c ${this.cs.currentScope.id} -a ${this.currentSecret.clientid} -s companyOnboardCode -b domainName $customerid -m Scan;`;
            this.scanCmd = ($event === 'windows') ? `${this.scanCmd}${windowRm}` : `${this.scanCmd}${linuxRm}`;
        } else {
            if (!this.msiRequired) {
                this.probeCmd = `${executePermission} ${this.agentCommands[$event]} -c ${this.cs.currentScope.id} -e ${this.tenantId} -i;`;
                this.scanCmd = `${executePermissionscan} ${this.agentCommands[$event]} -c ${this.cs.currentScope.id} -a ${this.currentSecret.clientid} -s companyOnboardCode -b domainName $customerid -m Scan;`;
                this.scanCmd = ($event === 'windows') ? `${this.scanCmd}${windowRm}` : `${this.scanCmd}${linuxRm}`;
            } else {
                const msiPermission = `[Net.ServicePointManager]::SecurityProtocol = [Net.SecurityProtocolType]::Tls12;`
                const msiSource = `$source = (Invoke-RestMethod -Method "Get" -URI "https://configuration.myconnectsecure.com/api/v3/configuration/agentlink?ostype=windows&msi_required=true");`
                const msiDest = `$destination = 'cybercnsagent.msi';`;
                const msiWebReq = `Invoke-WebRequest -Uri $source -OutFile $destination;`;
                const msiEnd = `Start-Process msiexec -ArgumentList $msiArguments -Wait`;
                this.probeCmd = `${msiPermission} ${msiSource} ${msiDest} ${msiWebReq} ${executePermission}"-c ${this.cs.currentScope.id} -e ${this.tenantId} -i"' ${msiEnd}`;
                this.lightweightCmd = `${msiPermission} ${msiSource} ${msiDest} ${msiWebReq} ${executePermission}"-c ${this.cs.currentScope.id} -e ${this.tenantId} -i"' ${msiEnd}`;
            }
        }
        const urls = {
            windows: `[Net.ServicePointManager]::SecurityProtocol = [Net.SecurityProtocolType]::Tls12;
              $source =  "${this.windowsUrl}";
              $destination = 'cybercnsagent.exe';
              Invoke-WebRequest -Uri $source -OutFile $destination;
              ./cybercnsagent.exe -c ${this.cs.currentScope.id} -e ${this.tenantId} -i;`,
            darwin: `curl "${this.macUrl}" -o cybercnsagent_darwin; chmod +x cybercnsagent_darwin;
             sudo ./cybercnsagent_darwin -c ${this.cs.currentScope.id} -e ${this.tenantId} -i;`
        }
        this.probeCmdHtml = this.probeCmd; // urls[$event];
        this._changeDetectorRef.markForCheck();
        this.showCmd = true;
        this.apiKey = [
            {
                icon: "mat_outline:developer_board",
                name: "Company ID",
                key: this.cs.currentScope.id
            },
            {
                icon: "mat_outline:insert_chart",
                name: "Tenant ID",
                key: this.tenantId
            }
        ]
    }

    selectedTab($event: MatTabChangeEvent): void {
        let type = ($event.tab.textLabel.toLocaleLowerCase() === 'mac') ? 'darwin' : $event.tab.textLabel.toLocaleLowerCase();
        this.updateCommand(type);
    }

    dWIAgent(): void {
        this._loaderService.display(true);
        this._baseService.doRequest(
        `/r/company/get_web_installer?company_id=${this.cs.currentScope.id}&os_type=${this.agentTypeone}`,
        'get').pipe(takeUntil(this._unsubscribeAll)).subscribe((result: any) => {
            this._loaderService.display(false);
            if (result.status) {
                window.open(result.message, '_blank');
            } else {
                if (this.inc < 5) {
                    this._loaderService.display(true, 'In-Progress...');
                    setTimeout(() => {
                        this._loaderService.display(false);
                        this._loaderService.display(true);
                        this.inc++; this.dWIAgent();
                        }, 5000);
                } else {
                    this._toast.sToast('error', 'In Queue, Please try again after few minutes.');
                }
            }
        });
    }

    dAgent(): void {
        if (this.agentURL) {
            window.open(this.agentURL, '_blank');
            return;
        }
        this._loaderService.display(true);
        this._baseService.doRequest(`r/company/agentlink?region=use&os_type=${this.agentTypeone}`,
            'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this._loaderService.display(false);
                window.open(result.message, '_blank');
            });


    }

    copyClip(cmd: string): void {
        this.iscopied = true;
        const el = document.createElement("textarea");
        el.value = cmd;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        this._toast.sToast("success", "Copied to clipboard");
    }

    copyCmdClip(): void {
        switch (this.selectedPanel) {
            case "probe":
                this.copyClip(this.probeCmdHtml);
                break;
            case "lightweight":
                this.copyClip(this.lightweightCmd);
                break;
            case "scan":
                this.copyClip(this.scanCmd);
                break;
        }
    }

    updateCommand($event: any): void {
        this.showCmd = false;
        $event = ($event.value) ? $event.value : $event;
        this.agentTypeone = $event;
        if (this.urlHash[$event]) {
            this.command = this.osCommands[$event];
            this._changeDetectorRef.markForCheck();
            this.installCommand($event);
            return;
        }
        this.installCommand($event);
        /*this._loaderService.display(true, 'Getting agent link...');
        this._baseService.doRequest(`r/company/agentlink?region=use&os_type=${$event}`,
            'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this._loaderService.display(false);
                if ($event === 'windows') {
                    this.windowsUrl = result.message;
                } else if ($event === 'linux') {
                    this.linuxUrl = result.message;
                } else if ($event === 'darwin') {
                    this.macUrl = result.message;
                } else if ($event === 'arm') {
                    this.macUrl = result.message;
                }
                this.installCommand($event);
            })*/
    }
}

import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { BaseRequestService } from 'app/_services/base.service';
import { TableComponent } from "../../../shared/table/table.component";
import { DynamicFormComponent } from 'app/modules/shared/forms/dynamic-form/dynamic-form.component';
import { LoaderService } from 'app/_services/loader.service';
import { ButtonModule } from "@syncfusion/ej2-angular-buttons";
import { CommonService } from 'app/_services/common.service';
import { AppFilterPipeModule } from "../../../../_filters/app.filter-pipe.module";
import { MyToastrService } from 'app/_services/toastr.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Subject, takeUntil } from 'rxjs';
import { QueryBuilderComponent, QueryBuilderModule } from "@syncfusion/ej2-angular-querybuilder";
import { MaterialModule } from 'app/material.module';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'settings-tags',
    standalone: true,
    imports: [CommonModule, TableComponent, QueryBuilderModule, AppFilterPipeModule, FormsModule, ReactiveFormsModule, MaterialModule],
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class TagsComponent implements OnDestroy, OnInit, AfterViewInit {
    loading: boolean = false
    accountForm: UntypedFormGroup;
    customOperators: any = [
        {
            "value": "startswith",
            "key": "Starts With"
        },
        {
            "value": "endswith",
            "key": "Ends With"
        },
        {
            "value": "contains",
            "key": "Contains"
        },
        {
            "value": "equal",
            "key": "Equal"
        },
        {
            "value": "notequal",
            "key": "Not Equal"
        },
        {
            "value": "in",
            "key": "In"
        }
    ]
    tagTableOptions: any = {
        columns: [
            { "header": "Name", "columnDef": "name", "filter": "", "cell": "(element: any) => ${element.name}", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": false, "iscolumnSearch": false },
            { "header": "Collection", "columnDef": "collection", "filter": "", "cell": "(element: any) => ${element.collection}", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": false, "iscolumnSearch": false },
            { "header": "Risk score", "columnDef": "risk_score", "filter": "", "cell": "(element: any) => ${element.risk_score}", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": false, "iscolumnSearch": false },
            { "header": "Description", "columnDef": "description", "filter": "ellipsis", "cell": "(element: any) => ${element.description}", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": false, "iscolumnSearch": false },
        ],
        sortOptions: { active: 'name', direction: 'asc' },
        _pageData: [],
        tableOptions: {
            title: 'Tag Rules',
            isServerSide: false,
            selectText: 'tags',
            loading: true,
            floatingFilter: true,
            rowSelection: false,
            showAction: true,
            actionMenuItems: [
                {
                    text: 'Edit',
                    icon: 'edit',
                    callback: 'editFunction',
                    hideLocal: false,
                    isGlobal: false,
                },
                {
                    text: 'Delete',
                    icon: 'delete',
                    id: 'delete',
                    callback: 'editFunction',
                }
            ],
            pagination: true,
            pageTotal: 0,
            pageOptions: [5, 10, 25, 100],
            pageSize: 5,
            search: true,
            showhideList: true,
            refreshData: true,
            exportExcel: true,
            add: true,
            columnSearch: false,
            compareData: false,
            filterDownload: false,
            serverSide: {
                url: '/r/company/tag_rules',
                dUrl: '/d/company/tag_rules',
                method: 'post',
                condition: '',
            }, id: 'tags'

        },
        customText: [
            {
                status: 1,
                DisplayText: 'Probe',
            },
            {
                status: 2,
                DisplayText: 'Lightweight Agent',
            },
            {
                status: 3,
                DisplayText: 'Lightweight Agent Installed',
            },
            {
                status: 4,
                DisplayText: 'External Scan Agent',
            },
            {
                range: { from: 0, to: 0 },
                color: '#c2ffc4',
                'text-color': '#000000'
            },
            {
                range: { from: 1, to: 40 },
                color: '#f8c851',
                'text-color': '#000000'
            },
            {
                range: { from: 41, to: 60 },
                color: '#f18c43',
                'text-color': '#000000'
            },
            {
                range: { from: 61, to: 90 },
                color: '#de4a50',
                'text-color': '#ffffff'
            },
            {
                range: { from: 91, to: 99999 },
                color: '#92243e',
                'text-color': '#ffffff'
            },
            {
                status: 25,
                DisplayText: 'Low',
                color: '#f8c851',
                'text-color': '#000000'
            },
            {
                status: 50,
                DisplayText: 'Medium',
                color: '#f18c43',
                'text-color': '#000000'
            },
            {
                status: 75,
                DisplayText: 'High',
                color: '#de4a50',
                'text-color': '#ffffff'
            },
            {
                status: 100,
                DisplayText: 'Critical',
                color: '#92243e',
                'text-color': '#ffffff'
            }
        ],
        changeValue: new Subject<any>(),
    }

    tagRules: any = {
        collection: '',
        tags: []
    };
    collections: any = [];
    sColumns: any = [];
    columns: any = [];
    @ViewChild('querybuilder') public qryBldrObj?: QueryBuilderComponent;
    currentTag: any = {};
    addEditTag = false;
    tagvalue: any = '';
    tagname: any = '';
    @ViewChild('tagNgForm') tagNgForm: NgForm;
    importRules = {
        'condition': 'and',
        'rules': []
    };
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _changeDetectorRef: ChangeDetectorRef,
        private _bs: BaseRequestService,
        private loaderService: LoaderService,
        private cs: CommonService,
        public confirmDialog: FuseConfirmationService,
        public toast: MyToastrService
    ) {
        this.cs.newTagCall.subscribe((trigger: any) => {
            this.addEditTag = true
        });
    }




    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.getCollectionSettings();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    ngAfterViewInit(): void {
        this._changeDetectorRef.markForCheck();
    }

    cancel() {
        this.addEditTag = false
        this._changeDetectorRef.detectChanges()
        this.getTagsData();
    }

    getTagsData(): void {
        let data = Object.assign({}, this.tagTableOptions);
        data.pageData = []; data.tableOptions.pageTotal = 0;
        this.tagTableOptions.pageData = []; this.tagTableOptions.tableOptions.pageTotal = 0;
        this.tagTableOptions = {}; this._changeDetectorRef.detectChanges();
        this.tagTableOptions = data;
        this._changeDetectorRef.detectChanges();
    }

    addTagData() {
        this.tagRules = {
            collection: '',
            tags: []
        };
        this.addEditTag = true;
    }


    ruleactionCall(data: any) {
        this.currentTag = data.row;
        this.addEditTag = true;
        if (data.action.text == 'Edit') {
            this.tagRules = {
                name: data.row.name,
                description: data.row.description,
                risk_score: data.row.risk_score,
                collection: data.row.collection,
                tags: data.row.tags,
            }
            const col = cloneDeep(this.columns);
            this.sColumns = null; this._changeDetectorRef.detectChanges();
            this.sColumns = col.filter((x: any) => x.columns.indexOf(data.row.collection) !== -1);
            setTimeout(() => {
                if (this.qryBldrObj) {
                    this.qryBldrObj!.setRules(data.row.rules);
                    this._changeDetectorRef.detectChanges();
                }
            }, 1000);


        }
    }


    changeCollection(change: any): void {
        const col = cloneDeep(this.columns);
        this.sColumns = null; this._changeDetectorRef.detectChanges();
        this.sColumns = col.filter((x: any) => x.columns.indexOf(change.value) !== -1);
        this._changeDetectorRef.markForCheck();
    }

    addTags(): void {
        if (!this.tagname || !this.tagvalue) { return; }
        if (this.tagRules && !this.tagRules.tags) {
            this.tagRules.tags = [];
        }
        this.tagRules.tags.push({
            name: this.tagname,
            value: this.tagvalue,
        });
        this.tagname = '';
        this.tagvalue = '';
    }

    saveTagRule(): void {
        const filterQuery = this.qryBldrObj!.getRules();
        const filteredData: any = filterQuery.rules.filter((obj: any) => Object.values(obj).some(value => value !== ''));
        if (filteredData.length === 0) {
            this.toast.sToast('error', 'Please add at least one rule.');
            return;
        }
        if (filterQuery.condition === 'and' && this.cs.hasDuplicates(filterQuery.rules, ['field', 'label', 'operator'])) {
            this.toast.sToast('error', 'Duplicate rules not allowed.');
            return;
        }
        const duplicates = this.tagRules.tags.filter((item: any, index: any, array: any) => {
            return array.slice(index + 1).some((otherItem: any) =>
                otherItem.name === item.name && otherItem.value === item.value
            );
        });
        if (duplicates && duplicates.length) {
            this.toast.sToast('error', 'Please check for duplicates.');
            return;
        }
        const duplicatesName = this.tagRules.tags.filter((item: any, index: any, array: any) => {
            return array.slice(index + 1).some((otherItem: any) =>
                otherItem.name === item.name
            );
        });
        if (duplicatesName && duplicatesName.length) {
            this.toast.sToast('error', 'Please check for duplicate Name field entered.');
            return;
        }
        var method = 'post'; var api = '/w/company/tag_rules';
        const data: any = Object.assign({}, this.tagRules);
        data.company_id = this.cs.currentScope.id; data.description = data.description || null;
        data.rules = filterQuery;
        var postdata: any = { data: data }
        if (this.currentTag && this.currentTag.id) {
            method = 'patch'
            api = '/w/company/tag_rules'
            postdata = { id: this.currentTag.id, data: data }
        }
        this.loaderService.display(true);
        this._bs
            .doRequest(api, method, postdata)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    this.currentTag = {};
                    this.addEditTag = false;
                    this.toast.sToast('success', `Saved successfully!`);
                    this.cancel();
                } else {
                    const data = (result.message)? result.message : result.data;
this.toast.sToast('error', data);
                }
            });
    }

    getCollectionSettings(): void {
        this.loaderService.display(true);
        this._bs.doRequest(`r/company/jsonconfigs/tags_config`, 'get')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: any) => {
            this.loaderService.display(false);
            if (res.status) {
                this.collections = res.data.collections;
                this.columns = res.data.columns;
                this._changeDetectorRef.markForCheck();
            } else {
                const data = (res.message)? res.message : res.data;
this.toast.sToast('error', data);
            }
        });
    }
}

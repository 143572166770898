import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRequestService } from "../../../_services/base.service";
import { CommonService } from "../../../_services/common.service";
import { LoaderService } from "../../../_services/loader.service";
import { MaterialModule } from "../../../material.module";
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-notify',
    standalone: true,
    imports: [CommonModule, MaterialModule],
    templateUrl: './notify.component.html',
    styleUrls: ['./notify.component.scss']
})
export class NotifyComponent implements OnInit, OnDestroy {
    show = false;
    cveList: any;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    constructor(private bs: BaseRequestService,
        private cs: CommonService, private ls: LoaderService) {
    }

    ngOnInit(): void {
        let cve = localStorage.getItem('tcve');
        if (!cve) {
            this.getTrendingCVEs();
        } else {
            try {
                this.cs.tcve = JSON.parse(cve);
                this.process(this.cs.tcve);
            } catch (e) {
                console.log(e);
            }
        }
    }

    process(data: any): void {
        if (data && this.cs.currentScope && this.cs.currentScope.id) {
            this.getCompanyTrendingCVEs(data, this.cs.currentScope.id);
        } else {
            this.show = true;
        }
    }

    getTrendingCVEs(): void {
        this.ls.display(true);
        this.bs.doRequest(`r/company/jsonconfigs/trending_cve`, 'get')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status) {
                    localStorage.setItem('tcve', JSON.stringify(res.data));
                    this.cs.tcve = res.data;
                    this.cveList = res.data;
                    if (this.cs.currentScope && this.cs.currentScope.id) {
                        this.getCompanyTrendingCVEs(res.data, this.cs.currentScope.id);
                    } else {
                        this.show = true;
                    }
                } else {
                    this.show = false;
                }
            })
    }

    getCompanyTrendingCVEs(cveList: any, company_id: any): void {
        // report_queries/problems_asset_count?problem_name=CVE-1999-0236,CVE-2023-29059,CVE-2023-2136&condition=company_id=237
        let problem_name = cveList.map((x: any) => x.cve).join(',');
        this.ls.display(true);
        let params: any = {
            problem_name,
            condition: `company_id=${company_id}`
        }
        this.bs.doRequest(`/report_queries/problems_asset_count`, 'get', null,)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.ls.display(false);
                if (res.status) {
                    this.cveList = res.data;
                    this.show = true;
                } else {
                    this.show = false;
                }
            })
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    searchCVE() {
        // TODO: emit call back to search cve page.
        this.cs.openSearchCVESettings.emit({});
    }

    closeNotify(): void {
        this.show = !this.show;
        this.cs.tcve = [];
    }
}

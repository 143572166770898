<router-outlet>
    <div *ngIf="loading$ | async " class="loader-class custom-class">
        <img ngSrc="/assets/images/loadings.gif" alt="" class="absolute w-full h-auto" fill>
        <p class="relative mb-10" *ngIf="!contentText || contentText === ''">Loading... </p>
        <p class="mb-10 relative">{{contentText}}</p>
    </div>
    <!--<div *ngIf="authService.sessionTimeout$ | async " class="loader-class custom-class p-3">
        <span class="mb-3 mt-3">Session about to expire in {{authService.timeoutSec}}s. Do you want to continue current session?</span>
        <div class="flex flex-row items-center">
            <span class="mr-2">
                <button (click)="authService.resetSession()" mat-raised-button
                                       color="primary">Yes</button>
            </span>
            <span>
                <button (click)="authService.logout()" mat-stroked-button
                          color="warn">Logout</button>
            </span>
        </div>
        <img ngSrc="/assets/images/loadings.gif" alt="" class="absolute w-50 px-[10%] inset-x-[14%] inset-y-[14%] h-auto" fill>
    </div>-->
</router-outlet>

<div class="flex flex-col w-full min-w-0">
    <mat-drawer-container class="flex-auto sm:h-full">
        <mat-drawer class="dark:bg-gray-900 pr-4" [ngClass]="{'w-full lg:w-3/4': drawerOpened}" [autoFocus]="false"
            [position]="drawerPosition" [mode]="drawerMode" [opened]="drawerOpened" #drawer>
        </mat-drawer>
        <mat-drawer-content class="flex flex-col">
            <div *ngIf="showTable">
                <div class="flex flex-row items-center justify-end pr-6 my-4">
                    <button class="e-btn e-primary e-qb-button" (click)="addCallBack()">Add</button>
                    <button class="e-btn e-warning e-qb-button mx-2" (click)="editAppBaseline()">Edit</button>
                    <button class="e-btn e-secondary e-qb-button" (click)="deleteAppBaseline()">Delete</button>
                </div>
                <stable aria-label="Application Rule Table" role="table" *ngIf="appBaseLineTableOptions"
                    [sTableOptions]="appBaseLineTableOptions">
                </stable>
            </div>
            <div class="pr-0" *ngIf="!showTable">
                <div>
                    <form class="flex flex-col p-4 bg-card shadow rounded" #ruleNgForm="ngForm">
                        <div class="flex flex-row items-center">
                            <div class="text-2xl font-semibold tracking-tight">
                                <span *ngIf="!currentAppBaseline.id">Add</span>
                                <span *ngIf="currentAppBaseline.id">Update</span>
                                Application Baseline Rule
                            </div>
                            <button class="ml-auto" mat-icon-button (click)="showTable=true">
                                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                            </button>
                        </div>
                        <div class="grid grid-cols-2 grid-cols-2 gap-2 mt-4">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Name</mat-label>
                                <input matInput [placeholder]="'Enter Name'" name="name" required autocomplete="off"
                                    [(ngModel)]="currentAppBaseline.name">
                            </mat-form-field>
                            <!-- (selectionChange)="filterByType($event)" -->
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>OS Type</mat-label>
                                <mat-select name="OS Type" required [(ngModel)]="currentAppBaseline.os_type">
                                    <ng-container *ngFor="let os of osTypes">
                                        <mat-option [value]="os.value"> {{os.key}} </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>OS Name</mat-label>
                                <input matInput [placeholder]="'Enter OS Name'" name="OS Name" required
                                    [(ngModel)]="currentAppBaseline.os_name">
                                <mat-hint>
                                    Ex: <span *ngIf="currentAppBaseline.os_type ==='LINUX'">ubuntu</span> <span *ngIf="currentAppBaseline.os_type ==='WINDOWS'">Windows Server 2012 R2</span> <span *ngIf="currentAppBaseline.os_type ==='MAC'">macOS</span>
                                </mat-hint>
                            </mat-form-field>
                            <!-- <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>OS Name</mat-label>
                                <mat-select id="OSName" name="OSName" [multiple]="false" required
                                    [(ngModel)]="currentAppBaseline.os_name">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="searchOSControl"
                                            [placeholderLabel]="'Search OS Name'"
                                            [noEntriesFoundLabel]="'No matching os found'"
                                            [searching]="searching"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option [matTooltip]="os.name"
                                        *ngFor="let os of osNames | search: searchOSControl.value;" [value]="os.value">
                                        {{os.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field> -->
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Type</mat-label>
                                <mat-select name="Type" required [(ngModel)]="currentAppBaseline.type"
                                    (selectionChange)="changeType()">
                                    <ng-container *ngFor="let os of types">
                                        <mat-option [value]="os.value"> {{os.key}} </mat-option>
                                    </ng-container>
                                </mat-select>
                                <mat-hint></mat-hint>
                            </mat-form-field>
                        </div>
                        <mat-divider class="my-4"></mat-divider>
                        <ng-container *ngIf="currentAppBaseline.type === 'application'">
                            <div class="flex flex-row items-center mt-4">
                                <mat-form-field class="w-3/4" [subscriptSizing]="'dynamic'">
                                    <mat-label>Denied Application</mat-label>
                                    <mat-icon matPrefix class="icon-size-4"
                                        [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
                                    <input matInput [formControl]="appSearchControl" [matAutocomplete]="matAutocomplete"
                                        [(ngModel)]="denied_app" [placeholder]="'Search...'"
                                        (keydown)="onKeydown($event)">
                                </mat-form-field>
                                <mat-autocomplete class="mt-1 rounded" [autoSelectActiveOption]="true"
                                    [disableRipple]="true" #matAutocomplete="matAutocomplete">
                                    <mat-option
                                        class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                                        *ngIf="uniqueApplications && !uniqueApplications.length">
                                        No results found!
                                    </mat-option>
                                    <ng-container *ngFor="let result of uniqueApplications; trackBy: trackByFn">
                                        <mat-option
                                            class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
                                            [value]="result.name">
                                            <div class="flex flex-col">
                                                <div class="truncate leading-normal" [innerHTML]="result.name">
                                                </div>
                                            </div>
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                                <mat-checkbox name="denRegularExpression" id="denRegularExpression" class="ml-2 mt-6"
                                    [(ngModel)]="denied_app_regex" value="" color="primary">Regex
                                </mat-checkbox>
                                <button class="e-btn e-primary ml-4 mt-6 e-qb-button" *ngIf="denied_app !==''"
                                    (click)="addDeniedApplications()">Add</button>
                            </div>
                            <div class="relative sm:rounded-lg mt-4 w-3/4"
                                *ngIf="currentAppBaseline.denied_applications && currentAppBaseline.denied_applications.length">
                                <table class="w-full text-sm text-left rtl:text-right">
                                    <thead
                                        class="text-medium font-medium bg-gray-200 dark:bg-gray-600 border-b mt-2 tracking-tight leading-6  bg-card">
                                        <tr>
                                            <th scope="col" class="pl-4 py-2">
                                                Application Name
                                            </th>
                                            <th scope="col" class="pl-4 py-2">
                                                Is Regex
                                            </th>
                                            <th scope="col" class="pl-4 py-2">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let app of currentAppBaseline.denied_applications; let i = index;"
                                            class="border-b">
                                            <td class="py-1 pl-4">
                                                {{app.application_name}}
                                            </td>
                                            <td class="py-1 pl-4">
                                                <span class=" text-xs font-medium me-2 px-2.5 py-0.5 rounded-full"
                                                    [ngClass]="{
                                    'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300': app.regular_expression,
                                    'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300': !app.regular_expression}">
                                                    <span *ngIf="app.regular_expression">Yes</span>
                                                    <span *ngIf="!app.regular_expression">No</span>
                                                </span>
                                            </td>
                                            <td class="py-1 pl-4">
                                                <button (click)="currentAppBaseline.denied_applications.splice(i, 1)"
                                                    mat-icon-button="" matTooltip="Delete record">
                                                    <mat-icon class="icon-size-5 text-primary">delete</mat-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="flex flex-row items-center mt-4">
                                <mat-form-field class="w-3/4" [subscriptSizing]="'dynamic'">
                                    <mat-label>Mandatory Application</mat-label>
                                    <mat-icon matPrefix class="icon-size-4"
                                        [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
                                    <input matInput [formControl]="appSearchControl" [matAutocomplete]="matAutocomplete"
                                        [(ngModel)]="mandatory_app" [placeholder]="'Search...'"
                                        (keydown)="onKeydown($event)">
                                </mat-form-field>
                                <mat-autocomplete class="mt-1 rounded" [autoSelectActiveOption]="true"
                                    [disableRipple]="true" #matAutocomplete="matAutocomplete">
                                    <mat-option
                                        class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                                        *ngIf="uniqueApplications && !uniqueApplications.length">
                                        No results found!
                                    </mat-option>
                                    <ng-container *ngFor="let result of uniqueApplications; trackBy: trackByFn">
                                        <mat-option
                                            class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
                                            [value]="result.name">
                                            <div class="flex flex-col">
                                                <div class="truncate leading-normal" [innerHTML]="result.name">
                                                </div>
                                            </div>
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                                <mat-checkbox name="manRegularExpression" id="manRegularExpression" class="ml-2 mt-6"
                                    [(ngModel)]="mandatory_app_regex" value="" color="primary">Regex
                                </mat-checkbox>
                                <button class="e-btn e-primary ml-4 mt-6 e-qb-button" *ngIf="mandatory_app !==''"
                                    (click)="addMandatoryApplications()">Add</button>
                            </div>
                            <div class="relative sm:rounded-lg mt-4 w-3/4"
                                *ngIf="currentAppBaseline.mandatory_applications && currentAppBaseline.mandatory_applications.length">
                                <table class="w-full text-sm text-left rtl:text-right">
                                    <thead
                                        class="text-medium font-medium bg-gray-200 dark:bg-gray-600 border-b mt-2 tracking-tight leading-6 bg-card">
                                        <tr>
                                            <th scope="col" class="pl-4 py-2">
                                                Application Name
                                            </th>
                                            <th scope="col" class="pl-4 py-2">
                                                Is Regex
                                            </th>
                                            <th scope="col" class="pl-4 py-2">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let app of currentAppBaseline.mandatory_applications;let i = index;"
                                            class="border-b">
                                            <td class="py-1 pl-4">
                                                {{app.application_name}}
                                            </td>
                                            <td class="py-1 pl-4">
                                                <span class=" text-xs font-medium me-2 px-2.5 py-0.5 rounded-full"
                                                    [ngClass]="{
                                    'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300': app.regular_expression,
                                    'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300': !app.regular_expression}">
                                                    <span *ngIf="app.regular_expression">Yes</span>
                                                    <span *ngIf="!app.regular_expression">No</span>
                                                </span>
                                            </td>
                                            <td class="py-1 pl-4">
                                                <button (click)="currentAppBaseline.mandatory_applications.splice(i, 1)"
                                                    mat-icon-button="" matTooltip="Delete record">
                                                    <mat-icon class="icon-size-5 text-primary">delete</mat-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="currentAppBaseline.type === 'service'">
                            <div class="flex flex-row items-center mt-4">
                                <mat-form-field class="w-[38%]" [subscriptSizing]="'dynamic'">
                                    <mat-label>Denied Service</mat-label>
                                    <mat-icon matPrefix class="icon-size-4"
                                        [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
                                    <input matInput [formControl]="serSearchControl" [matAutocomplete]="matAutocomplete"
                                        [(ngModel)]="denied_service_name" [placeholder]="'Search...'"
                                        (keydown)="onKeydown($event)">
                                </mat-form-field>
                                <mat-autocomplete class="mt-1 rounded" [autoSelectActiveOption]="true"
                                    [disableRipple]="true" #matAutocomplete="matAutocomplete">
                                    <mat-option
                                        class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                                        *ngIf="uniqueServices && !uniqueServices.length">
                                        No results found!
                                    </mat-option>
                                    <ng-container *ngFor="let result of uniqueServices; trackBy: trackByFn">
                                        <mat-option
                                            class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
                                            [value]="result.name">
                                            <div class="flex flex-col">
                                                <div class="truncate leading-normal" [innerHTML]="result.name">
                                                </div>
                                            </div>
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                                <mat-form-field class="w-[38%] ml-2" [subscriptSizing]="'dynamic'">
                                    <mat-label>Service Application Name</mat-label>
                                    <input matInput [placeholder]="'Service Application Name'" name="Service Name"
                                        [(ngModel)]="denied_application_name">
                                </mat-form-field>
                                <mat-checkbox name="denSerRegularExpression" id="denSerRegularExpression"
                                    class="w-[12%] ml-2 mt-6" [(ngModel)]="denied_service_regex" value=""
                                    color="primary">Regex
                                </mat-checkbox>
                                <button class="w-[12%] e-btn e-primary ml-4 mt-6 e-qb-button"
                                    *ngIf="denied_service_name !== '' && denied_application_name !==''"
                                    (click)="addDeniedService()">Add</button>
                            </div>
                            <div class="relative sm:rounded-lg mt-4 w-3/4"
                                *ngIf="currentAppBaseline.denied_services && currentAppBaseline.denied_services.length">
                                <table class="w-full text-sm text-left rtl:text-right">
                                    <thead
                                        class="text-medium font-medium bg-gray-200 dark:bg-gray-600 border-b mt-2 tracking-tight leading-6  bg-card">
                                        <tr>
                                            <th scope="col" class="pl-4 py-2">
                                                Service Name
                                            </th>
                                            <th scope="col" class="pl-4 py-2">
                                                Application Name
                                            </th>
                                            <th scope="col" class="pl-4 py-2">
                                                Is Regex
                                            </th>
                                            <th scope="col" class="pl-4 py-2">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let app of currentAppBaseline.denied_services;let i = index;">
                                            <td class="py-1 pl-4">
                                                {{app.service_name}}
                                            </td>
                                            <td class="py-1 pl-4">
                                                {{app.application_name}}
                                            </td>
                                            <td class="py-1 pl-4">
                                                <span class=" text-xs font-medium me-2 px-2.5 py-0.5 rounded-full"
                                                    [ngClass]="{
                                    'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300': app.regular_expression,
                                    'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300': !app.regular_expression}">
                                                    <span *ngIf="app.regular_expression">Yes</span>
                                                    <span *ngIf="!app.regular_expression">No</span>
                                                </span>
                                            </td>
                                            <td class="py-1 pl-4">
                                                <button (click)="currentAppBaseline.denied_services.splice(i, 1)"
                                                    mat-icon-button="" matTooltip="Delete record">
                                                    <mat-icon class="icon-size-5 text-primary">delete</mat-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="flex flex-row items-center mt-4">
                                <!-- <mat-form-field class="w-[38%]" [subscriptSizing]="'dynamic'">
                                    <mat-label>Mandatory Service</mat-label>
                                    <mat-select [(ngModel)]="mandatory_service" name="MandatoryService"
                                        id="MandatoryService">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="serSearchControl"
                                                [placeholderLabel]="'Search service'"
                                                [noEntriesFoundLabel]="'No matching service found'"
                                                [searching]="searching"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [matTooltip]="result.name"
                                            *ngFor="let result of uniqueServices" [value]="result.name">
                                            {{result.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> -->
                                <mat-form-field class="w-[38%]" [subscriptSizing]="'dynamic'">
                                    <mat-label>Mandatory Service</mat-label>
                                    <mat-icon matPrefix class="icon-size-4"
                                        [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
                                    <input matInput [formControl]="serSearchControl" [matAutocomplete]="matAutocomplete"
                                        [(ngModel)]="mandatory_service_name" [placeholder]="'Search...'"
                                        (keydown)="onKeydown($event)">
                                </mat-form-field>
                                <mat-autocomplete class="mt-1 rounded" [autoSelectActiveOption]="true"
                                    [disableRipple]="true" #matAutocomplete="matAutocomplete">
                                    <mat-option
                                        class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                                        *ngIf="uniqueServices && !uniqueServices.length">
                                        No results found!
                                    </mat-option>
                                    <ng-container *ngFor="let result of uniqueServices; trackBy: trackByFn">
                                        <mat-option
                                            class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
                                            [value]="result.name">
                                            <div class="flex flex-col">
                                                <div class="truncate leading-normal" [innerHTML]="result.name"></div>
                                            </div>
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                                <mat-form-field class="w-[38%] ml-2" [subscriptSizing]="'dynamic'">
                                    <mat-label>Service Application Name</mat-label>
                                    <input matInput [placeholder]="'Service Name'" name="Service Name"
                                        [(ngModel)]="mandatory_application_name">
                                </mat-form-field>
                                <mat-checkbox name="manSerRegularExpression" id="manSerRegularExpression"
                                    class="w-[12%] ml-2 mt-6" [(ngModel)]="mandatory_service_regex" value=""
                                    color="primary">Regex
                                </mat-checkbox>
                                <button class="w-[12%] e-btn e-primary ml-6 mt-4 e-qb-button"
                                    *ngIf="mandatory_service_name !=='' && mandatory_application_name !== ''"
                                    (click)="addMandatoryService()">Add</button>
                            </div>
                            <div class="relative sm:rounded-lg mt-4 w-3/4"
                                *ngIf="currentAppBaseline.mandatory_services && currentAppBaseline.mandatory_services.length">
                                <table class="w-full text-sm text-left rtl:text-right">
                                    <thead
                                        class="text-medium font-medium bg-gray-200 dark:bg-gray-600 border-b mt-2 tracking-tight leading-6  bg-card">
                                        <tr>
                                            <th scope="col" class="pl-4 py-2">
                                                Service Name
                                            </th>
                                            <th scope="col" class="pl-4 py-2">
                                                Application Name
                                            </th>
                                            <th scope="col" class="pl-4 py-2">
                                                Is Regex
                                            </th>
                                            <th scope="col" class="pl-4 py-2">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let app of currentAppBaseline.mandatory_services;let i = index;">
                                            <td class="py-1 pl-4">
                                                {{app.service_name}}
                                            </td>
                                            <td class="py-1 pl-4">
                                                {{app.application_name}}
                                            </td>
                                            <td class="py-1 pl-4">
                                                <span class=" text-xs font-medium me-2 px-2.5 py-0.5 rounded-full"
                                                    [ngClass]="{
                                    'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300': app.regular_expression,
                                    'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300': !app.regular_expression}">
                                                    <span *ngIf="app.regular_expression">Yes</span>
                                                    <span *ngIf="!app.regular_expression">No</span>
                                                </span>
                                            </td>
                                            <td class="py-1 pl-4">
                                                <button (click)="currentAppBaseline.mandatory_services.splice(i, 1)"
                                                    mat-icon-button="" matTooltip="Delete record">
                                                    <mat-icon class="icon-size-5 text-primary">delete</mat-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-container>
                        <mat-divider class="my-4"></mat-divider>
                        <div class="flex mt-4 w-full" *ngIf="_cs.currentScope === '*'">
                            <div class="flex flex-col w-1/2">
                                <span class="font-semibold pt-2"> Select Include/Exclude Companies</span>
                                <mat-radio-group class="flex flex-row" [color]="'primary'" name="Company Type" (change)="radioChange($event)" required
                                    [(ngModel)]="currentAppBaseline.company_type">
                                    <mat-radio-button class="mr-2" [value]="'include'">
                                        Include Companies <span
                                            *ngIf="currentAppBaseline.companies && currentAppBaseline.companies.length && !currentAppBaseline.companies.includes('*') && currentAppBaseline.company_type === 'include'">({{currentAppBaseline.companies.length}})</span>
                                    </mat-radio-button>
                                    <mat-radio-button class="mr-2" [value]="'exclude'">
                                        Exclude Companies <span
                                            *ngIf="currentAppBaseline.companies && currentAppBaseline.companies.length && !currentAppBaseline.companies.includes('*') && currentAppBaseline.company_type === 'exclude'">({{currentAppBaseline.companies.length}})</span>
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <mat-form-field class="w-1/2 mr-2 mt-2" [subscriptSizing]="'dynamic'"
                                *ngIf="currentAppBaseline.company_type !== ''">
                                <mat-label>Select Company</mat-label>
                                <mat-select id="company" name="company" [multiple]="true" required
                                    [(ngModel)]="currentAppBaseline.companies" (openedChange)="closeCurrentCompany($event)"
                                    (selectionChange)="currentAppBaseline.companies = ($event.value.includes('*')) ? ['*'] : $event.value;">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="searchCompanyControl"
                                            [placeholderLabel]="'Search Company'"
                                            [noEntriesFoundLabel]="'No matching company found'"
                                            [searching]="searching"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option [matTooltip]="'All Companies'" value="*"
                                        *ngIf="currentAppBaseline.company_type === 'include'">All
                                        Companies</mat-option>
                                    <mat-option [matTooltip]="com.name" *ngFor="let com of filteredCompanies | async "
                                        [value]="com.id">
                                        {{com.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <mat-divider class="my-4"></mat-divider>
                        <fuse-alert [type]="'info'" [appearance]="'border'" class="my-2">
                            <div class="flex flex-col">
                                <span class="text-sm mb-2 font-medium text-gray-900 dark:text-white me-3">
                                    In order for Include/Ignore Tags to work, the Tag Name and Value
                                    must be entered exactly, without spaces.
                                </span>
                                <span class="text-sm mb-2 font-medium text-gray-900 dark:text-white me-3">
                                    For example, if the Tag
                                    appears as
                                    <span
                                        class="bg-purple-100 text-purple-800 text-sm font-bold px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">
                                        Asset Type: Workstation
                                    </span>, then the <strong>Name</strong> field should be
                                    entered as <strong>Asset Type</strong> and <strong>Value</strong> field should be
                                    entered as
                                    <strong>Workstation</strong> respectively
                                </span>
                            </div>
                        </fuse-alert>
                        <div class="w-full flex flex-row mt-2">
                            <div class="w-1/2 border-r p-1 pr-2">
                                <div class="flex flex-row w-full gap-2 items-center">
                                    <div class="font-semibold my-2">Include Tags </div>
                                    <button (click)="addIncludeTag()" type="button" mat-icon-button=""
                                        matTooltip="Add Record">
                                        <mat-icon [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
                                    </button>
                                </div>
                                <div class="flex flex-row w-full gap-2 items-center mt-4"
                                    *ngFor="let tag of currentAppBaseline.include_tags; let i = index; let first = first; let last = last">
                                    <mat-form-field class="w-[45%] ml-2" [subscriptSizing]="'dynamic'">
                                        <mat-label *ngIf="first">Name</mat-label>
                                        <input matInput [placeholder]="'Name'" name="Name{{i}}" required
                                            autocomplete="off" [(ngModel)]="tag.key">
                                    </mat-form-field>
                                    <mat-form-field class="w-[45%] ml-2" [subscriptSizing]="'dynamic'">
                                        <mat-label *ngIf="first">Value</mat-label>
                                        <input matInput [placeholder]="'Value'" pattern="^[^\s].*[^\s]$"
                                            name="Value{{i}}" required autocomplete="off" [(ngModel)]="tag.value">
                                    </mat-form-field>
                                    <div class="" [ngClass]="{'mt-6': first}">
                                        <button (click)="currentAppBaseline.include_tags.splice(i, 1)" type="button"
                                            mat-icon-button="" matTooltip="Delete record">
                                            <mat-icon class="icon-size-5 text-gray">delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/2 p-1">
                                <div class="flex flex-row w-full gap-2 items-center">
                                    <div class="font-semibold my-2">Ignore Tags </div>
                                    <button (click)="addExcludeTag()" type="button" mat-icon-button=""
                                        matTooltip="Add Record">
                                        <mat-icon [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
                                    </button>
                                </div>
                                <div class="flex flex-row w-full gap-2 items-center mt-4"
                                    *ngFor="let tag of currentAppBaseline.ignore_tags; let i = index; let first = first; let last = last">
                                    <mat-form-field class="w-[45%] ml-2" [subscriptSizing]="'dynamic'">
                                        <mat-label *ngIf="first">Name</mat-label>
                                        <input matInput [placeholder]="'Name'" name="ignoreName{{i}}" required
                                            autocomplete="off" [(ngModel)]="tag.key">
                                    </mat-form-field>
                                    <mat-form-field class="w-[45%] ml-2" [subscriptSizing]="'dynamic'">
                                        <mat-label *ngIf="first">Value</mat-label>
                                        <input matInput [placeholder]="'Value'" pattern="^[^\s].*[^\s]$"
                                            name="ignoreValue{{i}}" required autocomplete="off" [(ngModel)]="tag.value">
                                    </mat-form-field>
                                    <div class="" [ngClass]="{'mt-6': first}">
                                        <button (click)="currentAppBaseline.ignore_tags.splice(i, 1)" type="button"
                                            mat-icon-button="" matTooltip="Delete record">
                                            <mat-icon class="icon-size-5 text-gray">delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center mt-10  py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t">
                            <button class="ml-auto" mat-button [matTooltip]="'Cancel'" type="button"
                                (click)="showTable = true">
                                Cancel
                            </button>
                            <button class="ml-2" mat-flat-button [color]="'primary'" type="button"
                                *ngIf="currentAppBaseline.type === 'service'" (click)="saveAppBaseline()" [disabled]="ruleNgForm.invalid || !currentAppBaseline || ( currentAppBaseline.mandatory_service?.length === 0
     && currentAppBaseline.denied_service?.length === 0)" [matTooltip]="'Save'">
                                Save
                            </button>
                            <button class="ml-2" mat-flat-button [color]="'primary'" type="button"
                                *ngIf="currentAppBaseline.type === 'application'" (click)="saveAppBaseline()"
                                [disabled]="ruleNgForm.invalid || !currentAppBaseline || ( currentAppBaseline.mandatory_applications?.length ===0
     && currentAppBaseline.denied_applications?.length === 0)" [matTooltip]="'Save'">
                                Save
                            </button>
                        </div>

                    </form>
                </div>
            </div>
            <!--<mat-tab-group class="stab-normal mt-4" mat-stretch-tabs="false" mat-align-tabs="start"
                [selectedIndex]="selectedIndex" (selectedIndexChange)="tabFn($event)">
                <mat-tab class="py-1" label="Rules">
                    <ng-template mat-tab-label>
                        <i class="fas fa-cog mx-2"></i>Rules
                    </ng-template>
                    <ng-template matTabContent>
                        <div class="mt-4" *ngIf="selectedIndex === 0 && selectedIndex !== null">

                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab class="py-1" label="Application Base Plans">
                    <ng-template mat-tab-label>
                        <i class="fas fa-cog mx-2"></i> Application Baseline Plans
                    </ng-template>
                    <ng-template matTabContent>
                        <div *ngIf="selectedIndex === 1 && selectedIndex !== null">

                        </div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>-->
        </mat-drawer-content>
    </mat-drawer-container>
</div>

import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FuseAlertComponent } from '@fuse/components/alert';
import { MaterialModule } from 'app/material.module';

@Component({
  selector: 'app-disable-roaming-app',
  templateUrl: './disable-roaming-app.component.html',
  standalone: true,
  imports: [CommonModule, FuseAlertComponent, FormsModule, NgIf, NgFor, MaterialModule],
  styleUrls: ['./disable-roaming-app.component.scss']
})
export class DisableRoamingAppComponent {
  @Output() checkBoxCallback = new EventEmitter();
  isMultiple = true;
  initSelectedValues = [];
  selection = new SelectionModel<any>(this.isMultiple, this.initSelectedValues);
  numSelected!: number;
  globalRoamingStatus : any = false;
  companyRoamingStatus : any = false;
  allCompanies: any = {
    "data": [
        {
            "c": "2023-11-23T07:22:48.447606",
            "name": "!!!!!!!!!!!!!!!All",
            "_id": "d1737726-3ebd-42d5-adc3-a5f06cc05824"
        },
        {
            "c": "2023-11-22T12:17:01.238101",
            "name": "!!!!!!all pii test",
            "_id": "afc2d1ba-521c-4d4f-a7c4-0914653973ca"
        },
        {
            "c": "2023-12-04T06:32:33.812163",
            "name": "!!!!!!aye",
            "_id": "f96f1df9-ed4c-45dc-9e21-afed5d789fcf"
        },
        {
            "c": "2023-11-04T05:28:03.965448",
            "name": "!!!!!Agent",
            "_id": "453afcc8-77bb-4baf-b247-c6b54158f684"
        },
        {
            "c": "2023-11-22T11:54:52.106702",
            "name": "!!!!*Touch Me Not*!!!!",
            "_id": "4c5341b0-9d3d-4df9-bcab-7c6e8e6905a7"
        },
        {
            "c": "2023-12-08T04:25:34.399077",
            "name": "!!!ACWTEST",
            "_id": "ac747f01-af21-455c-adf4-6cae99c74e8b"
        },
    ],
    "total": 830,
    "count": 830
};
  toggleGlobalLevel(event): void{
    this.globalRoamingStatus = event.checked;
    console.log("loginAuditStatus",this.globalRoamingStatus);
  }
  toggleCompanyLevel(event): void{
    this.companyRoamingStatus = event.checked;
    console.log("loginAuditStatus",this.companyRoamingStatus);
  }
  updateGlobalDisableAppStatus(gstatus:any){
    // const titleName = 'Confirmation';
    // const message =
    //     `Are you sure you want to update the changes?`;
    // const cancelText = 'No';
    // const acceptText = 'Yes';
    // this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    // this.confirmDialog.dialogResult.subscribe(res => {
    //   if (res) {
    //     const url: any = `/api/cyberutils/ccns/update_roaming_profile_app_status`;
    //     let params: any = {};
    //       params = {"global": gstatus};
    //       // this.allCompanies.forEach((company: any) => {
    //       //   params[company.companyid] = gstatus;
    //       // });
    //     this.loaderService.display(true);
    //     this.baseService.doRequest(url, 'post', params).subscribe((result: any) => {
    //       this.loaderService.display(false);
    //       if (result[0]) {
    //         this.toast.sToast('success', 'Updated successfully');
    //         setTimeout(() => {
    //           this.getAllCompanyStatus();
    //         }, 3000);
    //       } else {
    //        this.toast.sToast('error', result[1]);
    //       }
    //     });
    //   } else {
    //     // this.globalRoamingAppStatus = prevStatus;
    //   }
    // });
  }
  updateCompanyDisableAppStatus(cstatus:any, company?: any){
    // const titleName = 'Confirmation';
    // const message =  `Are you sure you want to update the changes?`;
    // const cancelText = 'No';
    // const acceptText = 'Yes';
    // this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    // this.confirmDialog.dialogResult.subscribe(res => {
    //   if (res) {
    //     let params : any = {};
    //         this.selectedCompanies.forEach((company: any) => {
    //           params[company.companyid] = cstatus;
    //         });
    //     this.loaderService.display(true);
    //     this.baseService.doRequest(`/api/cyberutils/dummy/update_roaming_profile_app_status`,
    //         'post', params).subscribe((result: any) => {
    //           this.loaderService.display(false);
    //           if (result[0]) {
    //             this.toast.sToast('success', 'Updated successfully');
    //             setTimeout(() => {
    //               this.getAllCompanyStatus();
    //               this.companyRoamingAppStatus = false;
    //             }, 2000);
    //           } else {
    //            this.toast.sToast('error', result[1]);
    //           }
    //     });
    //   }
    // });
  }
  clearSelection(): void {
    this.selection.clear();
    // @ts-ignore
    this.numSelected = undefined;
  }
  
  isAllSelected(): any {
    this.numSelected = this.selection.selected.length;
    const numRows = this.allCompanies.data.length;
    console.log("numSelected---->",this.numSelected);
    return this.numSelected === numRows;
  }
  
  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.allCompanies.data.forEach(row => this.selection.select(row));
      console.log("selected companies--->",this.allCompanies.data);
  }
  
  rowToggle(row: any): void {
    this.selection.toggle(row);
    console.log("row--->",this.selection);
  }
}

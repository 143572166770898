<div class="-mx-4">
    <ng-container *ngIf="uView === 'table'">
        <stable class="" aria-label="User Table" role="table" *ngIf="customerTableOption.tableOptions"
            (addCallback)="newUserCall()" [sTableOptions]="customerTableOption" (actionCallback)="actioncallback($event)">
        </stable>
    </ng-container>
    <ng-container *ngIf="uView === 'add'">
        <div class="rounded-xl m-2 bg-card p-4">
            <form [formGroup]="contactForm" class="p-4">
                <div class="flex flex-row items-center px-6">
                    <div class="text-2xl font-semibold tracking-tight">Add New Customer</div>
                    <button class="ml-auto" mat-icon-button (click)="uView = 'table';">
                        <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    </button>
                </div>
                <fuse-alert [appearance]="'border'" class="px-6 my-6" [type]="'info'">
                    Once you add the Customer here, an initialization email will be sent to the created customer's Email Address. Please complete the customer initialization to activate the customer.
                </fuse-alert>
                <div class="grid grid-cols-2 grid-cols-2 gap-4 px-6 mt-4">
                    <div>
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>First Name</mat-label>
                            <mat-icon matPrefix class="icon-size-5"
                                [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                            <input matInput [formControlName]="'first_name'" required [placeholder]="'First Name'"
                                [spellcheck]="false">
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-icon matPrefix class="icon-size-5"
                                [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                            <mat-label>Last Name</mat-label>
                            <input matInput [formControlName]="'last_name'" required [placeholder]="'Last Name'">
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-icon matPrefix class="icon-size-5" [svgIcon]="'mat_solid:email'"></mat-icon>
                            <mat-label>Email</mat-label>
                            <input matInput [formControlName]="'email'" appEmailCheck required [placeholder]="'Email'">
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Phone Number</mat-label>
                            <mat-icon matPrefix class="icon-size-5" [svgIcon]="'mat_solid:phone'"></mat-icon>
                            <input matInput type="number" [formControlName]="'mobile'" required
                                [placeholder]="'Phone Number'">
                        </mat-form-field>
                    </div>
                </div>
                <div
                    class="flex items-center mt-10 py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t bg-gray-50 dark:bg-transparent">
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'" type="button" (click)="uView = 'table'">
                        Cancel
                    </button>
                    <button class="ml-2" mat-flat-button [color]="'primary'" type="button"
                        [disabled]="contactForm.invalid" (click)="saveUser()" [matTooltip]="'Save'">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </ng-container>
</div>
<div class="w-full max-w-3xl">
    <!-- Form -->
    <form [formGroup]="planBillingForm">
        <!-- Section -->
        <div class="w-full">
            <div class="text-xl">Current Plan</div>
            <div class="text-secondary">Current plan indicates your total asset management and category.</div>
        </div>
        <div class="grid sm:grid-cols-3 gap-6 w-full mt-8">
            <!-- Plan -->
            <mat-radio-group
                class="invisible absolute w-0 h-0 pointer-events-none"
                [formControlName]="'plan'"
                #planRadioGroup="matRadioGroup">
                <ng-container *ngFor="let plan of plans; trackBy: trackByFn">
                    <mat-radio-button [value]="plan.value"></mat-radio-button>
                </ng-container>
            </mat-radio-group>
            <ng-container *ngFor="let plan of plans; trackBy: trackByFn">
                <div class="relative flex flex-col items-start justify-start p-6 rounded-md shadow cursor-pointer bg-card"
                    [ngClass]="{'ring ring-inset ring-primary': planRadioGroup.value === plan.value}">
                    <ng-container *ngIf="planRadioGroup.value === plan.value">
                        <mat-icon
                            class="absolute top-0 right-0 mt-3 mr-3 icon-size-7 text-primary"
                            [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
                    </ng-container>
                    <div class="font-semibold">{{plan.label}}</div>
                    <div class="mt-1 whitespace-normal text-secondary">{{plan.details}}</div>
                    <div class="flex-auto"></div>
                    <div class="mt-8 text-lg">
                        <span>{{plan.price | currency:'USD':'symbol':'1.0'}}</span>
                        <span class="text-secondary"> / month</span>
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- Section -->
        <div class="w-full mt-8">
            <div class="text-xl">Billing Summary</div>
            <div class="text-secondary">Viewing an overview of current month costs.</div>
        </div>
        <div class="grid sm:grid-cols-3 gap-6 w-full mt-2">
            <div class="relative flex flex-col items-start justify-start p-6 rounded-md shadow cursor-pointer bg-card">
                <div class="font-semibold">{{assetTotal}}</div>
                <div class="mt-1 whitespace-normal text-secondary">Assets</div>
            </div>
            <!--<div class="relative flex flex-col items-start justify-start p-6 rounded-md shadow cursor-pointer bg-card">
                <div class="font-semibold">-</div>
                <div class="mt-1 whitespace-normal text-secondary">External Assets</div>
            </div>
            <div class="relative flex flex-col items-start justify-start p-6 rounded-md shadow cursor-pointer bg-card">
                <div class="font-semibold">-</div>
                <div class="mt-1 whitespace-normal text-secondary">Firewalls</div>
            </div>-->
        </div>
        <!-- Divider -->
    </form>
    <div class="flex flex-row w-full min-w-0 mt-2 bg-card rounded-lg p-4 ring-1">
        <div class="flex-auto -mt-px">
            <div class="w-full">
                <table class="table-auto">
                    <thead class="broder-b">
                        <tr>
                            <th class="text-left">
                                Company Name
                            </th>
                            <th *ngFor="let s of stats;">
                                <span class="py-1 flex" (click)="sortFn(s.key)">
                                    {{s.text}}
                                    <span
                                        *ngIf="sortType === s.key && !sortReverse && s.key !=='accrued_cost'"
                                        class="fa fa-arrow-down ml-1 mt-1 text-xs cursor-pointer"></span>
                                    <span
                                        *ngIf="sortType === s.key && sortReverse && s.key !=='accrued_cost'"
                                        class="fa fa-arrow-up ml-1 mt-1 text-xs cursor-pointer"></span>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let c of allComp | search: search; let last = last" class="py-1" [ngClass]="{'border-b': !last}">
                            <td><span class="flex items-center text-secondary font-light text-sm" [matTooltip]="c.name">{{c.name | ellipsis: 30}}</span></td>
                            <td *ngFor="let s of stats;">
                                <div class="mx-auto flex flex-row flex-shrink-0 items-center"  [matTooltip]="s.title">
                                    <span class="mr-2"><i class="fas {{s.icon}} text-sm"></i></span>
                                    <span class="flex items-center text-secondary font-light text-sm">
                                        {{c[s.key]}}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!--<div class="px-8 pb-2">
                    <paginator-widget *ngIf="pageTotal" [pageSize]="pageSize" [pageTotal]="pageTotal"
                        (pageChanged)="pageChanged($event)"></paginator-widget>
                </div>-->
            </div>
        </div>
    </div>
</div>

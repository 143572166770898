import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { LoaderService } from 'app/_services/loader.service';
import { Subject, takeUntil } from 'rxjs';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DirectivesModule } from 'app/-directives/-directives.module';
import { MaterialModule } from 'app/material.module';
import { DynamicFormComponent } from 'app/modules/shared/forms/dynamic-form/dynamic-form.component';
import { TableComponent } from 'app/modules/shared/table/table.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { FuseAlertComponent } from '@fuse/components/alert';

@Component({
  selector: 'app-event-set',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, FuseAlertComponent, AppFilterPipeModule, DirectivesModule, TableComponent, MaterialModule, DynamicFormComponent, FormsModule, MatFormFieldModule, ReactiveFormsModule],
  templateUrl: './event-set.component.html',
  styleUrls: ['./event-set.component.scss']
})
export class EventSetComponent implements OnInit, OnDestroy {
  @Input() currentIntegration: any;
  @Input() integrationCred: any = [];
  @Output() tiggerFn = new EventEmitter();
  alertList: any = [];
  cView: any = 'table';
  alertTableOptions: any = {};
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  @ViewChild('eventNgForm') eventNgForm: NgForm;
  panelOpenState = true;
  alertRule: any = [];
  cEventSet: any = {
    name: ''
  };
  /**
     * Constructor
     */
  constructor(
    private cs: CommonService, private _bs: BaseRequestService, private _changeDetectorRef: ChangeDetectorRef,
    private toast: MyToastrService, private _ls: LoaderService, public confirmDialog: FuseConfirmationService
  ) {
  }

  ngOnInit(): void {
    this.getAlertRules();
    this.initEventSet();
  }


  getAlertRules(): void {
    this._ls.display(true);
    this._bs.doRequest('r/company/jsonconfigs/event_set', 'get')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (result.status) {
          try {
            result.data.forEach((obj: any) => {
              obj.event_rules.forEach((alert: any) => {
                alert.eventGroups = [];
                const groups: any = [...new Set(alert.events.map((x: any) => x.group))];
                groups.forEach((grp: any) => {
                  if (grp) {
                    alert.eventGroups.push({ name: grp, events: [] })
                  }
                })
                alert.events.forEach((event: any) => {
                  if (event.group) {
                    alert.eventGroups.forEach((grp: any) => {
                      if (grp.name === event.group) {
                        grp.events.push(event);
                      }
                    });
                  }
                });
                alert.events = alert.events.filter((eve: any) => !eve.group);
              });
            });
            this._ls.display(false);
            this.alertList = result.data;
            this._changeDetectorRef.detectChanges();
          } catch (e) {
            console.log(e);
          }
        }
      });
  }


  initEventSet(): void {
    this.alertTableOptions = {
      columns: [
        { "header": "Name", "columnDef": "name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
        { "header": "Notify By", "columnDef": "group_by", "cType": "string", "filter": "", "cell": "(element: any) => `${element.group_by}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      ],
      sortOptions: { active: 'name', direction: 'asc' },
      _pageData: [],
      tableOptions: {
        title: 'Event Set',
        id: 'event_set',
        isServerSide: false,
        selectText: '',
        loading: false,
        floatingFilter: true,
        rowSelection: false,
        showAction: true,
        actionMenuItems: [
          {
            text: 'Edit',
            id: 'edit',
            icon: 'edit',
            callback: 'editFn',
            hideLocal: false,
            isGlobal: false,
          },
          {
            text: 'Delete',
            id: 'delete',
            icon: 'delete',
            callback: 'deleteFn',
            hideLocal: false,
            isGlobal: false,
          }
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 5,
        search: false,
        showhideList: true,
        refreshData: true,
        showFilter: true,
        showTagFilter: false,
        exportExcel: true,
        add: true,
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        serverSide: {
          url: '/r/company/event_set',
          dUrl: '/d/company/event_set',
          condition: "",
          isGlobal: true,
        },
      },
      customText: [
        {
          status: true,
          DisplayText: 'Yes',
          color: '#16a34a',
          'text-color': '#000000'
        },
        {
          status: false,
          DisplayText: 'No',
          color: '#e48f8f',
          'text-color': '#000000'
        },
      ],
      changeValue: new Subject<any>(),

    };
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  addTableData(): void {
    this.alertRule = this.alertList;
    this.cEventSet = { name: '', group_by: '' }
    this.alertRule.forEach((obj: any) => {
      obj.event_rules.forEach((al: any) => {
        al.events.forEach((ev: any) => {
          ev.selected = false;
        });
        al.eventGroups.forEach((eve: any, index: any) => {
          eve.value = '';
          eve.events.map((x: any) => {
            if (x.isCheckbox) {
              x.selected = false;
            }
          })
        })
      });
    });
    this.cView = 'add';
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }


  saveEventSet(): void {
    let alertRule: any = {};
    alertRule.alert_rules = [];
    this.alertRule.forEach((sec: any) => {
      sec.event_rules.forEach((rule: any) => {
        let ruleObj: any = {}; ruleObj = { category_id: rule.category_id, event_ids: [] };
        rule.events.forEach((eve: any) => {
          if (eve.selected) { ruleObj.event_ids.push(eve.id); }
        });
        rule.eventGroups.forEach((eve: any, index: any) => {
          const selectedCheck = eve.events.filter((x: any) => x.isCheckbox && x.selected).map((s: any) => s.id);
          selectedCheck.push(eve.value)
          if (eve.value) { ruleObj.event_ids = [...ruleObj.event_ids, ...selectedCheck]; }
        })
        alertRule.alert_rules.push(ruleObj);
      });
    });
    alertRule.name = this.cEventSet.name;
    alertRule.group_by = this.cEventSet.group_by;
    // alertRule.is_default = false;
    const msg = (this.cEventSet.id) ? 'Updated' : 'Saved';
    const method = (this.cEventSet.id) ? 'patch' : 'post';
    const reqData: any = { data: alertRule };
    (this.cEventSet.id) ? reqData.id = this.cEventSet.id : null;
    if (!this.cEventSet.id) {
      const confirmation = this.confirmDialog.open({
        title: "Confirmation",
        message: "Would you like to create Integration Profile",
        icon: { show: false, name: "", color: "accent" },
        actions: {
          confirm: { show: true, label: "Yes", color: "primary" },
          cancel: { show: true, label: "Save Event Set" }
        },
        dismissible: false
      });
      confirmation.afterClosed().subscribe((result) => {
        this._ls.display(true);
        this._bs.doRequest(`w/company/event_set`, method, reqData)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res: any) => {
            this._ls.display(false);
            if (res && res !== null) {
              this.toast.sToast('success', `${msg} successfully`);
              this.cView = 'table';
              this.initEventSet(); this._changeDetectorRef.detectChanges();
              if (result === 'confirmed') {
                setTimeout(() => { this.tiggerFn.emit({ eventset: true, id: parseInt(res.id) }); }, 1000);
              }
            } else {
              this.toast.sToast('error', `Not ${msg}.`);
            }
          });
      })
    } else {
      this._ls.display(true);
      this._bs.doRequest(`w/company/event_set`, method, reqData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: any) => {
          this._ls.display(false);
          if (res && res !== null) {
            this.toast.sToast('success', `${msg} successfully`);
            this.cView = 'table';
            this.initEventSet(); this._changeDetectorRef.detectChanges();
          } else {
            this.toast.sToast('error', `Not ${msg}.`);
          }
        });
    }
  }

  actionCall($event: any): void {
    if ($event && $event !== '') {
      if ($event.action.text == 'Edit') {
        this.cEventSet = {
          name: $event.row.name,
          group_by: $event.row.group_by,
          id: $event.row.id
        };
        this.alertRule = this.alertList;
        let selectedEvents: any = $event.row.alert_rules.flatMap((x: any) => x.event_ids);
        this.alertRule.forEach((sec: any) => {
          sec.event_rules.forEach((rule: any) => {
            rule.events.forEach((eve: any) => {
              eve.selected = selectedEvents.includes(eve.id);
            });
            rule.eventGroups.forEach((r: any) => {
              const ids = r.events.map((x: any) => x.id);
              ids.forEach((id: any) => {
                if (selectedEvents.includes(id)) {
                  r.value = id;
                  r.events.map((x: any) => {
                    if (x.isCheckbox && selectedEvents.includes(x.id)) {
                      x.selected = true;
                    }
                  })
                }
              });
            });
          });
        });
        this.cView = 'add'
      }
    }
  }
}

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from "../../../shared/table/table.component";
import { Subject, Subscription, takeUntil } from "rxjs";
import { CommonService } from "../../../../_services/common.service";
import { BaseRequestService } from "../../../../_services/base.service";
import { UserService } from "../../../../core/user/user.service";
import { ModalService } from "../../../../_services/modal.service";
import { LoaderService } from "../../../../_services/loader.service";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { DynamicFormComponent } from "../../../shared/forms/dynamic-form/dynamic-form.component";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ModalComponent } from "../../../shared/modal.component";
import { FuseScrollbarDirective } from "../../../../../@fuse/directives/scrollbar";
import { MyToastrService } from 'app/_services/toastr.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';

@Component({
  selector: 'app-suppression',
  standalone: true,
  imports: [CommonModule, TableComponent, FormsModule, MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSlideToggleModule, DynamicFormComponent, MatCardModule, MatDividerModule, MatTooltipModule, ModalComponent, FuseScrollbarDirective],
  templateUrl: './suppression.component.html',
  styleUrls: ['./suppression.component.scss']
})
export class SuppressionComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  suppression: any = {};
  loadtable: boolean = false;
  private subs: Subscription;
  @Input() filterKey: any;
  asset_id: any;
  constructor(public cs: CommonService, private _bs: BaseRequestService,
    private _changeDetectorRef: ChangeDetectorRef,
    public confirmDialog: FuseConfirmationService,
    public toast: MyToastrService, private _baseRequestService: BaseRequestService,
    private uS: UserService, public modalService: ModalService, private loaderService: LoaderService) {
    this.subs = this.cs.selectedSiteChanged.subscribe((cmp: any) => {
      this._changeDetectorRef.markForCheck();
      this.initialTable();
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    if (this.cs.selctedAsset) {
      this.asset_id = this.cs.selctedAsset;
    } else if (this.cs.selectedTableRows['Assets'] && this.cs.selectedTableRows['Assets'].length) {
      this.asset_id = this.cs.selectedTableRows['Assets'][0].id;
    }
    setTimeout(() => this.initialTable());
  }

  initialTable(): void {
    let conditionParams: any = this.filterKey === 'problem_id' ? 'solution_id IS NULL' : 'problem_id IS NULL';
    if (this.asset_id) {
      conditionParams = conditionParams + ` and asset_id =  ${this.asset_id}`;
    } else {
      conditionParams = conditionParams + ` and asset_id IS NULL`;
    }
    this.suppression = {
      columns: [
        {
          "header": "Problem Name",
          "columnDef": "problem_name",
          "cType": "string",
          "filter": "",
          isHyperlink: false,
          "cell": "(element: any) => `${element.problem_name}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false
        },

        {
          "header": "Problem ID",
          "columnDef": "solution_id",
          "cType": "string",
          "filter": "",
          isHyperlink: false,
          "cell": "(element: any) => `${element.solution_id}`",
          "order": 0,
          "visible": false,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false
        },
        {
          "header": "Reason",
          "columnDef": "reason",
          "cType": "string",
          "filter": "",
          "cell": "(element: any) => `${element.reason}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false
        },
        {
          "header": "Suppression Request Sent Time",
          "columnDef": "created",
          "cType": "string",
          "filter": "utcToLocale",
          "cell": "(element: any) => `${element.created}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false
        },
        {
          "header": "Suppression Status",
          "columnDef": "suppression_status",
          "cType": "string",
          "filter": "",
          "cell": "(element: any) => `${element.suppression_status}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          isColoredCntrl: true,
          isCustomText: true,
          isCustomClass: true,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false
        },
        {
          "header": "Suppressed On",
          "columnDef": "suppressed_on",
          "cType": "string",
          "filter": "utcToLocale",
          "cell": "(element: any) => `${element.suppressed_on}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false
        },
        {
          "header": "Suppressed Till",
          "columnDef": "suppressed_till",
          "cType": "string",
          "filter": "utcToLocale",
          "cell": "(element: any) => `${element.suppressed_till}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false
        },
      ],
      sortOptions: { active: 'created', direction: 'desc' },
      _pageData: [],
      tableOptions: {
        id: 'suppression',
        title: 'Suppression',
        isServerSide: false,
        selectText: 'suppression',
        loading: true,
        floatingFilter: true,
        rowSelection: false,
        showAction: false,
        actionMenuItems: [],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 5,
        search: false,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        showFilter: true,
        showTagFilter: false,
        add: false,
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        serverSide: {
          url: '/r/exception/suppress_vulnerability',
          condition: conditionParams,
        },
      },
      customText: [
        {
          status: 'Approved',
          DisplayText: 'Approved',
          class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
        },
        {
          status: 'Open',
          DisplayText: 'Open',
          class: 'bg-blue-200 text-blue-800 dark:bg-blue-600 dark:text-blue-50'

        },
        {
          status: 'Rejected',
          DisplayText: 'Rejected',
          class: 'bg-red-200 text-red-600 dark:bg-red-600 dark:text-red-50'

        }, {
          status: 'Closed',
          DisplayText: 'Closed',
          class: 'bg-gray-200 text-gray-600 dark:bg-gray-600 dark:text-gray-50'
        }
      ],
      changeValue: new Subject<any>(),
    };
    this._changeDetectorRef.detectChanges();
  }
}




/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import cloneDeep from 'lodash-es/cloneDeep';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'overview',
        title: 'overview',
        subtitle: '',
        type: 'group',
        icon: 'heroicons_outline:home',
        api: 'overview',
        link: '',
        children: [
            {
                id: 'overview.overview',
                title: 'dashboard',
                type: 'basic',
                icon: 'mat_solid:dashboard',
                link: '/overview',
                api: 'overview',
            },
            {
                id: 'overview.metric',
                title: 'metrics',
                type: 'basic',
                icon: 'security',
                link: '/metrics',
                api: 'metrics',
            },
            {
                id: 'overview.agents',
                title: 'agents',
                type: 'basic',
                icon: 'mat_outline:connected_tv',
                link: '/agents',
                api: 'agents',
            }
        ],
    },
    {
        id: 'asset',
        title: 'assets',
        subtitle: '',
        type: 'group',
        icon: 'mat_solid:desktop_windows',
        api: 'assets_overview',
        link: '/asset',
        exactMatch: true,
        children: [
            {
                id: 'asset.asset',
                title: 'assets',
                type: 'basic',
                icon: 'mat_solid:desktop_windows',
                link: '/asset/assets',
                api: 'assets_overview',
                exactMatch: true,
            },
            {
                id: 'asset.firewall',
                title: 'firewalls',
                type: 'basic',
                icon: 'heroicons_outline:fire',
                link: '/asset/firewalls',
                api: 'assets_overview',
                exactMatch: true,
            },
            {
                id: 'asset.problems',
                title: 'problems',
                type: 'basic',
                icon: 'heroicons_outline:exclamation-triangle',
                link: '/asset/problems',
                api: 'problems_count_by_category_for_company',
                exactMatch: true,
            },
            {
                id: 'asset.remediation-plan',
                title: 'Solutions',
                type: 'basic',
                icon: 'heroicons_outline:check-badge',
                link: '/asset/remediation-plan',
                api: 'remediation_plan',
                exactMatch: true,
            },
            {
                id: 'asset.patch-compliance',
                title: 'Pending OS Patches',
                type: 'basic',
                icon: 'heroicons_solid:shield-check',
                link: '/asset/patch-compliance',
                api: 'windows_patches',
                exactMatch: true,
            },
            {
                id: 'asset.ports',
                title: 'Ports',
                type: 'basic',
                icon: 'mat_solid:settings_ethernet',
                link: '/asset/ports',
                api: 'ports_assets_companies',
                exactMatch: true,
            },
            {
                id: 'asset.external-assets',
                title: 'External Assets',
                type: 'basic',
                icon: 'heroicons_solid:magnifying-glass-circle',
                link: '/asset/external-assets',
                api: 'custom_profile',
                exactMatch: true,
            },
            {
                id: 'asset.deprecated-assets',
                title: 'Deprecated Assets',
                type: 'basic',
                icon: 'mat_outline:desktop_access_disabled',
                link: '/asset/deprecated-assets',
                api: 'custom_profile',
                exactMatch: true,
            },
            {
                id: 'asset.patching',
                title: 'Patching',
                type: 'basic',
                icon: 'mat_outline:system_update_alt',
                link: '/patching',
                exactMatch: true,
            },
            {
                id: 'asset.application-baseline-results',
                title: 'Application Baseline Results',
                type: 'basic',
                icon: 'heroicons_mini:clipboard-document-check',
                link: '/asset/application-baseline-results',
                api: 'custom_profile',
                exactMatch: true,
            },
            {
                id: 'asset.attack-surface-mapper',
                title: 'Attack Surface Mapper',
                type: 'basic',
                icon: 'mat_solid:screen_search_desktop',
                link: '/attack-surface-mapper',
                api: 'attack_surface_domain',
                exactMatch: true,
            },
            {
                id: 'asset.certificates',
                title: 'Certificates',
                type: 'basic',
                icon: 'mat_solid:stars',
                link: '/asset/certificates',
                api: 'cert_info_view',
                exactMatch: true,
            },
            {
                id: 'asset.scheduler',
                title: 'Scheduler',
                type: 'basic',
                icon: 'heroicons_outline:clock',
                link: '/asset/scheduler',
                api: 'scheduler',
                exactMatch: true,
            }
        ],
    },
    {
        id: 'vulnerabilities',
        title: 'Vulnerabilities',
        subtitle: '',
        type: 'group',
        icon: 'mat_outline:bug_report',
        api: 'problems_asset_count_by_category_for_company',
        children: [
            {
                id: 'vulnerabilities.vulnerabilities',
                title: 'Vulnerabilities',
                type: 'basic',
                icon: 'mat_outline:bug_report',
                link: '/vulnerabilities',
                api: 'problems_asset_count_by_category_for_company',
                exactMatch: true,
            },
            {
                id: 'vulnerabilities.application-vulnerabilities',
                title: 'Application Vulnerabilities',
                type: 'basic',
                icon: 'bug_report',
                link: '/application-vulnerabilities',
                api: 'application_vulnerabilities_view',
                exactMatch: true,
            },
            {
                id: 'vulnerabilities.network-scan-findings',
                title: 'Network Scan Findings',
                type: 'basic',
                icon: 'mat_outline:wifi_tethering',
                link: '/network-scan-findings',
                api: 'problems_asset_count_by_vulnerabilities_type',
                exactMatch: true,
            },
        ],
    },
    {
        id: 'compliance',
        title: 'compliance',
        subtitle: '',
        type: 'group',
        icon: 'heroicons_outline:shield-check',
        api: 'compliance_check_count',
        children: [
            {
                id: 'compliance.compliance-standards',
                title: 'compliance_check_mapping',
                type: 'basic',
                icon: 'policy',
                link: '/compliance/compliance-standards',
                api: 'compliance_check_count',
            },
            {
                id: 'compliance.compliance-check-master',
                title: 'compliance_check_master',
                type: 'basic',
                icon: 'security',
                link: '/compliance/compliance-check-master',
                api: 'compliance_master',
            },
        ],
    },
    {
        id: 'activedirectory',
        title: 'Active Directory',
        type: 'group',
        icon: 'feather:layers',
        api: 'ad_problems_count_by_category',
        children: [
            {
                id: 'activedirectory.problems-view',
                title: 'Problems',
                type: 'basic',
                icon: 'heroicons_outline:exclamation-triangle',
                link: '/active-directory/problems-overview',
                api: 'ad_problems_count_by_category',
            },
            {
                id: 'activedirectory.scan-result',
                title: 'AD Summary',
                type: 'basic',
                icon: 'layers',
                link: '/active-directory/summary',
                api: 'ad_problems_count_by_category',
            },
            {
                id: 'activedirectory.ad-audit',
                title: 'AD Audit',
                type: 'basic',
                icon: 'heroicons_mini:building-office-2',
                link: '/active-directory/ad-audit',
                api: 'ad_count',
            },
            {
                id: 'activedirectory.scan-result',
                title: 'Azure AD Summary',
                type: 'basic',
                icon: 'layers',
                link: '/active-directory/azure-summary',
                api: 'ad_problems_count_by_category',
            },
            {
                id: 'activedirectory.ad-view',
                title: 'Active Directory',
                type: 'basic',
                icon: 'heroicons_outline:computer-desktop',
                link: '/active-directory/ad-details',
                api: 'ad_count',
            },
            {
                id: 'activedirectory.azure-view',
                title: 'Azure Active Directory',
                type: 'basic',
                icon: 'heroicons_outline:queue-list',
                link: '/active-directory/azure',
                api: 'ad_count',
            },
            {
                id: 'activedirectory.microsoft-view',
                title: 'Microsoft Secure Score',
                type: 'basic',
                icon: 'mat_outline:score',
                link: '/active-directory/microsoft',
                api: 'azure_secure_score',
            },
        ]
    },
    {
        id: 'pii_scan',
        title: 'PII',
        type: 'basic',
        icon: 'feather:globe',
        api: 'pii_scan_settings',
        link: '/pii',
    },
    {
        id: 'reports',
        title: 'Reports',
        subtitle: '',
        type: 'group',
        icon: 'dashboard_customize',
        api: 'problems_asset_count_by_category_for_company',
        children: [
            {
                id: 'reports.report-builder',
                title: 'Report Builder',
                type: 'basic',
                icon: 'dashboard_customize',
                link: '/report-builder',
                api: 'report-builder',
            },
            {
                id: 'reports.standard-reports',
                title: 'Standard Reports',
                type: 'basic',
                icon: 'description',
                link: '/standard-reports',
                api: 'standard-reports',
            },
        ],
    },
];



/* {
        id: 'overview',
        title: 'overview',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/overview',
        api: 'overview',
    },*/

const compact: any = cloneDeep(defaultNavigation);
compact.map((x: any) => {
    if (x.children) {
        x.children = []; x.type = 'aside';
    } else {
        x.type = 'basic';
    }
});
export const compactNavigation: FuseNavigationItem[] = compact;

export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation;

const horizontal: any = cloneDeep(defaultNavigation);
horizontal.map((x: any) => { (x.children) ? x.children = [] : null });
export const horizontalNavigation: FuseNavigationItem[] = horizontal;

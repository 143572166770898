<div class="-mx-4">
    <stable aria-label="Tags" role="table" [sTableOptions]="tagTableOptions" *ngIf="!addEditTag && tagTableOptions.tableOptions"
        (actionCallback)="ruleactionCall($event)" (addCallback)="addTagData()"></stable>
</div>
<ng-container *ngIf="addEditTag">
    <div class="flex flex-col mt-2 p-4 bg-card rounded-2xl shadow overflow-hidden">
        <div class="flex flex-row items-center">
            <div class="text-2xl font-semibold tracking-tight">Tag Rule</div>
            <button class="ml-auto" mat-icon-button (click)="addEditTag = false;">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
        <form class="max-w-3xl" #tagNgForm="ngForm">
            <div class="grid grid-cols-2 gap-2 mt-4">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>Name</mat-label>
                    <input matInput [placeholder]="'Enter Name'" name="name" required autocomplete="off" [(ngModel)]="tagRules.name">
                </mat-form-field>
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>Risk Score</mat-label>
                    <input matInput type="number" [placeholder]="'Enter Risk Score'" autocomplete="off" name="Score" required
                        [(ngModel)]="tagRules.risk_score">
                </mat-form-field>
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>Collection</mat-label>
                    <mat-select (selectionChange)="changeCollection($event)" name="Collection" required
                        [(ngModel)]="tagRules.collection">
                        <ng-container *ngFor="let data of collections">
                            <mat-option [value]="data.value"> {{data.name}} </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>Description</mat-label>
                    <textarea [(ngModel)]="tagRules.description" autocomplete="off" name="Description" required matInput cdkTextareaAutosize></textarea>
                </mat-form-field>
            </div>
            <div class="flex flex-wrap flex-auto items-center mt-2 rounded-2xl" *ngIf="sColumns && sColumns.length">
                <div class="text-lg font-medium tracking-tight leading-6 truncate mt-4">Rules</div>
                <ejs-querybuilder #querybuilder class="row" width="100%">
                    <e-columns>
                        <e-column *ngFor="let c of sColumns; let i = index" [field]="c.field" [label]="c.label" [operators]="customOperators"
                            [type]="c.type"></e-column>
                    </e-columns>
                </ejs-querybuilder>
            </div>
            <div class="text-lg font-medium tracking-tight leading-6 truncate mt-4">Tags</div>
            <fuse-alert [type]="'info'" [appearance]="'border'" class="my-2" *ngIf="false">
                <div class="flex flex-col">
                    <span
                        class="text-sm mb-2 font-medium text-gray-900 dark:text-white me-3">
                        In order for Include/Ignore Tags to work, the Tag Name and Value
                        must be entered exactly, without spaces.
                    </span>
                    <span
                        class="text-sm mb-2 font-medium text-gray-900 dark:text-white me-3">
                        For example, if the Tag
                        appears as
                        <span
                            class="bg-purple-100 text-purple-800 text-sm font-bold px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">
                            Asset Type: Workstation
                        </span>, then the <strong>Name</strong> field should be
                        entered as <strong>Asset Type</strong> and <strong>Value</strong> field should be entered as
                        <strong>Workstation</strong> respectively
                    </span>
                </div>
            </fuse-alert>
            <div class="flex flex-row items-center mt-4">
                <mat-form-field class="w-[45%]" [subscriptSizing]="'dynamic'">
                    <mat-label>Name</mat-label>
                    <input matInput [placeholder]="'Enter Name'" name="Tagname" autocomplete="off" pattern="[^\s]*" [(ngModel)]="tagname">
                </mat-form-field>
                <mat-form-field class="w-[45%] ml-2" [subscriptSizing]="'dynamic'">
                    <mat-label>Value</mat-label>
                    <input matInput [placeholder]="'Value'" name="Value" autocomplete="off" pattern="[^\s]*" [(ngModel)]="tagvalue">
                </mat-form-field>
                <button type="button" class="w-[10%] e-btn e-primary ml-6 mt-6 e-qb-button"
                    (click)="addTags()">Add</button>
            </div>
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-4 w-3/4"
                *ngIf="tagRules.tags && tagRules.tags.length">
                <table class="w-full text-sm text-left rtl:text-right">
                    <thead
                        class="text-medium font-medium bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b mt-2 tracking-tight leading-6 bg-card">
                        <tr>
                            <th scope="col" class="pl-4 py-2">
                                Name
                            </th>
                            <th scope="col" class="pl-4 py-2">
                                Value
                            </th>
                            <th scope="col" class="pl-4 py-2">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let app of tagRules.tags;">
                            <td class="py-1 pl-4">
                                {{app.name}}
                            </td>
                            <td class="py-1 pl-4">
                                {{app.value}}
                            </td>
                            <td class="py-1 pl-4">
                                <button (click)="tagRules.tags.splice(i, 1)" type="button" mat-icon-button=""
                                    matTooltip="Delete record">
                                    <mat-icon class="icon-size-5 text-primary">delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="flex items-center mt-10  py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t">
                <button class="ml-auto" mat-button [matTooltip]="'Cancel'" (click)="addEditTag = false;">
                    Cancel
                </button>

                <button class="ml-2" mat-flat-button [color]="'primary'" appPreventMultiClick (click)="saveTagRule()"
                    [disabled]="tagNgForm.invalid || !tagRules || (!tagRules.tags || !tagRules.tags.length)" [matTooltip]="'Save'">
                    Save
                </button>
            </div>
        </form>
    </div>
</ng-container>
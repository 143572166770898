<div>
    <form #cveFrm="ngForm">
        <ng-container *ngIf="!tcve || !tcve.length">
            <div class="mt-4">
                <mat-form-field class="w-full sm:w-72 mt-4 sm:mt-0 sm:ml-4" [subscriptSizing]="'dynamic'">
                    <span matPrefix>CVE-</span>
                    <input autofocus type="text" trim="blur" [(ngModel)]="filterCVE" required matInput
                        pattern="((1999|2\d{3})-(0\d{2}[1-9]|[1-9]\d{3,}))" placeholder="YEAR-NNNNNN" name="filterCVE"
                        autocomplete="off">
                    <button mat-button *ngIf="filterCVE" matSuffix mat-icon-button aria-label="Clear"
                        (click)="filterCVE = undefined; filterCVE = ''" type="button">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-hint>Ex. 2021-21166</mat-hint>
                </mat-form-field>
                <button class="e-btn e-primary ml-4 e-qb-button" type="button" (click)="getCEV()"
                    [disabled]="!cveFrm.form.valid">Search</button>
            </div>
        </ng-container>
        <ng-container *ngIf="tcve && tcve.length">
            <div class="flex flex-row w-full items-end justify-between">
                <mat-form-field class="w-full sm:w-72 mt-4 sm:mt-0 sm:ml-4" [subscriptSizing]="'dynamic'">
                    <mat-label>Select CVE</mat-label>
                    <mat-select name="CVE" required [(ngModel)]="filterCVE" (selectionChange)="cveChange($event)">
                        <ng-container *ngFor="let item of tcve">
                            <mat-option [value]="item.cve"> {{item.cve}} </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <div class="flex items-end justify-end pr-4">
                    <a class="inline-flex items-center bg-card leading-6 hover:underline p-2 border rounded-full"
                        href="javascript:" (click)="backFn()">
                        <span class="inline-flex items-center">
                            <mat-icon class="icon-size-5 text-current"
                                [svgIcon]="'heroicons_solid:arrow-small-left'"></mat-icon>
                            <span class="ml-1.5 font-medium leading-5">Back to search</span>
                        </span>
                    </a>
                </div>
            </div>
        </ng-container>
    </form>
</div>
<mat-divider class="mt-4"></mat-divider>
<ng-container *ngIf="cveDetails && cveDetails.cve">
    <div
        class="mt-4 p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
        <div class="flex flex-col items-start justify-start w-3/4 bg-card bg-transparent">
            <div class="flex flex-row items-center justify-center mt-4">
                <div class="text-xl font-semibold leading-tight mr-2">{{cveDetails.cve}}</div>
                <span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full"
                    [ngClass]="'cs-' + cveDetails.severity?.toLowerCase()">
                    {{cveDetails.severity}}
                </span>
            </div>
            <div class="text-secondary text-medium leading-tight mt-2">{{cveDetails.description}}</div>
            <!-- <div class="flex items-center mt-2">
                    <mat-icon
                        class="icon-size-5 mr-3"
                        [svgIcon]="'heroicons_solid:clock'"></mat-icon>
                    <span class="leading-none">{{cveDetails.published_date | utcToLocale}}</span>
                </div> -->
            <ng-container *ngIf="cveDetails && cveDetails.scores">
                <hr class="w-full border-t mt-4 mb-2">
                <div class="w-full flex items-start justify-between my-4 px-2">
                    <div class="flex flex-col items-center">
                        <span class="font-bold">
                            <span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full" [ngClass]="{
                                            'cs-critical': cveDetails.scores.epss_score >= 0.95,
                                            'cs-high': cveDetails.scores.epss_score >= 0.90 && cveDetails.scores.epss_score <= 0.94,
                                            'cs-medium': cveDetails.scores.epss_score >= 0.85 && cveDetails.scores.epss_score <= 0.89,
                                            'cs-low': cveDetails.scores.epss_score >= 0.00 && cveDetails.scores.epss_score <= 0.84,
                                            }">
                                {{cveDetails.scores.epss_score}}
                            </span>
                        </span>
                        <span class="text-medium font-medium text-secondary mt-1">EPSS SCORE</span>
                    </div>
                    <div class="flex flex-col items-center">
                        <span class="font-bold">
                            <span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full cs-critical">
                                {{cveDetails.scores.base_score}}
                            </span>
                        </span>
                        <span class="text-medium font-medium text-secondary mt-1">BASE SCORE</span>
                    </div>
                    <div class="flex flex-col items-center">
                        <span class="font-bold"><span
                                class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full cs-critical">
                                {{cveDetails.scores.impact_score}}
                            </span></span>
                        <span class="text-medium font-medium text-secondary mt-1">IMPACT SCORE</span>
                    </div>
                    <div class="flex flex-col items-center">
                        <span class="font-bold"><span
                                class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full cs-critical">
                                {{cveDetails.scores.exploitability_score}}
                            </span></span>
                        <span class="text-medium font-medium text-secondary mt-1">EXPLOITABILITY SCORE</span>
                    </div>
                </div>
                <hr class="w-full border-t mt-2 mb-8">
            </ng-container>
        </div>
    </div>
</ng-container>
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { CommonModule, NgFor, NgIf, NgOptimizedImage } from '@angular/common';
import { MaterialModule } from 'app/material.module';
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { TableComponent } from 'app/modules/shared/table/table.component';
import { fuseAnimations } from '@fuse/animations';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { ShowMoreLessComponent } from 'app/modules/shared/show-more-less/show-more-less.component';
import { SkeletonComponent } from 'app/modules/shared/skeleton/skeleton.component';
import { IpvComponent } from 'app/modules/shared/ipv/ipv.component';

@Component({
  selector: 'app-asm-results',
  standalone: true,
  animations: fuseAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, IpvComponent,
    MaterialModule,
    AppFilterPipeModule,
    TableComponent,
    MatSidenavModule,
    FuseScrollbarDirective,
    ShowMoreLessComponent,
    SkeletonComponent,
    FormsModule],
  templateUrl: './asm-results.component.html',
  styleUrls: ['./asm-results.component.scss']
})
export class AsmResultsComponent implements OnInit, OnDestroy {
  @Input() currentJob: any;
  @Output() closeCallback = new EventEmitter();
  @Output() toggleFn = new EventEmitter();
  objectKeys = Object.keys;
  data: any = {};
  summary: any[];
  showData = null;
  @ViewChild('targetip', { static: false }) targetip: ElementRef;
  @ViewChild('emails', { static: false }) emails: ElementRef;
  @ViewChild('usernames', { static: false }) usernames: ElementRef;
  @ViewChild('subdomains', { static: false }) subdomains: ElementRef;
  @ViewChild('scrollableDiv', { static: false }) scrollableDiv: ElementRef;

  constructor(private _bs: BaseRequestService, private _cs: CommonService, private _changeDetectorRef: ChangeDetectorRef,
    private _ls: LoaderService, private toast: MyToastrService, private renderer: Renderer2) {
  }

  ngOnDestroy(): void {
  }
  ngOnInit(): void {
    this.callResult();
  }
  
  callResult() {
    this.showData = null;
    /*
    if (this.currentJob.job_details && this.currentJob.job_details.length) {
      condition.condition += ' and ' + "website='" + this.currentJob.job_details[0].domain + "'";
    }
    this._bs
      .doRequest('/r/company/attack_surface_results', 'get', null, condition)
      .subscribe((result: any) => {
        if (result.status) {
          try {
            if (result.data && result.data.length) {
              this.data = result.data[0];
              this.processData();
            } else {
              this.showData = true;
              this._changeDetectorRef.detectChanges();
            }
          } catch (e) {
            console.log(e);
          }
        }
      });*/
    this.data = this.currentJob;
    this.processData();
    this.showData = true; this._changeDetectorRef.detectChanges();
  }

  processData(): void {
    if (this.data) {
      if (this.data.s3buckets) {
        this.data.s3buckets[0] = this.data.s3buckets[0].filter((str: any) => str !== '');

      }
      if (this.data.guessed_emails) {
        this.data.guessed_emails[0] = this.data.guessed_emails[0].filter((str: any) => str !== '');
      }
      if (this.data.emails) {
        this.data.emails[0] = (this.data.emails && this.data.emails.length)
          ? this.data.emails[0].filter((str: any) => str !== '') : [];
      }
      if (this.data.hashes) {
        this.data.hashes[0] = (this.data.hashes && this.data.hashes.length)
          ? this.data.hashes[0].filter((str: any) => str !== '') : [];
      }
      if (this.data.usernames) {
        this.data.usernames[0] = (this.data.usernames && this.data.usernames.length)
          ? this.data.usernames[0].filter((str: any) => str !== '') : [];
      }
      if (this.data.employees) {
        this.data.employees[0] = (this.data.employees && this.data.employees.length)
          ? this.data.employees[0].filter((str: any) => str !== '') : [];
      }
      if (this.data.subdomains) {
        this.data.subdomains[0] = (this.data.subdomains && this.data.subdomains.length)
          ? this.data.subdomains[0].filter((str: any) => str !== '') : [];
      }
      if (this.data.creds) {
        this.data.creds[0] = (this.data.creds && this.data.creds.length)
          ? this.data.creds[0].filter((str: any) => str !== '') : [];
      }
      let ports: any = [];
      let vuls: any = [];
      if (this.data.target_ips && this.data.dns_records) {
        this.data.target_ips.forEach((obj: any) => {
          obj['port_protocol'].split(',').forEach((port: any) => {
            if (port && port !== '') { ports.push(port); }
          });
          obj.Vulnerabities.split(',').forEach((vul: any) => {
            if (vul && vul !== '') { vuls.push(vul); }
          });
        });
        this.data.dns_records = this._cs.processTable(this.data.dns_records[0]);
        ports = [...new Set(ports)];
        vuls = [...new Set(vuls)];
        this.summary = [
          { title: 'Vulnerabilities', img: '/assets/images/network/bug.svg', count: vuls.length },
          { title: 'Open Ports', img: '/assets/images/network/padlock.svg', count: ports.length },
          { title: 'Target IPs', img: '/assets/images/target-ip.png', count: this.data.target_ips.length },
          {
            title: 'Emails',
            img: '/assets/images/emails.png',
            count: (this.data.emails[0].length && this.data.emails[0][0]) ? this.data.emails[0].length : 0
          },
          {
            title: 'Usernames',
            img: '/assets/images/usernames.png',
            count: (this.data.usernames[0].length && this.data.usernames[0][0]) ? this.data.usernames[0].length : 0
          },
          {
            title: 'Subdomains',
            img: '/assets/images/subdomains.png',
            count: (this.data.subdomains[0].length && this.data.subdomains[0][0]) ? this.data.subdomains[0].length : 0
          }
        ];
      }
      setTimeout(() => {
        this.showData = true; this._changeDetectorRef.detectChanges();
      }, 100);
    }
  }

  /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  navigateTarget($event: any): void {
    try {
      if ($event.title === 'Target IPs' || $event.title === 'Vulnerabilities' || $event.title === 'Open Ports') {
        this.targetip.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      } else if ($event.title === 'Emails') {
        this.emails.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      } else if ($event.title === 'Usernames') {
        this.usernames.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      } else if ($event.title === 'Subdomains') {
        this.subdomains.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }
    } catch (err) { }
  }
}

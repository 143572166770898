<div *ngIf="objectKeys(data)">
    <div class=" flex flex-col w-full min-w-0 sm:inset-0 sm:overflow-hidden" [@fadeInLeft]>
        <div class=" w-auto rounded-2xl dark:bg-default mb-2" #scrollableDiv>
            <div class="flex flex-col flex-auto min-w-0 w-full">
                <div class="flex flex-row items-center pl-4 mt-4">
                    <div class="flex items-center py-1 px-0.5">
                        <mat-icon class="icon-size-5 text-grey-400" [svgIcon]="'mat_outline:domain'"></mat-icon>
                        <div
                            class="text-2xl font-semibold tracking-tight ml-2 text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
                            {{data.website}}</div>
                    </div>
                    <button class="ml-auto" mat-icon-button (click)="closeCallback.emit({})">
                        <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    </button>
                </div>
                <div *ngIf="objectKeys(data).length !== 0" class="flex-auto -mt-px pt-1 sm:pt-2 ">
                    <div class="w-full max-w-screen-xl mx-auto" fuseScrollbar>
                        <div class="p-2">
                            <div class="flex flex-row gap-2 w-full">
                                <ng-container *ngFor="let file of summary; trackBy:trackByFn">
                                    <a class="flex flex-col w-32 h-32 m-2 p-4 shadow rounded-2xl cursor-pointer bg-card duration-300 ease-out transform hover:-translate-y-1 hover:scale-105"
                                        href="javascript:" (click)="navigateTarget(file)">
                                        <div class="aspect-[9/6]">
                                            <div class="flex flex-col items-center justify-center h-full">
                                                <!-- Icons -->
                                                <div class="relative">
                                                    <img class="h-14 w-14" [src]="file.img" alt="">
                                                    <!-- <mat-icon class="icon-size-8 text-hint opacity-50"
                                                        [svgIcon]="file.img"></mat-icon> -->
                                                </div>
                                                <div class="whitespace-nowrap left-0 bottom-0 px-1.5 mt-2 rounded text-sm font-semibold leading-5 text-cyan-400"
                                                    [class.text-cyan-400]="file.title === 'Vulnerabilities'"
                                                    [class.text-indigo-400]="file.title === 'Open Ports'"
                                                    [class.text-green-400]="file.title === 'Target IPs'"
                                                    [class.text-violet-400]="file.title === 'Emails'"
                                                    [class.text-blue-400]="file.title === 'Usernames'"
                                                    [class.text-amber-400]="file.title === 'Subdomains'">
                                                    {{file.title}}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="flex flex-col flex-auto justify-center text-center text-sm font-medium">
                                            <div class="truncate flex justify-center text-center"
                                                [matTooltip]="file.count">
                                                <div class="fuse-vertical-navigation-item-badge">
                                                    <div
                                                        class="fuse-vertical-navigation-item-badge-content px-2.5 bg-teal-400 font-bold text-center text-black rounded-full">
                                                        {{file.count}} </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </ng-container>
                            </div>
                            <mat-divider class="mb-4"></mat-divider>
                            <div *ngIf="data.s3buckets && data.s3buckets[0].length > 0"
                                class="flex flex-col flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                                <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                    S3 Buckets
                                    <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                        {{data.s3buckets[0]?.length }}
                                    </span>
                                    <div class="border-t-2 mt-2">
                                        <show-more-less [type]="'list'" [items]="data.s3buckets[0]"
                                            [showAllItems]="false" [maxItemsToShow]="50" [showOrder]="'asm'">
                                        </show-more-less>
                                    </div>
                                    <mat-divider class="mt-4"></mat-divider>
                                </div>
                            </div>
                            <div *ngIf="data.target_ips && data.target_ips.length > 0" #targetip
                                class="flex flex-col flex-auto overflow-hidden w-full mt-4">
                                <div class="flex flex-col sm:flex-row items-start justify-between">
                                    <div class="text-medium font-bold tracking-tight leading-6 truncate">
                                        Target IP Addresses <span
                                            class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                            {{data.target_ips?.length }}
                                        </span></div>
                                </div>
                                <div class="flex flex-col mt-2">
                                    <app-ipv *ngFor="let ipData of data.target_ips" class="mb-4"
                                        [data]="ipData"></app-ipv>
                                </div>
                            </div>
                            <ng-container *ngIf="data.dns_records && data.dns_records.length>0">
                                <div
                                    class="flex flex-col flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                                    <div class="flex flex-col sm:flex-row items-start justify-between">
                                        <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                            DNS
                                            Records
                                            <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                                {{data.dns_records?.length }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="flex flex-col items-start border-t-2 mt-2 p-2">
                                        <table
                                            class="text-medium text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                            <tbody>
                                                <tr *ngFor="let rec of data.dns_records;">
                                                    <td
                                                        class="border px-4 py-2 font-bold w-40 text-blue-800 bg-blue-100 dark:text-blue-50 dark:bg-blue-500">
                                                        {{rec[0]| camelToHuman:true}}</td>
                                                    <td class="border px-4 py-2 break-all">{{rec[1]}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <mat-divider class="my-4"></mat-divider>
                            </ng-container>
                            <ng-container *ngIf="data.subdomains && data.subdomains[0].length >0">
                                <div #subdomains
                                    class="flex flex-col flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                                    <div class="flex flex-col sm:flex-row items-start justify-between">
                                        <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                            Sub Domains
                                            <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                                {{data.subdomains[0]?.length }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="border-t-2 mt-2">
                                        <show-more-less [type]="'list'" [items]="data.subdomains[0]"
                                            [showAllItems]="false" [maxItemsToShow]="50" [showOrder]="'href'">
                                        </show-more-less>
                                    </div>
                                </div>
                                <mat-divider class="my-4"></mat-divider>
                            </ng-container>
                            <ng-container *ngIf="data.mx.hosts && data.mx.hosts.length >0">
                                <div
                                    class="flex flex-col flex-auto overflow-hidden w-full bg-white border border-gray-200 p-4 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                                    <div class="flex flex-col sm:flex-row items-start justify-between">
                                        <div class="flex flex-row my-4">
                                            <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                                MX
                                                Records
                                                <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                                    {{data.mx.hosts?.length }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                                        <table
                                            class="w-full text-medium text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                            <thead
                                                class="text-medium font-medium text-black bg-blue-100 dark:text-blue-50 dark:bg-blue-500">
                                                <tr>
                                                    <th scope="col" class="px-6 py-3">
                                                        Address
                                                    </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        HostName
                                                    </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        Preference
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let mxhosts of data.mx.hosts">
                                                    <tr class=" break-all border-b dark:border-gray-700">
                                                        <th
                                                            class="px-6 py-4 font-medium text-gray-900 dark:text-white max-w-[17rem]">
                                                            {{ mxhosts.addresses.join(', ') }}
                                                        </th>
                                                        <th class="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                            {{mxhosts.hostname}}
                                                        </th>
                                                        <td class="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                            {{mxhosts.preference}}
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                <div class="m-6 ml-2" *ngIf="!data.mx.hosts || !data.mx.hosts.length">
                                                    <div> No record found.</div>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <mat-divider class="my-4"></mat-divider>
                            </ng-container>
                            <ng-container *ngIf="data.raw_headers && objectKeys(data.raw_headers).length">
                                <div
                                class="flex flex-col flex-auto overflow-hidden w-full bg-white border border-gray-200 p-4 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                                <div class="flex flex-col flex-auto overflow-hidden w-full">
                                    <div class="flex flex-col sm:flex-row items-start justify-between">
                                        <div class="flex flex-row my-4">
                                            <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                                RAW
                                                Headers
                                            </div>
                                        </div>
                                    </div>
                                    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                                        <table
                                            class="w-full text-medium text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                            <tbody>
                                                <tr *ngFor="let header of objectKeys(data.raw_headers)">
                                                    <td
                                                        class="border px-4 py-2 font-bold w-1/4 text-black bg-blue-100 dark:text-blue-50 dark:bg-blue-500">
                                                        {{ header| camelToHuman:true }}</td>
                                                    <td class="border px-4 py-2">
                                                        <show-more-less *ngIf="data.raw_headers[header]"
                                                            [type]="'string'"
                                                            [inputString]="data.raw_headers[header]"></show-more-less>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <mat-divider class="my-4"></mat-divider>
                            </ng-container>
                            <ng-container *ngIf="data.guessed_emails && data.guessed_emails.length && data.guessed_emails[0] && data.guessed_emails[0][0]">
                                <div
                                    class="flex flex-col flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                                    <div class="flex flex-col sm:flex-row items-start justify-between">
                                        <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                            Guessed Emails
                                            <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                                {{data["guessed_emails"][0]?.length }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="border-t-2 mt-2">
                                        <show-more-less [type]="'list'" [items]="data.guessed_emails[0]"
                                            [showAllItems]="false" [maxItemsToShow]="50" [showOrder]="'href'">
                                        </show-more-less>
                                    </div>
                                </div>
                                <mat-divider class="my-4"></mat-divider>
                            </ng-container>
                            <ng-container *ngIf="data.emails && data.emails.length && data.emails[0] && data.emails[0][0]">
                                <div #emails
                                    class="flex flex-col flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                                    <div class="flex flex-col sm:flex-row items-start justify-between">
                                        <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                            Email(s)
                                            <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                                {{data["emails"][0]?.length }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="border-t-2 mt-2">
                                        <show-more-less [type]="'list'" [items]="data.emails[0]"
                                            [showAllItems]="false" [maxItemsToShow]="50" [showOrder]="'href'">
                                        </show-more-less>
                                    </div>
                                </div>
                                <mat-divider class="my-4"></mat-divider>
                            </ng-container>
                            <ng-container *ngIf="data.hashes && data.hashes.length && data.hashes[0] && data.hashes[0][0]">
                                <div
                                    class="flex flex-col flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                                    <div class="flex flex-col sm:flex-row items-start justify-between">
                                        <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                            Hashes
                                            <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                                {{data["hashes"][0]?.length }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="border-t-2 mt-2">
                                        <show-more-less [type]="'list'" [items]="data.hashes[0]"
                                            [showAllItems]="false" [maxItemsToShow]="50" [showOrder]="'href'">
                                        </show-more-less>
                                    </div>
                                </div>
                                <mat-divider class="my-4"></mat-divider>
                            </ng-container>
                            <ng-container *ngIf="data.usernames && data.usernames.length && data.usernames[0] && data.usernames[0][0]">
                                <div #usernames
                                    class="flex flex-col flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                                    <div class="flex flex-col sm:flex-row items-start justify-between">
                                        <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                            Username(s)
                                            <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                                {{data["usernames"][0]?.length }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="border-t-2 mt-2">
                                        <show-more-less [type]="'list'" [items]="data.usernames[0]"
                                            [showAllItems]="false" [maxItemsToShow]="50" [showOrder]="'href'">
                                        </show-more-less>
                                    </div>
                                </div>
                                <mat-divider class="my-4"></mat-divider>
                            </ng-container>
                            <ng-container *ngIf="data.employees && data.employees.length && data.employees[0] && data.employees[0][0]">
                                <div
                                    class="flex flex-col flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                                    <div class="flex flex-col sm:flex-row items-start justify-between">
                                        <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                            Employees
                                            <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                                {{data["employees"][0]?.length }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="border-t-2 mt-2">
                                        <show-more-less [type]="'list'" [items]="data.employees[0]"
                                            [showAllItems]="false" [maxItemsToShow]="50" [showOrder]="'href'">
                                        </show-more-less>
                                    </div>
                                </div>
                                <mat-divider class="my-4"></mat-divider>
                            </ng-container>
                            <ng-container *ngIf="data.creds && data.creds.length && data.creds[0] && data.creds[0][0]">
                                <div
                                    class="flex flex-col flex-auto p-4 overflow-hidden w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                                    <div class="flex flex-col sm:flex-row items-start justify-between">
                                        <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                            Creds
                                            <span class="ml-1 px-2 bg-teal-400 text-black rounded py-0.5 text-sm">
                                                {{data["creds"][0]?.length }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="border-t-2 mt-2">
                                        <show-more-less [type]="'list'" [items]="data.creds[0]"
                                            [showAllItems]="false" [maxItemsToShow]="50" [showOrder]="'href'">
                                        </show-more-less>
                                    </div>
                                </div>
                                <mat-divider class="my-4"></mat-divider>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div *ngIf="objectKeys(data).length === 0" class="flex flex-col items-center justify-center gap-y-2">
                    <img src="/assets/images/empty.png" alt="" class="w-20">
                    <div> No data available!</div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-skeleton *ngIf="!data && !showData">

</app-skeleton>
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReplaySubject, Subject, Subscription, debounceTime, takeUntil } from 'rxjs';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { TableComponent } from 'app/modules/shared/table/table.component';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MaterialModule } from 'app/material.module';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PillCardComponent } from 'app/modules/shared/pill-card/pill-card.component';
import { DynamicSettingsService } from 'app/layout/common/dynamic-settings/dynamic-settings.service';
import { CommonService } from 'app/_services/common.service';
import { SkeletonComponent } from 'app/modules/shared/skeleton/skeleton.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-azure-ad-details',
  standalone: true,
  imports: [CommonModule, SkeletonComponent, FuseAlertComponent, TableComponent, PillCardComponent, MatSidenavModule, ReactiveFormsModule, FormsModule, NgxMatSelectSearchModule, MaterialModule, AppFilterPipeModule, FuseScrollbarDirective],
  templateUrl: './azure-ad-details.component.html',
  styleUrls: ['./azure-ad-details.component.scss']
})
export class AzureAdDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') drawer: MatDrawer;
  drawerPosition: 'start' | 'end' = 'end';
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean = false;

  @ViewChild('companySelect', { static: true }) companySelect!: MatSelect;
  public companyMapCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
  public companies: any = [];
  public allComp: any = [];
  totalCompany: any;
  showView: any;
  public searching = false;
  searchTxt: any = 'Search Company';
  public companyHash: any = {};
  isSearchK = false;
  company: any = {};
  isVisible: boolean = false;
  company_id: any;
  currentCompany: any;
  currentNode: any;
  protected onDestroySearch = new Subject<void>();
  private action: Subscription;
  searchFilter: any = '';
  private subs: Subscription;
  cView: any = 'users';
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private _bs: BaseRequestService, private _changeDetectorRef: ChangeDetectorRef, private cs: CommonService,
    private ls: LoaderService, private toast: MyToastrService, private _ds: DynamicSettingsService,) {
    this.action = this._ds.azureSyncEmitter.subscribe((res: any) => {
      if (res.target === 'azure-sync') {
        this.checkCompMapping();
      }
    });
    this.subs = this.cs.selectedSiteChanged.subscribe((cmp: any) => {
      this.company_id = this.cs.currentScope.id;
      this.initAzureData();
      this._changeDetectorRef.markForCheck();
    });
  }
  userTableOptions: any = {
    columns: [
      { "header": "User Principal Name", "isHyperlink": true, "columnDef": "distinguished_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.distinguished_name}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Name", "columnDef": "name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Display Name", "columnDef": "display_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.display_name}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Assigned Roles", "columnDef": "member_of", "cType": "string", "filter": "", "cell": "(element: any) => `${element.member_of}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Other Mails", "columnDef": "other_mails", "cType": "string", "filter": "", "cell": "(element: any) => `${element.other_mails}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "MFA Enabled", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "mfa_enabled", "cType": "string", "filter": "", "cell": "(element: any) => `${element.mfa_enabled}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Created On", "columnDef": "created", "cType": "string", "filter": "utcToLocale", "cell": "(element: any) => `${element.created}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
    ],
    sortOptions: { active: 'name', direction: 'asc' },
    _pageData: [],
    tableOptions: {
      title: 'Azure AD Users',
      isServerSide: false,
      selectText: '',
      loading: false,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showhideList: true,
      refreshData: true,
      showFilter: true,
      showTagFilter: false,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: "/report_queries/ad_users_view",
        isGlobal: true,
        condition: `source='azure'`
      }
    },
    customText: [
      {
        status: true,
        DisplayText: 'Yes',
        class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
      },
      {
        status: false,
        DisplayText: 'No',
        class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'

      },
    ],
    changeValue: new Subject<any>(),
  };
  compuTableOptions: any = {
    columns: [
      { "header": "Asset Name", "columnDef": "name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "AD OS", "columnDef": "os_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.os_name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "OS Version", "columnDef": "os_version", "cType": "string", "filter": "", "cell": "(element: any) => `${element.os_version}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Is Managed", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "is_managed", "cType": "string", "filter": "", "cell": "(element: any) => `${element.is_managed}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
    ],
    sortOptions: { active: 'name', direction: 'asc' },
    _pageData: [],
    tableOptions: {
      title: 'Azure AD Computers',
      isServerSide: false,
      selectText: '',
      loading: false,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showhideList: true,
      refreshData: true,
      showFilter: true,
      showTagFilter: false,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: "/report_queries/ad_computers_view",
        isGlobal: true,
        condition: `source='azure'`
      }
    },
    customText: [
      {
        status: true,
        DisplayText: 'Yes',
        class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
      },
      {
        status: false,
        DisplayText: 'No',
        class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'

      },
    ],
    changeValue: new Subject<any>(),
  }
  grouTableOptions: any = {
    columns: [
      { "header": "Display Name", "columnDef": "name", "isHyperlink": true, "cType": "string", "filter": "", "cell": "(element: any) => `${element.name}`", "order": 2, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Email", "columnDef": "mail", "cType": "string", "filter": "", "cell": "(element: any) => `${element.mail}`", "order": 2, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Group Type", "columnDef": "group_type", "cType": "string", "filter": "", "cell": "(element: any) => `${element.group_type}`", "order": 2, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Is Email Enabled", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "mail_enabled", "cType": "string", "filter": "", "cell": "(element: any) => `${element.mail_enabled}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Proxy Addresses", "columnDef": "proxy_addresses", "cType": "string", "filter": "", "cell": "(element: any) => `${element.proxy_addresses}`", "order": 2, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Resource Behaviour Options", "columnDef": "resource_behavior_options", "cType": "string", "filter": "", "cell": "(element: any) => `${element.resource_behavior_options}`", "order": 2, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Resource Provisioning Options", "columnDef": "resource_provisioning_options", "cType": "string", "filter": "", "cell": "(element: any) => `${element.resource_provisioning_options}`", "order": 2, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Is Security Enabled", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "columnDef": "is_security_enabled", "cType": "string", "filter": "", "cell": "(element: any) => `${element.is_security_enabled}`", "order": 3, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Description", "columnDef": "description", "cType": "string", "filter": "", "cell": "(element: any) => `${element.description}`", "order": 1, "visible": false, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
    ],
    sortOptions: { active: 'name', direction: 'asc' },
    _pageData: [],
    tableOptions: {
      title: 'Azure AD User Groups',
      isServerSide: false,
      selectText: '',
      loading: false,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showhideList: true,
      refreshData: true,
      showFilter: true,
      showTagFilter: false,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: "/report_queries/ad_groups_view",
        isGlobal: true,
        condition: `source='azure'`
      }
    },
    customText: [
      {
        status: true,
        DisplayText: 'Yes',
        class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
      },
      {
        status: false,
        DisplayText: 'No',
        class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'

      },
    ],
    changeValue: new Subject<any>(),
  }
  licenseTableOptions: any = {
    columns: [
      { "header": "License Friendly Name", "columnDef": "license_friendly_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.license_friendly_name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "SKU Part Number", "columnDef": "sku_part_number", "cType": "string", "filter": "", "cell": "(element: any) => `${element.sku_part_number}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Consumed Units", isColoredCntrl: true, isCustomText: false, "columnDef": "consumed_units", "cType": "number", "filter": "", "cell": "(element: any) => `${element.consumed_units}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Enabled Units", isColoredCntrl: true, isCustomText: false, "columnDef": "prepaid_units.enabled", "cType": "number", "filter": "", "cell": "(element: any) => `${element.prepaid_units.enabled}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": false, "iscolumnSearch": false },
      { "header": "Locked Out Units", isColoredCntrl: true, isCustomText: false, "columnDef": "prepaid_units.locked_out", "cType": "number", "filter": "", "cell": "(element: any) => `${element.prepaid_units.locked_out}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": false, "iscolumnSearch": false },
      { "header": "Suspended Units", isColoredCntrl: true, isCustomText: false, "columnDef": "prepaid_units.suspended", "cType": "number", "filter": "", "cell": "(element: any) => `${element.prepaid_units.suspended}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": false, "iscolumnSearch": false },
      { "header": "Warning Units", isColoredCntrl: true, isCustomText: false, "columnDef": "prepaid_units.warning", "cType": "number", "filter": "", "cell": "(element: any) => `${element.prepaid_units.warning}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": false, "iscolumnSearch": false },
    ],
    sortOptions: { active: 'license_friendly_name', direction: 'asc' },
    _pageData: [],
    tableOptions: {
      title: 'Azure AD License',
      isServerSide: false,
      selectText: '',
      loading: false,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showhideList: true,
      refreshData: true,
      showFilter: true,
      showTagFilter: false,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: "/report_queries/azure_licenses",
        isGlobal: true,
        condition: `free_license=FALSE`
      }
    },
    changeValue: new Subject<any>(),
  }
  logsTableOptions: any = {
    columns: [
      { "header": "Activity Display Name", "isHyperlink": true, "columnDef": "activity_display_name", "cType": "string", "filter": "", "cell": "(element: any) => `${element.activity_display_name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Category", "columnDef": "category", "cType": "string", "filter": "", "cell": "(element: any) => `${element.category}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Result", "columnDef": "result", isColoredCntrl: true, isCustomText: true, isCustomClass: true, "cType": "string", "filter": "", "cell": "(element: any) => `${element.result}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Result Reason", "columnDef": "result_reason", "cType": "string", "filter": "", "cell": "(element: any) => `${element.result_reason}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Correlation ID", "columnDef": "correlation_id", "cType": "string", "filter": "", "cell": "(element: any) => `${element.correlation_id}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Logged By Service", "columnDef": "logged_by_service", "cType": "string", "filter": "", "cell": "(element: any) => `${element.logged_by_service}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Initiated By User", "columnDef": "initiated_by.user.userPrincipalName", "cType": "number", "filter": "", "cell": "(element: any) => `${element.initiated_by.user.userPrincipalName}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Initiated From IP", "columnDef": "initiated_by.user.ipAddress", "cType": "number", "filter": "", "cell": "(element: any) => `${element.initiated_by.user.ipAddress}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
    ],
    sortOptions: { active: 'activity_display_name', direction: 'asc' },
    _pageData: [],
    tableOptions: {
      title: 'Azure AD Logs',
      isServerSide: false,
      selectText: '',
      loading: false,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showhideList: true,
      refreshData: true,
      showFilter: true,
      showTagFilter: false,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: "/report_queries/azure_ad_logs",
        condition: `source='azure'`,
        isGlobal: true,
      }
    },
    customText: [
      {
        status: 'success',
        DisplayText: 'Success',
        class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
      },
      {
        status: 'failure',
        DisplayText: 'Failed',
        class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'

      },
    ],
    changeValue: new Subject<any>(),
  }
  roleTableOptions: any = {
    columns: [
      { "header": "Display Name", "columnDef": "display_name", "isHyperlink": true, "cType": "string", "filter": "", "cell": "(element: any) => `${element.display_name}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Description", "columnDef": "description", "cType": "string", "filter": "", "cell": "(element: any) => `${element.description}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "User Count", "columnDef": "user_count", isColoredCntrl: true, isCustomText: false, "cType": "number", "filter": "", "cell": "(element: any) => `${element.user_count}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
      { "header": "Role Template ID", "columnDef": "role_template_id", "cType": "string", "filter": "", "cell": "(element: any) => `${element.role_template_id}`", "order": 0, "visible": true, "isToolTip": false, "isToolTipCol": "", "hasMultiData": false, "class": "", "color": "", "isProgressCntrl": false, "isColoredCntrl": false, "colList": [], "isfaicon": false, "isAddingText": false, "addingText": "", "img": false, "imgPath": "", "isSort": true, "iscolumnSearch": false },
    ],
    sortOptions: { active: 'display_name', direction: 'asc' },
    _pageData: [],
    tableOptions: {
      title: 'Azure AD Roles',
      isServerSide: false,
      selectText: '',
      loading: false,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showhideList: true,
      refreshData: true,
      showFilter: true,
      showTagFilter: false,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: "/report_queries/ad_roles",
        condition: `source='azure'`,
        isGlobal: true,
      }
    },
    customText: [
      {
        status: 'success',
        DisplayText: 'Success',
        class: 'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50'
      },
      {
        status: 'failure',
        DisplayText: 'Failed',
        class: 'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50'

      },
    ],
    changeValue: new Subject<any>(),
  }
  currentData: any = {};
  selectedType: any = 'user';
  userLicense: any = {
    assigned_licenses: [],
    assigned_plans: [],
    provisioned_plans: []
  };
  pillCards: any = [];
  groupMembers: any = [];
  roleMembers: any = [];
  noCmpMapped = undefined;
  basic_information: any = []
  compMapping: any = {};
  ngOnDestroy(): void {
    this.action.unsubscribe();
    this.subs.unsubscribe();
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    this.initAzureData();
  }

  initAzureData(): void {
    if (this.cs.currentScope && this.cs.currentScope.id) {
      this.company_id = this.cs.currentScope.id;
      this.noCmpMapped = undefined;
      this._bs.doRequest(`/r/integration/company_mappings`, 'get', null,
        { condition: `company_id=${this.company_id} and (integration_name='AzureADCSP' OR integration_name='AzureADNonCSP')`, skip: 0, limit: 1 })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: any) => {
          if (res.status) {
            if (res.data && res.data.length) {
              this.compMapping = res.data[0];
              this.updateCurrentCompany(this.cs.currentScope);
              this.noCmpMapped = false;
            } else {
              this.compMapping = {};
              this.noCmpMapped = true;
            }
          } else {
            const data = (res.message) ? res.message : res.data;
            this.toast.sToast('error', data);
          }
        });
    }
  }

  filterTable($event: any): void {

  }

  linkCall($event: any): void {
    this.searchFilter = ''; this.currentData = {};
    if ($event.col === 'distinguished_name') {
      this.getUserLicense($event.row);
    }
    if ($event.col === 'activity_display_name') {
      this.selectedType = 'logs';
      const target_resources = cloneDeep($event.row.target_resources[0]?.modified_properties);
      $event.row.targetResources = (target_resources && target_resources.length) ? target_resources : [];
      // $event.row.additional_details = JSON.parse($event.row.additional_details);
      this.currentData = $event.row;
      this._changeDetectorRef.detectChanges();
      this.drawerOpened = true;
      if (!this.drawer.opened) {
        this.drawer.toggle().then(r => console.log(r));
      }
    }
    if ($event.col === 'name') {
      this.getGroupRoles($event.row)
    }
    if ($event.col === 'display_name') {
      this.getRoleUser($event.row)
    }
  }

  getRoleUser($event: any): void {
    this.currentData = $event;
    const params: any = {
      condition: true,
      object_guid: $event.object_guid,
      company_id: $event.company_id
    }
    this.ls.display(true);
    this._bs.doRequest(`/report_queries/ad_roles_member`, 'get', null, params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        if (res.status) {
          this.selectedType = 'roles';
          if (res.data && res.data.length) {
            this.roleMembers = res.data;
          }
        } else {
          const data = (res.message) ? res.message : res.data;
          this.toast.sToast('error', data);
        }
        this.drawerOpened = true;
        if (!this.drawer.opened) {
          this.drawer.toggle().then(r => console.log(r));
        }
      });
  }
  getGroupRoles($event: any): void {
    this.currentData = $event;
    const params: any = {
      condition: true,
      object_guid: $event.object_guid,
      company_id: $event.company_id
    }
    this.ls.display(true);
    this._bs.doRequest(`/report_queries/ad_group_users`, 'get', null, params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        if (res.status) {
          this.selectedType = 'groups';
          if (res.data && res.data.length) {
            this.groupMembers = res.data;
          }
        } else {
          const data = (res.message) ? res.message : res.data;
          this.toast.sToast('error', data);
        }
        this.drawerOpened = true;
        if (!this.drawer.opened) {
          this.drawer.toggle().then(r => console.log(r));
        }
      });
  }
  getUserLicense($event: any): void {
    this.currentData = $event;
    this.ls.display(true);
    this._bs.doRequest(`/report_queries/ad_user_licenses`, 'get', null,
      { condition: `object_guid='${$event.object_guid}' and company_id=${$event.company_id}`, skip: 0, limit: 1 })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        if (res.status) {
          this.selectedType = 'user';
          if (res.data && res.data.length) {
            // res.data[0].assigned_licenses = JSON.parse(res.data[0].assigned_licenses);
            // res.data[0].assigned_plans = JSON.parse(res.data[0].assigned_plans);
            // res.data[0].provisioned_plans = JSON.parse(res.data[0].provisioned_plans);
            this.userLicense = res.data[0];
          }
        } else {
          const data = (res.message) ? res.message : res.data;
          this.toast.sToast('error', data);
        }
        this.drawerOpened = true;
        if (!this.drawer.opened) {
          this.drawer.toggle().then(r => console.log(r));
        }
      });
  }
  /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }



  dataParams(pdata: any, condition: any): any {
    const data = Object.assign({}, pdata);
    data.pageData = []; data.tableOptions.pageTotal = 0;
    data.tableOptions.serverSide.condition = condition;
    return data;
  }
  updateCurrentCompany(event: any): void {
    this.company = {}; this.isVisible = false; this.currentNode = undefined;
    setTimeout(() => {
      this.company_id = event.id;
      this.company = Object.assign({}, event);
      this.isVisible = true;
      this.getAzureADStats(); this.getAzureADBasicInfo();
      this.roleTableOptions = this.dataParams(this.roleTableOptions, `source='azure' and company_id=${parseInt(this.company_id)}`);
      this.logsTableOptions = this.dataParams(this.logsTableOptions, `source='azure' and company_id=${parseInt(this.company_id)}`);
      this.licenseTableOptions = this.dataParams(this.licenseTableOptions, `free_license=FALSE and company_id=${parseInt(this.company_id)}`);
      this.grouTableOptions = this.dataParams(this.grouTableOptions, `source='azure' and company_id=${parseInt(this.company_id)}`);
      this.compuTableOptions = this.dataParams(this.compuTableOptions, `source='azure' and company_id=${parseInt(this.company_id)}`);
      this.userTableOptions = this.dataParams(this.userTableOptions, `source='azure' and company_id=${parseInt(this.company_id)}`);
    });
  }
  getAzureADStats(): void {
    this.ls.display(false);
    this._bs.doRequest(`/r/company/company_stats`, 'get', null, {
      condition: `company_id=${parseInt(this.cs.currentScope.id)}`,
      skip: 0,
      limit: 1,
      order_by: 'updated desc'
    })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        if (res.data && res.data.length) {
          const result = res.data[0];
          this.pillCards = [
            {
              title: 'Users',
              img: '/assets/images/network/users.svg',
              icon: 'fa-user-friends',
              count: result.ad_stats.azure_user || 0,
              border: 'border-blue-600',
              data: [
                { name: 'enabled', value: result.ad_stats.azure_user_enabled || 0 },
                { name: 'disabled', value: result.ad_stats.azure_user_disabled || 0 },
              ],
            },
            {
              title: 'Computers',
              img: '/assets/images/network/computers.svg',
              count: result.ad_stats.azure_computer || 0,
              icon: 'fa-desktop',
              border: 'border-amber-600',
              data: [
                { name: 'enabled', value: result.ad_stats.azure_computer_enabled || 0 },
                { name: 'disabled', value: result.ad_stats.azure_computer_disabled || 0 },
              ],
            },
            {
              title: 'Groups',
              img: '/assets/images/network/group.svg',
              count: result.ad_stats.azure_group || 0,
              icon: 'fa-users',
              border: 'border-yellow-600',
              data: [
                {
                  name: 'Security count',
                  value: (result.ad_stats.azure_group - result.ad_stats.azure_security_not_enabled) || 0,
                },
                {
                  name: 'Non Security Enabled',
                  value: result.ad_stats.azure_security_not_enabled || 0,
                },
                {
                  name: 'Empty Groups',
                  value: result.ad_stats.azure_empty_group || 0,
                },

              ],
            },
            {
              title: 'License',
              img: '/assets/getting-started/welcome.png',
              count: result.ad_stats.azure_licenses || 0,
              icon: 'fa-id-badge',
              border: 'border-teal-600',
              data: [
                { name: 'active license', value: result.ad_stats.azure_active_licenses || 0 },
                { name: 'consumed license', value: result.ad_stats.azure_consumed_licenses || 0 },
              ],
            },
          ];
          this._changeDetectorRef.detectChanges();
        }
      })
  }
  getAzureADBasicInfo(): void {
    const params = {
      source: 'azure',
      company_id: parseInt(this.cs.currentScope.id),
      condition: true
    }
    this._bs.doRequest(`/report_queries/ad_basic_info`, 'get', null, params)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.ls.display(false);
        if (res.status) {
          if (res.data && res.data.length) {
            const result = res.data[0];
            this.basic_information = [
              {
                icon: 'mat_outline:table_chart',
                key: 'TENANT NAME',
                value: result.tenant_name,

              },
              {
                icon: 'mat_outline:star',
                key: 'TENANT ID',
                value: result.tenant_id
              },
              {
                icon: 'mat_outline:domain',
                key: 'PRIMARY DOMAIN',
                value: result.domain
              },
              {
                icon: 'mat_outline:info',
                key: 'AZURE AD CONNECT ENABLED',
                isBolean: true,
                value: result.ad_connect_enabled
              },
            ]
            this._changeDetectorRef.detectChanges();
          }

        }
      })
  }
  checkCompMapping(): void {
    if (this.noCmpMapped) {
      this.toast.sToast('info', 'This company is not Integrated and Mapped with the Azure Active Directory Integration.');
      return;
    }
    const result = this.compMapping;
    const reqData = { credential_id: result.credential_id, integration_name: result.integration_name, company_id: result.company_id }
    this._bs.doRequest(`w/integration/sync_now`, 'post', reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => { });
    this.toast.sToast('success', 'Azure AD data sync initiated.');
  }
  changeTab(type: any): void {
    this.cView = type;
    this.drawerOpened = false;
    if (this.drawer.opened) {
      this.drawer.toggle().then(r => console.log(r));
    }
  }
}

<div *ngIf="listOfFormElements && listOfFormElements.length">
    <form [formGroup]="form" novalidate="off"
          [ngClass]="{'grid-cols-2 gap-2': spanLayout === 2, 'grid-cols-3': spanLayout === 3}"
          class="px-4 py-4 bg-card rounded-2xl shadow sForm grid w-full">
        <div *ngFor="let formControl of listOfFormElements" class="form-row">
            <ng-container *ngIf="
            formControl.type !== 'companypicker'
             &&  formControl.type !== 'agentpicker'
             &&  formControl.type !== 's_captcha'
              && formControl.type !== 'select_with_options'">
                <app-dynamic-control class="sForm"
                                     [spanLayout]="spanLayout" [outputValue]="Valuesoutput"
                                     [formElement]="formControl" [forms]="form"
                                     [listOfFormElements]="listOfFormElements"
                                     (valChanges)="fieldValChange($event)">
                </app-dynamic-control>
            </ng-container>
            <!-- Company Selector -->
            <mat-form-field class="w-full mb-4 " *ngIf="formControl.type === 'companypicker'">
                <mat-label>{{formControl.label}}</mat-label>
                <mat-select #companySelect class="" [placeholder]="formControl.label"
                            (openedChange)="closeCurrentCompany($event, formControl.key)"
                            [(ngModel)]="Valuesoutput[formControl.key]"
                            [formControl]="companyCtrl"
                            (selectionChange)="updateCurrentCompany($event.value, formControl.key)">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="companyFilterCtrl" [placeholderLabel]="'Search Company'"
                                               [noEntriesFoundLabel]="'No matching company found'"
                                               [searching]="searching"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option [matTooltip]="company.name" *ngFor="let company of filteredCompanies | async"
                                [value]="company.id">
                        {{company.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- Company Selector-->

            <!--Agent Selector-->
            <mat-form-field class="w-full mb-4" *ngIf="formControl.type === 'agentpicker' && Valuesoutput.company_id">
                <mat-label>{{formControl.label}}</mat-label>
                <mat-select [(ngModel)]="Valuesoutput[formControl.key]" [formControlName]="formControl.key">
                    <mat-option *ngFor="let item of agentList" [value]="item.value">{{item.key}}</mat-option>
                </mat-select>
                <mat-error>{{formControl.label}} is required. <span class="ml10">{{formControl.errmsg}}</span>
                </mat-error>
            </mat-form-field>
            <!--Agent Selector-->

            <!-- Custom DropDown -->
            <mat-form-field class="w-full mb-4" *ngIf="formControl.type === 'select_with_options'">
                <mat-label>{{formControl.label}}</mat-label>
                <mat-select [(ngModel)]="Valuesoutput[formControl.key]" [formControlName]="formControl.key"
                            [id]="formControl.key">
                    <mat-option *ngFor="let item of formControl.options" [id]="item.key"
                                [value]="item.value">{{item.key}}({{item.value}})
                    </mat-option>
                </mat-select>
                <mat-error>
                    {{formControl.label}} is required. <span class="ml10">{{formControl.errmsg}}</span>
                </mat-error>
            </mat-form-field>
            <!-- Custom DropDown -->
            <!-- Captcha Form -->
            <ngx-turnstile [siteKey]="'0x4AAAAAAANDTXnOuMk7AG5w'" theme="auto" [formControlName]="formControl.key"
                           (resolved)="sendCaptchaResponse($event)"
                           *ngIf="formControl.type === 's_captcha'"></ngx-turnstile>
            <!-- Captcha Form-->
        </div>
        <div class="form-row flex items-center justify-end -mx-8 mt-4 px-8">
            <button type="submit" id="saveButton" *ngIf="isSaveBtn" appPreventMultiClick
                    [disabled]="!form || !form.valid" class="mr-2" mat-flat-button color="primary"
                    (click)="Save(form)">{{fnBtnName}}
            </button>
            <button id="closeButton" (click)="Cancel()" appPreventMultiClick *ngIf="isCloseBtn"
                    mat-flat-button>{{closeBtnName}}</button>
        </div>
    </form>
</div>

import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommonService} from "../../../_services/common.service";
import {MyToastrService} from "../../../_services/toastr.service";
import {FuseScrollbarDirective} from "../../../../@fuse/directives/scrollbar";
import {TimelineComponent} from "../timeline/timeline.component";
import { UserService } from 'app/core/user/user.service';

@Component({
    selector: 'app-system-events',
    standalone: true,
    imports: [CommonModule, FuseScrollbarDirective, TimelineComponent],
    templateUrl: './system-events.component.html',
    styleUrls: ['./system-events.component.scss']
})
export class SystemEventsComponent {
    inputData: any = [];
    dataMapping: any = [
        {
            field: 'columns',
            isArray: true,
            isStatic: false,
            mapTo: 'data',
            objectMapping: [
                {
                    destKey: 'id',
                    sourceKey: 'id',
                },
                {
                    destKey: 'icon',
                    sourceKey: 'icon',
                    type: 'icon',
                    valueMap: {
                        time: 'mat_solid:access_time',
                        star: 'heroicons_solid:star',
                        _default_: 'mat_solid:access_time',
                    },
                },
                /*{
                    destKey: 'img',
                    sourceKey: 'img',
                    type: 'img',
                    valueMap: {
                        new: '/assets/images/add.gif',
                        added: '/assets/images/add.gif',
                        updated: '/assets/images/edit.gif',
                        deleted: '/assets/images/delete.gif',
                        _default_: '/assets/images/clock.gif',
                    },
                },*/
                {
                    destKey: 'action', sourceKey: 'action'
                },
                {
                    destKey: 'event_name',
                    sourceKey: 'title',
                },
                {
                    destKey: 'status',
                    sourceKey: 'status',
                },
                {
                    destKey: 'description',
                    sourceKey: 'description',
                },
                {
                    destKey: 'category_name',
                    sourceKey: 'extraContent',
                },
                {
                    destKey: 'updated',
                    sourceKey: 'date',
                },
                {
                    destKey: 'tickets',
                    sourceKey: 'tickets',
                },
            ],
            objectRules: [],
            value: '',
        },
    ];
    asset_id: any = '';
    props: any = {
        title: 'System Events',
        tablename: '/r/company/system_events_view',
        isServerSide: true,
        description: '',
        orderBy: {direction: 'desc', columnName: 'updated'}
    };
    showView = false;
    constructor(private cs: CommonService, private toast: MyToastrService, private uS: UserService) {
    }

    ngOnInit(): void {
        this.uS._user_roles.forEach((u: any) => {
           if ((u.indexOf('admin') > -1) || (u.indexOf('asset') > -1) || (u.indexOf('customer') > -1)) {
               this.showView = true;
           }
        });
    }
}

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { TableComponent } from '../table/table.component';
import { ReplaySubject, Subject, Subscription, debounceTime, filter, map, takeUntil } from 'rxjs';
import { FormsModule, NgForm, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { MaterialModule } from 'app/material.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DynamicFormComponent } from '../forms/dynamic-form/dynamic-form.component';
import { GridsComponent } from '../grids/grids.component';
import { KeyPairsComponent } from '../key-pairs/key-pairs.component';
import { ActivatedRoute } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { DynamicSettingsService } from 'app/layout/common/dynamic-settings/dynamic-settings.service';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { cloneDeep } from 'lodash';
import { DirectivesModule } from 'app/-directives/-directives.module';
import { AssetDetailsComponent } from '../asset-details/asset-details.component';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-application-baseline',
  standalone: true,
  imports: [CommonModule, DirectivesModule, MatAutocompleteModule, GridsComponent, KeyPairsComponent, FormsModule, NgxMatSelectSearchModule, AsyncPipe,
    MatSidenavModule, TableComponent, FuseScrollbarDirective, MaterialModule, AppFilterPipeModule, ReactiveFormsModule,
    FuseAlertComponent, DynamicFormComponent, AssetDetailsComponent],
  templateUrl: './application-baseline.component.html',
  styleUrls: ['./application-baseline.component.scss'],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => (): BlockScrollStrategy => overlay.scrollStrategies.block(),
      deps: [Overlay]
    }
  ]
})
export class ApplicationBaselineComponent implements OnInit, OnDestroy {
  @ViewChild('ruleNgForm') ruleNgForm: NgForm;
  showTable = true;
  currentAppBaseline: any = {
    name: '',
    os_type: 'WINDOWS',
    os_name: '',
    type: 'application',
    denied_applications: [],
    mandatory_applications: [],
    companies: [],
    company_type: '',
    exclude_company: [],
    include_company: [],
    denied_services: [],
    mandatory_services: [],
  };
  appBaseLineTableOptions: any = {};
  osTypes: any = [
    { key: 'Windows', value: 'WINDOWS' },
    { key: 'macOS', value: 'MAC' },
    { key: 'Linux', value: 'LINUX' },
  ]
  types: any = [
    { key: 'Application', value: 'application' },
    { key: 'Service', value: 'service' },
  ]
  denied_app_regex = false;
  mandatory_app_regex = false;
  denied_app: any = '';
  mandatory_app: any = '';

  denied_service_regex = false;
  mandatory_service_regex = false;
  denied_application_name: any = '';
  mandatory_application_name: any = '';
  denied_service_name: any = '';
  mandatory_service_name: any = '';

  private subs: Subscription;
  private action: Subscription;

  public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
  searchCompanyControl: UntypedFormControl = new UntypedFormControl();
  searchOSControl: UntypedFormControl = new UntypedFormControl();
  companies: any = [];

  public filteredInCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
  searchInCompanyControl: UntypedFormControl = new UntypedFormControl();
  inCompanies: any = [];

  osNames: any = [];
  public searching = false;
  protected onDestroySearch = new Subject<void>();

  appSearchControl: UntypedFormControl = new UntypedFormControl();
  serSearchControl: UntypedFormControl = new UntypedFormControl();
  private _matAutocomplete: MatAutocomplete;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  @Input() debounce: number = 300;
  @Input() minLength: number = 2;
  opened: boolean = false;
  uniqueApplications: any = [];
  uniqueServices: any = [];
  @Input() appearance: 'basic' | 'bar' = 'basic';

  selectedIndex = 0;
  cView: 'company' | 'asset' | 'plan' | 'asset-details' = 'plan';
  appBaseLinePlanTableOptions: any = {};
  asseTableOptions: any = {
    columns: [
      {
        "header": "IP",
        "columnDef": "ip",
        "filter": "",
        "cell": "(element: any) => `${element.ip}`",
        "order": 0,
        "visible": true,
        "isHyperlink": true,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      },
      {
        "header": "Host Name",
        "columnDef": "host_name",
        "cType": "string",
        fillEmpty: 'ip',
        "filter": "",
        "cell": "(element: any) => `${element.host_name}`",
        "order": 0,
        "visible": true,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      },
      {
        header: 'Importance',
        cType: 'number',
        columnDef: 'importance',
        iscolumnSearch: true,
        selectFilter: true,
        selectFilterArr: [
          { name: 'Low', id: 'AssetLow', value: 25 },
          { name: 'Medium', id: 'AssetMedium', value: 50 },
          { name: 'High', id: 'AssetHigh', value: 75 },
          { name: 'Critical', id: 'AssetCritical', value: 100 }
        ],
        filter: '', width: '200px',
        isColoredCntrl: true,
        isCustomText: true,
        visible: true,
        order: 5,
        isSort: true
      },
      {
        "header": "Company Name",
        "columnDef": "company_name",
        "filter": "",
        "cell": "(element: any) => `${element.company_name}`",
        "order": 0,
        "visible": false,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      },
    ],
    sortOptions: { active: 'host_name', direction: 'asc' },
    _pageData: [],
    tableOptions: {
      title: 'Assets',
      isServerSide: false,
      selectText: 'asset',
      loading: false,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showhideList: true,
      refreshData: true,
      showFilter: true,
      showTagFilter: false,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: '/r/company/app_baseline_plan_assets',
        condition: true,
      },
    },
    customText: [
      {
        status: 1,
        DisplayText: 'Probe',
      },
      {
        status: 2,
        DisplayText: 'Lightweight Agent',
      },
      {
        status: 3,
        DisplayText: 'Lightweight Agent Installed',
      },
      {
        status: 4,
        DisplayText: 'External Scan Agent',
      },
      {
        range: { from: 0, to: 0 },
        color: '#c2ffc4',
        'text-color': '#000000'
      },
      {
        range: { from: 1, to: 40 },
        color: '#f8c851',
        'text-color': '#000000'
      },
      {
        range: { from: 41, to: 60 },
        color: '#f18c43',
        'text-color': '#000000'
      },
      {
        range: { from: 61, to: 90 },
        color: '#de4a50',
        'text-color': '#ffffff'
      },
      {
        range: { from: 91, to: 99999 },
        color: '#92243e',
        'text-color': '#ffffff'
      },
      {
        status: 25,
        DisplayText: 'Low',
        color: '#f8c851',
        'text-color': '#000000'
      },
      {
        status: 50,
        DisplayText: 'Medium',
        color: '#f18c43',
        'text-color': '#000000'
      },
      {
        status: 75,
        DisplayText: 'High',
        color: '#de4a50',
        'text-color': '#ffffff'
      },
      {
        status: 100,
        DisplayText: 'Critical',
        color: '#92243e',
        'text-color': '#ffffff'
      }
    ],
    changeValue: new Subject<any>(),
  };
  compTableOptions: any = {
    columns: [
      {
        "header": "Name",
        "columnDef": "name",
        "cType": "string",
        "filter": "",
        "cell": "(element: any) => `${element.name}`",
        "order": 0,
        "visible": true,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      },

      {
        "header": "Assets",
        "columnDef": "company_asset_count",
        "cType": "number",
        "filter": "",
        "isHyperlink": true,
        "cell": "(element: any) => `${element.company_asset_count}`",
        "order": 0,
        align: "left",
        "visible": true,
        "isToolTip": false,
        "isToolTipCol": "",
        "hasMultiData": false,
        "class": "",
        "color": "",
        "isProgressCntrl": false,
        "isColoredCntrl": false,
        "colList": [],
        "isfaicon": false,
        "isAddingText": false,
        "addingText": "",
        "img": false,
        "imgPath": "",
        "isSort": true,
        "iscolumnSearch": false
      },
    ],
    sortOptions: { active: 'name', direction: 'asc' },
    _pageData: [],
    tableOptions: {
      title: 'Companies',
      isServerSide: false,
      selectText: 'company',
      loading: false,
      floatingFilter: true,
      rowSelection: false,
      showAction: false,
      actionMenuItems: [],
      pagination: true,
      pageOptions: [5, 10, 25, 100],
      pageSize: 5,
      search: false,
      showhideList: true,
      refreshData: true,
      showFilter: true,
      showTagFilter: false,
      exportExcel: true,
      add: false,
      columnSearch: false,
      compareData: false,
      filterDownload: false,
      serverSide: {
        url: '/r/company/app_baseline_plan_companies',
        condition: true,
      },
    },
    customText: [
      {
        status: 'Critical',
        DisplayText: 'CRITICAL',
        color: '#92243e',
        'text-color': '#ffffff'
      },
      {
        status: 'High',
        DisplayText: 'HIGH',
        color: '#de4a50',
        'text-color': '#ffffff'
      },
      {
        status: 'Medium',
        DisplayText: 'MEDIUM',
        color: '#f18c43',
        'text-color': '#000000'
      },
      {
        status: 'Low',
        DisplayText: 'LOW',
        color: '#f8c851',
        'text-color': '#000000'
      },
      {
        status: 'Info',
        DisplayText: 'INFO',
        color: '#67ace1',
        'text-color': '#000000'
      },
      {
        range: { from: 0, to: 0 },
        color: '#c2ffc4',
        'text-color': '#000000'
      },
      {
        range: { from: 1, to: 4.9 },
        color: '#ffe24b',
        'text-color': '#000000'
      },
      {
        range: { from: 5, to: 8.9 },
        color: '#ec9b0c',
        'text-color': '#000000'
      },
      {
        range: { from: 9, to: 10 },
        color: '#ff6546',
        'text-color': '#000000'
      },

    ],
    changeValue: new Subject<any>(),
  };

  @ViewChild('drawer') drawer: MatDrawer;
  drawerPosition: 'start' | 'end' = 'end';
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean = false;
  cAsset: any = {};
  osNameList: any = [];
  /**
   * Constructor
   */
  constructor(
    public _cs: CommonService, private _lS: LoaderService,
    private route: ActivatedRoute, private _changeDetectorRef: ChangeDetectorRef,
    private _bs: BaseRequestService, private _ds: DynamicSettingsService,
    private toast: MyToastrService, public confirmDialog: FuseConfirmationService) {

    this.subs = this._cs.selectedSiteChanged.subscribe((cmp: any) => {
      this.showTable = true;
      this._changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  addCallBack(): void {
    if (this._cs.currentScope === '*') {
      this.getCompanies();
    }
    this.currentAppBaseline = {
      name: '', os_type: 'WINDOWS', os_name: '',
      type: 'application', denied_applications: [], mandatory_applications: [], exclude_company: [], include_company: [], denied_services: [],
      mandatory_services: [], ignore_tags: [], include_tags: [], companies: ['*'], company_type: 'include',
    };
    this.currentAppBaseline.companies =  ['*'];
    this.currentAppBaseline.company_type =  'include';
    this.showTable = false; this._changeDetectorRef.detectChanges();
    // this.filterByType({ value: 'WINDOWS' });
    this.showTable = false;
  };
  deleteAppBaseline(): void {
    const tableName = this.appBaseLineTableOptions.tableOptions.title;
    if (this._cs.selectedTableRows[tableName] && this._cs.selectedTableRows[tableName].length) {
      const isGlo = this._cs.selectedTableRows[tableName].filter((x: any) => x.is_global);
      if (isGlo && isGlo.length && this._cs.currentScope !== '*') {
        this.toast.sToast('info', 'It is not possible to delete the selected rule in Company view because it has been added globally. Please select Application Baseline in Global view in order to delete the globally added rule.');
        return;
      }
      const confirmation = this.confirmDialog.open({
        title: 'Confirmation',
        message: 'Are you sure you want to delete these Rule?',
        actions: {
          confirm: { show: true, label: 'Yes', color: 'primary' },
          cancel: { show: true, label: 'No' }
        },
        dismissible: false
      });
      confirmation.afterClosed().subscribe((result) => {
        if (result === 'confirmed') {
          const reqData: any = {
            ids: this._cs.selectedTableRows[tableName].map((obj: any) => obj.id)
          }
          this._lS.display(true);
          this._bs.doRequest(`d/company/application_baseline_rules/bulk_delete`, 'post', reqData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
              this._lS.display(false);
              if (result) {
                this.toast.sToast('success', 'Removed successfully');
                setTimeout(() => this.appBaseLineTableOptions.changeValue.next(true), 2000);
                this._cs.selectedTableRows[tableName] = [];
              } else {
                const data = (result.message) ? result.message : result.data;
                this.toast.sToast('error', data);
              }
            });
        }
      })
    } else {
      this.toast.sToast('info', 'Please select at least record.');
    }
  };

  editAppBaseline(): void {
    const tableName = this.appBaseLineTableOptions.tableOptions.title;
    if (this._cs.selectedTableRows[tableName] && this._cs.selectedTableRows[tableName].length) {
      if (this._cs.selectedTableRows[tableName].length > 1) {
        this.toast.sToast('info', 'Please select only one record.');
        return;
      }
      const $event: any = cloneDeep(this._cs.selectedTableRows[tableName][0]);
      if ($event.is_global && this._cs.currentScope !== '*') {
        this.toast.sToast('info', 'It is not possible to edit the selected rule in Company view because it has been added globally. Please select Application Baseline in Global view in order to edit the globally added rule.');
        return;
      }
      if (this._cs.currentScope === '*') {
        if ($event.include_company && $event.include_company.length) {
          $event.company_type = 'include'; $event.companies = $event.include_company;
        }
        if ($event.exclude_company && $event.exclude_company.length) {
          $event.company_type = 'exclude'; $event.companies = $event.exclude_company;
        }
        this.getCompanies();
      } else {
        $event.is_global = false; $event.exclude_company = []; $event.include_company = []; $event.companies = []; $event.company_type = '';
      }
      if ($event.include_tags && $event.include_tags.length) {
        const tags = [];
        $event.include_tags.map((obj: any) => {
          const key = Object.keys(obj)[0];
          tags.push({ key: key, value: obj[key] });
        });
        $event.include_tags = []; $event.include_tags = tags
      } else {
        $event.include_tags = [];
      }
      if ($event.ignore_tags && $event.ignore_tags.length) {
        const tags = [];
        $event.ignore_tags.map((obj: any) => {
          const key = Object.keys(obj)[0];
          tags.push({ key: key, value: obj[key] });
        });
        $event.ignore_tags = []; $event.ignore_tags = tags
      } else {
        $event.ignore_tags = [];
      }
      this.currentAppBaseline = $event;
      this.filterByType({ value: $event.os_type });
      this.showTable = false;
    } else {
      this.toast.sToast('info', 'Please select record.');
    }
  }

  saveAppBaseline(): void {
    const reqData: any = cloneDeep(this.currentAppBaseline);
    const incduplicates = reqData.include_tags.filter((item: any, index: any, array: any) => {
      return array.slice(index + 1).some((otherItem: any) =>
        otherItem.key === item.key && otherItem.value === item.value
      );
    });
    if (incduplicates && incduplicates.length) {
      this.toast.sToast('error', 'The Tag provided is already included in the Include Tags section. Please verify that there are no duplicated entries.');
      return;
    }
    const igduplicates = reqData.ignore_tags.filter((item: any, index: any, array: any) => {
      return array.slice(index + 1).some((otherItem: any) =>
        otherItem.name === item.name && otherItem.value === item.value
      );
    });
    if (igduplicates && igduplicates.length) {
      this.toast.sToast('error', 'The Tag provided is already included in the Ignore Tags section. Please verify that there are no duplicated entries.');
      return;
    }
    if (reqData.type === 'service') {
      reqData.denied_applications = []; reqData.mandatory_applications = [];
    } else if (reqData.type === 'application') {
      reqData.denied_services = []; reqData.mandatory_services = [];
    }
    if (reqData.include_tags && reqData.include_tags.length) {
      const outputArray = reqData.include_tags.map((item: any) => {
        const obj = {};
        obj[item.key] = item.value;
        return obj;
      });
      reqData.include_tags = outputArray;
    } else {
      reqData.include_tags = [];
    }
    if (reqData.ignore_tags && reqData.ignore_tags.length) {
      const outputArray = reqData.ignore_tags.map((item: any) => {
        const obj = {};
        obj[item.key] = item.value;
        return obj;
      });
      reqData.ignore_tags = outputArray;
    } else {
      reqData.ignore_tags = [];
    }
    this._lS.display(true);
    delete reqData.hovered; delete reqData.highlighted;
    if (this._cs.currentScope === '*') {
      if (reqData.company_type === 'include') {
        reqData.include_company = reqData.companies; reqData.exclude_company = [];
      } else {
        reqData.exclude_company = reqData.companies; reqData.include_company = [];
      }
      reqData.is_global = true; reqData.company_id = null;
    } else {
      reqData.is_global = false; reqData.exclude_company = []; reqData.include_company = []; reqData.company_id = parseInt(this._cs.currentScope.id);
    }
    delete reqData.companies; delete reqData.company_type;
    const data: any = { data: reqData };
    (reqData.id) ? data.id = reqData.id : null;
    const msg = reqData.id ? 'Updated' : 'Saved';
    const method = reqData.id ? 'patch' : 'post';
    try {
      this._bs
        .doRequest(`/w/company/application_baseline_rules`, method, data)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => {
          this._lS.display(false);
          if (result.status) {
            this.toast.sToast('success', `${msg} successfully`);
            this.showTable = true;
            setTimeout(() => this.appBaseLineTableOptions.changeValue.next(true));
          } else {
            const data = (result.message) ? result.message : result.data;
            this.toast.sToast('error', data);
          }
        });
    } catch (e) {
      console.log(e);
    }

  }
  @ViewChild('matAutocomplete')
  set matAutocomplete(value: MatAutocomplete) {
    this._matAutocomplete = value;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnChanges(changes: SimpleChanges): void {
    if ('appearance' in changes) {
      this.close();
    }
  }


  ngOnInit(): void {
    this.initTableData();
    // this.getOsNames();
    this.appSearchControl.valueChanges
      .pipe(
        debounceTime(this.debounce),
        takeUntil(this._unsubscribeAll),
        map((value) => {
          if (!value || value.length < this.minLength) {
            // this.resultSets = null;
          }
          return value;
        }),
        filter(value => value && value.length >= this.minLength)
      )
      .subscribe((value) => {
        const condition = value
          ? { condition: "name ilike '%" + value + "%'" }
          : {
            condition: true,
            skip: 0,
            limit: 50,
          };

        // if (this._cs.currentScope !== '*') {
        //   condition.condition += ` and all_company_id @> '[${parseInt(this._cs.currentScope.id)}]'`
        // }
        try {
          this._lS.display(true);
          this._bs.doRequest("r/company/distinct_software", "get", null, condition)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
              this._lS.display(false);
              if (result.status) {
                this.uniqueApplications = result.data;
              }
            });
        } catch (error) {
          // Handle errors here
          throw error;
        }
      });
    this.serSearchControl.valueChanges
      .pipe(
        debounceTime(this.debounce),
        takeUntil(this._unsubscribeAll),
        map((value) => {
          if (!value || value.length < this.minLength) {
            // this.resultSets = null;
          }
          return value;
        }),
        filter(value => value && value.length >= this.minLength)
      )
      .subscribe((value) => {
        const condition = value
          ? { condition: "name ilike '%" + value + "%'" }
          : {
            condition: true,
            skip: 0,
            limit: 50,
          };

        // if (this._cs.currentScope !== '*') {
        //   condition.condition += ` and all_company_id @> '[${parseInt(this._cs.currentScope.id)}]'`
        // }
        try {
          this._lS.display(true);
          this._bs.doRequest("report_queries/distinct_services", "get", null, condition)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
              this._lS.display(false);
              if (result.status) {
                this.uniqueServices = result.data;
              }
            });
        } catch (error) {
          // Handle errors here
          throw error;
        }
      });
    this.searchCompanyControl.valueChanges
      .pipe(debounceTime(300), takeUntil(this.onDestroySearch))
      .subscribe(() => {
        this.filterCompanies();
      });
  }

  initTableData(): void {
    let serverSide: any = {};
    if (this._cs.currentScope !== '*') {
      serverSide = {
        url: '/r/company/application_baseline_rules',
        condition: 'is_global=false',
      }
    } else {
      serverSide = {
        url: '/r/company/application_baseline_rules',
        condition: 'is_global=true',
      }
    }
    this.appBaseLineTableOptions = {
      columns: [
        {
          "header": "Name",
          "columnDef": "name",
          "filter": "",
          "cell": "(element: any) => `${element.name}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": false,
          "iscolumnSearch": false
        },
        {
          "header": "Type",
          "columnDef": "type",
          "filter": "enumString",
          "cell": "(element: any) => `${element.type}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
        {
          "header": "Denied Applications",
          "columnDef": "denied_applications",
          "filter": "appbaseline",
          "cell": "(element: any) => `${element.denied_applications}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
        {
          "header": "Mandatory Applications",
          "columnDef": "mandatory_applications",
          "filter": "appbaseline",
          "cell": "(element: any) => `${element.mandatory_applications}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
        {
          "header": "Denied Services",
          "columnDef": "denied_services",
          "filter": "appbaseline",
          "cell": "(element: any) => `${element.denied_services}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
        {
          "header": "Mandatory Services",
          "columnDef": "mandatory_services",
          "filter": "appbaseline",
          "cell": "(element: any) => `${element.mandatory_services}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
        {
          "header": "OS Name",
          "columnDef": "os_name",
          "filter": "enumString",
          "cell": "(element: any) => `${element.os_name}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
        {
          "header": "OS Type",
          "columnDef": "os_type",
          "filter": "enumString",
          "cell": "(element: any) => `${element.os_type}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        }
      ],
      sortOptions: { active: 'name', direction: 'asc' },
      _pageData: [],
      tableOptions: {
        title: 'Rules',
        isServerSide: false,
        selectText: 'baseline',
        id: 'application_baseline_rules',
        loading: true,
        floatingFilter: true,
        rowSelection: true,
        showAction: false,
        actionMenuItems: [],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 5,
        search: false,
        showhideList: true,
        refreshData: true,
        showFilter: true,
        showTagFilter: false,
        exportExcel: true,
        add: false,
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        serverSide: serverSide,
      },
      changeValue: new Subject<any>(),
    };

  }
  initPlanTableData(): void {
    let url: any = ''; let condition: any;
    if (this._cs.currentScope !== '*') {
      url = '/r/company/app_baseline_plan_company';
      // condition = 'is_global=false';
    } else {
      url = '/r/company/app_baseline_plan_global';
      // condition = 'is_global=true';
    }
    this.appBaseLinePlanTableOptions = {
      columns: [
        {
          "header": "Action",
          "columnDef": "action",
          "cType": "string",
          "filter": "remeaction",
          "cell": "(element: any) => `${element.action}`",
          "order": 0,
          isHtml: true,
          htmlIcon: true,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
        {
          "header": "Product",
          "columnDef": "product",
          "filter": "enumString",
          "cell": "(element: any) => `${element.product}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
        {
          "header": "Type",
          "columnDef": "type",
          "filter": "enumString",
          "cell": "(element: any) => `${element.type}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
        {
          "header": "OS Type",
          "columnDef": "os_type",
          "filter": "enumString",
          "cell": "(element: any) => `${element.os_type}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
        {
          "header": "OS Name",
          "columnDef": "os_name",
          "filter": "enumString",
          "cell": "(element: any) => `${element.os_name}`",
          "order": 0,
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
        {
          "header": "Assets",
          "columnDef": "asset_count",
          "filter": "",
          "isHyperlink": true,
          cType: 'number',
          "cell": "(element: any) => `${element.asset_count}`",
          "order": 0,
          align: "left",
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        },
        {
          "header": "Companies",
          "columnDef": "companies",
          "filter": "",
          cType: 'number',
          "isHyperlink": true,
          "cell": "(element: any) => `${element.companies}`",
          "order": 0,
          align: "left",
          "visible": true,
          "isToolTip": false,
          "isToolTipCol": "",
          "hasMultiData": false,
          "class": "",
          "color": "",
          "isProgressCntrl": false,
          "isColoredCntrl": false,
          "colList": [],
          "isfaicon": false,
          "isAddingText": false,
          "addingText": "",
          "img": false,
          "imgPath": "",
          "isSort": true,
          "iscolumnSearch": false
        }
      ],
      sortOptions: { active: 'product', direction: 'asc' },
      _pageData: [],
      tableOptions: {
        title: 'Plans',
        isServerSide: false,
        selectText: 'baseline',
        id: 'application_baseline_plan',
        loading: true,
        floatingFilter: true,
        rowSelection: false,
        showAction: false,
        actionMenuItems: [],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 5,
        search: false,
        showhideList: true,
        refreshData: true,
        showFilter: true,
        showTagFilter: false,
        exportExcel: true,
        add: false,
        columnSearch: false,
        compareData: false,
        filterDownload: false,
        serverSide: {
          url: url,
          condition: ''
        },
      },
      changeValue: new Subject<any>(),
    }
    this.cView = 'plan';
  }
  open(): void {
    if (this.opened) {
      return;
    }

    this.opened = true;
  }

  close(): void {
    if (!this.opened) {
      return;
    }
    this.appSearchControl.setValue('');
    this.opened = false;
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      if (this.appearance === 'bar' && !this._matAutocomplete.isOpen) {
        this.close();
      }
    }
  }

  addMandatoryApplications(): void {
    if (this.currentAppBaseline && !this.currentAppBaseline.mandatory_applications) {
      this.currentAppBaseline.mandatory_applications = [];
    }
    this.currentAppBaseline.mandatory_applications.push({
      application_name: this.mandatory_app,
      regular_expression: this.mandatory_app_regex,
    });
    this.mandatory_app = '';
    this.mandatory_app_regex = false; this.uniqueApplications = [];
    this._changeDetectorRef.detectChanges();
  }

  addDeniedApplications(): void {
    if (this.currentAppBaseline && !this.currentAppBaseline.denied_applications) {
      this.currentAppBaseline.denied_applications = [];
    }
    this.currentAppBaseline.denied_applications.push({
      application_name: this.denied_app,
      regular_expression: this.denied_app_regex,
    });
    this.denied_app = '';
    this.denied_app_regex = false; this.uniqueApplications = [];
    this._changeDetectorRef.detectChanges();
  }

  addMandatoryService(): void {
    if (this.currentAppBaseline && !this.currentAppBaseline.mandatory_services) {
      this.currentAppBaseline.mandatory_services = [];
    }
    this.currentAppBaseline.mandatory_services.push({
      service_name: this.mandatory_service_name,
      application_name: this.mandatory_application_name,
      regular_expression: this.mandatory_service_regex,
    });
    this.mandatory_service_name = '';
    this.mandatory_application_name = '';
    this.mandatory_service_regex = false; this.uniqueServices = [];
    this._changeDetectorRef.detectChanges();
  }
  addDeniedService(): void {
    if (this.currentAppBaseline && !this.currentAppBaseline.denied_services) {
      this.currentAppBaseline.denied_services = [];
    }
    this.currentAppBaseline.denied_services.push({
      service_name: this.denied_service_name,
      application_name: this.denied_application_name,
      regular_expression: this.denied_service_regex,
    });
    this.denied_service_name = '';
    this.denied_application_name = '';
    this.denied_service_regex = false; this.uniqueServices = [];
    this._changeDetectorRef.detectChanges();
  }

  private filterCompanies(): void {
    if (!this.companies) {
      return;
    }
    // get the search keyword
    let search = this.searchCompanyControl.value;
    if (!search) {
      this.filteredCompanies.next(this.companies.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.getCompanies(search);
  }

  getCompanies(search?: string): void {
    this.searching = true;
    let condition: any = search
      ? { condition: "name ilike '%" + search + "%'" }
      : {
        condition: true,
        skip: 0,
        limit: 1000,
        order_by: 'created desc'
      };
    this._bs
      .doRequest('/r/company/companies', 'get', null, condition)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (result.data.length) {
          result.data.sort((a: any, b: any) => {
            const c = a.name ? a.name.toLowerCase() : "";
            const d = b.name ? b.name.toLowerCase() : "";
            if (c < d) {
              return -1;
            } else if (c > d) {
              return 1;
            } else {
              return 0;
            }
          });
          if (!search) {
            this.companies = result.data;
          } else {
            this.companies = Array.from(new Map([...this.companies, ...result.data].map(obj => [obj.id, obj])).values());
          }
          this.filteredCompanies.next(result.data.slice());
          this.searching = false;
        } else {
          this.filteredCompanies.next([]);
          this.searching = false;
        }
      }, (error: any) => {
        this.searching = false;
      }
      );
  }

  closeCurrentCompany($event: boolean) {
    this.searching = false;
    if (this.companies) {
      this.filteredCompanies.next(this.companies.slice());
      this._changeDetectorRef.markForCheck();
    }
  }

  radioChange($event: MatRadioChange) {
    if($event.value === 'exclude') {
      this.currentAppBaseline.companies = this.currentAppBaseline.companies.filter((x: any) => x !== '*');
    }
  }

  filterByType(change: any): void {
    this.osNames = this.osNameList[change.value];
  }
  tabFn($event: any): void {
    this.selectedIndex = null; this.selectedIndex = $event;
    if ($event === 1) {
      this.initPlanTableData();
    }
  }
  changeType(): void {
    this.serSearchControl.setValue('');
    this.appSearchControl.setValue('');
    this.currentAppBaseline.denied_applications = [];
    this.currentAppBaseline.mandatory_applications = [];
    this.currentAppBaseline.denied_services = [];
    this.currentAppBaseline.mandatory_services = [];
    this._changeDetectorRef.detectChanges();
  }
  linkCall($event: any): void {
    if ($event.col === 'asset_count') {
      if (!$event.row[$event.col]) { return }
      let data = Object.assign({}, this.asseTableOptions);
      data.pageData = []; data.tableOptions.pageTotal = 0;
      data.tableOptions.serverSide.condition = `product='${$event.row.product}'`;
      this.asseTableOptions.pageData = []; this.asseTableOptions.tableOptions.pageTotal = 0;
      this.asseTableOptions = {}; this._changeDetectorRef.detectChanges();
      this.asseTableOptions = data;
      this.cView = 'asset'; this._changeDetectorRef.detectChanges();
    } else if ($event.col === 'company_asset_count') {
      if (!$event.row[$event.col]) { return };
      let data = Object.assign({}, this.asseTableOptions);
      data.pageData = []; data.tableOptions.pageTotal = 0;
      if (this._cs.currentScope === '*') {
        data.tableOptions.serverSide.condition = `product='${$event.row.product}' and action = '${$event.row.action}' and company_id=${parseInt($event.row.company_id)}`;
      } else {
        data.tableOptions.serverSide.condition = `product='${$event.row.product}' and action = '${$event.row.action}'`;
      }
      this.asseTableOptions.pageData = []; this.asseTableOptions.tableOptions.pageTotal = 0;
      this.asseTableOptions = {}; this._changeDetectorRef.detectChanges();
      this.asseTableOptions = data;
      this.cView = 'asset'; this._changeDetectorRef.detectChanges();
    }
    else if ($event.col === 'companies') {
      if (!$event.row[$event.col]) { return };
      let data = Object.assign({}, this.compTableOptions);
      data.pageData = []; data.tableOptions.pageTotal = 0;
      data.tableOptions.serverSide.condition = `product='${$event.row.product}' and os_name='${$event.row.os_name}' and action = '${$event.row.action}' and is_global = ${$event.row.is_global}`;
      data.tableOptions.serverSide.params = { os_name: $event.row.os_name };
      this.compTableOptions.pageData = []; this.compTableOptions.tableOptions.pageTotal = 0;
      this.compTableOptions = {}; this._changeDetectorRef.detectChanges();
      this.compTableOptions = data;
      this.cView = 'company'; this._changeDetectorRef.detectChanges();
    } else if ($event.col === 'host_name' || $event.col === 'ip') {
      this.getAsset($event.row.id);
    }
  }
  getAsset(id: string): void {
    if (!id) { return; }
    this._lS.display(true);
    this._bs.doRequest(`r/asset/assets/${id}`, 'get')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this._lS.display(false);
        if (result.status) {
          this.cAsset = result.data[0]; this.cView = 'asset-details'; this._changeDetectorRef.detectChanges();
        } else {
          this.toast.sToast('error', 'Asset not found!');
        }
      });
  }
  toggleChange($event: any): void {
    this.cAsset = {}; this.cView = 'asset'; this._changeDetectorRef.detectChanges();
  }
  addExcludeTag(): void {
    this.currentAppBaseline.ignore_tags.push({ key: null, value: null });
  }
  addIncludeTag(): void {
    this.currentAppBaseline.include_tags.push({ key: null, value: null });
  }
  getOsNames(): void {
    try {
      this._lS.display(true);
      this._bs.doRequest("r/company/jsonconfigs/os_names", "get")
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => {
          this._lS.display(false);
          if (result.status) {
            this.osNameList = result.data;
          }
        });
    } catch (error) {
      // Handle errors here
      throw error;
    }
  }
}

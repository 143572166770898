import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/material.module';
import { IntegrationCredentialsComponent } from '../integration-credentials/integration-credentials.component';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { IntegrationProfileComponent } from '../integration-profile/integration-profile.component';
import { NonpsaCompanyMappingComponent } from '../nonpsa-company-mapping/nonpsa-company-mapping.component';
import { EventSetComponent } from '../event-set/event-set.component';
import { PsaCompanyMappingComponent } from '../psa-company-mapping/psa-company-mapping.component';

@Component({
  selector: 'integrations-overview',
  standalone: true,
  imports: [CommonModule, MaterialModule, PsaCompanyMappingComponent, EventSetComponent, IntegrationCredentialsComponent, AppFilterPipeModule, IntegrationProfileComponent, NonpsaCompanyMappingComponent],
  templateUrl: './integrations-overview.component.html',
  styleUrls: ['./integrations-overview.component.scss']
})
export class IntegrationsOverviewComponent {
  @Input() currentIntegration: any;
  @Output() toggleFn = new EventEmitter();
  integrationCred: any = [];
  newCred = false;
  credData: any = {};
  selectedIndex = 0;
  triggerNew: any = {};
  /**
     * Constructor
     */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
  }

  /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  updateSelectionIndex(index: any): void {
    if (index === 1) {
      // Place holder for tab change event
    }
  }

  toggleChangeFn(): void {
    this.toggleFn.emit({});
  }

  updateCredFn($event: any): void {
    this.integrationCred = $event.result;
  }

  addNewData($event: any): void {
    this.triggerNew = {show: false, id: null};
    if($event.eventset === null || $event.eventset === undefined){ return;}
    if($event.eventset) {
      this.selectedIndex = 2;
      this.triggerNew = {show: true, id: $event.id};
    } 
    if(!$event.eventset) {
      this.selectedIndex = 3;
      this.triggerNew = {show: true, id: $event.id};
    } 
  }
}

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonService } from "../../../../_services/common.service";
import { BaseRequestService } from "../../../../_services/base.service";
import { GridsComponent } from "../../../shared/grids/grids.component";
import { ModalComponent } from "../../../shared/modal.component";
import { MaterialModule } from "../../../../material.module";
import { ModalService } from "../../../../_services/modal.service";
import { FormsModule } from "@angular/forms";
import { FuseAlertComponent } from "../../../../../@fuse/components/alert";
import { DynamicFormComponent } from "../../../shared/forms/dynamic-form/dynamic-form.component";
import { LoaderService } from "../../../../_services/loader.service";
import { UserService } from "../../../../core/user/user.service";
import { Subject, Subscription, takeUntil } from "rxjs";
import { ExpandTableComponent } from "../../../shared/expand-table/expand-table.component";
import { TableComponent } from "../../../shared/table/table.component";
import { TranslocoModule } from '@ngneat/transloco';
import { FuseScrollbarDirective } from "../../../../../@fuse/directives/scrollbar";
import { MyToastrService } from 'app/_services/toastr.service';
import { InstallProbesAgentsComponent } from 'app/modules/shared/install-probes-agents/install-probes-agents.component';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { DirectivesModule } from 'app/-directives/-directives.module';
import { DynamicSettingsService } from 'app/layout/common/dynamic-settings/dynamic-settings.service';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'agents',
    standalone: true,
    imports: [CommonModule, TranslocoModule, DirectivesModule, InstallProbesAgentsComponent, GridsComponent, ModalComponent, MaterialModule, FormsModule, FuseAlertComponent, DynamicFormComponent, ExpandTableComponent, TableComponent, FuseScrollbarDirective],
    templateUrl: './agents.component.html',
    styleUrls: ['./agents.component.scss']
})
export class AgentsComponent implements OnInit, OnDestroy {
    settingsdata: any = [];
    @Input() isGlobal: boolean = false;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    agent: any = {
        osType: 'windows',
        command: '',
        companyId: '',
        tenantId: ''
    };
    aView: any = 'agent';
    osCommands = {};
    agentTypes: any = [
        {
            value: 'windows',
            label: 'WINDOWS',
            details: 'Install agent service in windows.',
            isIcon: true,
            icon: 'fa-windows',
        },
        {
            value: 'linux',
            label: 'LINUX',
            details: 'Install agent service in linux.',
            isIcon: true,
            icon: 'fa-linux',
        },
        {
            value: 'darwin',
            label: 'Mac',
            details: 'Install agent service in mac.',
            isIcon: true,
            icon: 'fa-apple',
        },
    ];
    user: any;
    agentConf: any = {
        showAction: false,
        formElements: [
            {
                "name": "osType",
                "description": "OS Type",
                "required": true,
                callback: true,
                "schema": {
                    "type": "custom-radiogroup",
                    "radioList": this.agentTypes,
                    "span": 3,
                }
            },
            {
                "name": "command",
                "title": "Commandline Script",
                "alertType": 'basic',
                'appearance': 'soft',
                "schema": {
                    "type": "copy-to-clipboard",
                }
            },
            {
                "name": "companyId",
                "title": "Company ID",
                "alertType": 'basic',
                'appearance': 'soft',
                "schema": {
                    "type": "alert",
                }
            },
            {
                "name": "tenantId",
                "title": "Tenant ID",
                "alertType": 'basic',
                'appearance': 'soft',
                "schema": {
                    "type": "alert",
                }
            },
        ],
        closeBtn: 'Close',
        saveBtn: 'Download',
    }
    private urlHash: any = {};
    private windowsUrl: any;
    private linuxUrl: any;
    private macUrl: any;
    loadtable: boolean = false;
    agentdiscoverymap: any = {};
    agentcredentialsymap: any = {};
    agenTableOptions: any = {};
    reset_config: any = '';
    configTypes: any = [
        { key: 'Reset AD Data', value: 'reset_ad_data' },
        { key: 'Update Agent', value: 'update_agent' },
        { key: 'Reset Agent', value: 'reset_agent' },
    ];
    scan_types = [
        { field: 'Full Scan', label: 'FULLSCAN', type: 'string' },
        { field: 'Network Scan', label: 'NETWORKSCAN', type: 'string' },
        { field: 'Active Directory Scan', label: 'ADSCAN', type: 'string' },
        { field: 'Compliance Scan', label: 'COMPLIANCE', type: 'string' },
        { field: 'Firewall Scan', label: 'FIREWALL', type: 'string' },
        { field: 'Remote Install', label: 'REMOTEINSTALL', type: 'string' }
    ]
    light_scan_types = [
        { field: 'Compliance Scan', label: 'COMPLIANCE', type: 'string' }
    ]
    agentcred = {
        formElements: [
            {
                "name": "credentials_id",
                "label": "Credentials",
                "description": "Credentials",
                "example": "Select Credentials",
                "required": true,
                "callback": true,
                "key": "credentials_id",
                "type": "dropdown",
                "options": [],
                "dValue": "dValue",
                "dKey": "dKey",
                "multiple": true,
                "schema": {
                    "type": "select",
                }
            },
        ],
        saveBtn: 'Save',
        closeBtn: 'Cancel',
        valueOutput: {
            name: '',
            address_type: '',
            address: ''
        }
    }
    scanassetoutput: any = {}
    scanasset = {
        formElements: [
            {
                "name": "scan_type",
                "label": "Scan Type",
                "description": "Scan Type",
                "example": "Select Scan Type",
                "required": true,
                "callback": true,
                "key": "scan_type",
                "type": "dropdown",
                "dValue": "label",
                "dKey": "field",
                "multiple": false,
                "options": this.scan_types,
            },
            {
                "label": "Scan Profile",
                "key": "scan_profile",
                "name": "scan_profile",
                "description": "",
                "type": "dropdown",
                "condition": "scan_type",
                "condition_values": ['PII Scan'],
                "multiple": false,
                "example": "select Scan Profile",
                "required": false,
                "dValue": "displayName",
                "dKey": "name",
                "callback": "scan_profile",
                "options": [],
            }
        ],
        saveBtn: 'Scan',
        closeBtn: 'Cancel',
        valueOutput: {
            name: '',
            address_type: '',
            address: ''
        }
    }
    agentdisco: any = {
        formElements: [
            {
                "name": "discovery_settings_id",
                "label": "Discovery Settings",
                "description": "Discovery Settings",
                "example": "Select Discovery Settings",
                "callback": true,
                "key": "discovery_settings_id",
                "dValue": "dValue",
                "dKey": "dKey",
                "type": "dropdown",
                "options": [],
                "multiple": true,
                "schema": {
                    "type": "select",
                }
            },
            {
                "name": "credentials_id",
                "label": "Credentials",
                "description": "Credentials",
                "example": "Select Credentials",
                "callback": true,
                "key": "credentials_id",
                "type": "dropdown",
                "options": [],
                "dValue": "dValue",
                "dKey": "dKey",
                "multiple": true,
                "schema": {
                    "type": "select",
                }
            },
        ],
        saveBtn: 'Save',
        closeBtn: 'Cancel',
        valueOutput: {
            name: '',
            address_type: '',
            address: ''
        }
    }
    currentData: any = {}
    private subs: Subscription;
    private action: Subscription;
    resetParam: any = {};
    selectedIndex = 0;
    selectedData: any = [];

    constructor(public cs: CommonService, private _bs: BaseRequestService, public confirmDialog: FuseConfirmationService,
        private _changeDetectorRef: ChangeDetectorRef, public toast: MyToastrService, private _ds: DynamicSettingsService,
        private uS: UserService, public modalService: ModalService, private loaderService: LoaderService) {
        this.subs = this.cs.selectedSiteChanged.subscribe((cmp: any) => {
            this.agent.companyId = this.cs.currentScope.id;
            this.aView = 'agent';
            this._changeDetectorRef.markForCheck();
            this.initTable();
        });
        this.action = this._ds.agentEmitter.subscribe((res: any) => {
            const tableName = this.agenTableOptions.tableOptions.title;
            if (res.target === 'reset-configuration') {
                if (this.cs.selectedTableRows[tableName] && this.cs.selectedTableRows[tableName].length) {
                    const lW = this.cs.selectedTableRows[tableName].filter((x: any) => x.agent_type === 'LIGHTWEIGHT');
                    if (!lW || !lW.length) {
                        this.toast.sToast('error', 'Please select Lightweight agent');
                        return;
                    }
                    const reqParams = {
                        company_id: this.cs.selectedTableRows[tableName][0].company_id,
                        agent_ids: lW.map((l: any) => l.id),
                        message: []
                    }
                    // this.resetConfig(reqParams);
                    this.modalService.open('resetagent');
                } else {
                    this.toast.sToast('info', 'Please select at least one agent.');
                }
            }
        })
        this.cs.newAgentCall.subscribe((trigger: any) => {
            this.aView = 'download_agent';
            this._changeDetectorRef.markForCheck();
        });
        this.isGlobal = (this.cs.currentScope === '*');
    }

    newAgentCall(): void {
        this.cs.newAgentCall.emit("");
    }

    ngOnInit(): void {
        this.initTable();
        this.uS.userd$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: any) => {
            this.user = user;
            let s = Object.keys(user["urn:zitadel:iam:org:project:roles"])
            let tenantid = Object.keys(user["urn:zitadel:iam:org:project:roles"][s[0]])[0]
            this.agent.companyId = this.cs.currentScope.id;
            this.agent.tenantId = tenantid;
            this.agent.command = this.osCommands[this.agent.osType];
            this._changeDetectorRef.markForCheck();
        });
    }


    initTable() {
        const selectedIdx = this.selectedIndex;
        const tableOptions: any = {
            columns: [
                {
                            "header": "Status",
                            "columnDef": "status",
                            "filter": "",
                            width: 'w-[80px]',
                            "cType": "bool",
                            "cell": "(element: any) => `${element.status}`",
                            visible: true,
                            isToolTip: false,
                            isToolTipCol: '',
                            hasMultiData: false,
                            class: '',
                            color: '',
                            isProgressCntrl: false,
                            isColoredCntrl: false,
                            colList: [],
                            isfaicon: false,
                            isAddingText: false,
                            selectFilter: true,
                            addingText: '',
                            img: false,
                            imgPath: '',
                            isSort: false,
                            iscolumnSearch: false,
                            statusicon: true,
                            success: 'online',
                            failure: 'offline',
                            successIconPath: '/assets/images/on.png',
                            successToolTip: 'Online',
                            failureIconPath: '/assets/images/off.png',
                            failureToolTip: 'Offline',
                        },
                {
                    "header": "Host Name",
                    "columnDef": "host_name",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => ${element.host_name}",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    "header": "IP",
                    "columnDef": "ip",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => ${element.ip}",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    "header": "Version",
                    "columnDef": "agent_version",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => ${element.version}",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    "header": "Agent Type",
                    "columnDef": "agent_type",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => ${element.agent_type}",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    isColoredCntrl: false,
                    isCustomText: false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    "header": "OS Name",
                    "columnDef": "os_name",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => ${element.os_name}",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    "header": "OS Platform",
                    "columnDef": "os_platform",
                    "cType": "string",
                    "filter": "cameltohuman",
                    "cell": "(element: any) => ${element.os_platform}",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    "header": "OS Version",
                    "columnDef": "os_version",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => ${element.os_version}",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    "header": "Kernel",
                    "columnDef": "kernel",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => ${element.kernel}",
                    "order": 0,
                    "visible": false,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    "header": "Installed Date",
                    "columnDef": "created",
                    "cType": "date",
                    "filter": "utcToLocale",
                    "cell": "(element: any) => ${element.created}",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    "header": "Last Scanned Time",
                    "columnDef": "last_scanned_time",
                    "cType": "date",
                    "filter": "utcToLocale",
                    "cell": "(element: any) => ${element.last_scanned_time}",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    "header": "Is Agent Reported",
                    align: "center",
                    "columnDef": "reported",
                    "cType": "boolean",
                    "filter": "",
                    "cell": "(element: any) => ${element.reported}",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false,
                    statusicon: true,
                    success: true,
                    failure: false,
                    successIconPath: '/assets/images/auditLogins/tick.svg',
                    successToolTip: 'Reported',
                    failureIconPath: '/assets/images/auditLogins/alert.svg',
                    failureToolTip: 'Not Reported',
                },
                {
                    "header": "Max Thread Count",
                    "columnDef": "max_thread_count",
                    "cType": "number",
                    "filter": "",
                    "cell": "(element: any) => ${element.max_thread_count}",
                    "order": 0,
                    "visible": false,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    "header": "Is Active",
                    align: "center",
                    "columnDef": "is_deprecated",
                    "cType": "boolean",
                    "filter": "",
                    "cell": "(element: any) => ${element.is_deprecated}",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false,
                    statusicon: true,
                    success: true,
                    failure: false,
                    successIconPath: '/assets/images/auditLogins/alert.svg',
                    successToolTip: 'Deprecated',
                    failureIconPath: '/assets/images/auditLogins/tick.svg',
                    failureToolTip: 'Active',
                    selectFilterArr: [
                        { name: 'Deprecated', id: 'AgentDeprecated', value: true },
                        { name: 'Active', id: 'AgentActive', value: false },
                    ],
                },
                {
                    "header": "Deprecated Time",
                    "columnDef": "deprecated_time",
                    "cType": "date",
                    "filter": "utcToLocale",
                    "cell": "(element: any) => ${element.deprecated_time}",
                    "order": 0,
                    "visible": !!(selectedIdx === 2),
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": true,
                    "iscolumnSearch": false
                },
                {
                    "header": "Company Name",
                    "columnDef": "company_name",
                    "cType": "string",
                    "filter": "",
                    "cell": "(element: any) => ${element.company_name}",
                    "order": 0,
                    "visible": true,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": false,
                    "iscolumnSearch": false
                },
                {
                    "header": "Company ID",
                    "columnDef": "company_id",
                    "cType": "number",
                    "filter": "",
                    "cell": "(element: any) => ${element.company_id}",
                    "order": 0,
                    "visible": false,
                    "isToolTip": false,
                    "isToolTipCol": "",
                    "hasMultiData": false,
                    "class": "",
                    "color": "",
                    "isProgressCntrl": false,
                    "isColoredCntrl": false,
                    "colList": [],
                    "isfaicon": false,
                    "isAddingText": false,
                    "addingText": "",
                    "img": false,
                    "imgPath": "",
                    "isSort": false,
                    "iscolumnSearch": false
                }],
            sortOptions: { active: 'host_name', direction: 'asc' },
            _pageData: [],
            tableOptions: {
                title: 'Probe Agents',
                isServerSide: false,
                selectText: 'agents',
                loading: true,
                floatingFilter: true,
                isHideGlobal: true,
                rowSelection: true,
                showAction: true,
                isActionCondition: true,
                actionMenuItems: [
                    {
                        text: 'Map Discovery & Credentials',
                        icon: 'map',
                        callback: 'editFunction',
                        actionConditions: { key: 'agent_type' },
                        allowedCondition: ['PROBE', 'LIGHTWEIGHT']
                    },
                    {
                        text: 'Reset Agent Configuration',
                        icon: 'restart_alt',
                        callback: 'resetFunction',
                        actionConditions: { key: 'agent_type' },
                        allowedCondition: ['PROBE', 'LIGHTWEIGHT']
                    },
                    {
                        text: 'Scan',
                        icon: 'scanner',
                        callback: 'editFunction',
                        actionConditions: { key: 'agent_type' },
                        allowedCondition: ['PROBE', 'LIGHTWEIGHT'],
                        isGlobal: true,
                        hideLocal: false,
                    },
                    {
                        text: 'Uninstall',
                        id: 'uninstall',
                        icon: 'delete_forever',
                        callback: 'editFunction',
                        actionConditions: { key: 'agent_type' },
                        allowedCondition: ['PROBE', 'LIGHTWEIGHT'],
                        isGlobal: true,
                        hideLocal: false,
                    },
                    {
                        text: 'Deprecate',
                        icon: 'repeat',
                        callback: 'deprecateFn',
                        actionConditions: { key: 'is_deprecated' },
                        allowedCondition: ['false']
                    },
                    {
                        text: 'Restore',
                        icon: 'repeat',
                        callback: 'deprecateFn',
                        actionConditions: { key: 'is_deprecated' },
                        allowedCondition: ['true']
                    },
                    {
                        text: 'Activate AD Audit',
                        icon: 'play_circle_outline',
                        callback: 'startDebug',
                        actionConditions: { key: 'agent_type' },
                        allowedCondition: ['PROBE', 'LIGHTWEIGHT']
                    },
                    {
                        text: 'Deactivate AD Audit',
                        icon: 'motion_photos_paused',
                        callback: 'startDebug',
                        actionConditions: { key: 'agent_type' },
                        allowedCondition: ['PROBE', 'LIGHTWEIGHT']
                    },
                    {
                        text: 'Start Debug',
                        icon: 'outlined_flag',
                        callback: 'startDebug',
                        actionConditions: { key: 'agent_type' },
                        allowedCondition: ['PROBE', 'LIGHTWEIGHT']
                    },
                    {
                        text: 'End Debug',
                        icon: 'stop_circle',
                        callback: 'endDebug',
                        actionConditions: { key: 'agent_type' },
                        allowedCondition: ['PROBE', 'LIGHTWEIGHT']
                    },
                    {
                        text: 'Fetch Logs',
                        icon: 'upload_file',
                        callback: 'fetchLogs',
                        actionConditions: { key: 'agent_type' },
                        allowedCondition: ['PROBE', 'LIGHTWEIGHT']
                    },
                    {
                        text: 'Restart Agent',
                        icon: 'restart_alt',
                        callback: 'restartAgent',
                        actionConditions: { key: 'agent_type' },
                        allowedCondition: ['PROBE', 'LIGHTWEIGHT']
                    },
                    {
                        text: 'Download Logs',
                        icon: 'download_for_offline',
                        callback: 'downloadLogs',
                        actionConditions: { key: 'agent_type' },
                        allowedCondition: ['PROBE', 'LIGHTWEIGHT']
                    },

                ],
                pagination: true,
                pageOptions: [5, 10, 25, 100],
                pageSize: 5,
                search: false,
                showFilter: true,
                showTagFilter: false,
                showhideList: true,
                refreshData: true,
                exportExcel: true,
                add: (this.cs.currentScope !== '*') ? true : false, addText: 'Download Agent',
                addIcon: 'file_download',
                columnSearch: false,
                compareData: false,
                filterDownload: false,
                serverSide: {
                    url: '/r/reports',
                    condition: `agent_type='PROBE' and is_deprecated=FALSE`,
                    type: 'post',
                    isGlobal: true,
                    params: { "name": (this.cs.currentScope !== '*') ? "agent_discovery_credentials" : "agent_discovery_credentials_global" },
                    isMergeApi: true,
                    mergeurl: '/api/agent_status',
                    mergeCondition: 'agent_ids',
                    mergeKey: 'agent_id',
                    srcKey: 'id'
                },
                id: 'agents'
            },
            customText: [

                {
                    status: "PROBE",
                    DisplayText: 'Probe',
                },
                {
                    status: "LIGHTWEIGHT",
                    DisplayText: 'LightWeight',
                },
                {
                    range: { from: 0, to: 0 },
                    color: '#c2ffc4',
                    'text-color': '#000000'
                },
                {
                    range: { from: 1, to: 50 },
                    color: '#ffcb70',
                    'text-color': '#000000'
                },
                {
                    range: { from: 51, to: 10000 },
                    color: '#ffb4a3',
                    'text-color': '#000000'
                },
                {
                    status: 25,
                    DisplayText: 'Low',
                    color: '#ffff00',
                    'text-color': '#000000'
                },
                {
                    status: 50,
                    DisplayText: 'Medium',
                    color: '#ff8c00'
                },
                {
                    status: 75,
                    DisplayText: 'High',
                    color: '#de4a50',
                    'text-color': '#ffffff'
                },
                {
                    status: 100,
                    DisplayText: 'Critical',
                    color: '#92243e',
                    'text-color': '#ffffff'
                }
            ],
            changeValue: new Subject<any>(),
        }
        this.agenTableOptions = {};
        this.selectedIndex = null;
        const name = (this.cs.currentScope === '*') ? "agent_discovery_credentials_global" : "agent_discovery_credentials";
        if (selectedIdx === 0) {
            tableOptions.title = 'Probe Agents';
            tableOptions.pageData = [];
            tableOptions.tableOptions.pageTotal = 0;
            tableOptions.tableOptions.serverSide = {
                url: '/r/reports',
                condition: (this.cs.currentScope === '*') ? `agent_type='PROBE' and is_deprecated=FALSE` : `agent_type='PROBE' and is_deprecated=FALSE and company_id=${parseInt(this.cs.currentScope.id)}`,
                type: 'post',
                isGlobal: true,
                params: { "name": name },
                isMergeApi: true,
                mergeurl: '/api/agent_status',
                mergeCondition: 'agent_ids',
                mergeKey: 'agent_id',
                srcKey: 'id'
            };

            this.selectedIndex = selectedIdx;
            setTimeout(() =>
                this.agenTableOptions = tableOptions
            );
        }
        if (selectedIdx === 1) {
            tableOptions.tableOptions.title = 'LightWeight Agents';
            tableOptions.pageData = [];
            tableOptions.tableOptions.pageTotal = 0;
            tableOptions.tableOptions.serverSide = {
                url: '/r/reports',
                condition: (this.cs.currentScope === '*') ? `agent_type='LIGHTWEIGHT' and is_deprecated=FALSE` : `agent_type='LIGHTWEIGHT' and is_deprecated=FALSE and company_id=${parseInt(this.cs.currentScope.id)}`,
                type: 'post', isGlobal: true,
                params: { "name": name },
                isMergeApi: true,
                mergeurl: '/api/agent_status',
                mergeCondition: 'agent_ids',
                mergeKey: 'agent_id',
                srcKey: 'id'
            };
            this.selectedIndex = selectedIdx;
            setTimeout(() =>
                this.agenTableOptions = tableOptions
            );
        }
        if (selectedIdx === 2) {
            tableOptions.tableOptions.title = 'Deprecated Agents';
            tableOptions.pageData = [];
            tableOptions.tableOptions.pageTotal = 0;
            tableOptions.tableOptions.serverSide = {
                url: '/r/reports',
                condition: (this.cs.currentScope === '*') ? `is_deprecated=TRUE` : `is_deprecated=TRUE and company_id=${parseInt(this.cs.currentScope.id)}`,
                type: 'post', isGlobal: true,
                params: { "name": name },
            };
            this.selectedIndex = selectedIdx;
            setTimeout(() =>
                this.agenTableOptions = tableOptions
            );
        }

    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    updateCode($event: any) {

    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.subs.unsubscribe();
    }

    actioncallback(data: any): void {
        console.log(data)
        this.currentData = data.row
        if (data.action.text == 'Map Discovery Settings') {
            this.getsettings(data.row)
        } else if (data.action.text == 'Reset Agent Configuration') {
            this.resetParam = { company_id: data.row.company_id, agent_ids: [data.row.id] }
            this.modalService.open('resetagent');
        } else if (data.action.text == 'Map Discovery & Credentials') {
            this.getCredentials(data.row);
            this.getsettings(data.row);
        } else if (data.action.text == 'Scan') {
            this.getScanProfiles(data.row)
        } else if (data.action.text == 'Uninstall') {
            this.deleteAgent(data.row)
        } else if (data.action.text == 'Deprecate') {
            this.deprecateRestoreAgent(data.row, true);
        } else if (data.action.text == 'Restore') {
            this.deprecateRestoreAgent(data.row, false);
        } else if (data.action.text == 'Start Debug') {
            this.agentDebug(data.row, 'start_debug');
        } else if (data.action.text == 'End Debug') {
            this.agentDebug(data.row, 'end_debug');
        } else if (data.action.text == 'Activate AD Audit') {
            this.agentDebug(data.row, 'activate_ad_audit');
        } else if (data.action.text == 'Deactivate AD Audit') {
            this.agentDebug(data.row, 'deactivate_ad_audit');
        } else if (data.action.text == 'Download Logs') {
            this.agentDebug(data.row, 'download_logs');
        } else if (data.action.text == 'Fetch Logs') {
            this.agentDebug(data.row, 'fetch_logs');
        } else if (data.action.text == 'Restart Agent') {
            this.agentDebug(data.row, 'restart_agent');
        }
    }

    agentDebug(agent: any, action: any): void {
        const agentAction = {
            start_debug: 'Start Debugging Process', end_debug: 'End Debugging Process', fetch_logs: 'Fetch Logs',
            download_logs: 'Download Logs', restart_agent: 'Restart Agent', activate_ad_audit: 'Activate AD Audit',
            deactivate_ad_audit: 'Deactivate AD Audit'
        }
        const titleName = `${agentAction[action]}:`;
        const message = `Would you like to ${agentAction[action]} for the chosen Agent?`;
        const cancelText = 'No';
        const acceptText = 'Yes';
        const confirmation = this.confirmDialog.open({
            title: titleName,
            message: message,
            icon: { show: false, name: "", color: "primary" },
            actions: {
                confirm: { show: true, label: acceptText, color: "primary" },
                cancel: { show: true, label: cancelText }
            },
            dismissible: false,
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                if (action === 'download_logs') {
                    const reqData: any = { company_id: agent.company_id, agent_id: agent.id, region: 'use' };
                    this.loaderService.display(true);
                    this._bs.doRequest('/w/company/download_agent_logs', 'post', reqData)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((result: any) => {
                            this.loaderService.display(false);
                            if (result.status) {
                                window.open(result.message);
                            } else {
                                const data = (result.message) ? result.message : result.data;
                                this.toast.sToast('error', data);
                            }
                            this._changeDetectorRef.detectChanges()
                        });
                } else {
                    const reqData: any = { company_id: agent.company_id, agent_ids: [agent.id], message: action };
                    this.loaderService.display(true);
                    this._bs.doRequest('/w/company/reset_agents', 'post', reqData)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((result: any) => {
                            this.loaderService.display(false);
                            if (result.status) {
                                this.toast.sToast('success', result.message);
                            } else {
                                const data = (result.message) ? result.message : result.data;
                                this.toast.sToast('error', data);
                            }
                            this._changeDetectorRef.detectChanges()
                        });
                }
            }
        });
    }

    deprecateRestoreAgent(idata: any, is_deprecated: any) {
        this.cs.reason = '';
        const titleName = (!is_deprecated) ? 'Restore' : 'Deprecate';
        const message = (idata.updated_status === 'Online') ? `Are you sure that you need to ${titleName} an Online Agent(s)?` : `Do you want to ${titleName} the selected Agent(s)?`;
        const cancelText = 'No';
        const acceptText = 'Yes';
        const confirmation = this.confirmDialog.open({
            title: titleName,
            message: message,
            icon: { show: false, name: "", color: "accent" },
            actions: {
                confirm: { show: true, label: acceptText, color: "primary" },
                cancel: { show: true, label: cancelText }
            },
            dismissible: false,
            isReason: true,
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                const params: any = {
                    agent_ids: [idata.id],
                    is_deprecated: is_deprecated,
                    reason: this.cs.reason
                };
                this.loaderService.display(true);
                this._bs.doRequest('/w/company/bulk_deprecate', 'post',
                    params)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        this.loaderService.display(false);
                        if (result.status) {
                            this.toast.sToast('success', (!is_deprecated) ? 'Agent Restored Successfully.' : 'Agent Deprecated Successfully.');
                            setTimeout(() => this.agenTableOptions.changeValue.next(true), 1000);
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        })
    }

    resetConfig(): void {
        this.modalService.close('resetagent');
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: `Are you sure you want to reset these configurations to the selected agent ?`,

            "icon": {
                "show": false,
                "name": "",
                "color": "success"
            },
            "actions": {
                "confirm": {
                    "show": true,
                    "label": "Yes",
                    "color": "primary"
                },
                "cancel": {
                    "show": true,
                    "label": "No"
                }
            },
            "dismissible": true
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                const params: any = this.resetParam;
                params.message = this.reset_config;
                this.loaderService.display(true);
                this._bs.doRequest('/w/company/reset_agents', 'post',
                    params)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        this.loaderService.display(false);
                        if (result.status) {
                            this.toast.sToast('success', result.message);
                            this.reset_config = '';
                            setTimeout(() => this.agenTableOptions.changeValue.next(true), 2000);
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        });
    }

    deleteAgent($event: any): void {
        const confirmation = this.confirmDialog.open({
            title: 'Confirmation',
            message: `Are you sure you want to uninstall the selected agent?`,
            actions: {
                confirm: {
                    label: 'Uninstall'
                }
            }
        });
        confirmation.afterClosed().subscribe((result) => {
            const reqData: any = {
                id: '' + $event.id,
                company_id: '' + $event.company_id
            }
            if (result === 'confirmed') {
                this.loaderService.display(true);
                this._bs.doRequest('/d/company/delete_agent', 'post',
                    reqData)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((result: any) => {
                        this.loaderService.display(false);
                        if (result.status) {
                            this.toast.sToast('success', 'Uninstalled successfully');
                            setTimeout(() => this.agenTableOptions.changeValue.next(true), 2000);
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                    });
            }
        });

    }

    getScanProfiles(data: any) {
        this.scanassetoutput = {
            scan_type: '',
            scan_profile: ''
        }
        if (data.agent_type === 'LIGHTWEIGHT') {
            this.scanassetoutput.scan_type = 'COMPLIANCE';
            this.scanasset.formElements[0].options = this.light_scan_types;
        } else {
            this.scanasset.formElements[0].options = this.scan_types;
        }
        this.modalService.open('scanagents')
    }

    scanadd(event: any) {
        if (event.scan_type === 'REMOTEINSTALL') {
            const confirmation = this.confirmDialog.open({
                title: "Confirmation",
                message: "Please confirm, if you want to Remotely Install Lightweight agents on all the discovered assets.",
                icon: { show: false, name: "", color: "accent" },
                actions: {
                    confirm: { show: true, label: "Yes", color: "primary" },
                    cancel: { show: true, label: "No" }
                },
                dismissible: false
            });
            confirmation.afterClosed().subscribe((result) => {
                if (result === 'confirmed') {
                    const reqData: any = {
                        data: {}
                    }
                    reqData.data.agent_id = this.currentData.id;
                    reqData.data.company_id = this.currentData.company_id;
                    reqData.data.scan_type = event.scan_type;
                    this.loaderService.display(true);
                    this._bs.doRequest('/w/company/scan', 'post',
                        reqData)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((result: any) => {
                            this.loaderService.display(false);
                            if (result.status) {
                                this.modalService.close('scanagents')
                                this.toast.sToast('success', result.data);
                            } else {
                                const data = (result.message) ? result.message : result.data;
                                this.toast.sToast('error', data);
                            }
                            this._changeDetectorRef.detectChanges()
                        });
                }
            });
        } else {
            const reqData: any = {
                data: {}
            }
            reqData.data.agent_id = this.currentData.id;
            reqData.data.company_id = this.currentData.company_id;
            reqData.data.scan_type = event.scan_type;
            this.loaderService.display(true);
            this._bs.doRequest('/w/company/scan', 'post',
                reqData)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    this.loaderService.display(false);
                    if (result.status) {
                        this.modalService.close('scanagents')
                        this.toast.sToast('success', result.data);
                    } else {
                        const data = (result.message) ? result.message : result.data;
                        this.toast.sToast('error', data);
                    }
                    this._changeDetectorRef.detectChanges()
                });
        }


    }

    getCredentials(data: any) {
        this.settingsdata = [];
        this.loaderService.display(true, `Getting credential for company id ${data.company_id}`);
        this._bs.doRequest('/r/company/credentials', 'get',
            null, {
            condition: "company_id='" + data.company_id + "'",
            skip: 0,
            limit: 100,
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                this.settingsdata = result.data;
                var listdisc = []
                result.data.forEach((ele: any) => {
                    var temp = { dKey: ele.name, dValue: ele.id }
                    listdisc.push(temp)
                });
                if (data.credentials_ids && data.credentials_ids.length && data.credentials_ids[0] !== null) {
                    this.agentdiscoverymap.credentials_id = data.credentials_ids;
                } else {
                    this.agentdiscoverymap.credentials_id = []
                }
                this.agentdisco.formElements[1].options = listdisc
                this._changeDetectorRef.detectChanges();
            });
    }

    getsettings(data: any) {
        this.settingsdata = [];
        this.loaderService.display(true, `Getting discovery setting for company id ${data.company_id}`);
        const skip = 0;
        const limit = 1;
        this._bs.doRequest('/r/company/discovery_settings', 'get',
            null, {
            condition: `discovery_settings_type='assetscan' and company_id=${parseInt(data.company_id)}`,
            skip: 0,
            limit: 100,
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                this.settingsdata = result.data;
                var listdisc = []
                result.data.forEach(ele => {
                    var temp = { dKey: `${ele.name} [${ele.address}]`, dValue: ele.id }
                    listdisc.push(temp)
                })
                this.agentdisco.formElements[0].options = listdisc;
                if (data.discovery_settings_ids && data.discovery_settings_ids.length && data.discovery_settings_ids[0] !== null) {
                    this.agentdiscoverymap.discovery_settings_id = data.discovery_settings_ids;
                } else {
                    this.agentdiscoverymap.discovery_settings_id = [];
                }
                this.modalService.open('mapdiscoveryandcreds');
                this._changeDetectorRef.detectChanges();

            });
    }

    agentcredsave(data: any): void {
        const reqData: any = {
            data: {}
        };
        reqData.data.agent_id = this.currentData.id;
        reqData.data.company_id = this.currentData.company_id;
        reqData.data.mapping_ids = data.credentials_id;
        reqData.data.setting_type = "credentials";
        this.loaderService.display(true);
        this._bs.doRequest('/w/company/creddiscoverymapping', 'post', reqData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.loaderService.display(false);
                if (res.status) {
                    this.toast.sToast('success', 'Mapped successfully.');
                    this.loadtable = true
                } else {
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                }
            })
    }

    agentdiscosave(data: any): void {
        const reqData: any = {
            data: {}
        };
        reqData.data.agent_id = this.currentData.id;
        reqData.data.company_id = this.currentData.company_id;
        reqData.data.mapping_ids = data.discovery_settings_id;
        reqData.data.setting_type = "discovery_settings";
        this.loaderService.display(true);
        this._bs.doRequest('/w/company/creddiscoverymapping', 'post', reqData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.loaderService.display(false);
                if (res.status) {
                    this.toast.sToast('success', 'Mapped successfully.');
                    this.loadtable = true
                    this.initTable()
                }
            })

    }

    closeCallBack($event: any): void {
        this.aView = 'agent';
    }

    agentDisCredSave(): void {
        const reqData: any = {
            data: {
                agent_id: this.currentData.id,
                company_id: this.currentData.company_id,
                mapping_ids: this.agentdiscoverymap.discovery_settings_id,
                setting_type: "discovery_settings"
            }
        };
        this.loaderService.display(true, 'Saving discovery settings...');
        this._bs.doRequest('/w/company/creddiscoverymapping', 'post', reqData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.loaderService.display(false);
                if (res.status) {
                    const reqData: any = {
                        data: {
                            agent_id: this.currentData.id,
                            company_id: this.currentData.company_id,
                            mapping_ids: this.agentdiscoverymap.credentials_id,
                            setting_type: "credentials"
                        }
                    };
                    this.loaderService.display(false);
                    this.loaderService.display(true, 'Updating credentials...');
                    this._bs.doRequest('/w/company/creddiscoverymapping', 'post', reqData)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((res: any) => {
                            this.loaderService.display(false);
                            if (res.status) {
                                this.toast.sToast('success', 'Mapped successfully.');
                                this.modalService.close('mapdiscoveryandcreds')
                                this.loadtable = true
                                setTimeout(() => this.initTable(), 2000);
                            } else {
                                const data = (res.message) ? res.message : res.data;
                                this.toast.sToast('error', data);
                            }
                        })
                } else {
                    const data = (res.message) ? res.message : res.data;
                    this.toast.sToast('error', data);
                }
            });

    }

    tabFn($event: any): void {
        this.selectedIndex = null;
        this.agenTableOptions.tableOptions.add = true;
        const name = (this.cs.currentScope === '*') ? "agent_discovery_credentials_global" : "agent_discovery_credentials";
        if ($event === 0) {
            this.agenTableOptions.tableOptions.title = 'Probe Agents';
            this.agenTableOptions.pageData = [];
            this.agenTableOptions.tableOptions.pageTotal = 0;
            this.agenTableOptions.tableOptions.serverSide = {
                url: '/r/reports',
                condition: (this.cs.currentScope === '*') ? `agent_type='PROBE' and is_deprecated=FALSE` : `agent_type='PROBE' and is_deprecated=FALSE and company_id=${parseInt(this.cs.currentScope.id)}`,
                type: 'post', isGlobal: true,
                params: { "name": name },
                isMergeApi: true,
                mergeurl: '/api/agent_status',
                mergeCondition: 'agent_ids',
                mergeKey: 'agent_id',
                srcKey: 'id'
            };
            this.selectedIndex = $event;
        }
        if ($event === 1) {
            this.agenTableOptions.tableOptions.title = 'LightWeight Agents';
            this.agenTableOptions.pageData = [];
            this.agenTableOptions.tableOptions.pageTotal = 0;
            this.agenTableOptions.tableOptions.serverSide = {
                url: '/r/reports',
                condition: (this.cs.currentScope === '*') ? `agent_type='LIGHTWEIGHT' and is_deprecated=FALSE` : `agent_type='LIGHTWEIGHT' and is_deprecated=FALSE and company_id=${parseInt(this.cs.currentScope.id)}`,
                type: 'post', isGlobal: true,
                params: { "name": name },
                isMergeApi: true,
                mergeurl: '/api/agent_status',
                mergeCondition: 'agent_ids',
                mergeKey: 'agent_id',
                srcKey: 'id'
            };
            this.selectedIndex = $event;
        }
        if ($event === 2) {
            this.agenTableOptions.tableOptions.title = 'Deprecated Agents';
            this.agenTableOptions.pageData = [];
            this.agenTableOptions.tableOptions.pageTotal = 0;
            this.agenTableOptions.tableOptions.add = false;
            this.agenTableOptions.tableOptions.serverSide = {
                url: '/r/reports',
                condition: (this.cs.currentScope === '*') ? `is_deprecated=TRUE` : `is_deprecated=TRUE and company_id=${parseInt(this.cs.currentScope.id)}`,
                type: 'post', isGlobal: true,
                params: { "name": name }
            };
            this.selectedIndex = $event;
        }
    }

    globalActionCall(data: any): void {
        if (data.action.text === 'Uninstall') {
            const confirmation = this.confirmDialog.open({
                title: 'Confirmation',
                message: `Are you sure you want to uninstall the selected agent?`,
                actions: {
                    confirm: {
                        label: 'Uninstall'
                    }
                }
            });
            confirmation.afterClosed().subscribe((result) => {

                if (result === 'confirmed') {
                    this.loaderService.display(true);
                    data.row.forEach((obj: any, index: number) => {
                        const reqData: any = {
                            id: '' + obj.id,
                            company_id: '' + obj.company_id
                        }
                        this._bs.doRequest('/d/company/delete_agent', 'post',
                            reqData)
                            .pipe(takeUntil(this._unsubscribeAll))
                            .subscribe((result: any) => {
                                if (index === data.row.length - 1) {
                                    this.loaderService.display(false);
                                    if (result.status) {
                                        this.toast.sToast('success', 'Uninstalled successfully');
                                        setTimeout(() => this.agenTableOptions.changeValue.next(true), 2000);
                                    } else {
                                        const data = (result.message) ? result.message : result.data;
                                        this.toast.sToast('error', data);
                                    }
                                }
                            });

                    })
                }
            });
        }

        if (data.action.text === 'Scan') {
            this.selectedData = data.row;
            this.scanassetoutput = {
                scan_type: '',
                scan_profile: ''
            }
            if (this.selectedData[0].agent_type === 'LIGHTWEIGHT') {
                this.scanasset.formElements[0].options = this.light_scan_types;
            } else {
                this.scanasset.formElements[0].options = this.scan_types;
            }
            this.modalService.open('scanagentsMul');
        }
    }

    scanaddMul(event: any) {
        this.loaderService.display(true);
        this.selectedData.forEach((obj: any, index: number) => {
            const reqData: any = {
                data: {}
            }
            reqData.data.agent_id = obj.id;
            reqData.data.company_id = obj.company_id;
            reqData.data.scan_type = event.scan_type;

            this._bs.doRequest('/w/company/scan', 'post',
                reqData)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((result: any) => {
                    if (index === this.selectedData.length - 1) {
                        this.loaderService.display(false);
                        if (result.status) {
                            this.modalService.close('scanagentsMul');
                            this.toast.sToast('success', result.data);
                            setTimeout(() => this.agenTableOptions.changeValue.next(true), 2000);
                        } else {
                            const data = (result.message) ? result.message : result.data;
                            this.toast.sToast('error', data);
                        }
                        this._changeDetectorRef.detectChanges()

                    }
                });

        })
    }

    mapupdatedisc($event: any) {

    }

    downloadAgent($event: any) {

    }

    updateCommand($event: any) {

    }
}

<div class="flex items-center justify-end w-full max-w-3xl mx-auto" *ngIf="showClose">
    <button mat-icon-button aria-label="close modal icon" matTooltip="Close" appPreventMultiClick (click)="closeCallback.emit({})">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-tab-group (selectedTabChange)="selectedTab($event)" class="sm:px-2 max-w-3xl mx-auto" mat-stretch-tabs="false"
    [animationDuration]="'0'">
    <mat-tab label="Windows">
        <ng-template mat-tab-label>
            <i class="fab fa-windows fa-2x text-primary mr-3"></i><span id="Windows" class="text-lg">Windows</span>
        </ng-template>
        <ng-template matTabContent>
            <div class="mt-2 px-6">
                <ng-container>
                    <fuse-alert [appearance]="'border'" [type]="'info'">
                        An <em>agent</em> is installed at the end user’s system using the below command. The agent scan fetches
                        the system’s details on to the portal. Further the discovery settings associated to the agent's
                        network can be added to discover all the assets defined under the agent’s network with the agent
                        scan.
                    </fuse-alert>
                    <app-skeleton *ngIf="!showCmd"></app-skeleton>
                    <fuse-card class="flex flex-col max-w-150 w-full mt-2" *ngIf="showCmd">
                        <div class="terminal br-4 p-4">
                            <div [innerHTML]="probeCmdHtml | safeHTML">
                            </div>
                            <hr class="border-b mt-4 mb-4">
                            <div class="flex items-end justify-end mx-3 sm:mx-5">
                                <button class="px-3 mr-1" mat-raised-button color="primary" appPreventMultiClick (click)="dWIAgent();">
                                    <mat-icon class="text-primary dark:text-primary-500" [svgIcon]="'file_download'">
                                    </mat-icon> <span class="ml-1 text-white">Download Web Installer</span>
                                </button>
                                <button class="px-3 mr-1" mat-button appPreventMultiClick (click)="dAgent();">
                                    <mat-icon class="text-primary dark:text-primary-500" [svgIcon]="'file_download'">
                                    </mat-icon> <span class="ml-1 text-white">Download</span>
                                </button>
                                <button class="px-3 mr-1" mat-button appPreventMultiClick (click)="copyCmdClip();">
                                    <span>
                                        <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                            aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                    </span> <span class="ml-1 text-white">Copy to clipboard</span>
                                </button>
                            </div>
                        </div>
                        <div class="flex flex-col mt-8 divide-y border-t border-b">
                            <ng-container *ngFor="let api of apiKey; trackBy: trackByFn;">
                                <div class="flex flex-col sm:flex-row sm:items-center py-2 px-2">
                                    <div class="flex items-center">
                                        <div
                                            class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                            <ng-container>
                                                <mat-icon class="text-primary dark:text-primary-500"
                                                    [svgIcon]="api.icon">
                                                </mat-icon>
                                            </ng-container>
                                        </div>
                                        <div class="ml-4">
                                            <div class="font-medium">
                                                {{api.name}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex items-center mt-4 sm:mt-0 sm:ml-auto">
                                        <div class="order-2 sm:order-1 ml-4 sm:ml-0">
                                            {{api.key}}
                                        </div>
                                        <div class="order-1 sm:order-2 sm:ml-3 mb-1">
                                            <button mat-icon-button appPreventMultiClick (click)="copyClip(api.key)">
                                                <span>
                                                    <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                                        aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </fuse-card>
                </ng-container>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="Mac">
        <ng-template mat-tab-label>
            <i class="fab fa-apple fa-2x text-primary mr-3"></i><span id="Mac" class="text-lg">Mac</span>
        </ng-template>
        <ng-template matTabContent>
            <div class="mt-2 px-6">
                <ng-container>
                    <fuse-alert [appearance]="'border'" [type]="'info'">
                        An <em>agent</em> is installed at the end user’s system using the below command. The agent scan fetches
                        the system’s details on to the portal. Further the discovery settings associated to the agent's
                        network can be added to discover all the assets defined under the agent’s network with the agent
                        scan.
                    </fuse-alert>
                    <app-skeleton *ngIf="!showCmd"></app-skeleton>
                    <fuse-card class="flex flex-col max-w-150 w-full mt-2" *ngIf="showCmd">
                        <div class="terminal br-4 p-4">
                            <div [innerHTML]="probeCmdHtml | safeHTML">
                            </div>
                            <hr class="border-b mt-4 mb-4">
                            <div class="flex items-end justify-end mx-3 sm:mx-5">
                                <button class="px-3 mr-1" mat-button appPreventMultiClick (click)="dAgent();">
                                    <mat-icon class="text-primary dark:text-primary-500" [svgIcon]="'file_download'">
                                    </mat-icon> <span class="ml-1 text-white">Download</span>
                                </button>
                                <button class="px-3 mr-1" mat-button appPreventMultiClick (click)="copyCmdClip();">
                                    <span>
                                        <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                            aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                    </span> <span class="ml-1 text-white">Copy to clipboard</span>
                                </button>
                            </div>
                        </div>
                        <div class="flex flex-col mt-8 divide-y border-t border-b">
                            <ng-container *ngFor="let api of apiKey; trackBy: trackByFn;">
                                <div class="flex flex-col sm:flex-row sm:items-center py-2 px-2">
                                    <div class="flex items-center">
                                        <div
                                            class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                            <ng-container>
                                                <mat-icon class="text-primary dark:text-primary-500"
                                                    [svgIcon]="api.icon">
                                                </mat-icon>
                                            </ng-container>
                                        </div>
                                        <div class="ml-4">
                                            <div class="font-medium">
                                                {{api.name}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex items-center mt-4 sm:mt-0 sm:ml-auto">
                                        <div class="order-2 sm:order-1 ml-4 sm:ml-0">
                                            {{api.key}}
                                        </div>
                                        <div class="order-1 sm:order-2 sm:ml-3">
                                            <button mat-icon-button appPreventMultiClick (click)="copyClip(api.key)">
                                                <span>
                                                    <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                                        aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </fuse-card>
                </ng-container>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="Linux">
        <ng-template mat-tab-label>
            <i class="fab fa-linux fa-2x text-primary mr-3"></i><span id="Linux" class="text-lg">Linux</span>
        </ng-template>
        <ng-template matTabContent>
            <div class="mt-2 px-6">
                <ng-container>
                    <fuse-alert [appearance]="'border'" [type]="'info'">
                        An <em>agent</em> is installed at the end user’s system using the below command. The agent scan fetches
                        the system’s details on to the portal. Further the discovery settings associated to the agent's
                        network can be added to discover all the assets defined under the agent’s network with the agent
                        scan.
                    </fuse-alert>
                    <app-skeleton *ngIf="!showCmd"></app-skeleton>
                    <fuse-card class="flex flex-col max-w-150 w-full mt-2" *ngIf="showCmd">
                        <div class="terminal br-4 p-4">
                            <div [innerHTML]="probeCmdHtml | safeHTML">
                            </div>
                            <hr class="border-b mt-4 mb-4">
                            <div class="flex items-end justify-end mx-3 sm:mx-5">
                                <button class="px-3 mr-1" mat-button appPreventMultiClick (click)="dAgent();">
                                    <mat-icon class="text-primary dark:text-primary-500" [svgIcon]="'file_download'">
                                    </mat-icon> <span class="ml-1 text-white">Download</span>
                                </button>
                                <button class="px-3 mr-1" mat-button appPreventMultiClick (click)="copyCmdClip();">
                                    <span>
                                        <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                            aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                    </span> <span class="ml-1 text-white">Copy to clipboard</span>
                                </button>
                            </div>
                        </div>
                        <div class="flex flex-col mt-8 divide-y border-t border-b">
                            <ng-container *ngFor="let api of apiKey; trackBy: trackByFn;">
                                <div class="flex flex-col sm:flex-row sm:items-center py-2 px-2">
                                    <div class="flex items-center">
                                        <div
                                            class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                            <ng-container>
                                                <mat-icon class="text-primary dark:text-primary-500"
                                                    [svgIcon]="api.icon">
                                                </mat-icon>
                                            </ng-container>
                                        </div>
                                        <div class="ml-4">
                                            <div class="font-medium">
                                                {{api.name}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex items-center mt-4 sm:mt-0 sm:ml-auto">
                                        <div class="order-2 sm:order-1 ml-4 sm:ml-0">
                                            {{api.key}}
                                        </div>
                                        <div class="order-1 sm:order-2 sm:ml-3">
                                            <button mat-icon-button appPreventMultiClick (click)="copyClip(api.key)">
                                                <span>
                                                    <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                                        aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </fuse-card>
                </ng-container>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="ARM">
        <ng-template mat-tab-label>
            <i class="fas fa-microchip fa-2x text-primary mr-3"></i><span id="ARM" class="text-lg">ARM</span>
        </ng-template>
        <ng-template matTabContent>
            <div class="mt-2 px-6">
                <ng-container>
                    <fuse-alert [appearance]="'border'" [type]="'info'">
                        An <em>agent</em> is installed at the end user’s system using the below command. The agent scan fetches
                        the system’s details on to the portal. Further the discovery settings associated to the agent's
                        network can be added to discover all the assets defined under the agent’s network with the agent
                        scan.
                    </fuse-alert>
                    <app-skeleton *ngIf="!showCmd"></app-skeleton>
                    <fuse-card class="flex flex-col max-w-150 w-full mt-2" *ngIf="showCmd">
                        <div class="terminal br-4 p-4">
                            <div [innerHTML]="probeCmdHtml | safeHTML">
                            </div>
                            <hr class="border-b mt-4 mb-4">
                            <div class="flex items-end justify-end mx-3 sm:mx-5">
                                <button class="px-3 mr-1" mat-button appPreventMultiClick (click)="dAgent();">
                                    <mat-icon class="text-primary dark:text-primary-500" [svgIcon]="'file_download'">
                                    </mat-icon> <span class="ml-1 text-white">Download</span>
                                </button>
                                <button class="px-3 mr-1" mat-button appPreventMultiClick (click)="copyCmdClip();">
                                    <span>
                                        <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                            aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                    </span> <span class="ml-1 text-white">Copy to clipboard</span>
                                </button>
                            </div>
                        </div>
                        <div class="flex flex-col mt-8 divide-y border-t border-b">
                            <ng-container *ngFor="let api of apiKey; trackBy: trackByFn;">
                                <div class="flex flex-col sm:flex-row sm:items-center py-2 px-2">
                                    <div class="flex items-center">
                                        <div
                                            class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                            <ng-container>
                                                <mat-icon class="text-primary dark:text-primary-500"
                                                    [svgIcon]="api.icon">
                                                </mat-icon>
                                            </ng-container>
                                        </div>
                                        <div class="ml-4">
                                            <div class="font-medium">
                                                {{api.name}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex items-center mt-4 sm:mt-0 sm:ml-auto">
                                        <div class="order-2 sm:order-1 ml-4 sm:ml-0">
                                            {{api.key}}
                                        </div>
                                        <div class="order-1 sm:order-2 sm:ml-3">
                                            <button mat-icon-button appPreventMultiClick (click)="copyClip(api.key)">
                                                <span>
                                                    <i class="pointer fa fa-clipboard text-xl text-primary dark:text-primary-500 mr-1"
                                                        aria-hidden="true" matTooltip="Copy to clipboard"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </fuse-card>
                </ng-container>
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>

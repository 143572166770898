import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild, AfterViewInit, OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { CommonService } from 'app/_services/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'paginator-widget',
  standalone: true,
  imports: [CommonModule, MatPaginatorModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './paginator-widget.component.html',
  styleUrls: ['./paginator-widget.component.scss']
})
export class PaginatorWidgetComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: false })
  paginator!: MatPaginator
  @Input() pageSize: any = 5;
  @Input() pageTotal: any;
  @Input() pageOptions: any = [5, 10, 20, 30, 50, 100, 200];
  @Output() pageChanged = new EventEmitter();
  refs: Subscription;
  constructor(private _changeDetectorRef: ChangeDetectorRef, private _cS: CommonService,) {
    this.refs = this._cS.reloadServerSideTable.subscribe((company: any) => {
      console.log(company);
      this.paginator.pageIndex = 0
    });
  }

  pChanged(page: any): void {
    this.pageChanged.emit(page);
  }

  /**
  * On init
  */
  ngOnInit(): void {
    (this.paginator) ? this.paginator.pageIndex = 0 : null;
  }

  ngAfterViewInit(): void {
    this._changeDetectorRef.markForCheck();
  }
  ngOnDestroy(): void {
    this.refs.unsubscribe();
  }
}

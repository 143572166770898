<div class="-mx-4">
    <ng-container *ngIf="uView === 'table'">
        <stable class="" aria-label="User Table" role="table" *ngIf="userTableOption.tableOptions"
            (addCallback)="newUserCall()" [sTableOptions]="userTableOption"
                (hyperlinkCallback)="linkCall($event)"
                (actionCallback)="actioncallback($event)">
        </stable>
    </ng-container>
    <ng-container *ngIf="uView === 'add'">
        <div class="rounded-xl m-2 bg-card p-4">
            <form [formGroup]="contactForm" class="p-4">
                <div class="flex flex-row items-center px-6">
                    <div class="text-2xl font-semibold tracking-tight">Add New User</div>
                    <button class="ml-auto" mat-icon-button (click)="uView = 'table';">
                        <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    </button>
                </div>
                <fuse-alert [appearance]="'border'" class="px-6 my-6" [type]="'info'">
                    Once you add the User here, an initialization email will be sent to the created user's Email
                    Address. Please complete the user initialization to activate the user.
                </fuse-alert>
                <div class="grid grid-cols-2 grid-cols-2 gap-4 px-6 mt-4">
                    <div>
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>First Name</mat-label>
                            <mat-icon matPrefix class="icon-size-5"
                                [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                            <input matInput [formControlName]="'first_name'" required [placeholder]="'First Name'"
                                [spellcheck]="false">
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-icon matPrefix class="icon-size-5"
                                [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                            <mat-label>Last Name</mat-label>
                            <input matInput [formControlName]="'last_name'" required [placeholder]="'Last Name'">
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-icon matPrefix class="icon-size-5" [svgIcon]="'mat_solid:email'"></mat-icon>
                            <mat-label>Email</mat-label>
                            <input matInput [formControlName]="'email'" appEmailCheck required [placeholder]="'Email'">
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label>Phone Number</mat-label>
                            <mat-icon matPrefix class="icon-size-5" [svgIcon]="'mat_solid:phone'"></mat-icon>
                            <input matInput type="number" [formControlName]="'mobile'" required
                                [placeholder]="'Phone Number'">
                        </mat-form-field>
                    </div>
                    <mat-form-field class="w-full" *ngIf="false">
                        <mat-label>Password</mat-label>
                        <input id="password" matInput type="password" required [formControlName]="'password'"
                            #passwordField>
                        <button mat-icon-button type="button"
                            (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                            matSuffix>
                            <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'password'"
                                [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                            <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'text'"
                                [svgIcon]="'heroicons_solid:eye-slash'"></mat-icon>
                        </button>
                        <mat-error>
                            Password must contain at least one uppercase letter, one lowercase letter, and one number.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-full" *ngIf="false">
                        <mat-label>Password (Confirm)</mat-label>
                        <input id="password-confirm" matInput type="password" required
                            [formControlName]="'passwordConfirm'" #passwordConfirmField>
                        <button mat-icon-button type="button"
                            (click)="passwordConfirmField.type === 'password' ? passwordConfirmField.type = 'text' : passwordConfirmField.type = 'password'"
                            matSuffix>
                            <mat-icon class="icon-size-5" *ngIf="passwordConfirmField.type === 'password'"
                                [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                            <mat-icon class="icon-size-5" *ngIf="passwordConfirmField.type === 'text'"
                                [svgIcon]="'heroicons_solid:eye-slash'"></mat-icon>
                        </button>
                        <mat-error *ngIf="contactForm.get('passwordConfirm').hasError('required')">
                            Password confirmation is required
                        </mat-error>
                        <mat-error *ngIf="contactForm.get('passwordConfirm').hasError('mustMatch')">
                            Passwords must match
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-full">
                        <mat-label>Select Roles</mat-label>
                        <mat-select [multiple]="true" [formControlName]="'roles'" required placeholder="choose roles"
                            (selectionChange)="contactForm.get('roles').setValue(($event.value.includes('admin')) ? ['admin'] : $event.value);">
                            <mat-option disabled *ngIf="roles.length === 0">
                                <span class="dark:text-white">No Roles present</span>
                            </mat-option>
                            <mat-option *ngFor="let role of roles" [value]="role">{{role | uppercase}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="grid grid-cols-2 grid-cols-2 gap-4 px-6 mt-4">
                    <ng-container
                        *ngIf="contactForm.get('roles').value && contactForm.get('roles').value.length &&  contactForm.get('roles').value.indexOf('admin') === -1">
                        <div class="flex flex-col">
                            <span class="font-semibold pt-2"> Company level access</span>
                            <mat-radio-group class="flex flex-row" [color]="'primary'"
                                [formControlName]="'company_type'">
                                <mat-radio-button class="mr-2" [value]="'include'">
                                    Allowed Companies <span
                                        *ngIf="!contactForm.get('companies').value.includes('*') && contactForm.get('company_type').value === 'include'">({{contactForm.get('companies').value.length}})</span>
                                </mat-radio-button>
                                <mat-radio-button class="mr-2" [value]="'exclude'">
                                    Denied Companies <span
                                        *ngIf="!contactForm.get('companies').value.includes('*') && contactForm.get('company_type').value === 'exclude'">({{contactForm.get('companies').value.length}})</span>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'"
                            *ngIf="contactForm.get('company_type').value">
                            <mat-label>Choose Company</mat-label>
                            <mat-select id="IncCmp" [multiple]="true" [formControlName]="'companies'"
                                (selectionChange)="contactForm.get('companies').setValue(($event.value.includes('*')) ? ['*'] : $event.value);">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="includeControl"
                                        [placeholderLabel]="'Search Company'"
                                        [noEntriesFoundLabel]="'No matching company found'"
                                        [searching]="searching"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option [matTooltip]="'All Companies'" value="*"
                                    *ngIf="contactForm.get('company_type').value === 'include'">All
                                    Companies</mat-option>
                                <mat-option [matTooltip]="company.name"
                                    *ngFor="let company of sourceCompany | search: includeControl.value"
                                    [value]="company.id">
                                    {{company.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                </div>
                <div
                    class="flex items-center mt-10 py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t bg-gray-50 dark:bg-transparent">
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'" type="button" (click)="uView = 'table'">
                        Cancel
                    </button>
                    <button class="ml-2" mat-flat-button [color]="'primary'" type="button"
                        [disabled]="contactForm.invalid" (click)="saveUser()" [matTooltip]="'Save'">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </ng-container>
</div>
<s-modal id="userRoleEdit">
    <mat-card class="modal w-[600px]">
        <mat-card-header>
            <mat-card-title class="ml-4 font-bold">
                <div class="flex flex-col mb-4">
                    <div class="flex flex-row items-center">
                        <span class="text-medium text-secondary mr-2">User Name:</span>
                        <span class="text-medium font-bold">{{currentUser.first_name}} {{currentUser.last_name}}</span>
                    </div>
                    <div class="flex flex-row items-center">
                        <span class="text-medium text-secondary mr-2">Email:</span>
                        <span class="text-medium font-bold">{{currentUser.email}}</span>
                    </div>
                </div>

            </mat-card-title>
            <div class="spacer">&nbsp;</div>
            <button class=" mini-form-field" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="modalService.close('userRoleEdit');">
                <mat-icon>close</mat-icon>
            </button>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content fuseScrollbar>
            <form class="flex flex-col p-4 overflow-hidden" #updateForm="ngForm">
                <mat-form-field class="w-3/4 mt-4">
                    <mat-label>Select Roles</mat-label>
                    <mat-select [multiple]="true" [(ngModel)]="currentUser.roles" name="roles" required
                        (selectionChange)="currentUser.roles = ($event.value.includes('admin')) ? ['admin'] : $event.value;"
                        placeholder="choose roles">
                        <mat-option disabled *ngIf="roles.length === 0">
                            <span class="dark:text-white">No Roles present</span>
                        </mat-option>
                        <mat-option *ngFor="let role of roles" [value]="role">{{role}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-container *ngIf="currentUser.roles && currentUser.roles.indexOf('admin') === -1">
                    <div class="flex flex-col mt-8 gt-sm:mt-0 gt-sm:ml-16">
                        <span class="font-semibold mb-2"> Company level access</span>
                        <mat-radio-group class="flex flex-row" [color]="'primary'" required name="aclCompanies"
                            [(ngModel)]="currentUser.company_type">
                            <mat-radio-button class="mr-2" [value]="'include'">
                                Allowed Companies <span
                                    *ngIf="!currentUser.companies.includes('*') && currentUser.company_type === 'include'">({{currentUser.companies.length}})</span>
                            </mat-radio-button>
                            <mat-radio-button class="mr-2" [value]="'exclude'">
                                Denied Companies <span
                                    *ngIf="!currentUser.companies.includes('*') && currentUser.company_type === 'exclude'">({{currentUser.companies.length}})</span>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <mat-form-field class="w-3/4 mt-4" [subscriptSizing]="'dynamic'" *ngIf="currentUser.company_type">
                        <mat-label>Choose Company</mat-label>
                        <mat-select name="Company" id="IncCmp" required [multiple]="true"
                            [(ngModel)]="currentUser.companies"
                            (selectionChange)="currentUser.companies = ($event.value.includes('*')) ? ['*'] : $event.value;">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="includeControl"
                                    [placeholderLabel]="'Search Company'"
                                    [noEntriesFoundLabel]="'No matching company found'"
                                    [searching]="searching"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option [matTooltip]="'All Companies'" value="*"
                                *ngIf="currentUser.company_type === 'include'">All Companies</mat-option>
                            <mat-option [matTooltip]="company.name"
                                *ngFor="let company of sourceCompany | search: includeControl.value"
                                [value]="company.id">
                                {{company.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
                <div class="flex items-center mt-10 -mx-6  p-4 border-t dark:bg-transparent">
                    <!-- Cancel -->
                    <button class="ml-auto" mat-button type="button" [matTooltip]="'Cancel'"
                        (click)="modalService.close('userRoleEdit')">
                        Cancel
                    </button>
                    <!-- Save -->
                    <button class="ml-2" mat-flat-button type="button" [color]="'primary'" appPreventMultiClick
                        [matTooltip]="'Save'" (click)="updateUserRoles()" [disabled]="updateForm.invalid">
                        Update
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</s-modal>

<div class="flex flex-wrap flex-auto items-center rounded-2xl m-4" *ngIf="showFilter">
    <ejs-querybuilder #querybuilder class="row" width="100%">
        <e-columns>
            <ng-container *ngFor="let c of sColumns; let i = index">
                <e-column [field]="c.columnDef" [label]="c.header" type="string" [operators]="customOperators" *ngIf="c.isDropdown">
                    <ng-template #template let-data>
                        <ejs-dropdownlist [dataSource]='getDataSourceValues(c.dataSource)' [value]='data.rule.value' (change)="filterChange($event, data.ruleID, c.dataSource)">
                        </ejs-dropdownlist>
                    </ng-template>
                </e-column>
                <e-column [field]="c.columnDef" [label]="c.header" type="string" [operators]="customOperators" *ngIf="c.isCheckbox">
                    <ng-template #template let-data>
                        <ejs-checkbox [label]="c.header" [checked]='data.rule.value === c.successValue ? true: false' (change)="checkboxChange($event, data.ruleID)">
                        </ejs-checkbox>
                    </ng-template>
                </e-column>
                <e-column [field]="c.columnDef" [label]="c.header" type="boolean" [values]="c.dataSource" *ngIf="c.radiobutton"
                ></e-column>
                <e-column [field]="c.columnDef" [label]="c.header" type="date" [format]="c.header" *ngIf="c.datePicker"></e-column>
                <e-column  [field]="c.columnDef" [label]="c.header" *ngIf="!c.isDropdown && !c.isCheckbox && !c.radiobutton && !c.datePicker"
                    [type]="c.cType"></e-column>
                
            </ng-container>
        </e-columns>
    </ejs-querybuilder>
    <div class="flex flex-row items-center mt-4">
        <button class="e-btn e-primary e-qb-button" (click)="setRules()">Submit</button>
        <button class="e-btn e-warning e-qb-button mx-2" (click)="resetRules()">Reset</button>
        <button class="e-btn e-secondary e-qb-button mx-2" (click)="toggleFilter()">Close</button>
    </div>
</div>

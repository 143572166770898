import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/material.module';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { Router } from '@angular/router';
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from "../../../_services/loader.service";

@Component({
  selector: 'app-getting-started',
  standalone: true,
  imports: [CommonModule, MaterialModule, AppFilterPipeModule],
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss']
})
export class GettingStartedComponent {
  gettingStartedList: any = [];
  docLinks: any = [];
  constructor(private router: Router, private bs: BaseRequestService, private ls: LoaderService) {
    this.gettingStartedList = [
      {
        title: 'Tenant Info', // @ts-ignore
        description:
          `<div class="flex flex-row gap-2 mt-2"> <span class="text-[#22d3ee] w-[100px]">Tenant Name :  </span><strong class="text-[#e879f9] font-mono">${this.bs.user().tenant_name}</strong></div>\n` +
          `<div class="flex flex-row gap-2"> <span class="text-[#22d3ee] w-[100px]" >Base URL : </span><strong class="text-[#e879f9] font-mono">${window.location.host}</strong></div>\n` +
          `<div class="flex flex-row gap-2"> <span class="text-[#22d3ee] w-[100px]" >POD : </span><strong class="text-[#e879f9] font-mono uppercase">${localStorage.getItem('_pid')}</strong></div>\n`,
        highlight: '',
        linkDesc: 'Check out the ',
        linkText: 'V4 Docs',
        link: 'https://cybercns.atlassian.net/wiki/x/JgBseg',
        img: '/assets/getting-started/code.png',
        time: '',
      },
      {
        title: 'Welcome to ConnectSecure',
        description: `<div class="mt-2 text-medium font-medium">Discover: </div>
        <div class="mt-0.5 line-clamp-2 text-secondary">Automatically discover all your Network assets with our deep asset discovery capability</div>
        <div class="mt-2 text-medium font-medium">Optimize: </div>
        <div class="mt-0.5 line-clamp-2 text-secondary"> Monitor your Network Assets for Uptime, Configuration issues and much more.</div>
        <div class="mt-2 text-medium font-medium">Secure: </div>
        <div class="mt-0.5 line-clamp-2 text-secondary">Track Network Vulnerabilities and Exploits including support for Active Directory, Office 365 and more.</div>
        <div class="mt-0.5 line-clamp-2 text-secondary">You can also submit a support request email to
            <a class="text-primary" href="mailto:support@connectsecure.com" target="_top"><strong>support@connectsecure.com</strong></a>
        </div>`,
        highlight: '',
        img: '/assets/getting-started/welcome.png',
        time: '',
      },
      /*{
        title: 'Scan your external attack surface',
        description: '<div class="mt-0.5 line-clamp-2 text-secondary">Add your websites and internet-facing IP addresses and CyberCNS will scan them from external scanners installed on the CyberCNS instance.</div>',
        highlight: 'Ideal for company websites, VPN servers, and firewalls.',
        link: 'https://cybercns.atlassian.net/l/cp/S0w1NSon',
        linkDesc: 'Learn more about ',
        linkText: 'External Scans',
        img: '/assets/getting-started/worldwide.png',
        time: '',
        button: 'ScanNow'
      },
      {
        title: 'Connect a device directly',
        description: '<div class="mt-0.5 line-clamp-2 text-secondary">CyberCNS Light Weight Agent monitors single endpoint from anywhere and offers the most in-depth scans with zero configuration.</div>',
        highlight: 'Ideal for mobile or isolated assets, such as laptops and cloud servers',
        link: 'https://cybercns.atlassian.net/l/c/dg0013zX',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Lightweight Agents',
        img: '/assets/getting-started/key.png',
        time: '',
        button: 'ConnectNow'
      },
      {
        title: 'Connect your Network Devices',
        description: '<div class="mt-0.5 line-clamp-2 text-secondary">CyberCNS Probe/Agent monitors all networks and all connected assets including IoT, networking gear, and other unmanaged assets.</div>',
        highlight: 'Deploy on servers, workstations, or as a virtual appliance',
        link: 'https://cybercns.atlassian.net/l/c/jPRMNiRY',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Probes/Agents',
        img: '/assets/getting-started/agents.png',
        time: '',
        button: 'ConnectNow'
      },
      {
        title: 'Connect your Active Directory',
        description: '<div class="mt-0.5 line-clamp-2 text-secondary">The Active Directory Scan is performed using the SMB protocol. Once the scan is initiated, it will collect relevant data from the Active Directory server and send that to your CyberCNS instance</div>',
        highlight: 'Deploy it on Domain Controllers, servers, or workstations',
        link: 'https://cybercns.atlassian.net/l/c/iZXsztjG',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Active Directory SCAN',
        img: '/assets/getting-started/ad.png',
        time: '',
        button: 'ConnectAD'
      },
      {
        title: 'Integrate with many providers, vendors',
        description: '<div class="mt-0.5 line-clamp-2 text-secondary">CyberCNS supports PSA Integrations such as ConnectWise, AutoTask & SyncroMSP to help integrate to ticketing systems, and other third-party products, (email and such)</div>',
        highlight: 'Other integrations like Jira, Microsoft Teams, Email, Slack, Generic Webhook',
        link: 'https://cybercns.atlassian.net/l/c/Et0kQADk',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Integrations',
        img: '/assets/getting-started/worldwide.png',
        time: '',
        button: 'ConnectInt'
      },
      {
        title: 'View Remediation Plan',
        description: '<div class="mt-0.5 line-clamp-2 text-secondary">CyberCNS remediation plan is generated based on successful scans performed on your Assets. This plan helps the security team to plan your next steps for securing the assets.</div>',
        highlight: 'Missing security patches for the operating system and latest application versions',
        link: 'https://cybercns.atlassian.net/l/c/6fhZ9cDP',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Remediation Plan',
        img: '/assets/getting-started/bug.png',
        time: '',
        button: 'RemediationPlan'
      },
      {
        title: 'Patching',
        description: '<div class="mt-0.5 line-clamp-2 text-secondary">CyberCNS has capability to patch 3rd party applications using the Remediation plan.</div>',
        highlight: 'Security patches for the operating system and latest application versions',
        link: 'https://cybercns.atlassian.net/l/c/6fhZ9cDP',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Patching',
        img: '/assets/getting-started/bug.png',
        time: '',
        button: 'Patching'
      },
      {
        title: 'Create Notification Rules',
        description: '<div class="mt-0.5 line-clamp-2 text-secondary">In CyberCNS, you can get notified of certain conditions based on your rule-set. This user-defined rule can be applied at the Company level or Globally.</div>',
        highlight: 'Get notified using the notification rule-set',
        link: 'https://cybercns.atlassian.net/l/c/aHUdJQxv',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Notification Rules',
        img: '/assets/getting-started/reminder.png',
        time: '',
        button: 'NotificationRule'
      },
      {
        title: 'Manage Application Baseline',
        description: '<div class="mt-0.5 line-clamp-2 text-secondary">CyberCNS can help you define a Mandatory or Denied list of application(s) at the Company level and report non compliant applications, based on the rule-set.</div>',
        highlight: 'Define a Mandatory or Denied list of application(s)',
        link: 'https://cybercns.atlassian.net/l/c/H2Zu7kLV',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Application Baseline',
        img: '/assets/getting-started/application.png',
        time: '',
        button: 'ApplicationBaseline'
      },
      {
        title: 'Manage Users',
        description: '<div class="mt-0.5 line-clamp-2 text-secondary">The User view allows creating User logins for all your customer support and security specialists. It can also define the role they will play (eg. User or administrator).</div>',
        highlight: 'Assign RBAC to users',
        link: 'https://cybercns.atlassian.net/l/c/3jX0GX3m',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Users',
        img: '/assets/getting-started/users.png',
        time: '',
        button: 'Users'
      },

      {
        title: 'Reports and Report Builder',
        description: '<div class="mt-0.5 line-clamp-2 text-secondary">CyberCNS provides very exhaustive reports in various usable formats such as Excel, Word, Powerpoint. CyberCNS Report Builder allows you to build a report with different templates.</div>',
        highlight: 'Generate, Schedule Reports',
        link: 'https://cybercns.atlassian.net/l/c/ZWm2NS9t',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Reports',
        img: '/assets/getting-started/report.png',
        time: '',
        button: 'ReportBuilder'
      },
      {
        title: 'Get support',
        description: `<div class="mt-0.5 line-clamp-2 text-secondary">Visit our Knowledge Base for detailed instructions, FAQs, and more.</div>
        <div class="mt-0.5 line-clamp-2 text-secondary">You can also submit a support request email to</div>
        <a class="text-primary" href="mailto:support@connectsecure.com" target="_top">support@connectsecure.com</a>`,
        highlight: '',
        link: 'https://cybercns.atlassian.net/wiki/spaces/Verison2/pages/1569947788/CyberCNS+V2+Overview',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Knowledge Base',
        img: '/assets/getting-started/support.png',
        time: '',
      },*/
    ];
  }

  ngOnInit(): void {
    this.getDocLinks();
  }

  getDocLinks(): void {
    this.ls.display(true);
    this.bs.doRequest(`${this.bs.configURL}/getDocLinks`, 'get').subscribe((result: any) => {
      this.ls.display(false);
      this.docLinks = result.data;
    });
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
  
  reDirect(url: any): void {
    window.open(url);
  }
}

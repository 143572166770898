import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule, NgFor, NgIf, NgOptimizedImage } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { fuseAnimations } from '@fuse/animations';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { AppFilterPipeModule } from 'app/_filters/app.filter-pipe.module';
import { MaterialModule } from 'app/material.module';
import { ChartsComponent } from '../../../shared/charts/charts.component';
import { GridsComponent } from '../../../shared/grids/grids.component';
import { KeyPairsComponent } from '../../../shared/key-pairs/key-pairs.component';
import { ShowMoreLessComponent } from '../../../shared/show-more-less/show-more-less.component';
import { TableComponent } from '../../../shared/table/table.component';
import { ImportIntegrationCredentialsComponent } from './integration-credentials/integration-credentials.component';
import { ImportCompanyMappingComponent } from './import-company-mapping/import-company-mapping.component';
import { SkeletonComponent } from 'app/modules/shared/skeleton/skeleton.component';

@Component({
  selector: 'app-import-company',
  standalone: true,
  imports: [
    CommonModule,
    GridsComponent,
    KeyPairsComponent,
    ChartsComponent,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MaterialModule,
    NgOptimizedImage,
    AppFilterPipeModule,
    ShowMoreLessComponent,
    TableComponent,
    MatSidenavModule,
    FuseScrollbarDirective,
    ImportIntegrationCredentialsComponent,
    ImportCompanyMappingComponent,
    SkeletonComponent,
    FormsModule,
    NgFor, NgIf
  ],
  animations: fuseAnimations,
  templateUrl: './import-company.component.html',
  styleUrls: ['./import-company.component.scss']
})
export class ImportCompanyComponent implements OnInit, OnDestroy {
  @ViewChild('integrationOverview', { static: false }) integrationOverview: ElementRef;
  cIntegration: any = undefined;
  integrations: any = [];
  integrationTypeHash: any = {};
  public integrationSearch: any = "";
  categoryList: any = [];
  subs: Subscription;
  selectedIndex = 0;
  integrationCred: any = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private cs: CommonService,
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private _bs: BaseRequestService,
    private toast: MyToastrService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {

  }
  ngOnInit(): void {
    this.getAllIntegrations();
  }
  /**
     * On destroy
     */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  getAllIntegrations(): void {
    this.loaderService.display(true);
    this._bs.doRequest(`r/integration/get_integrations`, "get")
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.loaderService.display(false);
        this.integrations = [];
        if (result.status) {
          this.integrations = result.data.filter((x: any) => x.integrationtype === 'psa');
        } else {
          this.toast.sToast("error", "Integration not found!");
        }
        this._changeDetectorRef.detectChanges();
      });
  }

  setCIntegration($event: any): void {
    this.cIntegration = undefined;
    this.cIntegration = $event;
    setTimeout(() => {
      this.integrationOverview.nativeElement.scrollTo(0, 0);
    }, 100);
  }

  toggleChange($event: any): void {
    this.cIntegration = undefined;
  }
  updateCredFn($event: any): void {
    this.integrationCred = $event.result;
  }

  // getCompany($event?: any): void {
  //   const params: any = {
  //     integration_id: this.selectedCred,
  //     integration_name: this.cIntegration.name,
  //     action_name: 'get_companies',
  //     requestparams: {}
  //   };
  //   (this.companyParams) ? params.requestparams = this.companyParams : {};
  //   this.comMapForm.reset();
  //   this._cs.executeAction(params).then((item: any) => {
  //     if (item.status) {
  //       this.destCompany = item.data;
  //     }
  //   });
  // }
}
